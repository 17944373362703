import React, { Component } from 'react';
import { Route } from 'react-router';
import { withRouter, Redirect } from 'react-router-dom';
import { DocumentContent } from './DocumentContent';

import { Product } from './Products';
import { Login } from './Login';
import { CheckOut } from './CheckOut';
import { InvoiceOrderSearch } from './InvoiceOrderSearch';
import { OrderSearch } from './OrderSearch'
import { Budget } from './Budget'
import { AdminUser } from './AdminUser'
import { Users } from './Users';
import { ClearCartPrompt } from './ClearCartPrompt';
import { OrderApproval } from './OrderApproval'
import { SessionTimedOut } from './SessionTimedOut';
import { DocumentsManage } from './DocumentsManage';
import { AdminManage } from './AdminManage';

export class SavedCarts extends Component {
    displayName = SavedCarts.name

    constructor(props) {
        super(props);

        this.state = {
            CartHeaderVisibility: 'hidden',
            CartContentVisibility: 'hidden',

            userDetails: {},
            CartHeaderWidth: '100%',
            CartContentWidth: '0%',

            loadingCartHeaders: false,
            loadingCart: false,

            cart: [],
            cartHeaders: [],

            ToDocument: false,
            ToDocumentContent: false,

            toCheckOut: false,
            toSaveCart: false,
            toManage: false,
            toDocuments: false,
            toClearCartPrompt: false,
            toOrderForm: false,
            toAdmin: false,
            toLogIn: false,
            toBudgets: false,
            toOrderSearch: false,
            toOrderApproval: false,
            toSavedCarts: false,
            isAdminVisibile: 'hidden',
            toProducts: false,
            canTrack: 'hidden',
            toSessionTimedOut: false,
            toDocumentsManage: false,

        };

        this.CheckSessionInitialize();
    }

    CheckSessionInitialize() {
        this.setState({ complete_update: false });
        console.log('get CheckSession called');

        fetch('api/Login/CheckSession?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });
                    this.GetUserDetails();
                    this.GetCartHeaders();
                    this.CheckIfAdmin();
                    this.CheckIfCanTrack();
                }
                else if (data == 'timedout'){
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }

    CheckSession() {
        this.setState({ complete_update: false });
        console.log('get CheckSession called');

        fetch('api/Login/CheckSession?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });
                }
                else if (data == 'timedout') {
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }


    ProductLink() {
        this.setState({ toProductsPage: true });
    }


    GetCartHeaders() {

        this.setState({ loadingCartHeaders: true });

        fetch('api/SaveCart/GetCartHeaders', { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.json())
            .then(data => {
                this.setState({ cartHeaders: data, loadingCartHeaders: false });
            })
    }

    GetCart(cartId) {

        this.setState({ loadingCart: true });
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            fetch('api/SaveCart/GetCartContent?cartId=' + cartId, { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
                .then(response => response.json())
                .then(data => {
                    this.setState({ cart: data, loadingCart: false });
                });
        }
    }

    RemoveCart(cartId) {

        this.setState({ loadingCart: true });
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            fetch('api/SaveCart/RemoveCart?cartId=' + cartId, { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
                .then(response => response.json())
                .then(data => {
                    this.setState({ cartHeaders: data, loadingCartHeaders: false });
                });
        }
    }

    LoadCart(cartId) {
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            fetch('api/SaveCart/LoadCartToList?cartId=' + cartId, { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
                .then(() => { this.setState({ toProducts: true }) });
        }
    }

    renderCartHeader() {
        console.log('loading cart Headers');
        if (this.state.loadingCartHeaders) {
            return (<tr><td>Loading</td></tr>);
        }
        else {
            return (this.state.cartHeaders.map(header =>
                <tr style={{ backgroundColor: 'white' }}>
                    <td className="sideBorder" style={{ padding: '0 20px', color: 'blue', border: 'black', cursor: 'pointer' }} onClick={() => { this.GetCart(header.cartId) }}>{header.cartId}</td>
                    <td className="sideBorder" style={{ padding: '0 20px' }}>{header.cartName}</td>
                    <td className="sideBorder" style={{ padding: '0 20px' }}> {header.customerID} </td>
                    <td className="sideBorder" style={{ padding: '0 20px', color: 'green', border: 'black', cursor: 'pointer' }} onClick={() => { this.LoadCart(header.cartId) }}>load</td>
                    <td className="sideBorder" style={{ padding: '0 20px', color: 'red', border: 'black', cursor: 'pointer' }} onClick={() => { this.RemoveCart(header.cartId) }}>delete</td>
                </tr>
            )
            );
        }

    }

    renderCart() {
        console.log('loading cart ');
        if (this.state.loadingCart) {
            return (<tr>
                <td>Loading</td>
                <td>Loading</td>
                <td>Loading</td>
                <td>Loading</td>
            </tr>);
        }
        else {
            return (this.state.cart.map(cart =>
                <tr style={{ backgroundColor: 'white' }}>
                    <td className="sideBorder" style={{ padding: '0 20px' }}>{cart.productID}</td>
                    <td className="sideBorder" style={{ padding: '0 20px' }}>{cart.qty}</td>
                    <td className="sideBorder" style={{ padding: '0 20px' }}>{cart.itemDescription}</td>
                    <td className="sideBorder" style={{ padding: '0 20px' }}>{cart.uomDescription}</td>
                </tr>
            )
            );
        }

    }
    render() {

        //Route Changers
        if (this.state.toSessionTimedOut) {
            return <Route path='/' component={SessionTimedOut} />
        }

        if (this.state.toAdmin) {
            return <Route path='/' component={AdminManage} />
        }

        if (this.state.toDocumentsManage) {
            return <Route path='/' component={DocumentsManage} />
        }

        if (this.state.GoToOrderSearch) {
            return <Route path='/' component={OrderSearch} />
        }

        if (this.state.toBudgets) {
            return <Route path='/' component={Budget} />
        }

        if (this.state.toCheckOut) {
            return <Route path='/' component={CheckOut} />
        }

        if (this.state.toDocuments) {
            return <Route path='/' component={InvoiceOrderSearch} />
        }

        if (this.state.toOrderApproval) {
            return <Route path='/' component={OrderApproval} />
        }

        if (this.state.toLogIn) {
            return <Route path='/' component={Login} />
        }

        if (this.state.toProducts) {
            return <Route path='/' component={Product} />
        }

        if (this.state.toClearCartPrompt) {
            return <Route path='/' component={ClearCartPrompt} />
        }

        //end

        if (this.state.ToDocument) {
            return <Route path='/' component={DocumentContent} />
        }

        let cartHeaders = this.renderCartHeader();
        let cart = this.renderCart();

        return (
            <div style={{ top: '0', left: '0', height: '100%' }}>
                <div className="header" style={{ position: 'fixed', zIndex : '500'}}>
                    <div className="grid_container_header" style={{ display: 'inline-grid', gridTemplateColumns: '30% 40% 30%', display: 'grid -ms-grid', msGridColumns: '30% 40% 30%', columnCount: '3' }}>
                        <div className="grid_header">
                            <div className="icon_home" style={{ width: '100%', height: '50%', 'margin-top': '30px', 'margin-left': '10%', float: 'left', 'background-repeat': 'no-repeat' }}></div>
                        </div>
                        <div className="grid_header" style={{ 'text-align': 'left' }}>
                            <div className="header_container" style={{ width: '100%' }}>
                                <div>
                                    <div style={{ display: 'inline' }}>
                                        <a className="header_text" onClick={() => this.GoBackToProducts()}  >Place Order</a>|
                                                <a className="header_text" onClick={() => this.GoToDocumentsManage()} >Documents</a><span style={{ visibility: this.state.canTrack }}>|</span>
                                        <a className="header_text" onClick={() => this.GoToOrderApproval()} style={{ visibility: this.state.canTrack }} >Order Approval</a><span style={{ visibility: this.state.isAdminVisibile }}>|</span>
                                        <a className="header_text" onClick={() => this.GoToBudget()} style={{ visibility: 'hidden' }}  >Budgets</a>
                                        <span style={{ visibility: this.state.isAdminVisibile }}>|</span>
                                        <a className="header_text" onClick={() => this.GoToAdmin()} style={{ visibility: this.state.isAdminVisibile }} >Admin</a>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="grid_header">
                            <div className="header_container greeting" style={{ width: '100%' }}>
                                <div className="header_text grid_item_1" style={{ lineHeight: '40px', textAlign: 'right' }}>
                                    <div style={{ height: '28px' }} className="overflow_header"> {this.state.userDetails.greeting + ', ' + this.state.userDetails.fullname}</div>
                                    <div style={{ height: '25px' }} className="overflow_header">{this.state.userDetails.accountName}</div>
                                    <div style={{ height: '28px' }} className="overflow_header">Group : {this.state.userDetails.accountCode}</div>
                                </div>
                                <div className="grid_item_2"><a className="header_text" onClick={() => { this.GetCartState() }} id="A1">LOG OUT</a></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" sidenav customer_base_background" style={{ width: '100%', minHeight: '1000px' }}>
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <br />
                    </div>
                    <div style={{ padding: '20px', height: '100%', width: '100%' }}>
                        <div style={{ height: '100%', width: '100%' }}>
                            <div >
                                <hr />
                                <div><label className="text customer_background_foreground" style={{ fontFamily: 'TW Cen MT', fontSize: 'large', textAlign: 'center', width: '100%' }}>Saved Carts</label></div>
                                <hr />
                                <div style={{ width: '20%', marginLeft: '40%', marginRight: '40%' }}>
                                    <table className="table table-bordered scrollable-element" style={{ fontFamily: 'TW Cen MT', overflow: 'scroll', whiteSpace: 'nowrap' }}>
                                        <thead className="customer_background_foreground_reverse" >
                                            <th style={{ padding: '0 20px' }}> Cart Id</th>
                                            <th style={{ padding: '0 20px' }}> Cart Name</th>
                                            <th style={{ padding: '0 20px' }}> Customer Id</th>
                                            <th style={{ padding: '0 20px' }}></th>
                                        </thead>
                                        {cartHeaders}
                                    </table>
                                </div>
                            </div>
                            <div >
                                <hr />
                                <div><label className="text customer_background_foreground" style={{ fontFamily: 'TW Cen MT', fontSize: 'large', textAlign: 'center', width: '100%' }}>Content</label></div>
                                <hr />
                                <div style={{ width: '32%', marginLeft: '34%', marginRight: '34%' }}>
                                    <table className="table table-bordered scrollable-element" style={{ fontFamily: 'TW Cen MT', overflow: 'scroll', whiteSpace: 'nowrap' }}>
                                        <thead className="customer_background_foreground_reverse" >
                                            <th style={{ padding: '0 20px' }}> Product Id</th>
                                            <th style={{ padding: '0 20px' }}> Quantity</th>
                                            <th style={{ padding: '0 20px' }}> Item Description</th>
                                            <th style={{ padding: '0 20px' }}> UOM Description</th>
                                        </thead>
                                        {cart}
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        );
    }

    GoToDocumentsManage() {
        this.setState({ toDocumentsManage: true });
    }


    //Class Methods
    GetCartState() {

        fetch('/api/Product/GetCartCount', { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == "valued") {
                    this.setState({ toClearCartPrompt: true });
                } else {
                    window.location.href = '\\';
                }
            });
    }

    CheckIfAdmin() {
        fetch('/api/Login/isAdmin', { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => { this.setState({ isAdminVisibile: (data == 'Y' ? 'visible' : 'hidden') }, () => { console.log('visibility : data=' + data + ' value= ' + this.state.isAdminVisibile); }) })
    }

    GoToOrderSearch() {
        this.setState({ GoToOrderSearch: true });
    }

    GoToDocuments() {
        this.setState({ toDocuments: true });
    }

    toOrderForm() {
        this.setState({ toOrderForm: true });
    }

    GoToLogIn() {
        this.setState({ toLogIn: true });
    }

    GoToAdmin() {
        this.setState({ toAdmin: true });
    }

    GoBackToProducts() {
        this.setState({ toProducts: true });
    }

    GoToBudget() {
        this.setState({ toBudgets: true });
    }

    GoToOrderForm() {
        this.setState({ toOrderForms: true });
    }

    GoToOrderSearch() {
        this.setState({ GoToOrderSearch: true });
    }

    GoToOrderApproval() {
        this.setState({ toOrderApproval: true });
    }

    GoToSavedCarts() {
        this.setState({ toSavedCarts: true });
    }

    GetUserDetails() {
        fetch('api/Login/GetUserDetails', { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.json())
            .then(data => { this.setState({ userDetails: data }) })
            .then(() => {
                console.log("user details");
                console.log(this.state.userDetails);
            })
    }

    CheckIfCanTrack() {
        fetch('/api/Login/canTrack', { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => { this.setState({ canTrack: (data == 'Y' ? 'visible' : 'hidden') }, () => { console.log('visibility : data=' + data + ' value= ' + this.state.isAdminVisibile); }) })
    }
}