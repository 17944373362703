import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { Route } from 'react-router';
import { SessionTimedOut } from './SessionTimedOut';
import { ChangePassword } from './ChangePassword';

import { Budget } from './Budget';
import { Product } from './Products';
import { Login } from './Login';
import { CheckOut } from './CheckOut';
import { SavedCarts } from './SavedCarts';
import { InvoiceOrderSearch } from './InvoiceOrderSearch';
import { OrderSearch } from './OrderSearch'
import { AdminUser } from './AdminUser'
import { Users } from './Users';
import { ClearCartPrompt } from './ClearCartPrompt';
import { OrderApproval } from './OrderApproval';
import { DocumentsManage } from './DocumentsManage';
import { AdminManage } from './AdminManage';

export class BudgetCategories extends Component {
    displayName = BudgetCategories.name

    constructor(props) {
        super(props);
        this.state = {
            toProducts: false,
            budgetCatList: [],
            loadingBudgetCategories: false,
            listName: '',
            toClearCartPrompt: false,
            toSessionTimedOut: false,
            toChangePassword: false,
            menuVisibility: 'hidden',

            userDetails: {},

            toCheckOut: false,
            toSaveCart: false,
            toManage: false,
            toDocuments: false,
            toOrderForm: false,
            toAdmin: false,
            toLogIn: false,
            toBudgets: false,
            toOrderSearch: false,
            toOrderApproval: false,
            toSavedCarts: false,
            isAdminVisibile: 'hidden',
            canTrack: 'hidden',
            toSessionTimedOut: false,
            toBudget: false
        };
        this.CheckSessionInitialize();
    }


    ToChangePassword() {
        fetch('/api/Login/CanUpdatePassword' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(response => {

                if (response == 'true')
                    this.setState({ toChangePassword: true });
                else
                    alert('You currently do not have the required permissions to change your password.');

            });
    }

    MenuClick() {

        if (this.state.menuVisibility == 'hidden') {
            this.setState({ menuVisibility: 'visible' });
        }
        else if (this.state.menuVisibility == 'visible') {
            this.setState({ menuVisibility: 'hidden' });
        }
    }

    CheckSessionInitialize() {
        this.setState({ complete_update: false });
        console.log('get CheckSession called');

        fetch('api/Login/CheckSession?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });
                    this.GetBudgetCategories();
                    this.CheckIfAdmin();
                    this.CheckIfCanTrack();
                }
                else if (data == 'timedout'){
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }

    CheckSession() {
        this.setState({ complete_update: false });
        console.log('get CheckSession called');

        fetch('api/Login/CheckSession?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });
                }
                else if (data == 'timedout'){
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }

    GoBackToProducts() {
        this.setState({ toProducts: true });
    }

    GetBudgetCategories() {

        this.setState({ loadingBudgets: true });
        console.log('loading budget list');

        fetch('/api/Budget/GetCategories' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.json())
            .then(data => {
                this.setState({ budgetCatList: data },
                    () => {
                        this.setState({ loadingBudgetCategories: false });
                        console.log('budget category list :');
                        console.log(this.state.budgetCatList);
                    })
            })
    }

    handleCheckedCategory(e) {
        let checked = e.target.checked;
        let value = e.target.value;

        console.log('checked : ' + checked);
        console.log('value : ' + value);

        if (checked)
            this.AddCategory(value);
        else
            this.RemoveCategory(value);
    }

    AddCategory(code) {
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            fetch('/api/Budget/CheckCategory?code=' + code + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' });
        }
    }

    RemoveCategory(code) {
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            fetch('/api/Budget/UnCheckCategory?code=' + code + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' });
        }
    }

    ToBudget() {
        this.setState({ toBudget: true });
    }

    LoadBudgetCategoryTable() {

        if (this.state.loadingBudgetCategories) {
            return (
                <tr>
                    <td>loading...</td>
                    <td>loading...</td>
                    <td>loading...</td>
                </tr>
            );

        }
        else {
            return (
                this.state.budgetCatList.map(budgetCat =>
                    <tr>
                        <td className="sideBorder" style={{ lineHeight: '1px' }}>{budgetCat.code}</td>
                        <td className="sideBorder" style={{ lineHeight: '1px' }}>{budgetCat.description}</td>
                        <td className="sideBorder" style={{ lineHeight: '1px' }}>
                            <input type="checkbox" id={budgetCat.code} value={budgetCat.code} onChange={this.handleCheckedCategory.bind(this)} />
                        </td>
                    </tr>
                ));
        }

    }

    render() {

        //header routes
        if (this.state.toSessionTimedOut) {
            return <Route path='/' component={SessionTimedOut} />
        }

        if (this.state.toAdmin) {
            return <Route path='/' component={AdminManage} />
        }


        if (this.state.GoToOrderSearch) {
            return <Route path='/' component={OrderSearch} />
        }

        if (this.state.toSavedCarts) {
            return <Route path='/' component={SavedCarts} />
        }

        if (this.state.toCheckOut) {
            return <Route path='/' component={CheckOut} />
        }

        if (this.state.toDocuments) {
            return <Route path='/' component={InvoiceOrderSearch} />
        }

        if (this.state.toChangePassword) {
            return <Route path='/' component={ChangePassword} />

        }

        if (this.state.toOrderApproval) {
            return <Route path='/' component={OrderApproval} />
        }

        if (this.state.toLogIn) {
            return <Route path='/' component={Login} />
        }

        if (this.state.toDocumentsManage) {
            return <Route path='/' component={DocumentsManage} />
        }
        //////////////end

        if (this.state.toBudget) {
            return <Route path='/' component={Budget} />
        }

        if (this.state.toClearCartPrompt) {
            return <Route path='/' component={ClearCartPrompt} />
        }


        if (this.state.toProducts) {
            return <Route path='/' component={Product} />
        }
        else {
            let budgetCategories = this.LoadBudgetCategoryTable();
            return (
                <div className="outer-container customer_background " style={{ msHyphens: 'auto', width: '100%', background: 'url("/images/LithotechEye_Logo-blured.jpg")', 'background-repeat': 'no-repeat', 'object-fit': 'fill', 'background-size': 'cover' }}>
                    <div className="header" style={{ position: 'fixed', zIndex : '500'}}>
                        <div className="grid_container_header" style={{ display: 'inline-grid', gridTemplateColumns: '30% 40% 30%', display: 'grid -ms-grid', msGridColumns: '30% 40% 30%', columnCount: '3' }}>
                            <div className="grid_header">
                                <div className="icon_home" style={{ width: '100%', height: '50%', 'margin-top': '30px', 'margin-left': '10%', float: 'left', 'background-repeat': 'no-repeat' }}></div>
                            </div>
                            <div className="grid_header" style={{ 'text-align': 'left', overflow: 'hidden' }}>
                                <div className="header_container" style={{ width: '100%' }}>
                                    <div>
                                        <div style={{ display: 'inline' }}>
                                            <a className="header_text" onClick={() => this.GoBackToProducts()}  >Place Order</a>|
                                                <a className="header_text" onClick={() => this.GoToDocumentsManage()} >Documents</a><span style={{ visibility: this.state.canTrack }}>|</span>
                                            <a className="header_text" onClick={() => this.GoToOrderApproval()} style={{ visibility: this.state.canTrack }} >Order Approval</a><span style={{ visibility: this.state.isAdminVisibile }}>|</span>
                                            <a className="header_text" onClick={() => this.ToBudget()} >Budgets</a>
                                            <span style={{ visibility: this.state.isAdminVisibile }}>|</span>
                                            <a className="header_text" onClick={() => this.GoToAdmin()} style={{ visibility: this.state.isAdminVisibile }} >Admin</a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="grid_header">
                                <div className="header_container greeting" style={{ width: '100%' }}>
                                    <div className="header_text grid_item_1" style={{ lineHeight: '40px', textAlign: 'right' }}>
                                        <div style={{ height: '28px' }} className="overflow_header"> {this.state.userDetails.greeting + ', ' + this.state.userDetails.fullname}</div>
                                        <div style={{ height: '25px' }} className="overflow_header">{this.state.userDetails.accountName}</div>
                                        <div style={{ height: '28px' }} className="overflow_header">Group : {this.state.userDetails.accountCode}</div>
                                    </div>
                                    <div className="grid_item_2_reverse  h100">
                                        <div style={{ width: '100%', 'height': '100%' }}>
                                            <button id="btnMenu" onClick={() => this.MenuClick()} className="btn primary_btn header_btn twfont" style={{ lineHeight: '0.8', marginTop: '10px'  }}  ><i className="material-icons" style={{ width: '100%' }} >menu</i><label>Options</label></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="header_menu_container" style={{ visibility: this.state.menuVisibility}}>
                        <div className="grid_item_1 max-height-menu">
                            <button onClick={() => { this.MenuClick() }} style={{ width: '100%', height: '100%', backgroundColor: 'transparent', backgroundColor: 'transparent', border: 'none'}}></button>
                        </div>
                        <div className="header_menu grid_item_2 max-height-menu customer_background_foreground">
                            <hr />
                            <div>
                                <button id="canUpdateBtn" name="canUpdateBtn" className="btn  header_menu_item" onClick={() => { this.ToChangePassword() }}>Reset Password <i className="material-icons" style={{ position: 'relative', top: '6px', paddingLeft: '10px' }}>vpn_key</i></button>
                            </div>
                            <hr />
                            <div>
                                <button className="btn  header_menu_item" onClick={() => { this.GetCartState() }}>Logout <i className="material-icons" style={{ position: 'relative', top: '6px', paddingLeft: '10px' }}>exit_to_app</i></button>
                            </div>
                            <hr />
                        </div>
                    </div>
                    <div className=" sidenav customer_background_foreground" style={{ width: '100%', minHeight: '1000px', marginTop: '100px' }}>
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <br />
                            <div className="customer_background_foreground" style={{ width: '30%', margin: '0 35%', padding: '20px', verticalAlign: 'middle', height: '300px' }}>
                                <div>
                                    <label className="customer_background_foreground" style={{ fontFamily: 'Tw Cen MT', fontSize: 'large', fontWeight: 'bold', width: '100%', textAlign: 'center' }}> Add Categories</label>
                                    <hr />
                                </div>
                                <div>
                                    <label ID="lblMessage" ></label><br />
                                </div>
                                <div style={{ 'display': 'grid', 'grid-row-gap': '10px', width: '81%' }}>

                                    <table className="table table-bordered" style={{ fontFamily: 'TW Cen MT', display: 'block', overflowY: 'scroll', whiteSpace: 'nowrap', height: '400px', width: '80%', marginLeft: '20%', marginRight: '20%' }}>
                                        <thead className="customer_base_background">
                                            <th>Code</th>
                                            <th>Description</th>
                                            <th></th>
                                        </thead>
                                        {budgetCategories}
                                    </table>
                                </div >
                                <br />
                                <div style={{ width: '100%', display: 'grid' }}>
                                    <button ID="btnSubmit" className="btn primary_btn" style={{ width: '90%', 'font-family': 'Tw Cen MT' }} onClick={() => this.ToBudget()}>Save Budget Categories</button>
                                </div >
                            </div>
                        </div>
                    </div >
                </div>
            );
        }
    }

    //// Route Change

    GetCartState() {

        fetch('/api/Product/GetCartCount' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == "valued") {
                    this.setState({ toClearCartPrompt: true });
                } else {
                    window.location.href = '\\';
                }
            });
    }

    CheckIfAdmin() {
        fetch('/api/Login/isAdmin' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => { this.setState({ isAdminVisibile: (data == 'Y' ? 'visible' : 'hidden') }, () => { console.log('visibility : data=' + data + ' value= ' + this.state.isAdminVisibile); }) })
    }

    GoToOrderSearch() {
        this.setState({ GoToOrderSearch: true });
    }

    GoToDocuments() {
        this.setState({ toDocuments: true });
    }

    GoToLogIn() {
        this.setState({ toLogIn: true });
    }

    GoToAdmin() {
        this.setState({ toAdmin: true });
    }

    GoBackToProducts() {
        this.setState({ toProducts: true });
    }

    GoToBudget() {
        this.setState({ toBudgets: true });
    }

    GoToOrderForm() {
        this.setState({ toOrderForms: true });
    }

    GoToOrderSearch() {
        this.setState({ GoToOrderSearch: true });
    }

    GoToOrderApproval() {
        this.setState({ toOrderApproval: true });
    }

    GoToSavedCarts() {
        this.setState({ toSavedCarts: true });
    }

    GetUserDetails() {
        fetch('api/Login/GetUserDetails' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.json())
            .then(data => { this.setState({ userDetails: data }) })
            .then(() => {
                console.log("user details");
                console.log(this.state.userDetails);
            })
    }

    GoToDocumentsManage() {
        this.setState({ toDocumentsManage: true });
    }


    CheckIfCanTrack() {
        fetch('/api/Login/canTrack' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => { this.setState({ canTrack: (data == 'Y' ? 'visible' : 'hidden') }, () => { console.log('visibility : data=' + data + ' value= ' + this.state.isAdminVisibile); }) })
    }
}

