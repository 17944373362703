import React, { Component } from 'react';  import { withRouter, Redirect } from 'react-router-dom';
import { Product } from './Products';
import { Budget } from './Budget';
import { Route } from 'react-router';
import App from '../App';

export class ViewBudgetCategories extends Component {
    displayName = ViewBudgetCategories.name

    constructor(props) {
        super(props);


        this.state = {
            stylesheet: '',
            toProducts: false,
            budgetCatList: [],
            budgetCatListAll: [],
            loadingBudgetCategories: false,
            loadingBudgetCategoriesAll: false,
            listName: '',
            stylesheet: '',

            toBudget: false
        };

        this.GetBudgetCategories();
        this.GetBudgetCategoriesAll();
    }


    GoBackToProducts() {
        this.setState({ toProducts: true });
    }

    GetBudgetCategories() {

        this.setState({ loadingBudgetCategoriesAll: true });
        console.log('loading budget list');

        fetch('/api/Budget/GetBudgetCategoriesForBudget', { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.json())
            .then(data => {
                this.setState({ budgetCatList: data },
                    () => {
                        this.setState({ loadingBudgetCategoriesAll: false });
                        console.log('budget category list all:');
                        console.log(this.state.budgetCatListAll);
                    })
            })
    }

    GetBudgetCategoriesAll() {

        this.setState({ loadingBudgetsAll: true });
        console.log('loading budget list');

        fetch('/api/Budget/GetUnselectedCategories', { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.json())
            .then(data => {
                this.setState({ budgetCatListAll: data },
                    () => {
                        this.setState({ loadingBudgetCategoriesAll: false });
                        console.log('budget category list :');
                        console.log(this.state.budgetCatListAll);
                    })
            })
    }

    handleCheckedCategory(e) {
        let checked = e.target.checked;
        let value = e.target.value;

        console.log('checked : ' + checked);
        console.log('value : ' + value);

        if (checked)
            this.AddCategory(value);
        else
            this.RemoveCategory(value);

    }

    AddCategory(code) {
        fetch('/api/Budget/CheckCategory?code=' + code, { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' });

        this.GetBudgetCategoriesAll();
        this.GetBudgetCategories();
    }

    RemoveCategory(code) {
        fetch('/api/Budget/UnCheckCategory?code=' + code, { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' });
        this.GetBudgetCategoriesAll();
        this.GetBudgetCategories();
    }

    ToBudget() {
        this.setState({ toBudget: true });
    }

    LoadBudgetCategoryTable() {

        if (this.state.loadingBudgetCategories) {
            return (
                <tr>
                    <td>loading...</td>
                    <td>loading...</td>
                </tr>
            );

        }
        else {
            return (
                this.state.budgetCatList.map(budgetCat =>
                    <tr>
                        <td className="sideBorder " style={{ lineHeight: '1px' }}>{budgetCat.code}</td>
                        <td className="sideBorder" style={{ lineHeight: '1px' }}>{budgetCat.description}</td>
                        <td className="sideBorder" style={{ lineHeight: '1px', cursor: 'pointer', color: 'red' }} onClick={() => this.RemoveCategory(budgetCat.code)}>remove</td>
                    </tr>
                ));
        }

    }

    LoadBudgetCategoryTableAll() {

        if (this.state.loadingBudgetCategoriesAll) {
            return (
                <tr>
                    <td>loading...</td>
                    <td>loading...</td>
                </tr>
            );

        }
        else {
            return (
                this.state.budgetCatListAll.map(budgetCatAll =>
                    <tr>
                        <td className="sideBorder" style={{ lineHeight: '1px' }}>{budgetCatAll.code}</td>
                        <td className="sideBorder" style={{ lineHeight: '1px' }}>{budgetCatAll.description}</td>
                        <td className="sideBorder" style={{ lineHeight: '1px', cursor: 'pointer', color: 'green' }} onClick={() => this.AddCategory(budgetCatAll.code)}>add</td>
                    </tr>
                ));
        }

    }

    render() {

        if (this.state.toBudget) {
            return <Route path='/' component={Budget} />
        }

        if (this.state.toProducts) {
            return <Route path='/' component={Product} />
        }
        else {
            let budgetCategories = this.LoadBudgetCategoryTable();
            let budgetCategoriesAll = this.LoadBudgetCategoryTableAll();
            return (
                <div className="outer-container customer_background" >
                    <div className="header" style={{ position: 'fixed', zIndex : '500'}}>
                        <div className="grid_container_header" style={{ msHyphens: 'auto', display: 'inline-grid', gridTemplateColumns: '30% 40% 30%', display: 'grid -ms-grid', msGridColumns: '30% 40% 30%', columnCount: '3' }}>
                            <div className="grid_header">
                                <div className="icon_home" style={{ width: '100%', height: '50%', 'margin-top': '30px', 'margin-left': '10%', float: 'left', 'background-repeat': 'no-repeat' }}></div>
                            </div>
                            <div className="grid_header" style={{ 'text-align': 'left', overflow: 'hidden' }}>
                                <div className="header_container" style={{ width: '100%' }}>
                                    <div>
                                        <div style={{ display: 'inline' }}>
                                            <a className="header_text" onClick={() => this.GoBackToProducts()}>Go Back</a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="grid_header">
                                <div className="header_container" style={{ width: '100%' }}>
                                    <div>
                                        <div style={{ display: 'inline' }}>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" sidenav customer_background_foreground" style={{ width: '100%', minHeight: '1000px', marginTop: '100px' }}>
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <br />
                            <div className="customer_background_foreground" style={{ width: '30%', margin: '0 35%', padding: '20px', verticalAlign: 'middle', height: '300px' }}>
                                <div>
                                    <label className="customer_background_foreground" style={{ fontFamily: 'Tw Cen MT', fontSize: 'large', fontWeight: 'bold', width: '100%', textAlign: 'center' }}> View Categories</label>
                                    <hr />
                                </div>
                                <div>
                                    <label ID="lblMessage" style={{ paddingLeft: '20%' }}></label><br />
                                </div>
                                
                                <label className="twfont">Selected</label>
                                <table id="2" className="table table-bordered" style={{ fontFamily: 'TW Cen MT', overflowY: 'scroll', whiteSpace: 'nowrap', maxHeight: '400px', width: '80%', marginLeft: '10%', marginRight: '10%' }}>
                                    <thead className="customer_base_background">
                                        <th>Code</th>
                                        <th>Description</th>
                                    </thead>
                                    {budgetCategories}
                                </table>
                                <label className="twfont" >All</label>
                                <table id="2" className="table table-bordered" style={{ fontFamily: 'TW Cen MT', overflowY: 'scroll', whiteSpace: 'nowrap', maxHeight: '400px', width: '80%', marginLeft: '10%', marginRight: '10%' }}>
                                    <thead className="customer_base_background">
                                        <th>Code</th>
                                        <th>Description</th>
                                        <td ></td>
                                    </thead>
                                    {budgetCategoriesAll}
                                </table>
                                <div style={{ 'display': 'grid', 'grid-row-gap': '10px' }}>
                                    <div style={{ width: '100%', display: 'grid' }}>
                                        <button ID="btnCancel" className="btn secondary_btn" style={{ 'font-family': 'Tw Cen MT' }} onClick={() => this.ToBudget()}>Done</button>
                                        <br />
                                    </div >
                                </div>
                            </div >
                        </div>
                    </div >
                </div>
            );
        }
    }
}

