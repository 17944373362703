import React, { Component, ReactDOM, Ref, createRef } from 'react';
import { Login } from './Login';
import { CheckOut } from './CheckOut';
import { Route } from 'react-router';
import { SaveCart } from './SaveCart';
import { OrderFormList } from './OrderFormList';
import { InvoiceOrderSearch } from './InvoiceOrderSearch';
import { Manage } from './Manage';
import { Product } from './Products';
import { GetDefaultImageFolder } from '../registerServiceWorker';
import { withRouter, Redirect } from 'react-router-dom';
import { SessionTimedOut } from './SessionTimedOut';

export class OrderForm extends Component {
    displayName = OrderForm.name

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            logout: false,
            productDisplayType: 2,

            //Product Display type
            // 0 - Grid
            // 1 - List with images
            // 2 - List Table
            settingQuantity: false,
            products: [],
            brands: [],
            cartItems: [],
            itemQuantity: 0,
            selectedBrand: '',
            searchedText: '',
            loadingBrands: true,
            loadingCart: false,
            availableCartItems: false,
            loadingProducts: false,
            productCount: 0,

            shoppingCartWidth: '100%',
            productListWidth: '100%',

            closeCartButtonWidth: '50px',
            closeCartButtonPadding: '6px 12px',
            closeCartButtonVisibility: 'visible',

            openCartButtonWidth: '0',
            openCartButtonPadding: '0',
            openCartButtonVisibility: 'hidden',

            toCheckOut: false,
            toSaveCart: false,
            toManage: false,
            toInvoiceCredits: false,
            toOrderFormList: false,
            imagelocation: '',
            toSessionTimedOut: false,
        };

        this.CheckSessionInitialize();
    }

    CheckSessionInitialize() {
        this.setState({ complete_update: false });
        console.log('get CheckSession called');

        fetch('api/Login/CheckSession?buster=' +  new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });
                    this.GetDefaultImageFolder();
                    this.GetCartItems();
                    this.Getbrands();

                }
                else if (data == 'timedout'){
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }

    CheckSession() {
        this.setState({ complete_update: false });
        console.log('get CheckSession called');

        fetch('api/Login/CheckSession?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });
                }
                else if (data == 'timedout'){
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }


    GetDefaultImageFolder() {
        fetch('api/Global/GetDefaultImageLocation', { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                this.setState({ imagelocation: data }, () => {
                    console.log('default image location is: ' + this.state.imagelocation);
                })
            })
    }

    openShoppingCart() {

        console.log('Open navigator function called');

        this.setState({
            shoppingCartWidth: '30%',
            productListWidth: '70%',

            closeCartButtonWidth: '50px',
            closeCartButtonPadding: '6px 12px',
            closeCartButtonVisibility: 'visible',

            openCartButtonWidth: '0',
            openCartButtonPadding: '0',
            openCartButtonVisibility: 'hidden'
        })
    }

    closeShoppingCart() {

        console.log('Close navigator function called');

        this.setState({ shoppingCartWidth: '0%' });
        this.setState({ productListWidth: "100%" });

        this.setState({ openCartButtonWidth: "50px" });
        this.setState({ openCartButtonPadding: "6px 12px" });
        this.setState({ openCartButtonVisibility: "visible" });

        this.setState({ closeCartButtonWidth: "0" });
        this.setState({ closeCartButtonPadding: "0" });
        this.setState({ closeCartButtonVisibility: "hidden" });

    }

    handleSelectedBrand(e) {
        console.log('value : ' + e.target.value + '; text : ' + e.target.name);
        let { name, value } = e.target;
        this.setState({ selectedBrand: e.target.value }
            , () => {
                console.log(this.state.selectedBrand);

                if (this.state.selectedBrand == '') {
                    console.log('select brand is empty products');
                }
                else {
                    this.GetProducts();
                    console.log('getting products');
                }
            })
    }

    handleItemQuantity

    SelectBrand(value) {
        this.setState({ selectedBrand: value },
            () => { this.GetProducts() });

    }

    handleSearchBrand(e) {
        console.log('search value' + e.target.value);
        let inputtext = e.target.value;
        this.setState({ searchedText: inputtext });
    }

    Getbrands() {
        fetch('/api/Product/GetBrands' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.json())
            .then(data => { this.setState({ brands: data }) })
            .then(() => {
                this.setState({ loadingBrands: false });
                console.log(this.state.brands);
            })
    }

    GetProducts() {

        this.setState({ loadingProducts: true });
        console.log('selected brand : ' + this.state.selectedBrand);
        fetch('/api/Product/GetProducts?brandcode=' + this.state.selectedBrand + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.json())
            .then(data => { this.setState({ products: data, loadingProducts: true }); })
            .then(() => {
                this.setState({ loadingProducts: false });
                console.log('products have been logged GetProducts()');

            });

    }



    GetAutoForm() {

        this.setState({ loadingProducts: true });
        fetch('/api/Product/GetAutoOrderForm' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.json())
            .then(data => { this.setState({ products: data, loadingProducts: true }); })
            .then(() => {
                this.setState({ loadingProducts: false });
                console.log('products have been logged GetProducts()');

            });

    }


    SearchProducts() {
        this.setState({ products: [], loadingProducts: true });
        console.log('state : ' + this.state.searchedText);
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            fetch('/api/Product/SearchProducts?search=' + this.state.searchedText + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(response => response.json())
                .then(data => { this.setState({ products: data, loadingProducts: true }); })
                .then(() => {
                    this.setState({ loadingProducts: false });
                    console.log('products have been logged GetProducts()');

                });
        }
    }

    AddFormItem(id) {
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            this.setState({ loadingCart: true });

            fetch('/api/Orderform/CreateOrderFormContent?id=' + id + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(response => response.json())
                .then(data => { this.setState({ cartItems: data }) })
                .then(() => {
                    this.setState({ loadingCart: false });
                    console.log(this.state.cartItems);
                });
        }


    }

    SetNewQuantityProd(e) {
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            this.setState({ settingQuantity: true });

            console.log('itemId' + e.target.id + '   value: ' + e.target.value);
            let id = e.target.id;
            let quantity = e.target.value;

            fetch('/api/Product/SetNewQuantityProd?itemCode=' + id + '&quantity=' + quantity + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(() => {
                    this.setState({ settingQuantity: false });
                });
        }


    }

    SetNewQuantityCart(e) {
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            this.setState({ settingQuantity: true });

            console.log('itemId' + e.target.id + '   value: ' + e.target.value);
            let id = e.target.id;
            let quantity = e.target.value;

            fetch('/api/Product/SetNewQuantityCart?itemCode=' + id + '&quantity=' + quantity + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(() => {
                    this.setState({ settingQuantity: false });
                });
        }

    }

    Checkout() {
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            fetch('/api/Product/PlaceOrder' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(() => { this.setState({ toCheckOut: true }); });
        }
    }

    SaveCart() {
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            fetch('/api/Orderform/SaveOrderForm' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(() => { this.setState({ toOrderFormList: true }); });
        }
    }

    InvoiceCredits() {
        this.setState({ toInvoiceCredits: true });
    }

    Manage() {
        this.setState({ toManage: true });
    }

    OrderFormList() {
        this.setState({ toOrderFormList: true });
    }

    RemoveCartItem(id) {
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            this.setState({ loadingCart: true });

            fetch('/api/Orderform/DeleteOrderFormContent?productId=' + id + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(response => response.json())
                .then(data => { this.setState({ cartItems: data }) })
                .then(() => {
                    this.setState({ loadingCart: false });
                    console.log(this.state.cartItems);
                });
        }
    }

    RemoveAllCartItems() {
        this.setState({ loadingCart: true });
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            fetch('/api/Orderform/DeleteAllOrderFormContent' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(response => response.json())
                .then(data => { this.setState({ cartItems: data }) })
                .then(() => {
                    this.setState({ loadingCart: false });
                    console.log(this.state.cartItems);
                });
        }
    }

    LoadBrandToDropDown() {

        if (this.state.loadingBrands) {
            return (<option>loading...</option>);
        }
        else {
            return (this.state.brands.map(brand =>
                <div style={{ width: '100%' }}>
                    <button className="icon_add_to_cart" style={{ width: '85%', textAlign: 'left', fontWeight: '700', padding: '4px 10px', marginBottom: '5px' }} onClick={() => this.SelectBrand(brand.value)}>{brand.text}</button>
                </div>
            ));
        }
    }

    LoadProductsGrid() {

        let altImageLocation = this.state.imagelocation;
        if (this.state.loadingProducts) {
            return (<div className="inner-container">
                <div className="centered-content twfont  customer_background_foreground">
                    <div className="spinner-border" style={{ color: 'rgb(8, 15, 83)' }} role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </div>)
        }
        else {
            return (
                this.state.products.map(product =>
                    <div className="grid">
                        <div className="cardContainer #343A40 ">
                            <div id={product.itemCode} className="card customer_border_color" style={{ width: '100%', height: '100%' }}>
                                <div id="top" className="card-img-top customer_background_foreground" style={{ paddingTop: '5px', backgroundColor: 'whitesmoke', borderRadius: '0' }}>
                                    <div className="item_image underline-from-center">
                                        <div className="cardTop customer_background_foreground">
                                            <div style={{ 'padding-left': '10px', 'padding-top': '2px', 'width': '240px' }}>
                                                <label ID="imghotel" className="horizontal_overflow">{product.description}</label>
                                            </div>
                                            <a onClick={() => { this.AddFormItem(product.itemCode) }}>
                                                <i className="icon_add_to_cart material-icons" >add</i>
                                            </a>
                                        </div>

                                        <img type="image" style={{ backgroundColor: 'white' }} onError={(e) => { e.target.onerror = null; e.target.src = altImageLocation }} id="imProd" src={product.imageUrl} className="image_border border_bottom customer_tertiary_background" onClick={() => { this.AddCartItem(product.itemCode) }} />
                                    </div>
                                </div>
                                <div className="card-body customer_background_foreground" style={{ 'background-color': 'whitesmoke', width: '100%', display: 'inline-block', 'vertical-align': 'bottom', 'padding-bottom': '5px', 'padding-top': '5%', 'border-radius': '0' }}>
                                    <table className="cardTable" style={{ width: '100%', 'text-align': 'center', 'vertical-align': 'central' }}>
                                        <tr>
                                            <td style={{ 'font-weight': 'bold' }}>
                                                <label>R</label><label ID="lblPrice">{product.unitPrice}</label>
                                            </td>
                                            <td style={{ 'font-weight': 'bold' }}>
                                                <label style={{ 'margin-right': '3px' }}>QTY </label>
                                                <label id="lblPrice">{product.quantityOnHand}</label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label>Item Code</label>
                                            </td>
                                            <td>
                                                <label>{product.itemCode}</label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label>Unit Of Measure</label>
                                            </td>
                                            <td>
                                                <label>{product.uomdescription}</label>
                                            </td>
                                        </tr>
                                    </table >
                                </div >
                            </div >
                        </div>
                    </div>
                ));
        }

    }

    IncreaseSize(e) {
        console.log(e.handler);
        console.log(e.target);

        if (e.target.height != 200)
            e.currentTarget.style.height = '200px';
        else
            e.currentTarget.style.height = '50px';

        console.log('updated : ');
        console.log(e.target);
    }


    LoadProductsTable() {
        let altImageLocation = this.state.imagelocation;
        if (this.state.loadingProducts) {
            return (
                <tr>
                    <td>loading</td>
                </tr>
            )
        }
        else {
            let backcolor = 'white';

            return (this.state.products.map(product =>
                <tr style={{ lineHeight: '50px', borderBottom: 'solid 1px' }}>
                    <td style={{ width: '60px' }}><img type="image" className="table_image_border" style={{ backgroundColor: 'white' }} onClick={this.IncreaseSize.bind(this)} onError={(e) => { e.target.onerror = null; e.target.src = altImageLocation }} id="imProd" src={product.imageUrl} /></td>
                    <td>{product.itemCode}</td>
                    <td>{product.description}</td>
                    <td>{product.uomdescription}</td>
                    <td>{product.quantityOnHand}</td>
                    <td>R {Number(product.unitPrice).toFixed(2)}</td>
                    <td style={{ display: 'collapse' }}>{product.sysproProductID}</td>
                    <td><input id={product.itemCode} style={{ width: '60px' }} className="form-control" type="number" onChange={this.SetNewQuantityProd.bind(this)} defaultValue={product.quantityChosen} /></td>
                    <td><button className="btn " onClick={() => { this.AddFormItem(product.itemCode) }} style={{ cursor: 'pointer', height: '38px' }}><i class="icon_add_to_cart material-icons">add</i></button></td>
                </tr>
            ));
        }
    }

    LoadProducts() {
        if (this.state.productDisplayType == 0) {
            let productsGrid = this.LoadProductsGrid();

            return (
                <div id="GridContainer" className="gridContainer customer_secondary_background">
                    {productsGrid}
                </div>
            );
        }
        else if (this.state.productDisplayType == 1) {

        }
        else if (this.state.productDisplayType == 2) {
            let tableGrid = this.LoadProductsTable();

            return (
                <table className="customer_background_foreground" style={{ width: '100%' }}>
                    <tr className="customer_background_foreground_reverse">
                        <th></th>
                        <th>Item Code</th>
                        <th>Description</th>
                        <th>UOM</th>
                        <th>Available</th>
                        <th>Unit Price</th>
                        <th style={{ display: 'collapse' }}>Item Ref.</th>
                        <th>Quantity</th>
                        <th></th>
                    </tr>
                    {tableGrid}
                </table>
            );
        }
    }

    GetCartItems() {

        fetch('/api/OrderForm/IsAvailableItems' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.json())
            .then(data => {
                this.setState({ availableCartItems: data },
                    () => {

                        console.log('the available item status is = ');
                        console.log(this.state.availableCartItems);

                        if (this.state.availableCartItems == true) {
                            this.setState({ loadingCart: true });

                            fetch('/api/OrderForm/GetCartItems' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                                .then(response => response.json())
                                .then(data => { this.setState({ cartItems: data }) })
                                .then(() => {
                                    this.setState({ loadingCart: false });
                                    console.log(this.state.cartItems);
                                })
                        }
                    })
            });
    }


    LoadCartItems() {
        if (this.state.loadingCart) {
            return (<div className="inner-container">
                <div className="centered-content twfont  customer_background_foreground">
                    <div className="spinner-border" style={{ color: 'rgb(8, 15, 83)' }} role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </div>)

        }
        else {
            return (
                this.state.cartItems.map(cartItem =>
                    <div>
                        <div className="info-box" style={{ margin: '2% 3% 0 3%', width: '90%', height: '100px' }}>

                            <div className="content twfont" style={{ float: 'left', width: '80%', margin: '0' }}>
                                <div >
                                    <div>
                                        <div className="text twfont" style={{ fontWeight: 'bold' }}>{cartItem.id}</div>
                                        <div className="text twfont" style={{ width: '171px', height: '19.2px', overflow: 'hidden', textOverflow: 'ellipsis', fontWeight: 'bold' }}>{cartItem.description}</div>
                                        <div className="text twfont" style={{ fontWeight: 'bold' }}><input id={cartItem.id} style={{ width: '40px', height: '19.2px' }} type="number" onChange={this.SetNewQuantityCart.bind(this)} defaultValue={cartItem.quantity} /> : {cartItem.uom}</div>
                                        <div className="number count-to" style={{ marginTop: '1px' }} data-from="0" data-to="125" data-speed="1000" data-fresh-interval="20">R {Number(cartItem.price * cartItem.quantity).toFixed(2)}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="icon" style={{ backgroundColor: 'white', float: 'right' }}>
                                <a style={{ padding: '0' }} onClick={() => { this.RemoveCartItem(cartItem.id) }} Style="padding-left: 0">
                                    <i className="material-icons" style={{ color: 'red', fontSize: '30px' }}>delete</i>
                                </a>
                            </div>
                        </div>
                    </div>
                )
            )
        }
    }

    LogUserOut() {
        this.setState({ logout: true });
    }

    render() {
        if (this.state.toSessionTimedOut) {
            return <Route path='/' component={SessionTimedOut} />
        }

        if (this.state.toSaveCart) {
            return <Route path='/' component={Product} />
        }

        if (this.state.toOrderFormList) {
            return <Route path='/' component={OrderFormList} />
        }


        if (this.state.toManage) {
            return <Route path='/' component={Manage} />
        }

        if (this.state.toCheckOut) {
            return <Route path='/' component={CheckOut} />
        }

        if (this.state.toInvoiceCredits) {
            return <Route path='/' component={InvoiceOrderSearch} />
        }
        else {
            if (this.state.logout) {
                return <Route path='/' component={Login} />
            }
            else {
                let brandsDropDown = this.LoadBrandToDropDown();
                let products = this.LoadProducts();
                let cartItems = this.LoadCartItems();

                return (
                    <div style={{ top: '0', left: '0', height: '100%' }}>
                        <div className="header" style={{ position: 'fixed', zIndex : '500'}}>
                            <div className="grid_container_header" style={{ msHyphens: 'auto', display: 'inline-grid', gridTemplateColumns: '30% 40% 30%', display: 'grid -ms-grid', msGridColumns: '30% 40% 30%', columnCount: '3' }}>
                                <div className="grid_header">
                                    <div className="icon_home" style={{ width: '100%', height: '50%', 'margin-top': '30px', 'margin-left': '10%', float: 'left', 'background-repeat': 'no-repeat' }}></div>
                                </div>
                                <div className="grid_header" style={{ 'text-align': 'left', overflow: 'hidden' }}>
                                    <div className="header_container" style={{ width: '100%' }}>
                                        <div>
                                            <div style={{ display: 'inline' }}>
                                                <a className="header_text" onClick={() => this.OrderFormList()} >Go Back</a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="grid_header">
                                    <div className="header_container" style={{ width: '100%', 'padding-right': '15%' }}>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ height: '100%', width: '100%' }}>
                            <div className="product_container">
                                <div className="BrandList customer_base_background  ">
                                    <hr />
                                    <div id="Filters" style={{ 'border-color': '#2C3160', fontFamily: 'TW Cen MT' }}>
                                        <div>
                                            <label style={{ fontFamily: 'TW Cen MT', fontSize: 'x-large', padding: '10px' }}>Products</label>
                                            <div style={{ height: '100%', marginLeft: '10px', paddingBottom: '50px' }} className="customer_secondary_background">
                                                <br />
                                                <div style={{ height: '100%', paddingLeft: '10px' }}>
                                                    {brandsDropDown}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="productList" className="main_content_container" style={{ backgroundColor: '#138496', color: 'black' }} >
                                    <hr />
                                    <div className="order_form_header_grid">
                                        <div className="input-group mb-3 grid_item_1" >
                                            <input type="text" ID="txtMainSearch" className="form-control" placeholder="Search..." onChange={this.handleSearchBrand.bind(this)} />
                                            <div className="input-group-prepend">
                                                <button ID="btnMainSearch" Text="" className="btn btn-dark" style={{ height: '38px' }} ><i className="material-icons" onClick={() => this.SearchProducts()}>search</i></button>
                                                <a id="opencart" style={{ cursor: 'pointer', height: '38px', width: this.state.openCartButtonWidth, padding: this.state.openCartButtonPadding, visibility: this.state.openCartButtonVisibility, backgroundColor: 'black', color: 'white' }} onClick={() => this.openShoppingCart()} className="btn btn-dark"><i className="material-icons">shopping_cart</i></a>
                                                <a id="closecart" style={{ cursor: 'pointer', height: '38px', width: this.state.closeCartButtonWidth, padding: this.state.closeCartButtonPadding, visibility: this.state.closeCartButtonVisibility, backgroundColor: 'white', color: 'black' }} onClick={() => this.closeShoppingCart()} className="btn btn-dark"><i className="material-icons">shopping_cart</i></a>
                                            </div>
                                        </div>
                                        <div className="grid_item_2" style={{ textAlign: 'center', fontFamily: 'TW Cen MT', fontSize: 'x-large', color: 'white' }}>
                                            <label>Create Order Form</label>
                                        </div>
                                    </div>
                                    <hr />
                                    <div style={{ height: '100%', 'font-family': 'Tw Cen MT' }}>
                                        {products}
                                    </div >
                                </div>
                                <div id="shoppingCart" className="sidenav customer_background_foreground" style={{ width: '100%', border: 'none' }}>

                                    <div id="divRight" className="cart_container customer_base_background " style={{ padding: '10px' }}>
                                        <hr />
                                        <div className="shopping_cart_button_grid">
                                            <div className="grid_item_1">
                                                <button ID="btnCheckout" onClick={() => this.OrderFormList()} className="btn primary_btn" style={{ 'font-family': 'Tw Cen MT', width: '100%', margin: '3% 0 3%', border: 'solid 1px' }} >Cancel<i className="material-icons" style={{ position: 'absolute', paddingLeft: '10px' }}>block</i></button>
                                            </div>
                                            <div className="grid_item_2">
                                            </div>
                                            <div className="grid_item_3">
                                                <button ID="btnCancel" onClick={() => this.RemoveAllCartItems()} className="btn secondary_btn" style={{ 'margin-left': '10px', 'font-family': 'Tw Cen MT', width: '100%', margin: '3% 0 0' }}>Clear<i className="material-icons" style={{ position: 'absolute', paddingLeft: '10px' }}>block</i></button>
                                            </div>
                                        </div>
                                        <div style={{}}>
                                            <button ID="btnSave" onClick={() => this.SaveCart()} className="btn secondary_btn" style={{ 'font-family': 'Tw Cen MT', width: '100%', margin: '3% 0 0' }} >Save<i className="material-icons" style={{ position: 'absolute', paddingLeft: '10px' }}>save</i></button>
                                        </div>
                                        <div style={{ height: '100%' }} className="customer_secondary_background">

                                            <div>
                                                {cartItems}
                                            </div>
                                        </div >

                                    </div >


                                </div >
                            </div>
                        </div>
                    </div>
                );
            }
        }
    }
}

//<button id="filter_btn" className="customer_btn btn btn-outline-dark default_btn" style={{ width: '20%', 'font-family': 'Tw Cen MT' }} data-toggle="collapse" data-target="#Filters">Filters</button>

//    <button ID="GridButton" className="btn customer_background_foreground customer_btn" style={{ padding: '6px', height: '38px' }}>
//        <i className="material-icons">grid_on</i>
//    </button>

//    <button ID="ListButton" className="btn customer_background_foreground customer_btn" style={{ padding: '6px', height: '38px' }}>
//        <i className="material-icons">view_list</i>
//    </button >


//    <hr />


