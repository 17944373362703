import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Glyphicon, Nav, Navbar, NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';


export class NavMenu extends Component {
  displayName = NavMenu.name

  render() {
    return (
        <div class="header">
            <div className="grid_container_header" style={{ display: 'inline-grid', gridTemplateColumns: '30% 40% 30%', display: 'grid -ms-grid', msGridColumns: '30% 40% 30%', columnCount: '3' }}>
                <div class="grid_header">
                    <div class="icon_home"></div>
                </div>
                <div class="grid_header" style={{'text-align': 'left' }}>
                    <div class="header_container" style={{width: '100%'}}>
                        <div>
                            <div style={{display: 'inline'}}>
                                <a runat="server" class="header_text" href="Products.aspx">PLACE ORDER</a>|
                                <a runat="server" class="header_text" href="PreviousOrders.aspx">VIEW ORDER</a>|
                                <a runat="server" class="header_text" href="MyAccount.aspx">MANAGE</a>|
                                <a runat="server" class="header_text" href="OrderApproval.aspx" id="notification">NOTIFICATIONS</a>|
                                <a runat="server" class="header_text" href="Default.aspx" id="A1">LOG OUT</a>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="grid_header">
                    <div class="header_container" style={{ width: '100%', 'padding-right': '15%'}}>
                        <div class="input-group mb-3" style={{ 'margin-top': '9%', 'margin-right': '2%'}}>
                            <input type="text" ID="txtMainSearch" runat="server" class="form-control" placeholder="Search..."/>
                            <div class="input-group-prepend">
                                <button ID="btnMainSearch" Text="" class="btn btn-outline-secondary" Height="38px" runat="server"><i class="material-icons">search</i></button>
                                <span id="opencart" style={{cursor: 'pointer', height: '38px', width: '0', padding: '0', 'background-color' : 'black', color: 'white'}} onclick="openNav()" class="btn btn-dark"><i class="material-icons">shopping_cart</i></span>
                                <span id="closecart" style={{ cursor: 'pointer', height: '38px', 'background-color': 'white', color: 'black' }} onclick="closeNav()" class="btn btn-dark"><i class="material-icons">shopping_cart</i></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    }



    GetUserDetails() {
        fetch('api/Login/GetUserDetails', { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.json())
            .then(data => { this.setState({ userDetails: data }) })
            .then(() => {
                console.log("user details");
                console.log(this.state.userDetails);
            })
    }
}
