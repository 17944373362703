import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { Route } from 'react-router';
import { OrderSearch } from './OrderSearch'
import { SessionTimedOut } from './SessionTimedOut';
import { ChangePassword } from './ChangePassword';

import { DocumentContent } from './DocumentContent';
import { CreditContent } from './CreditContent';
import { Product } from './Products';
import { Login } from './Login';
import { CheckOut } from './CheckOut';
import { SavedCarts } from './SavedCarts';
import { InvoiceOrderSearch } from './InvoiceOrderSearch';
import { Budget } from './Budget'
import { AdminUser } from './AdminUser'
import { Users } from './Users';
import { ClearCartPrompt } from './ClearCartPrompt';
import { OrderApproval } from './OrderApproval';
import { DocumentsManage } from './DocumentsManage';
import { AdminManage } from './AdminManage';

export class OrderContent extends Component {
    displayName = OrderContent.name

    constructor(props) {
        super(props);

        this.state = {
            orderList: [],
            loadingOrders: false,
            orderHeader: {},
            loadingHeader: false,
            orderDate: '',
            deliveryDate: '',
            userDetails: {},
            ToList: false,
            toChangePassword: false,
            menuVisibility: 'hidden',

            ToOrderDocument: false,
            toCheckOut: false,
            toSaveCart: false,
            toManage: false,
            toDocuments: false,
            toOrderForm: false,
            toAdmin: false,
            toLogIn: false,
            toBudgets: false,
            toOrderSearch: false,
            toOrderApproval: false,
            toClearCartPrompt: false,
            toSavedCarts: false,
            isAdminVisibile: 'hidden',
            canTrack: 'hidden',
            ApproveVisibility: 'hidden',
            toProducts: false,
            toOrderApprovalButtonVisibility: 'hidden',
            toOrderSearchButtonVisibility: 'hidden',
            toOrderApprovalButtonWidth: '0%',
            toOrderSearchButtonWidth: '0%',
            declineVisibility: 'hidden',
            toSessionTimedOut: false,
            toDocumentsManage: false,

        };

        this.CheckSessionInitialize();
    }

    CheckSessionInitialize() {
        this.setState({ complete_update: false });
        console.log('get CheckSession called');

        fetch('api/Login/CheckSession?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });
                    this.GetPredecessor();
                    this.GetUserDetails();
                    this.GetOrderContentList();
                    this.CheckIfAdmin();
                    this.CheckIfCanTrack();
                    this.GetOrder();
                }
                else if (data == 'timedout'){
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }

    CheckSession() {
        this.setState({ complete_update: false });
        console.log('get CheckSession called');

        fetch('api/Login/CheckSession?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });
                }
                else if (data == 'timedout'){
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }

    GetPredecessor() {
        fetch('/api/InvoiceOrder/GetOrderContentPredecessor' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'OA') {
                    this.setState({
                        toOrderApprovalButtonVisibility: 'visible',
                        toOrderApprovalButtonWidth: '20%',
                        toOrderSearchButtonVisibility: 'hidden',
                        toOrderSearchButtonWidth: '0%',
                    });
                }
                else if (data == 'O') {
                    this.setState({
                        toOrderSearchButtonVisibility: 'visible',
                        toOrderSearchButtonWidth: '20%',
                        toOrderApprovalButtonVisibility: 'hidden',
                        toOrderApprovalButtonWidth: '0%',
                    });
                }
            })

    } 

    CheckIfAdmin() {
        fetch('/api/Login/isAdmin' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => { this.setState({ isAdminVisibile: (data == 'Y' ? 'visible' : 'hidden') }, () => { console.log('visibility : data=' + data + ' value= ' + this.state.isAdminVisibile); }) })
    }

    PrintOrder() {
        fetch('api/InvoiceOrder/PrintOrder?&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then((output) => {
                const url = "data:application/pdf;base64," + output;
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'file.pdf');
                document.body.appendChild(link);
                link.click();
            });
    }

    InvoiceCredits() {
        this.setState({ ToList: true });
    }

    GetOrder() {
        this.setState({ loadingHeader: true });
        console.log('loading ordrer ');

        fetch('/api/InvoiceOrder/GetCurrentOrder' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.json())
            .then(data => { this.setState({ orderHeader: data, loadingHeader: false, orderDate: data.orderDate, deliverDate: data.deliveryDate }, console.log(data)) })
            .then(() => {
                console.log("order");
                console.log(this.state.orderHeader);

                if (this.state.orderHeader.orderStatus == 'declined') {
                    this.setState({ declineVisibility: 'visible' });
                }
            })
    }

    GetOrderContentList() {
        this.setState({ loadingInvoice: true });
        console.log('loading order item list');

        fetch('/api/InvoiceOrder/GetOrderItems' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.json())
            .then(data => { this.setState({ orderList: data, loadingOrders: false }) });
    }

    LoadTableOrderItems() {

        if (this.state.loadingOrders) {
            return (
                <tr>
                    <td>loading...</td>
                    <td>loading...</td>
                    <td>loading...</td>
                    <td>loading...</td>
                    <td>loading...</td>
                    <td>loading...</td>
                    <td>loading...</td>
                    <td>loading...</td>
                </tr>
            );

        }
        else {
            return (
                this.state.orderList.map(orderItem =>
                    <tr style={{ lineHeight: '1px' }}>
                        <td style={{ textAlign: 'left' }}>{orderItem.productID}</td>
                        <td style={{ textAlign: 'left' }}>{orderItem.description}</td>
                        <td style={{ textAlign: 'left' }}>{orderItem.dateAdded}</td>
                        <td style={{ textAlign: 'left' }}>{orderItem.qty}</td>
                        <td style={{ textAlign: 'left'  }}>R {Number(orderItem.price).toFixed(2)}</td>
                        <td style={{ textAlign: 'left'  }}>R {Number(orderItem.price * orderItem.qty).toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        <td style={{ textAlign: 'left' }}>R {Number(orderItem.vat * (orderItem.qty * orderItem.price)/100).toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        <td style={{ textAlign: 'left'  }}>R {Number(orderItem.totalIncludingVAt).toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                    </tr>
                ));
        }

    }

    LoadOrder() {


        if (this.state.loadingHeader) {
            return (
                <div>
                    <div style={{ backgroundColor: 'white', height: '100px', display: 'inline-grid', gridTemplateColumns: '20% 80%', width: '100%' }}>
                        <div style={{ wordBreak: 'break-all' }}>
                            Loading
                    </div>
                        <div>
                        </div>
                    </div>
                    <div style={{ backgroundColor: 'white', height: '12%', width: '100%' }}>
                        <table style={{ width: '100%' }}>
                            <tr>
                                <td>CustomerCode : Loading...</td>
                                <td>Invoice No :  Loading...</td>
                                <td>Order No : Loading...</td>
                            </tr>
                            <tr>
                                <td>inv Date : Loading...</td>
                                <td>Delivery Date : Loading...</td>
                                <td>Web/Order Ref: Loading...</td></tr>
                            <tr>
                                <td>Your Order Ref : Loading...</td>
                                <td></td>
                                <td></td>
                            </tr>
                        </table>
                    </div>
                </div>);
        }
        else {
            let order = this.state.orderHeader;
            let orderdate = this.state.orderDate;
            let deliverydate = this.state.deliveryDate;
            let declineVisbile = '';


            let declineReason = this.state.orderHeader.reasonForDecline;

            return (
                <div>
                    <div style={{ backgroundColor: 'white', height: '12%', width: '100%' }}>
                        <table style={{ width: '100%' }}>
                            <tr>
                                <td>Customer Code : {order.customerID}</td>
                                <td>Customer Reference :  {order.customerRef}</td>
                                <td style={{ textAlign: 'right' }}>Total (Excluding VAT.) : </td>
                                <td style={{ textAlign: 'right' }}>R {Number(order.total).toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                            </tr>
                            <tr>
                                <td>Order Date : {orderdate}</td>
                                <td>Email Address : {order.emailAddress}</td>
                                <td style={{ textAlign: 'right' }}>Vat : </td>
                                <td style={{ textAlign: 'right' }}>R {Number(order.vat).toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                            </tr>
                            <tr>
                                <td>Syspro Reference : L{order.orderID}</td>
                                <td></td>
                                <td style={{ textAlign: 'right' }}>Total (Including VAT) : </td>
                                <td style={{ textAlign: 'right' }}>R {Number(order.totalWithVat).toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                            </tr>
                            <tr>
                                <td colspan="4">
                                    <hr/>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="4">Note : {order.orderNotes}</td>
                            </tr>
                            <tr style={{ visibility: this.state.declineVisibility }}>
                                <td colspan="4">Decline Reason : {declineReason} </td>
                            </tr>
                        </table>
                    </div>
                </div >
            );
        }
    }

    ToChangePassword() {
        fetch('/api/Login/CanUpdatePassword' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(response => {

                if (response == 'true')
                    this.setState({ toChangePassword: true });
                else
                    alert('You currently do not have the required permissions to change your password.');

            });
    }

    MenuClick() {

        if (this.state.menuVisibility == 'hidden') {
            this.setState({ menuVisibility: 'visible' });
        }
        else if (this.state.menuVisibility == 'visible') {
            this.setState({ menuVisibility: 'hidden' });
        }
    }

    render() {

        //Route Changers
        if (this.state.toSessionTimedOut) {
            return <Route path='/' component={SessionTimedOut} />
        }

        if (this.state.toAdmin) {
            return <Route path='/' component={AdminManage} />
        }


        if (this.state.toChangePassword) {
            return <Route path='/' component={ChangePassword} />

        }


        if (this.state.GoToOrderSearch) {
            return <Route path='/' component={OrderSearch} />
        }

        if (this.state.toBudgets) {
            return <Route path='/' component={Budget} />
        }

        if (this.state.toSavedCarts) {
            return <Route path='/' component={SavedCarts} />
        }

        if (this.state.toCheckOut) {
            return <Route path='/' component={CheckOut} />
        }

        if (this.state.toDocuments) {
            return <Route path='/' component={InvoiceOrderSearch} />
        }

        if (this.state.toDocumentsManage) {
            return <Route path='/' component={DocumentsManage} />
        }

        if (this.state.toLogIn) {
            return <Route path='/' component={Login} />
        }

        if (this.state.toProducts) {
            return <Route path='/' component={Product} />
        }

        if (this.state.ToCreditDocument) {
            return <Route path='/' component={CreditContent} />
        }

        if (this.state.ToDocument) {
            return <Route path='/' component={DocumentContent} />
        }

        if (this.state.ToOrderDocument) {
            return <Route path='/' component={OrderContent} />
        }


        if (this.state.toOrderApproval) {
            return <Route path='/' component={OrderApproval} />
        }

        if (this.state.toClearCartPrompt) {
            return <Route path='/' component={ClearCartPrompt} />
        }

        if (this.state.ToList) {
            return <Route path='/' component={OrderSearch} />
        }

        let orderList = this.LoadTableOrderItems();
        let order = this.LoadOrder();

        return (
            <div style={{ width: "100%", height: "100%", color: 'black' }}>
                <div className="header" style={{ position: 'fixed', zIndex : '500'}}>
                    <div className="grid_container_header" style={{ msHyphens: 'auto', display: 'inline-grid', gridTemplateColumns: '30% 40% 30%', display: 'grid -ms-grid', msGridColumns: '30% 40% 30%', columnCount: '3' }}>
                        <div className="grid_header">
                            <div className="icon_home" style={{ width: '100%', height: '50%', 'margin-top': '30px', 'margin-left': '10%', float: 'left', 'background-repeat': 'no-repeat' }}></div>
                        </div>
                        <div className="grid_header" style={{ 'text-align': 'left', overflow: 'hidden' }}>
                            <div className="header_container" style={{ width: '100%' }}>
                                <div>

                                    <div style={{ display: 'inline' }}>
                                        <a className="header_text" onClick={() => this.GoBackToProducts()}  >Place Order</a>|
                                                <a className="header_text" onClick={() => this.GoToDocumentsManage()} >Documents</a><span style={{ visibility: this.state.canTrack }}>|</span>
                                        <a className="header_text" onClick={() => this.GoToOrderApproval()} style={{ visibility: this.state.canTrack }} >Order Approval</a>
                                        <span style={{ visibility: 'visible' }}>|</span>
                                        <a className="header_text" onClick={() => this.GoToBudget()} style={{ visibility: 'visible' }}>Budgets</a>
                                        <span style={{ visibility: this.state.isAdminVisibile }}>|</span>
                                        <a className="header_text" onClick={() => this.GoToAdmin()} style={{ visibility: this.state.isAdminVisibile }} >Admin</a>
                                    </div>
                                </div> 

                            </div>
                        </div>
                        <div className="grid_header">
                            <div className="header_container greeting" style={{ width: '100%' }}>
                                <div className="header_text grid_item_1" style={{ lineHeight: '40px', textAlign: 'right' }}>
                                    <div style={{ height: '28px' }} className="overflow_header"> {this.state.userDetails.greeting + ', ' + this.state.userDetails.fullname}</div>
                                    <div style={{ height: '25px' }} className="overflow_header">{this.state.userDetails.accountName}</div>
                                    <div style={{ height: '28px' }} className="overflow_header">Group : {this.state.userDetails.accountCode}</div>
                                </div>
                                <div className="grid_item_2_reverse  h100">
                                    <div style={{ width: '100%', 'height': '100%' }}>
                                        <button id="btnMenu" onClick={() => this.MenuClick()} className="btn primary_btn header_btn twfont" style={{ lineHeight: '0.8', marginTop: '10px'  }}  ><i className="material-icons" style={{ width: '100%' }} >menu</i><label>Options</label></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header_menu_container" style={{ visibility: this.state.menuVisibility}}>
                    <div className="grid_item_1 max-height-menu">
                        <button onClick={() => { this.MenuClick() }} style={{ width: '100%', height: '100%', backgroundColor: 'transparent', border: 'none' }}></button>
                    </div>
                    <div className="header_menu grid_item_2 max-height-menu customer_background_foreground">
                        <hr />
                        <div>
                            <button id="canUpdateBtn" name="canUpdateBtn" className="btn  header_menu_item" onClick={() => { this.ToChangePassword() }}>Reset Password <i className="material-icons" style={{ position: 'relative', top: '6px', paddingLeft: '10px' }}>vpn_key</i></button>
                        </div>
                        <hr />
                        <div>
                            <button className="btn  header_menu_item" onClick={() => { this.GetCartState() }}>Logout <i className="material-icons" style={{ position: 'relative', top: '6px', paddingLeft: '10px' }}>exit_to_app</i></button>
                        </div>
                        <hr />
                    </div>
                </div>

                <div className="CssPageContatiner" style={{ paddingTop: '100px'}}>
                    <div>
                        <button ID="btnBackO" className="btn secondary_btn" style={{ width: this.state.toOrderSearchButtonWidth, 'font-family': 'Tw Cen MT', visibility: this.state.toOrderSearchButtonVisibility }} onClick={() => this.GoToOrderSearch()}>Back to Orders</button >
                        <button ID="btnBackOA" className="btn secondary_btn" style={{ width: this.state.toOrderApprovalButtonWidth, 'font-family': 'Tw Cen MT', visibility: this.state.toOrderApprovalButtonVisibility }} onClick={() => this.GoToOrderApproval()}>Back to Order Approval</button >

                        <br />
                        <br />
                    </div>
                    <div id="Page" className="CssPage">
                        <div className="pageContainer" style={{ display: 'inline-grid', gridTemplateColumns: '100%', padding: '30px', fontFamily: 'Tw Cen MT' }}>
                            <div className="pageContainer" style={{ backgroundColor: 'white', paddingRight: '30px', height: '100%' }}>
                                <div style={{ borderBottom: 'solid', marginBottom: '0' }}>
                                    <label style={{ fontSize: 'xx-large', fontWeight: 'bolder', paddingTop: '15px', marginBottom: '0' }}>Computer Generated Copy Order</label>
                                </div>
                                <div style={{ height: '87%', width: '100%', backgroundColor: 'white', marginTop: '20px' }}>
                                    {order}
                                    <div style={{ backgroundColor: 'white', height: '1%' }}>
                                        <hr />
                                    </div>
                                    <div style={{ height: '55%', backgroundColor: 'white' }}>
                                        <table className="table " style={{ fontFamily: 'TW Cen MT', display: 'block', overflow: 'scroll', whiteSpace: 'nowrap', height: '350px' }}>
                                            <thead style={{ lineHeight: '1px', borderBottom: 'solid' }}>
                                                <th style={{ textAlign: 'left' }}>Product ID</th>
                                                <th style={{ textAlign: 'left' }}>Description</th>
                                                <th style={{ textAlign: 'left' }}>Date Added</th>
                                                <th style={{ textAlign: 'left' }}>Quantity</th>
                                                <th style={{ width: 'inherit', textAlign: 'left' }} >Unit Price</th>
                                                <th style={{ width: 'inherit', textAlign: 'left' }} >Extended Price</th>
                                                <th style={{ width: 'inherit', textAlign: 'left' }} >VAT</th>
                                                <th style={{ width: 'inherit', textAlign: 'left' }} >Total (Including VAT)</th>
                                            </thead>
                                            {orderList}
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    ///Global methods

    GetCartState() {

        fetch('/api/Product/GetCartCount' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == "valued") {
                    this.setState({ toClearCartPrompt: true });
                } else {
                    window.location.href = '\\';
                }
            });
    }

    CheckIfAdmin() {
        fetch('/api/Login/isAdmin' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => { this.setState({ isAdminVisibile: (data == 'Y' ? 'visible' : 'hidden') }, () => { console.log('visibility : data=' + data + ' value= ' + this.state.isAdminVisibile); }) })
    }

    GoToDocumentsManage() {
        this.setState({ toDocumentsManage: true });
    }


    GoToOrderSearch() {
        this.setState({ GoToOrderSearch: true });
    }


    GoToOrderApproval() {
        this.setState({ toOrderApproval: true });
    }

    GoToDocuments() {
        this.setState({ toDocuments: true });
    }

    toOrderForm() {
        this.setState({ toOrderForm: true });
    }

    GoToLogIn() {
        this.setState({ toLogIn: true });
    }

    GoToAdmin() {
        this.setState({ toAdmin: true });
    }

    GoBackToProducts() {
        this.setState({ toProducts: true });
    }

    GoToBudget() {
        this.setState({ toBudgets: true });
    }

    GoToOrderForm() {
        this.setState({ toOrderForms: true });
    }

    GoToOrderSearch() {
        this.setState({ GoToOrderSearch: true });
    }


    GoToSavedCarts() {
        this.setState({ toSavedCarts: true });
    }

    GetUserDetails() {
        fetch('api/Login/GetUserDetails' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.json())
            .then(data => { this.setState({ userDetails: data }) })
            .then(() => {
                console.log("user details");
                console.log(this.state.userDetails);
            })
    }



    CheckIfCanTrack() {
        fetch('/api/Login/canTrack' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => { this.setState({ canTrack: (data == 'Y' ? 'visible' : 'hidden') }, () => { console.log('visibility : data=' + data + ' value= ' + this.state.isAdminVisibile); }) })
    }
}

//<button ID="btnPrint" className="btn tertiary_btn" style={{ 'font-family': 'Tw Cen MT', width: '20%' }} onClick={() => this.PrintOrder()}>Print</button >