import React, { Component } from 'react';
import { Route } from 'react-router';
import { DocumentContent } from './DocumentContent';
import { OrderContent } from './OrderContent';
import { CreditContent } from './CreditContent';
import { OrderSearch } from './OrderSearch';
import { Product } from './Products';
import { Login } from './Login';
import { CheckOut } from './CheckOut';
import { SavedCarts } from './SavedCarts';
import { InvoiceOrderSearch } from './InvoiceOrderSearch';
import { Budget } from './Budget'
import { AdminUser } from './AdminUser'
import { Users } from './Users';
import { OrderApproval } from './OrderApproval'
import { withRouter, Redirect } from 'react-router-dom';
import { SessionTimedOut } from './SessionTimedOut';
import { ClearCartPrompt } from './ClearCartPrompt';
import { AdminManage } from './AdminManage';

export class PaymentPortal extends Component {
    displayName = PaymentPortal.name

    constructor(props) {
        super(props);

        this.state = {
            valid: false,
            userDetails: {},
            referenceNumber: '',
            startDate: '',
            endDate: '',
            documentType: 'Orders',
            ApproveVisibility: 'hidden',

            orderItems: [],
            orderTotal: '',
            loadingOrders: false,

            ToDocument: false,
            ToOrderDocument: false,
            ToCreditDocument: false,
            toCheckOut: false,
            toSaveCart: false,
            toManage: false,
            toClearCartPrompt: false,
            toDocuments: false,
            toOrderForm: false,
            toAdmin: false,
            toLogIn: false,
            toBudgets: false,
            toOrderSearch: false,
            toOrderApproval: false,
            toSavedCarts: false
        };

        this.CheckSessionInitialize();
    }

    RunAllInitializers() {
        this.GetOrders();
        this.GetTotalAmount();
    }

    CheckSessionInitialize() {
        this.setState({ complete_update: false });
        console.log('get CheckSession called');

        fetch('api/Login/CheckSession?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                this.RunAllInitializers();
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });
                    this.GetUserDetails();
                    this.CheckIfApprover();
                    this.CheckIfAdmin();
                    this.CheckIfCanTrack();
                    this.RunAllInitializers();
                }
                else {
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }

    submitForm() {
        var oAmount = document.getElementById("Lite_Order_Amount");
        document.getElementById("Lite_Order_LineItems_Amount_1").value = oAmount.value;
    }

    CheckSession() {
        this.setState({ complete_update: false });
        console.log('get CheckSession called');

        fetch('api/Login/CheckSession?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });
                }
                else if (data == 'timedout') {
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }

    CheckIfApprover() {
        fetch('/api/Login/isApprover' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => { this.setState({ ApproveVisibility: (data == 'Y' ? 'visible' : 'hidden') }, () => { console.log('visibility : data=' + data + ' value= ' + this.state.ApproveVisibility); }) })
    }

    ProductLink() {
        this.setState({ toProductsPage: true });
    }

    GetOrders() {

        this.setState({ loadingOrders: true });
        this.CheckSession();
        console.log('starting order pull');

        fetch('/api/PaymentPortal/GetOrderItemsForPaypal' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.json())
            .then(data => { this.setState({ orderItems: data }); console.log(data) })
            .then(console.log('complete.'));
    }

    GetTotalAmount() {
        this.CheckSession();
        console.log('starting order total pull');

        fetch('/api/PaymentPortal/GetTotalAmount' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => { this.setState({ orderTotal: data }); console.log(data) })
            .then(console.log('complete.'));
    }

    renderOrderItems() {

        return (this.state.orderItems.map(orderItem =>
            <tr style={{ padding : '10px 10%'}}>
                <td style={{ width: '70%' }} ><input type="text" readonly="readonly" className="twfont form-control" name={orderItem.productId} id={orderItem.productId} value={orderItem.productName} /></td>
                <td style={{ width: '15%' }} ><input type="text" readonly="readonly" className="twfont form-control" name={orderItem.quantityId} id={orderItem.quantityId} value={orderItem.quantity} /></td>
                <td style={{ width: '15%' }} ><input type="text" readonly="readonly" className="twfont form-control" name={orderItem.amountId} id={orderItem.amountId} value={Number(orderItem.amount).toFixed(2)} /></td>
            </tr>
        ))
    }

    render() {

        //Route Change
        if (this.state.toSessionTimedOut) {
            return <Route path='/' component={SessionTimedOut} />
        }

        if (this.state.toClearCartPrompt) {
            return <Route path='/' component={ClearCartPrompt} />
        }

        if (this.state.toAdmin) {
            return <Route path='/' component={AdminManage} />
        }

        if (this.state.ToOrderDocument) {
            return <Route path='/' component={OrderContent} />
        }

        if (this.state.GoToOrderSearch) {
            return <Route path='/' component={OrderSearch} />
        }

        if (this.state.toBudgets) {
            return <Route path='/' component={Budget} />
        }

        if (this.state.toSavedCarts) {
            return <Route path='/' component={SavedCarts} />
        }

        if (this.state.toCheckOut) {
            return <Route path='/' component={CheckOut} />
        }

        if (this.state.toDocuments) {
            return <Route path='/' component={InvoiceOrderSearch} />
        }

        if (this.state.toOrderApproval) {
            return <Route path='/' component={OrderApproval} />
        }

        if (this.state.toLogIn) {
            return <Route path='/' component={Login} />
        }

        if (this.state.toProducts) {
            return <Route path='/' component={Product} />
        }

        if (this.state.toClearCartPrompt) {
            return <Route path='/' component={ClearCartPrompt} />
        }

        //end

        if (this.state.ToCreditDocument) {
            return <Route path='/' component={CreditContent} />
        }

        if (this.state.ToDocument) {
            return <Route path='/' component={DocumentContent} />
        }

        if (this.state.ToOrderDocument) {
            return <Route path='/' component={OrderContent} />
        }

        let paypalItems = this.renderOrderItems();
        let paypalOrderTotal = this.state.orderTotal;

        return (

            <div>
                <div style={{ top: '0', left: '0', height: '100%' }}>
                    <div className="header" style={{ position: 'fixed', zIndex : '500'}}>
                        <div className="grid_container_header" style={{ msHyphens: 'auto', display: 'inline-grid', gridTemplateColumns: '30% 40% 30%', display: 'grid -ms-grid', msGridColumns: '30% 40% 30%', columnCount: '3' }}>
                            <div className="grid_header">
                                <div className="icon_home" style={{ width: '100%', height: '50%', 'margin-top': '30px', 'margin-left': '10%', float: 'left', 'background-repeat': 'no-repeat' }}></div>
                            </div>
                            <div className="grid_header" style={{ 'text-align': 'left', overflow: 'hidden' }}>
                                <div className="header_container" style={{ width: '100%' }}>
                                    <div>
                                        <div style={{ display: 'inline' }}>
                                            <a className="header_text" onClick={() => this.GoBackToProducts()}  >Place Order</a>|
                                                <a className="header_text" onClick={() => this.GoToOrderSearch()} >Documents</a><span style={{ visibility: this.state.canTrack }}>|</span>
                                            <a className="header_text" onClick={() => this.GoToOrderApproval()} style={{ visibility: this.state.canTrack }} >Order Approval</a><span style={{ visibility: this.state.isAdminVisibile }}>|</span>
                                            <a className="header_text" onClick={() => this.GoToBudget()} style={{ visibility: 'hidden' }}>Budgets</a>
                                            <span style={{ visibility: this.state.isAdminVisibile }}>|</span>
                                            <a className="header_text" onClick={() => this.GoToAdmin()} style={{ visibility: this.state.isAdminVisibile }} >Admin</a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="grid_header">
                                <div className="header_container greeting" style={{ width: '100%' }}>
                                    <div className="header_text grid_item_1" style={{ lineHeight: '40px', textAlign: 'right' }}>
                                        <div style={{ height: '28px' }} className="overflow_header"> {this.state.userDetails.greeting + ', ' + this.state.userDetails.fullname}</div>
                                        <div style={{ height: '25px' }} className="overflow_header">{this.state.userDetails.accountName}</div>
                                        <div style={{ height: '28px' }} className="overflow_header">Group : {this.state.userDetails.accountCode}</div>
                                    </div>
                                    <div className="grid_item_2"><a className="header_text" onClick={() => { this.GetCartState() }} id="A1">LOG OUT</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" sidenav customer_background_foreground" style={{ width: '100%', minHeight: '1000px', marginTop: '100px'}}>
                        <div style={{ width: '100%', textAlign: 'center' }}>

                            <div id="divContent" CssClass="clsContent" align="center"><br />
                                <form name="Form1" method="post" action="https://portal.nedsecure.co.za/Lite/Authorise.aspx" id="Form1" >
                                    <table className="twfont" cellspacing="0" align="center" border="0">
                                        <tr>
                                            <td CssClass="clsInformation" align="center" colspan="3">
                                                <b>Confirm Details</b>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td CssClass="clsQueryHeading" align="left">
                                                Name:
                                            </td>
                                            <td className="grid_header" align="left" style={{ borderTop: '1px solid black' }} colspan="2">
                                                <input className="grid_item_1 form-control" type="text" value="M." readonly="readonly" name="Ecom_BillTo_Postal_Name_Prefix" id="Ecom_BillTo_Postal_Name_Prefix" />
                                                <input className="grid_item_2 form-control" name="Ecom_BillTo_Postal_Name_First" readonly="readonly" type="text" value="John" id="Ecom_BillTo_Postal_Name_First" />
                                                <input className="" type="hidden" name="Ecom_BillTo_Postal_Name_Middle" id="Ecom_BillTo_Postal_Name_Middle" />
                                                <input className="grid_item_3 form-control" name="Ecom_BillTo_Postal_Name_Last" readonly="readonly" type="text" value="Doe" id="Ecom_BillTo_Postal_Name_Last" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td CssClass="clsQueryHeading" align="left">
                                                Email:
                                            </td>
                                            <td className="twfont" align="left" colspan="2">
                                                <input name="Ecom_BillTo_Online_Email" readonly="readonly" type="text" value="jdoe@mail.com" maxlength="50" id="Ecom_BillTo_Online_Email" className="twfont form-control" />

                                            </td>
                                        </tr>
                                        <tr>
                                            <td CssClass="clsQueryHeading" align="left">

                                                Ship To Street (Line 1):
                                            </td>
                                            <td className="twfont" align="left" colspan="2">
                                                <input type="text" readonly="readonly" className="twfont form-control" name="Ecom_ShipTo_Postal_Street_Line1" id="Ecom_ShipTo_Postal_Street_Line1" value="50 Sunny Drive Avenue" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td CssClass="clsQueryHeading" align="left">
                                                Ship To Street (Line 2):
                                            </td>

                                            <td className="twfont" align="left" colspan="2">
                                                <input type="text" readonly="readonly" className="twfont form-control" name="Ecom_ShipTo_Postal_Street_Line2" id="Ecom_ShipTo_Postal_Street_Line2" value="Sunsetville" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td CssClass="clsQueryHeading" align="left">
                                                Ship To City:
                                            </td>
                                            <td className="twfont" align="left" colspan="2">

                                                <input type="text" readonly="readonly" className="twfont form-control" name="Ecom_ShipTo_Postal_City" id="Ecom_ShipTo_Postal_City" value="Johannesburg" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td CssClass="clsQueryHeading" align="left">
                                                Ship To Province:
                                            </td>
                                            <td className="twfont" align="left" colspan="2">
                                                <input type="text" readonly="readonly" className="twfont form-control" name="Ecom_ShipTo_Postal_StateProv" id="Ecom_ShipTo_Postal_StateProv" value="Gauteng" />

                                            </td>
                                        </tr>
                                        <tr>
                                            <td CssClass="clsQueryHeading" align="left">
                                                Ship To Postal Code:
                                            </td>
                                            <td className="twfont" align="left" colspan="2">
                                                <input type="text" readonly="readonly" className="twfont form-control" name="Ecom_ShipTo_Postal_PostalCode" id="Ecom_ShipTo_Postal_PostalCode" value="2185" />
                                            </td>

                                        </tr>
                                        <tr>
                                            <td CssClass="clsQueryHeading" align="left">
                                                Merchant Reference:
                                            </td>
                                            <td className="twfont" align="left" style={{ borderBottom: '1px solid black' }} colspan="2">
                                                <input name="Ecom_ConsumerOrderID" readonly="readonly" type="text" value="AUTOGENERATE" maxlength="20" id="Ecom_ConsumerOrderID" className="twfont form-control" />
                                                <input type="hidden" name="Ecom_SchemaVersion" id="Ecom_SchemaVersion" />
                                                <input type="hidden" name="Ecom_TransactionComplete" id="Ecom_TransactionComplete" value="false" />

                                                <input type="hidden" name="Lite_Authorisation" id="Lite_Authorisation" value="false" />
                                                <input type="hidden" name="Lite_Version" id="Lite_Version" value="4.0" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td CssClass="clsInformation" colspan="3" style={{ padding: '5px' }} align="center"><b>Line Items</b></td>
                                        </tr>
                                        <tr>
                                            <td className="twfont">Quantity</td>
                                            <td className="twfont">Product Description</td>
                                            <td className="twfont">Unit Cost</td>
                                        </tr>
                                        
                                        <tr>
                                            <td><input type="text" readonly="readonly" className="twfont form-control" name="Lite_Order_LineItems_Quantity_1" id="Lite_Order_LineItems_Quantity_1" value="1" /></td>
                                            <td><input type="text" readonly="readonly" className="twfont form-control" name="Lite_Order_LineItems_Product_1" id="Lite_Order_LineItems_Product_1" value="Example Product#1" /></td>
                                            <td><input type="text" readonly="readonly" className="twfont form-control" name="Lite_Order_LineItems_Amount_1" id="Lite_Order_LineItems_Amount_1" value="100" /></td>
                                        </tr>
                                        <tr>
                                            <td CssClass="clsQueryHeading" align="left">
                                                
                                            </td>
                                            <td className="twfont">Total Order Amount:</td>
                                            <td className="twfont" align="left" colspan="1">
                                                <input name="Lite_Order_Amount" readOnly type="text" value="100" onkeypress="javascript: submitForm();" onchange="javascript: submitForm();" maxlength="10" id="Lite_Order_Amount" className="twfont form-control" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td CssClass="clsQueryHeading" align="left">

                                            </td>
                                            <td align="left" colspan="3">
                                                <input name="Lite_Merchant_ApplicationID" type="hidden" value="99185D93-6729-4E78-A534-7F8999E1F8FE" maxlength="40" id="Lite_Merchant_ApplicationID" className="twfont form-control" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td CssClass="clsInformation" align="center" colspan="3">
                                            </td>
                                        </tr>
                                        <tr><td><br /></td></tr>
                                        <tr>
                                            <td CssClass="clsInformation" align="center" colspan="3">
                                                <input type="submit" name="buttonSubmit" value="Submit" onclick={() => { this.submitForm() }} id="btn primary_btn" />
                                            </td>
                                        </tr>
                                    </table>
                                    <table id="tblCardInformation" className="twfont" style={{ top: '10px', visibility: 'hidden' }}>
                                        <tr>
                                            <td CssClass="clsQueryHeading" align="left">
                                            </td>

                                            <td className="twfont" align="left">
                                                <input type="hidden" name="Ecom_Payment_Card_Protocols" id="Ecom_Payment_Card_Protocols" value="iVeri" />
                                            </td>

                                        </tr>
                                    </table>
                                    <input type="hidden" name="Lite_Order_Terminal" id="Lite_Order_Terminal" value="77777001" />

                                    <input type="hidden" name="Lite_Order_AuthorisationCode" id="Lite_Order_AuthorisationCode" />
                                    <input type="hidden" name="Lite_Website_TextColor" id="Lite_Website_TextColor" value="#ffffff" />
                                    <input type="hidden" name="Lite_Website_BGColor" id="Lite_Website_BGColor" value="#1C4231" />
                                    <input type="hidden" name="Lite_ConsumerOrderID_PreFix" id="Lite_ConsumerOrderID_PreFix" value="LITE" />

                                    <input type="hidden" name="Lite_Website_Successful_Url" id="Lite_Website_Successful_Url" value="http://localhost:53968/Result.aspx" />
                                    <input type="hidden" name="Lite_Website_Fail_Url" id="Lite_Website_Fail_Url" value="http://localhost:53968/Result.aspx" />
                                    <input type="hidden" name="Lite_Website_Error_Url" id="Lite_Website_Error_Url" value="http://localhost:53968/Result.aspx" />
                                    <input type="hidden" name="Lite_Website_Trylater_Url" id="Lite_Website_Trylater_Url" value="http://localhost:53968/Result.aspx" />


                                    <input type="hidden" name="Ecom_ShipTo_Postal_Name_Prefix" id="Ecom_ShipTo_Postal_Name_Prefix" />
                                    <input type="hidden" name="Ecom_ShipTo_Postal_Name_First" id="Ecom_ShipTo_Postal_Name_First" />
                                    <input type="hidden" name="Ecom_ShipTo_Postal_Name_Middle" id="Ecom_ShipTo_Postal_Name_Middle" />
                                    <input type="hidden" name="Ecom_ShipTo_Postal_Name_Last" id="Ecom_ShipTo_Postal_Name_Last" />
                                    <input type="hidden" name="Ecom_ShipTo_Postal_Name_Suffix" id="Ecom_ShipTo_Postal_Name_Suffix" />

                                    <input type="hidden" name="Ecom_ShipTo_Postal_Street_Line3" id="Ecom_ShipTo_Postal_Street_Line3" />
                                    <input type="hidden" name="Ecom_ShipTo_Postal_CountryCode" id="Ecom_ShipTo_Postal_CountryCode" />
                                    <input type="text" readonly="readonly" class="clsInputReadOnlyText" name="Ecom_ShipTo_Telecom_Phone_Number" id="Ecom_ShipTo_Telecom_Phone_Number" />

                                    <input type="hidden" name="Ecom_ShipTo_Online_Email" id="Ecom_ShipTo_Online_Email" />


                                    <input type="hidden" name="Ecom_ReceiptTo_Postal_Name_Prefix" id="Ecom_ReceiptTo_Postal_Name_Prefix" />
                                    <input type="hidden" name="Ecom_ReceiptTo_Postal_Name_First" id="Ecom_ReceiptTo_Postal_Name_First" />
                                    <input type="hidden" name="Ecom_ReceiptTo_Postal_Name_Middle" id="Ecom_ReceiptTo_Postal_Name_Middle" />
                                    <input type="hidden" name="Ecom_ReceiptTo_Postal_Name_Last" id="Ecom_ReceiptTo_Postal_Name_Last" />
                                    <input type="hidden" name="Ecom_ReceiptTo_Postal_Name_Suffix" id="Ecom_ReceiptTo_Postal_Name_Suffix" />
                                    <input type="hidden" name="Ecom_ReceiptTo_Postal_Street_Line1" id="Ecom_ReceiptTo_Postal_Street_Line1" />
                                    <input type="hidden" name="Ecom_ReceiptTo_Postal_Street_Line2" id="Ecom_ReceiptTo_Postal_Street_Line2" />

                                    <input type="hidden" name="Ecom_ReceiptTo_Postal_Street_Line3" id="Ecom_ReceiptTo_Postal_Street_Line3" />
                                    <input type="hidden" name="Ecom_ReceiptTo_Postal_City" id="Ecom_ReceiptTo_Postal_City" />
                                    <input type="hidden" name="Ecom_ReceiptTo_Postal_StateProv" id="Ecom_ReceiptTo_Postal_StateProv" />
                                    <input type="hidden" name="Ecom_ReceiptTo_Postal_PostalCode" id="Ecom_ReceiptTo_Postal_PostalCode" />
                                    <input type="hidden" name="Ecom_ReceiptTo_Postal_CountryCode" id="Ecom_ReceiptTo_Postal_CountryCode" />
                                    <input type="hidden" name="Ecom_ReceiptTo_Telecom_Phone_Number" id="Ecom_ReceiptTo_Telecom_Phone_Number" />
                                    <input type="hidden" name="Ecom_ReceiptTo_Online_Email" id="Ecom_ReceiptTo_Online_Email" />


                                    <input type="hidden" name="Ecom_BillTo_Postal_Name_Suffix" id="Ecom_BillTo_Postal_Name_Suffix" />

                                    <input type="hidden" name="Ecom_BillTo_Postal_Street_Line1" id="Ecom_BillTo_Postal_Street_Line1" />
                                    <input type="hidden" name="Ecom_BillTo_Postal_Street_Line2" id="Ecom_BillTo_Postal_Street_Line2" />
                                    <input type="hidden" name="Ecom_BillTo_Postal_Street_Line3" id="Ecom_BillTo_Postal_Street_Line3" />
                                    <input type="hidden" name="Ecom_BillTo_Postal_City" id="Ecom_BillTo_Postal_City" />
                                    <input type="hidden" name="Ecom_BillTo_Postal_StateProv" id="Ecom_BillTo_Postal_StateProv" />
                                    <input type="hidden" name="Ecom_BillTo_Postal_PostalCode" id="Ecom_BillTo_Postal_PostalCode" />
                                    <input type="hidden" name="Ecom_BillTo_Postal_CountryCode" id="Ecom_BillTo_Postal_CountryCode" />
                                    <input type="hidden" name="Ecom_BillTo_Telecom_Phone_Number" id="Ecom_BillTo_Telecom_Phone_Number" />
                                </form>

                            </div>
                            <br />
                        </div>
                    </div>
                </div>
            </div >
        );
    }

    //Class Methods
    GetCartState() {

        fetch('/api/Product/GetCartCount')
            .then(response => response.text())
            .then(data => {
                if (data == "valued") {
                    this.setState({ toClearCartPrompt: true });
                } else {
                    window.location.href = '\\';
                }
            });
    }

    CheckIfAdmin() {
        fetch('/api/Login/isAdmin?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => { this.setState({ isAdminVisibile: (data == 'Y' ? 'visible' : 'hidden') }, () => { console.log('visibility : data=' + data + ' value= ' + this.state.isAdminVisibile); }) })
    }

    GoToOrderSearch() {
        this.setState({ GoToOrderSearch: true });
    }

    GoToDocuments() {
        this.setState({ toDocuments: true });
    }

    toOrderForm() {
        this.setState({ toOrderForm: true });
    }

    GoToLogIn() {
        this.setState({ toLogIn: true });
    }

    GoToAdmin() {
        this.setState({ toAdmin: true });
    }

    GoBackToProducts() {
        this.setState({ toProducts: true });
    }

    GoToBudget() {
        this.setState({ toBudgets: true });
    }

    GoToOrderForm() {
        this.setState({ toOrderForms: true });
    }

    GoToOrderSearch() {
        this.setState({ GoToOrderSearch: true });
    }

    GoToOrderApproval() {
        this.setState({ toOrderApproval: true });
    }

    GoToSavedCarts() {
        this.setState({ toSavedCarts: true });
    }

    GetUserDetails() {
        fetch('api/Login/GetUserDetails?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.json())
            .then(data => { this.setState({ userDetails: data }) })
            .then(() => {
                console.log("user details");
                console.log(this.state.userDetails);
            })
    }

    CheckIfCanTrack() {
        fetch('/api/Login/canTrack?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => { this.setState({ canTrack: (data == 'Y' ? 'visible' : 'hidden') }, () => { console.log('visibility : data=' + data + ' value= ' + this.state.isAdminVisibile); }) })
    }
}