import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { Route } from 'react-router';
import { DocumentContent } from './DocumentContent';
import { OrderContent } from './OrderContent';
import { CreditContent } from './CreditContent';
import { ChangePassword } from './ChangePassword';

import { Product } from './Products';
import { Login } from './Login';
import { CheckOut } from './CheckOut';
import { SavedCarts } from './SavedCarts';
import { InvoiceOrderSearch } from './InvoiceOrderSearch';
import { OrderSearch } from './OrderSearch'
import { Budget } from './Budget'
import { AdminUser } from './AdminUser'
import { Users } from './Users';
import { ClearCartPrompt } from './ClearCartPrompt';
import { SessionTimedOut } from './SessionTimedOut';
import { DocumentsManage } from './DocumentsManage';
import { OrderApprovalHistory } from './OrderApprovalHistory';
import { AdminManage } from './AdminManage';

export class OrderApproval extends Component {
    displayName = OrderApproval.name

    constructor(props) {
        super(props);

        this.state = {
            valid: false,
            referenceNumber: '',
            toChangePassword: false,
            menuVisibility: 'hidden',
            startDate: '',
            endDate: '',
            documentType: 'Current',
            ApproveVisibility: 'hidden',

            orders: [],

            orderList: [],
            loadingOrders: true,
            loadingOrderList: true,
            ordersHeight: '500px',
            ordersVisibility: 'visible',

            ToDocument: false,
            ToOrderDocument: false,
            ToCreditDocument: false,

            userDetails: {},
            budget: {},
            ToOrderDocument: false,
            toCheckOut: false,
            toSaveCart: false,
            toManage: false,
            toDocuments: false,
            toClearCartPrompt: false,
            toOrderForm: false,
            toAdmin: false,
            toLogIn: false,
            toBudgets: false,
            toOrderSearch: false,
            toOrderApproval: false,
            toSavedCarts: false,
            isAdminVisibile: 'hidden',
            toProducts: false,
            approvalCount: 0,
            approvedVisible: 'visible',
            declineCount: 0,
            canTrack: 'hidden',
            declineVisible: 'visible',
            declineReason: '',
            declineId: 0,
            declineModalVisibility: 'hidden',
            stylesheet: '',
            toSessionTimedOut: false,
            invalidDateMessage: '',
            invalidDateModalVisibility: 'hidden',
            invalidBudgetModalVisibility: 'hidden',
            toDocumentsManage: false,
            documentType: 'Current',
            toOrderApproval: false,
            approveOrderId: 0,
            selectedFilter: ''
        };

        this.CheckSessionInitialize();

    }

    GetInitialDates() {
        //GetInitialDate
        console.log('GetInitialDate');
        fetch('/api/InvoiceOrder/GetInitialDates?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    startDate: data.startDate,
                    endDate: data.endDate
                });

                console.log('start date: ' + this.state.startDate);
                console.log('end date: ' + this.state.endDate);
            });
    }

    ExitModal() {
        this.setState({ invalidDateModalVisibility: 'hidden' });

    }
    ExitBudgetModal() {
        this.setState({ invalidBudgetModalVisibility: 'hidden' });

    }
    handleSelectedFilter(e) {
        console.log('target: ' + e.target);
        console.log('target.value: ' + e.target.value);
        let inputValue = e.target.value;
        this.setState({ selectedFilter: inputValue });
    }

    GetBudget(orderId, budgetId) {

        fetch('/api/Budget/GetBudgetForOrder?budgetId=' + budgetId + '&orderId=' + orderId + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.json())
            .then(data => {
                this.setState({ budget: data });
            });
    }

    setCheckedTab() {

        fetch('api/InvoiceOrder/GetPageContent?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.json())
            .then(data => {


                this.setState({
                    referenceNumber: data.searchText,
                    startDate: data.startDateValue,
                    endDate: data.endDateValue
                });

                if (data.radioValue == 'history') {
                    document.getElementById('historyRadioButton').checked = true;
                    this.GetOrdersByRefHistory();
                    this.setState({ documentType: 'History' });
                }
                else {
                    document.getElementById('currentRadioButton').checked = true;
                    this.GetOrdersByRef();
                    this.setState({ documentType: 'Current' });
                }
            });


    }

    CheckSessionInitialize() {
        this.setState({ complete_update: false });

        fetch('api/Login/CheckSession?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });
                    this.GetInitialDates();
                    this.setCheckedTab();
                    this.GetUserDetails();
                    this.CheckIfApprover();
                    this.CheckIfAdmin();
                    this.CheckIfCanTrack();
                    //this.GetDocumentsByReference();
                }
                else if (data == 'timedout') {
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }

    CheckSession() {
        this.setState({ complete_update: false });


        fetch('api/Login/CheckSession?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });
                }
                else if (data == 'timedout') {
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }

    CheckIfApprover() {
        fetch('/api/Login/isApprover?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                let apprVis = (data == 'Y' ? 'visible' : 'hidden');
                this.setState({ ApproveVisibility: apprVis });
            });
    }

    SetOrderIdentifier(orderId) {
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            fetch('api/InvoiceOrder/SetOrderIdentifier?orderId=' + orderId + '&forApprover=' + 1 + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(() => {
                    this.SetOrderPredecessor();
                });
        }

    }

    SetOrderPredecessor() {
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            fetch('api/InvoiceOrder/SetOrderContentPredecessor?predecessor=OA&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(() => { this.setState({ ToOrderDocument: true }) });
        }
    }

    ProductLink() {
        this.setState({ toProductsPage: true });
    }

    handleReferenceNumberChange(e) {
        this.setState({ referenceNumber: e.target.value });
    }

    handleReasonChange(e) {
        this.setState({ declineReason: e.target.value });
    }

    handleStartDateChange(e) {
        this.setState({ startDate: e.target.value });
    }

    handleEndDateChange(e) {
        this.setState({ endDate: e.target.value });
    }

    GetDocumentsByReference() {

        console.log('doctype r: ' + this.state.documentType);

        if (this.state.documentType == 'Current')
            this.GetOrdersByRef();
        else if (this.state.documentType == 'History')
            this.GetOrdersByRefHistory();

    }

    GetDocuments() {
        if (this.state.documentType == 'Current')
            this.GetOrdersByDate();
        else if (this.state.documentType == 'History')
            this.GetOrdersByDateHistory();
    }

    //////////////////////////////////Search//////////////////////////////
    GetOrdersByDate() {

        this.CheckSession();
        this.setState({ loadingOrders: true });


        fetch('api/InvoiceOrder/ValidateDates?startDate=' + this.state.startDate + '&endDate=' + this.state.endDate + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'Available') {
                    this.setState({ isValidDate: true });

                    if (this.state.toSessionTimedOut == false) {

                        fetch('api/InvoiceOrder/GetPendingOrdersByDate?startDate=' + this.state.startDate + '&endDate=' + this.state.endDate + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                            .then(response => response.json())
                            .then(data => {
                                this.setState({ orders: data, loadingOrders: false });
                            })
                            .then(() => {

                            });
                    }
                }
                else {
                    this.setState({ invalidDateMessage: data, invalidDateModalVisibility: 'visible' });
                }
            });


    }

    GetOrdersByDateHistory() {

        this.CheckSession();
        this.setState({ loadingOrders: true });


        fetch('api/InvoiceOrder/ValidateDates?startDate=' + this.state.startDate + '&endDate=' + this.state.endDate + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'Available') {
                    this.setState({ isValidDate: true });

                    if (this.state.toSessionTimedOut == false) {

                        fetch('api/InvoiceOrder/GetApprovalHistoryByDate?startDate=' + this.state.startDate + '&endDate=' + this.state.endDate + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                            .then(response => response.json())
                            .then(data => {
                                this.setState({ orders: data, loadingOrders: false });
                            })
                            .then(() => {

                            });
                    }
                }
                else {
                    this.setState({ invalidDateMessage: data, invalidDateModalVisibility: 'visible' });
                }
            });
        

    }

    GetOrdersByRef() {
        this.CheckSession();
        this.setState({ loadingOrders: true });
        console.log('loading orders: ' + this.state.loadingOrders);

        if (this.state.toSessionTimedOut == false) {

            //fetch('api/InvoiceOrder/GetPendingOrdersByReference?referenceNo=' + this.state.referenceNumber + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            fetch('api/InvoiceOrder/GetPendingOrdersByReference?referenceNo=' + this.state.referenceNumber + '&filter=' + this.state.selectedFilter + '&startDate=' + this.state.startDate + '&endDate=' + this.state.endDate + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(response => response.json())
                .then(data => {
                    this.setState({ orders: data });
                })
                .then(() => {
                    this.setState({ loadingOrders: false });
                    console.log('loading orders: ' + this.state.loadingOrders);
                });
        }
    }

    GetOrdersByRefHistory() {
        this.CheckSession();

        console.log('history');
        console.log('history: ' + this.state.selectedFilter);
        this.setState({ loadingOrders: true });

        if (this.state.toSessionTimedOut == false) {

            //fetch('api/InvoiceOrder/GetApprovalHistoryReference?referenceNo=' + this.state.referenceNumber + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            fetch('api/InvoiceOrder/GetApprovalHistoryReference?referenceNo=' + this.state.referenceNumber + '&filter=' + this.state.selectedFilter + '&startDate=' + this.state.startDate + '&endDate=' + this.state.endDate + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(response => response.json())
                .then(data => {
                    this.setState({ orders: data, loadingOrders: false });
                });
        }
    }
    //////////////////////////////////Search//////////////////////////////

    SetOrderPredecessorApprovalHistory() {
        fetch('api/InvoiceOrder/SetOrderContentPredecessor?predecessor=OA&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(() => {
                this.setState({ toOrderApprovalHistory: true });
            });
    }

    SetApprovalHistoryOrderIdentifier(orderId) {
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            fetch('api/InvoiceOrder/SetApprovalHistoryOrderId?orderId=' + orderId + '&forApprover=' + 0 + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(() => {
                    this.SetOrderPredecessorApprovalHistory();
                });
        }

    }

    SetOrderIdentifierForEdit(orderId, accountCode) {
        this.CheckSession();
        let valid = this.state.toSessionTimedOut == false && this.state.documentType == 'Current';

        if (valid) {
            fetch('api/Product/EditCartProducts?orderId=' + orderId + '&accountCode=' + accountCode + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(() => { this.setState({ toProducts: true }) });
        }
    }

    CheckIfOrderAboveBudget(id, budgetid) {

        console.log('orderid : ' + id + ', budgetId: ' + budgetid)

        if (budgetid == 0) {
            this.ApproveOrder(id);
        }
        else {
            this.PromptAboveBudget(id, budgetid);
        }
    }

    PromptAboveBudget(orderId, budgetid) {
        //this.setState({  });
        this.setState({ approveOrderId: orderId, invalidBudgetModalVisibility: 'visible' });
        this.GetBudget(orderId, budgetid);
    }

    ApproveOrder(id) {
        this.CheckSession();
        this.setState({ loadingOrders: true });
        let valid = this.state.toSessionTimedOut == false && this.state.documentType == 'Current';
        this.setState({ invalidBudgetModalVisibility: 'hidden' });
        if (valid) {
            fetch('api/InvoiceOrder/ApproveOrder?orderId=' + id + '&referenceNo=' + this.state.referenceNumber + '&filter=' + this.state.selectedFilter + '&startDate=' + this.state.startDate + '&endDate=' + this.state.endDate + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(response => response.json())
                .then(data => {
                    this.setState({ orders: data, loadingOrders: false });
                })
                .then(() => {
                    this.setState({ approvalCount: this.state.approvalCount + 1 });
                    alert('Order L' + id + ' has been approved succesfully!');
                });
        }

    }

    DeclineOrder(id, declinereason) {
        this.CheckSession();
        this.setState({ loadingOrders: true });

        if (this.state.toSessionTimedOut == false) {
            fetch('api/InvoiceOrder/DeclineOrder?orderId=' + id + '&declineReason=' + declinereason + '&referenceNo=' + this.state.referenceNumber + '&filter=' + this.state.selectedFilter + '&startDate=' + this.state.startDate + '&endDate=' + this.state.endDate + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(response => response.json())
                .then(data => {
                    this.setState({ orders: data, loadingOrders: false });
                })
                .then(() => {
                    this.setState({ declineCount: this.state.declineCount + 1, declineReason: '' });
                    alert('Order L' + id + ' has been declined.');
                });
        }
    }

    OpenDeclineModal(id) {
        this.CheckSession();

        let valid = this.state.toSessionTimedOut == false && this.state.documentType == 'Current';

        if (valid) {
            this.setState({ declineModalVisibility: 'visible', declineId: id });
        }
    }

    handleDocumentTypeChange(e) {

        let docType = e.target.value;
        this.state.orders = [];
        this.setState({ documentType: docType, orders: [] },
            () => {
                this.GetDocumentsByReference();
            });

        console.log('doctype (target value): ' + docType);
        console.log('doctype (state): ' + this.state.documentType);


    }

    GetOrderContentList(id) {
        this.CheckSession();
        this.setState({ loadingOrderList: true });

        if (this.state.toSessionTimedOut == false) {


            fetch('/api/InvoiceOrder/GetOrderItemsById?id=' + id + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(response => response.json())
                .then(data => { this.setState({ orderList: data, loadingOrderList: false }) });
        }
    }

    LoadTableOrderItems() {

        if (this.state.loadingOrders) {
            return (
                <tr>
                    <td>loading...</td>
                    <td>loading...</td>
                    <td>loading...</td>
                    <td>loading...</td>
                    <td>loading...</td>
                    <td>loading...</td>
                    <td>loading...</td>
                </tr>
            );

        }
        else {
            return (
                this.state.orderList.map(orderItem =>
                    <tr style={{ lineHeight: '1px', backgroundColor: 'white' }}>
                        <td>{orderItem.productID}</td>
                        <td>{orderItem.dateAdded}</td>
                        <td>{Number(orderItem.price * orderItem.qty).toFixed(2)}</td>
                        <td>R {Number(orderItem.price).toFixed(2)}</td>
                        <td> {orderItem.qty}</td>
                    </tr>
                ));
        }

    }

    RejectOrder() {
        if (this.state.declineReason != '') {
            this.DeclineOrder(this.state.declineId, this.state.declineReason);
            this.setState({ declineModalVisibility: 'hidden' });
        } else {
            alert('Please enter a decline reason');
        }
    }

    renderOrders() {

        if (this.state.loadingOrders) {
            return (<tr>
                <td colspan="11">
                    <div style={{ textAlign: 'center' }}>
                        <div>
                            <div className="spinner-border" style={{ color: 'black' }} role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>);
        }
        else {
            return (this.state.orders.map(order =>
                <tr style={{ backgroundColor: 'white' }}>
                    <td className="sideBorder"> L{order.orderID} </td>
                    <td className="sideBorder"> {order.customerID} </td>
                    <td className="sideBorder"> {order.orderDate} </td>
                    <td className="sideBorder"> {order.placedBy} </td>
                    <td className="sideBorder"> {order.orderNotes}  </td>
                    <td className="sideBorder"> {order.orderStatus}  </td>
                    <td className="sideBorder" style={{ color: 'blue', cursor: 'pointer' }} onClick={() => this.SetOrderIdentifier(order.orderID)}>View</td>
                    <td className="sideBorder" style={{ color: this.state.documentType == 'Current' ? 'blue' : 'grey', cursor: 'pointer' }} onClick={() => this.SetOrderIdentifierForEdit(order.orderID, order.customerID)}>Edit</td>
                    <td className="sideBorder" style={{ visibility: this.state.ApproveVisibility, color: this.state.documentType == 'Current' ? (order.isAboveBudget ? 'orange' : 'green') : 'grey', cursor: 'pointer' }} onClick={() => this.CheckIfOrderAboveBudget(order.orderID, order.budgetId)}>Approve</td>
                    <td className="sideBorder" style={{ visibility: this.state.ApproveVisibility, color: this.state.documentType == 'Current' ? 'red' : 'grey', cursor: 'pointer' }} onClick={() => this.OpenDeclineModal(order.orderID)} >Decline</td>
                    <td className="sideBorder" style={{ color: 'blue', cursor: 'pointer', textAlign: 'center', visibility: this.state.documentType == 'Current' ? 'hidden' : 'visibile' }} onClick={() => this.SetApprovalHistoryOrderIdentifier(order.orderID)}>Approval</td>
                </tr>
            )
            );
        }

    }

    CancelDecline() {
        this.setState({ declineModalVisibility: 'hidden', declineId: 0, declineReason: '' });

    }

    ToChangePassword() {
        fetch('/api/Login/CanUpdatePassword' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(response => {

                if (response == 'true')
                    this.setState({ toChangePassword: true });
                else
                    alert('You currently do not have the required permissions to change your password.');

            });
    }

    MenuClick() {

        if (this.state.menuVisibility == 'hidden') {
            this.setState({ menuVisibility: 'visible' });
        }
        else if (this.state.menuVisibility == 'visible') {
            this.setState({ menuVisibility: 'hidden' });
        }
    }

    render() {


        if (this.state.toOrderApprovalHistory) {
            return <Route path='/' component={OrderApprovalHistory} />
        }


        if (this.state.toChangePassword) {
            return <Route path='/' component={ChangePassword} />

        }

        //Route Changers
        if (this.state.toSessionTimedOut) {
            return <Route path='/' component={SessionTimedOut} />
        }

        if (this.state.toAdmin) {
            return <Route path='/' component={AdminManage} />
        }


        if (this.state.toClearCartPrompt) {
            return <Route path='/' component={ClearCartPrompt} />
        }

        if (this.state.GoToOrderSearch) {
            return <Route path='/' component={OrderSearch} />
        }

        if (this.state.toBudgets) {
            return <Route path='/' component={Budget} />
        }

        if (this.state.toSavedCarts) {
            return <Route path='/' component={SavedCarts} />
        }

        if (this.state.toCheckOut) {
            return <Route path='/' component={CheckOut} />
        }

        if (this.state.toDocuments) {
            return <Route path='/' component={InvoiceOrderSearch} />
        }

        if (this.state.toDocumentsManage) {
            return <Route path='/' component={DocumentsManage} />
        }

        if (this.state.toLogIn) {
            return <Route path='/' component={Login} />
        }

        if (this.state.toProducts) {
            return <Route path='/' component={Product} />
        }

        if (this.state.ToCreditDocument) {
            return <Route path='/' component={CreditContent} />
        }

        if (this.state.ToDocument) {
            return <Route path='/' component={DocumentContent} />
        }

        if (this.state.ToOrderDocument) {
            return <Route path='/' component={OrderContent} />
        }

        let orderRows = this.renderOrders();
        let orderList = this.LoadTableOrderItems();
        let budget = this.state.budget;

        return (
            <div>
                <link href={this.state.stylesheet} rel="stylesheet" />
                <div style={{ top: '0', left: '0', height: '100%' }}>
                    <div className="header" style={{ position: 'fixed', zIndex : '500'}}>
                        <div className="grid_container_header" style={{ msHyphens: 'auto', display: 'inline-grid', gridTemplateColumns: '30% 40% 30%', display: 'grid -ms-grid', msGridColumns: '30% 40% 30%', columnCount: '3' }}>
                            <div className="grid_header">
                                <div className="icon_home" style={{ width: '100%', height: '50%', 'margin-top': '30px', 'margin-left': '10%', float: 'left', 'background-repeat': 'no-repeat' }}></div>
                            </div>
                            <div className="grid_header" style={{ 'text-align': 'left', overflow: 'hidden' }}>
                                <div className="header_container" style={{ width: '100%' }}>
                                    <div>
                                        <div style={{ display: 'inline' }}>
                                            <a className="header_text" onClick={() => this.GoBackToProducts()}  >Place Order</a>|
                                                <a className="header_text" onClick={() => this.GoToDocumentsManage()} >Documents</a><span style={{ visibility: this.state.canTrack }}>|</span>
                                            <a className="header_text" onClick={() => this.GoToOrderApproval()} style={{ visibility: this.state.canTrack }} >Order Approval</a>
                                            <span style={{ visibility: 'visible' }}>|</span>
                                            <a className="header_text" onClick={() => this.GoToBudget()} style={{ visibility: 'visible' }}>Budgets</a>
                                            <span style={{ visibility: this.state.isAdminVisibile }}>|</span>
                                            <a className="header_text" onClick={() => this.GoToAdmin()} style={{ visibility: this.state.isAdminVisibile }} >Admin</a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="grid_header">
                                <div className="header_container greeting" style={{ width: '100%' }}>
                                    <div className="header_text grid_item_1" style={{ lineHeight: '40px', textAlign: 'right' }}>
                                        <div style={{ height: '28px' }} className="overflow_header"> {this.state.userDetails.greeting + ', ' + this.state.userDetails.fullname}</div>
                                        <div style={{ height: '25px' }} className="overflow_header">{this.state.userDetails.accountName}</div>
                                        <div style={{ height: '28px' }} className="overflow_header">Group : {this.state.userDetails.accountCode}</div>
                                    </div>
                                    <div className="grid_item_2_reverse  h100">
                                        <div style={{ width: '100%', 'height': '100%' }}>
                                            <button id="btnMenu" onClick={() => this.MenuClick()} className="btn primary_btn header_btn twfont" style={{ lineHeight: '0.8', marginTop: '10px'  }}  ><i className="material-icons" style={{ width: '100%' }} >menu</i><label>Options</label></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="header_menu_container" style={{ visibility: this.state.menuVisibility }}>
                        <div className="grid_item_1 max-height-menu">
                            <button onClick={() => { this.MenuClick() }} style={{ width: '100%', height: '100%', backgroundColor: 'transparent', backgroundColor: 'transparent', border: 'none' }}></button>
                        </div>
                        <div className="header_menu grid_item_2 max-height-menu customer_background_foreground">
                            <hr />
                            <div>
                                <button id="canUpdateBtn" name="canUpdateBtn" className="btn  header_menu_item" onClick={() => { this.ToChangePassword() }}>Reset Password <i className="material-icons" style={{ position: 'relative', top: '6px', paddingLeft: '10px' }}>vpn_key</i></button>
                            </div>
                            <hr />
                            <div>
                                <button className="btn  header_menu_item" onClick={() => { this.GetCartState() }}>Logout <i className="material-icons" style={{ position: 'relative', top: '6px', paddingLeft: '10px' }}>exit_to_app</i></button>
                            </div>
                            <hr />
                        </div>
                    </div>
                    <div className=" sidenav customer_background_foreground" style={{ width: '100%', marginTop: '100px' }}>
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <br />
                            <div className="customer_background_foreground" style={{ width: '50%', margin: '0 25%', padding: '20px', verticalAlign: 'middle', height: '400px' }}>
                                <table style={{ width: '100%' }}>
                                    <tr>
                                        <td colspan="2">
                                            <label className="customer_background_foreground" style={{ fontFamily: 'Tw Cen MT', fontSize: 'large', fontWeight: 'bold', width: '100%', textAlign: 'center' }}>Search orders pending approval</label>
                                        </td>
                                    </tr>

                                    <tr><td colspan="2"><hr /></td></tr>
                                    <tr>
                                        <td style={{ width: '100%', fontSize: 'large', padding: '0px 20px' }}>
                                            <div className="divGridRadio2" style={{ visibility: this.state.ApproveVisibility }}>
                                                <div className="radio row1 col1">
                                                    <label className="checkbox-inline" ><input type="radio" id="currentRadioButton" style={{ cursor: 'pointer' }} name="optradio" value="Current" onChange={this.handleDocumentTypeChange.bind(this)} /> Current</label>
                                                </div>
                                                <div className="radio row1 col2">
                                                    <label className="checkbox-inline"><input type="radio" id="historyRadioButton" style={{ cursor: 'pointer' }} name="optradio" value="History" onChange={this.handleDocumentTypeChange.bind(this)} /> History</label>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr><td colspan="2"><br /></td></tr>
                                    <tr className="default_background">
                                        <td style={{ width: '400px', alignContent: 'center', textAlign: 'center' }}>
                                            <div className="div-grid-3" style={{ height: '15px' }}>
                                                <div className="row1 col1">
                                                    <label style={{ width: '100%', textAlign: 'center' }}>Search by</label>
                                                </div>
                                                <div className="row1 col2"></div>
                                                <div className="row1 col3">
                                                    <label style={{ width: '100%', textAlign: 'center' }}>Search text</label>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="default_background">
                                        <td style={{ width: '400px', alignContent: 'center', textAlign: 'center' }}>
                                            <div className="div-grid-3" style={{ height: '15px' }}>
                                                <div className="row1 col1">
                                                    <select ID="ddlAccounts" className="form-control twfont" onChange={this.handleSelectedFilter.bind(this)} style={{ textAlign: 'center' }}>
                                                        <option style={{ textAlign: 'center' }} value="default">-All Available-</option>
                                                        <option style={{ textAlign: 'center' }} value="orderId">Order Id</option>
                                                        <option style={{ textAlign: 'center' }} value="createdBy">Created By</option>
                                                        <option style={{ textAlign: 'center' }} value="account">Account</option>
                                                        <option style={{ textAlign: 'center' }} value="notes">Notes</option>
                                                        <option style={{ textAlign: 'center' }} value="status">Status</option>
                                                    </select>
                                                </div>
                                                <div className="row1 col2"></div>
                                                <div className="row1 col3" >
                                                    <input id="refno" name="refno" type="text" onChange={this.handleReferenceNumberChange.bind(this)} className="form-control twfont" placeholder="Reference Number" value={this.state.referenceNumber} />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr><td colspan="2"><br /></td></tr>
                                    <tr className="default_background">
                                        <td style={{ width: '400px', alignContent: 'center', textAlign: 'center' }}>
                                            <div className="div-grid-3" style={{ height: '15px' }}>
                                                <div className="row1 col1">
                                                    <label style={{ width: '100%', textAlign: 'center' }}>Start date</label>
                                                </div>
                                                <div className="row1 col2"></div>
                                                <div className="row1 col3">
                                                    <label style={{ width: '100%', textAlign: 'center' }}>End date</label>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr className="default_background">
                                        <td colspan="2" style={{ alignContent: 'center', textAlign: 'center' }}>
                                            <div className="div-grid-3">
                                                <div className="row1 col1"><input id="startDate" name="startDate" type="date" onChange={this.handleStartDateChange.bind(this)} value={this.state.startDate} className="form-control twfont" placeholder={this.state.startDate} /></div>
                                                <div className="row1 col2"></div>
                                                <div className="row1 col3"><input id="endDate" name="endDate" type="date" onChange={this.handleEndDateChange.bind(this)} value={this.state.endDate} className="form-control twfont" placeholder={this.state.endDate} /></div>
                                            </div>
                                        </td>
                                    </tr>
                                    <br />

                                    <tr>

                                        <td colspan="2" style={{ width: '100%' }}>
                                            <button ID="butSearch" className="btn primary_btn twfont" onClick={() => this.GetDocumentsByReference()} style={{ width: '100%' }} >Search</button>
                                        </td>
                                    </tr>
                                </table >
                            </div>
                        </div>
                        <div style={{ padding: '20px', height: '100%', width: '100%' }}>
                            <div style={{ height: '100%', width: '100%' }}>
                                <div style={{ visibility: this.state.ordersVisibility, height: this.state.ordersHeight, width: '90%', margin: '0px 5%' }}>
                                    <hr />
                                    <label className="text customer_background_foreground" style={{ fontFamily: 'TW Cen MT', fontSize: 'large', width: '100%', textAlign: 'center' }}>Orders</label>
                                    <hr />
                                    <table className="table table-bordered" style={{ fontFamily: 'TW Cen MT', overflow: 'scroll', overflow: 'scroll', maxHeight: '400px', fontSize: 'small' }}>
                                        <thead className="customer_base_background">
                                            <th style={{ padding: '0 20px', textAlign: 'center' }}> Order Id</th>
                                            <th style={{ padding: '0 20px', textAlign: 'center' }}> Account</th>
                                            <th style={{ padding: '0 20px', textAlign: 'center' }}> Order Date</th>
                                            <th style={{ padding: '0 20px', textAlign: 'center' }}> Created By</th>
                                            <th style={{ padding: '0 20px', textAlign: 'center' }}> Order Notes</th>
                                            <th style={{ padding: '0 20px', textAlign: 'center' }}> Order Status</th>
                                            <th className="customer_base_background" colSpan="2" style={{ padding: '0 20px', textAlign: 'center', visibility: this.state.ApproveVisibility }}></th>
                                            <th className="customer_base_background" colSpan="3" style={{ padding: '0 20px', textAlign: 'center', visibility: this.state.ApproveVisibility }}>Actions</th>
                                        </thead>
                                        {orderRows}
                                    </table>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />

                                </div>
                            </div>
                        </div>
                        <div style={{ visibility: this.state.declineModalVisibility }}>
                            <div className="outer-container" style={{ top: '0', fontFamily: 'TW Cent', backgroundColor: 'rgba(10,10,10, 0.8)' }} >
                                <div className="inner-container" style={{ height: '1000px' }}>
                                    <div className="centered-content twfont  customer_background_foreground"
                                        style={{ boxShadow: '2px 2px 8px #888888', border: 'none', width: '90%', maxWidth: '450px', backgroundColor: 'rgba(255,255,255,0.3)', fontFamily: 'TW Cen MT' }}>
                                        <table className="customer_background_foreground" style={{ width: '100%', margin: '0 auto', verticalAlign: 'middle', height: '150px', backgroundColor: 'rgba(255,255,255,0.3)' }}>
                                            <tbody>
                                                <tr><td><label style={{ width: '100%', textAlign: 'left', fontSize: 'larger', fontWeight: 'bolder' }}>Decline order : L{this.state.declineId}</label></td></tr>
                                                <tr>
                                                    <td colSpan="2" style={{ width: '100%' }}>
                                                        <textarea id="user" name="user" className="form-control twfont" value={this.state.declineReason} onChange={this.handleReasonChange.bind(this)} placeholder="Rejection reason..." style={{ height: '100px' }} />
                                                    </td>
                                                </tr>
                                                <tr><td><hr /></td></tr>
                                                <tr>
                                                    <td style={{ width: '50%' }}>
                                                        <button id="butCancel" className="btn primary_btn twfont" type="button" onClick={() => { this.CancelDecline() }} style={{ width: '95%' }} >Cancel</button>
                                                    </td>
                                                    <td style={{ width: '50%' }}>
                                                        <button id="butLogin" className="btn twfont" style={{ border: 'solid 1px black', backgroundColor: 'red', color: 'black', fontWeight: 'bolder', width: '95%' }} type="button" onClick={() => { this.RejectOrder() }} >Decline</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table >
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{ visibility: this.state.invalidDateModalVisibility }}>
                            <div className="outer-container" style={{ top: '0', fontFamily: 'TW Cent', backgroundColor: 'rgba(10,10,10, 0.8)' }} >
                                <div className="inner-container" style={{ height: '1000px' }}>
                                    <div className="centered-content twfont  customer_background_foreground"
                                        style={{ boxShadow: '2px 2px 8px #888888', border: 'none', width: '90%', maxWidth: '450px', backgroundColor: 'rgba(255,255,255,0.3)', fontFamily: 'TW Cen MT' }}>
                                        <table className="customer_background_foreground" style={{ width: '100%', margin: '0 auto', verticalAlign: 'middle', height: '150px', backgroundColor: 'rgba(255,255,255,0.3)' }}>
                                            <tbody>
                                                <tr><td><label style={{ width: '100%', textAlign: 'left', fontSize: 'larger', fontWeight: 'bolder' }}>Message:</label></td></tr>
                                                <tr>
                                                    <td colSpan="2" style={{ width: '100%' }}>
                                                        <textarea id="txt" name="txt" className="form-control twfont" value={this.state.invalidDateMessage} disabled style={{ height: '100px' }} />
                                                    </td>
                                                </tr>
                                                <tr><td><hr /></td></tr>
                                                <tr>
                                                    <td colSpan="2" style={{ width: '80%' }}>
                                                        <button id="butLogin" className="btn twfont" style={{ border: 'solid 1px black', backgroundColor: 'red', color: 'black', fontWeight: 'bolder', width: '95%' }} type="button" onClick={() => { this.ExitModal() }}>OK</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ visibility: this.state.invalidBudgetModalVisibility }}>
                        <div className="outer-container" style={{ top: '0', fontFamily: 'TW Cent', backgroundColor: 'rgba(10,10,10, 0.8)' }} >
                            <div className="inner-container" style={{ height: '1000px', paddingTop: '100px', verticalAlign: 'inherit' }}>
                                <div className="centered-content twfont  customer_background_foreground"
                                    style={{ boxShadow: '2px 2px 8px #888888', border: 'none', width: '90%', maxWidth: '450px', backgroundColor: 'rgba(255,255,255,0.3)', fontFamily: 'TW Cen MT', height: '400px' }}>
                                    <table className="customer_background_foreground" style={{ width: '100%', margin: '0 auto', verticalAlign: 'middle', height: '250px', backgroundColor: 'rgba(255,255,255,0.3)' }}>
                                        <tbody>
                                            <tr><td colSpan="2"><label style={{ width: '100%', textAlign: 'left', fontSize: 'larger', fontWeight: 'bolder' }}>Budget Summary (Order: L{this.state.approveOrderId}):</label></td></tr>
                                            <tr>
                                                <td colSpan="2" style={{ width: '100%' }}>
                                                    <div style={{ backgroundColor: 'white', width: '100%' }}>
                                                        <table className="report-tbl" style={{ lineHeight: 'inherit', fontSize: 'inherit' }}>
                                                            <tr>
                                                                <td className="label">Name</td>
                                                                <td>{budget.name}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="label">Account</td>
                                                                <td>{budget.account}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="label">Budget: </td>
                                                                <td>R {Number(budget.amount).toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="label">Timespan:</td>
                                                                <td>{budget.fromDate} :: {budget.toDate}</td>
                                                            </tr>
                                                            <tr><td colspan="2" className="label" style={{ width: '100%', textAlign: 'center' }}>Current</td></tr>
                                                            <tr>
                                                                <td className="label">% Used: * </td>
                                                                <td style={{ color: 'red' }}>{Number((budget.amountSpentWithVat / budget.amount) * 100).toFixed(2)} %</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="label">Total spent: </td>
                                                                <td>R {Number(budget.amountSpentWithVat).toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                            </tr>
                                                            <tr><td colspan="2" className="label" style={{ width: '100%', textAlign: 'center' }}>After</td></tr>
                                                            <tr>
                                                                <td className="label">% Used: * </td>
                                                                <td style={{ color: 'red' }}>{Number((budget.amountSpentWithVatAndOrder / budget.amount) * 100).toFixed(2)} %</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="label">Total spent: </td>
                                                                <td>R {Number(budget.amountSpentWithVatAndOrder).toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                            </tr>

                                                        </table>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr><td colspan="2"><hr /></td></tr>
                                            <tr>
                                                <td style={{ width: '50%' }}>
                                                    <button id="btnApproveModal" className="btn twfont" style={{ border: 'solid 1px black', backgroundColor: 'green', color: 'black', fontWeight: 'bolder', width: '95%' }} type="button" onClick={() => { this.ApproveOrder(this.state.approveOrderId) }}>Approve</button>
                                                </td>
                                                <td style={{ width: '50%' }}>
                                                    <button id="btnLoginModal" className="btn twfont" style={{ border: 'solid 1px black', backgroundColor: 'red', color: 'black', fontWeight: 'bolder', width: '95%' }} type="button" onClick={() => { this.ExitBudgetModal() }}>Cancel</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table >
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        );
    }

    ///Global methods

    GetCartState() {

        fetch('/api/Product/GetCartCount' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == "valued") {
                    this.setState({ toClearCartPrompt: true });
                } else {
                    window.location.href = '\\';
                }
            });
    }

    GoToDocumentsManage() {
        this.setState({ toDocumentsManage: true });
    }


    CheckIfAdmin() {
        fetch('/api/Login/isAdmin' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                let apprVis = (data == 'Y' ? 'visible' : 'hidden');
                this.setState({ isAdminVisibile: apprVis });
            });
    }

    GoToOrderSearch() {
        this.setState({ GoToOrderSearch: true });
    }

    GoToDocuments() {
        this.setState({ toDocuments: true });
    }

    toOrderForm() {
        this.setState({ toOrderForm: true });
    }

    GoToLogIn() {
        this.setState({ toLogIn: true });
    }

    GoToAdmin() {
        this.setState({ toAdmin: true });
    }

    GoBackToProducts() {
        this.setState({ toProducts: true });
    }

    GoToBudget() {
        this.setState({ toBudgets: true });
    }

    GoToOrderForm() {
        this.setState({ toOrderForms: true });
    }

    GoToOrderSearch() {
        this.setState({ GoToOrderSearch: true });
    }


    GoToSavedCarts() {
        this.setState({ toSavedCarts: true });
    }


    GoToOrderApproval() {
        this.setState({ toOrderApproval: true });
    }

    GetUserDetails() {
        fetch('api/Login/GetUserDetails' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.json())
            .then(data => { this.setState({ userDetails: data }) });
    }

    CheckIfCanTrack() {
        fetch('/api/Login/canTrack' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                let track = (data == 'Y' ? 'visible' : 'hidden');
                this.setState({ canTrack: track });
            });
    }
}


//<td className="sideBorder" style={{ padding: '0 20px', color: 'blue', border: 'black' }} onClick={() => this.SetOrderIdentifier(order.orderID)}>print</td>


//Approve Decline prompt
//<table className="table table-bordered twfont" style={{ width: '20%', textAlign: 'center', position: 'absolute', right: '40px', top: '120px' }}>
//    <tr><th style={{ backgroundColor: 'lightgreen' }}>Approved</th><td style={{ color: 'green', backgroundColor: 'white', fontWeight: 'bold', verticalAlign: 'inherit', padding: '0 3px', fontSize: 'large' }}>{this.state.approvalCount}</td>
//        <th style={{ backgroundColor: 'lightcoral' }}>Declined</th><td style={{ color: 'red', backgroundColor: 'white', fontWeight: 'bold', verticalAlign: 'inherit', padding: '0 3px', fontSize: 'large' }}> {this.state.declineCount}</td></tr>
//</table>



//<label className="text customer_background_foreground" style={{ fontFamily: 'TW Cen MT', fontSize: 'large', width: '100%', textAlign: 'center' }}>Order Content</label>
//    <hr />
//    <table className="table table-bordered" style={{ fontFamily: 'TW Cen MT', overflow: 'scroll', whiteSpace: 'nowrap', maxHeight: '350px' }}>
//        <thead className="customer_base_background">
//            <th>Product ID</th>
//            <th>Date Added</th>
//            <th>Excluded</th>
//            <th style={{ width: 'inherit' }} >Price</th>
//            <th>Qty</th>
//        </thead>
//        {orderList}
//    </table>

//<td className="sideBorder" style={{ padding: (this.state.ApproveVisibility == 'hidden' ? '0' : '0 20px'), width: (this.state.ApproveVisibility == 'hidden' ? '0.01px' : 'auto'), visibility: this.state.ApproveVisibility, color: (order.budgetAmount < order.budgetSpent ? 'darkred' : 'black'), fontStyle: 'italic' }}>Please note, the current budget is R{Number(order.budgetAmount).toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })},<br /> the current actual spent in this budget is R{Number(order.budgetSpent).toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>

//<th style={{ padding: '0 20px', textAlign: 'center', visibility: this.state.ApproveVisibility }}>Budgets</th>


//<tr>
//    <td className="label">Total spent (Excluding VAT): </td>
//    <td>R {Number(budget.amountSpent).toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
//</tr>
//    <tr>
//        <td className="label">VAT : </td>
//        <td>R {Number(budget.vat).toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
//    </tr>