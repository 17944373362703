import React, { Component } from 'react';
import { Route } from 'react-router';
import { DocumentContent } from './DocumentContent';
import { OrderContent } from './OrderContent';
import { CreditContent } from './CreditContent';
import { OrderSearch } from './OrderSearch';
import { Product } from './Products';
import { ChangePassword } from './ChangePassword';
import { Login } from './Login';
import { CheckOut } from './CheckOut';
import { SavedCarts } from './SavedCarts';
import { InvoiceOrderSearch } from './InvoiceOrderSearch';
import { Budget } from './Budget'
import { AdminUser } from './AdminUser'
import { Users } from './Users';
import { OrderApproval } from './OrderApproval'
import { withRouter, Redirect } from 'react-router-dom';
import { SessionTimedOut } from './SessionTimedOut';
import { ClearCartPrompt } from './ClearCartPrompt';
import { LabelAddressList } from './LabelAddressList';

export class AdminManage extends Component {
    displayName = AdminManage.name

    constructor(props) {
        super(props);

        this.state = {
            valid: false,
            userDetails: {},
            referenceNumber: '',
            toChangePassword: false,
            menuVisibility: 'hidden',
            startDate: '',
            endDate: '',
            documentType: 'Orders',
            ApproveVisibility: 'hidden',

            orders: [],
            loadingOrders: false,
            ordersHeight: '500px',
            ordersVisibility: 'visible',

            ToDocument: false,
            ToOrderDocument: false,
            ToCreditDocument: false,

            toCheckOut: false,
            toSaveCart: false,
            toManage: false,
            toClearCartPrompt: false,
            toDocuments: false,
            toOrderForm: false,
            toAdmin: false,
            toLogIn: false,
            toBudgets: false,
            toOrderSearch: false,
            toOrderApproval: false,
            toSavedCarts: false,
            isAdminVisibile: 'hidden',
            toProducts: false,
            canTrack: 'hidden',
            toSessionTimedOut: false,
            invalidDateMessage: '',
            invalidDateModalVisibility: 'hidden',
            invalidDateModalVisibility: 'hidden',
            invalidDateMessage: '',
            invalidDateModalVisibility: 'hidden',
            toLabelAddressList: false,

            userButtonActive: false,
            labelButtonActive: false
        };

        this.CheckSessionInitialize();
    }


    ToChangePassword() {
        fetch('/api/Login/CanUpdatePassword' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(response => {

                if (response == 'true')
                    this.setState({ toChangePassword: true });
                else
                    alert('You currently do not have the required permissions to change your password.');

            });
    }

    MenuClick() {

        if (this.state.menuVisibility == 'hidden') {
            this.setState({ menuVisibility: 'visible' });
        }
        else if (this.state.menuVisibility == 'visible') {
            this.setState({ menuVisibility: 'hidden' });
        }
    }

    CheckSessionInitialize() {
        this.setState({ complete_update: false });

        fetch('api/Login/CheckSession?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });
                    this.GetUserDetails();
                    this.CheckIfApprover();
                    this.CheckIfAdmin();
                    this.CheckIfCanTrack();
                    this.SetButtonStates();
                }
                else {
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }

    CheckSession() {
        this.setState({ complete_update: false });

        fetch('api/Login/CheckSession?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });
                }
                else if (data == 'timedout') {
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }

    SetButtonStates() {
        fetch('/api/Admin/GetAdminDetails?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.json())
            .then(data => {
                if (!data.isDsvUser)
                    document.getElementById('btnLabels').setAttribute('disabled', 'disabled');
            })
    }

    CheckIfApprover() {
        fetch('/api/Login/isApprover' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => { this.setState({ ApproveVisibility: (data == 'Y' ? 'visible' : 'hidden') }) })
    }

    ProductLink() {
        this.setState({ toProductsPage: true });
    }

    handleReferenceNumberChange(e) {
        this.setState({ referenceNumber: e.target.value }
        );
    }

    handleStartDateChange(e) {
        this.setState({ startDate: e.target.value }
        );
    }

    handleEndDateChange(e) {
        this.setState({ endDate: e.target.value }
        );
    }

    renderOrders() {
        if (this.state.loadingOrders) {
            return (<tr><td>Loading</td></tr>);
        }
        else {
            return (this.state.orders.map(order =>
                <tr style={{ backgroundColor: 'white' }}>
                    <td className="sideBorder" style={{ padding: '0 1%', color: 'blue', border: 'black', cursor: 'pointer' }} onClick={() => this.SetOrderIdentifier(order.orderID)}>L{order.orderID}</td>
                    <td className="sideBorder" style={{ padding: '0 1%' }}> {order.orderDate} </td>
                    <td className="sideBorder" style={{ padding: '0 1%' }}> {order.deliveryDate}  </td>
                    <td className="sideBorder overflow_table" style={{ padding: '0 1%' }}> {order.orderNotes}  </td>
                    <td className="sideBorder" style={{ padding: '0 1%' }}> {order.approvalStatus}  </td>
                    <td className="sideBorder" style={{ padding: '0 1%' }}> {order.orderStatus}  </td>
                    <td className="sideBorder overflow_table" style={{ padding: '0 1%' }}> {order.customerRef}  </td>
                    <td className="sideBorder" style={{ padding: '0 1%' }}> {order.sysproRef}  </td>
                    <td className="sideBorder" style={{ padding: '0 1%' }}> {order.placedBy}  </td>
                </tr>
            )
            );
        }

    }

    render() {

        //Route Change
        if (this.state.toSessionTimedOut) {
            return <Route path='/' component={SessionTimedOut} />
        }

        if (this.state.toAdmin) {
            return <Route path='/' component={Users} />
        }

        if (this.state.toClearCartPrompt) {
            return <Route path='/' component={ClearCartPrompt} />
        }


        if (this.state.ToOrderDocument) {
            return <Route path='/' component={OrderContent} />
        }

        if (this.state.GoToOrderSearch) {
            return <Route path='/' component={OrderSearch} />
        }

        if (this.state.toChangePassword) {
            return <Route path='/' component={ChangePassword} />

        }

        if (this.state.toBudgets) {
            return <Route path='/' component={Budget} />
        }

        if (this.state.toSavedCarts) {
            return <Route path='/' component={SavedCarts} />
        }


        if (this.state.toCheckOut) {
            return <Route path='/' component={CheckOut} />
        }

        if (this.state.toDocuments) {
            return <Route path='/' component={InvoiceOrderSearch} />
        }

        if (this.state.toOrderApproval) {
            return <Route path='/' component={OrderApproval} />
        }

        if (this.state.toLogIn) {
            return <Route path='/' component={Login} />
        }

        if (this.state.toProducts) {
            return <Route path='/' component={Product} />
        }

        if (this.state.toClearCartPrompt) {
            return <Route path='/' component={ClearCartPrompt} />
        }

        //end

        if (this.state.ToCreditDocument) {
            return <Route path='/' component={CreditContent} />
        }

        if (this.state.toLabelAddressList) {
            return <Route path='/' component={LabelAddressList} />
        }

        if (this.state.ToOrderDocument) {
            return <Route path='/' component={OrderContent} />
        }

        let orderRows = this.renderOrders();

        return (
            <div>
                <div style={{ top: '0', left: '0', height: '100%' }}>
                    <div className="header" style={{ position: 'fixed', zIndex: '500' }}>
                        <div className="grid_container_header" style={{ msHyphens: 'auto', display: 'inline-grid', gridTemplateColumns: '30% 40% 30%', display: 'grid -ms-grid', msGridColumns: '30% 40% 30%', columnCount: '3' }}>
                            <div className="grid_header">
                                <div className="icon_home" style={{ width: '100%', height: '50%', marginTop: '30px', marginLeft: '10%', float: 'left', backgroundRepeat: 'no-repeat' }}></div>
                            </div>
                            <div className="grid_header" style={{ textAlign: 'left', overflow: 'hidden' }}>
                                <div className="header_container" style={{ width: '100%' }}>
                                    <div>
                                        <div style={{ display: 'inline' }}>
                                            <a className="header_text" onClick={() => this.GoBackToProducts()}  >Place Order</a>|
                                            <a className="header_text" onClick={() => this.GoToOrderSearch()} >Documents</a><span style={{ visibility: this.state.canTrack }}>|</span>
                                            <a className="header_text" onClick={() => this.GoToOrderApproval()} style={{ visibility: this.state.canTrack }} >Order Approval</a>
                                            <span style={{ visibility: 'visible' }}>|</span>
                                            <a className="header_text" onClick={() => this.GoToBudget()} style={{ visibility: 'visible' }}>Budgets</a>
                                            <span style={{ visibility: this.state.isAdminVisibile }}>|</span>
                                            <a className="header_text" onClick={() => this.GoToAdmin()} style={{ visibility: this.state.isAdminVisibile }} >Admin</a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="grid_header">
                                <div className="header_container greeting" style={{ width: '100%' }}>
                                    <div className="header_text grid_item_1" style={{ lineHeight: '40px', textAlign: 'right' }}>
                                        <div style={{ height: '28px' }} className="overflow_header"> {this.state.userDetails.greeting + ', ' + this.state.userDetails.fullname}</div>
                                        <div style={{ height: '25px' }} className="overflow_header">{this.state.userDetails.accountName}</div>
                                        <div style={{ height: '28px' }} className="overflow_header">Group : {this.state.userDetails.accountCode}</div>
                                    </div>
                                    <div className="grid_item_2_reverse  h100">
                                        <div style={{ width: '100%', 'height': '100%' }}>
                                            <button id="btnMenu" onClick={() => this.MenuClick()} className="btn primary_btn header_btn twfont" style={{ lineHeight: '0.8', marginTop: '10px' }}  ><i className="material-icons" style={{ width: '100%' }} >menu</i><label>Options</label></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="header_menu_container" style={{ visibility: this.state.menuVisibility }}>
                        <div className="grid_item_1 max-height-menu">
                            <button onClick={() => { this.MenuClick() }} style={{ width: '100%', height: '100%', backgroundColor: 'transparent', backgroundColor: 'transparent', border: 'none' }}></button>
                        </div>
                        <div className="header_menu grid_item_2 max-height-menu customer_background_foreground">
                            <hr />
                            <div>
                                <button id="canUpdateBtn" name="canUpdateBtn" className="btn  header_menu_item" onClick={() => { this.ToChangePassword() }}>Reset Password <i className="material-icons" style={{ position: 'relative', top: '6px', paddingLeft: '10px' }}>vpn_key</i></button>
                            </div>
                            <hr />
                            <div>
                                <button className="btn  header_menu_item" onClick={() => { this.GetCartState() }}>Logout <i className="material-icons" style={{ position: 'relative', top: '6px', paddingLeft: '10px' }}>exit_to_app</i></button>
                            </div>
                            <hr />
                        </div>
                    </div>
                    <div className=" sidenav customer_background_foreground" style={{ width: '100%', minHeight: '1000px', marginTop: '100px' }}>
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <br />
                            <div className="customer_background_foreground" style={{ width: '80%', margin: '5%', verticalAlign: 'middle', height: '500px' }}>
                                <hr />
                                <div>
                                    <label className="customer_background_foreground" style={{ fontFamily: 'Tw Cen MT', fontSize: 'large', fontWeight: 'bold', width: '100%', textAlign: 'center' }}>Select Option</label>
                                </div>
                                <hr />
                                <table style={{ width: '100%' }}>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <button id="btnUsers" className="btn secondary_btn twfont" onClick={() => this.GoToUsers()} style={{ width: '100%', height: '100px' }} >Users</button>
                                            </td>
                                            <td>
                                                <button id="btn1" className="btn twfont" disabled style={{ width: '100%', height: '100px', backgroundColor: 'silver', border: 'dotted black', color: 'silver' }} >Admin</button>
                                            </td>
                                            <td>
                                                <button id="btn2" className="btn twfont" disabled style={{ width: '100%', height: '100px', backgroundColor: 'silver', border: 'dotted black', color: 'silver' }} >Admin</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <button id="btnLabels" className="btn secondary_btn twfont" onClick={() => this.GoToLabelAddressList()} style={{ width: '100%', height: '100px' }} >Labels</button>
                                            </td>
                                            <td>
                                                <button id="btn3" className="btn twfont" disabled style={{ width: '100%', height: '100px', backgroundColor: 'silver', border: 'dotted black', color: 'silver' }} >Admin</button>
                                            </td>
                                            <td>
                                                <button id="btn4" className="btn twfont" disabled style={{ width: '100%', height: '100px', backgroundColor: 'silver', border: 'dotted black', color: 'silver' }} >Admin</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <button id="btn5" className="btn twfont" disabled style={{ width: '100%', height: '100px', backgroundColor: 'silver', border: 'dotted black', color: 'silver' }} >Admin</button>
                                            </td>
                                            <td>
                                                <button id="btn6" className="btn twfont" disabled style={{ width: '100%', height: '100px', backgroundColor: 'silver', border: 'dotted black', color: 'silver' }} >Admin</button>
                                            </td>
                                            <td>
                                                <button id="btn7" className="btn twfont" disabled style={{ width: '100%', height: '100px', backgroundColor: 'silver', border: 'dotted black', color: 'silver' }} >Admin</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table >
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        );
    }

    //Class Methods
    GetCartState() {

        fetch('/api/Product/GetCartCount', { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == "valued") {
                    this.setState({ toClearCartPrompt: true });
                } else {
                    window.location.href = '\\';
                }
            });
    }

    CheckIfAdmin() {
        fetch('/api/Login/isAdmin?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => { this.setState({ isAdminVisibile: (data == 'Y' ? 'visible' : 'hidden') }) })
    }

    GoToOrderSearch() {
        this.setState({ GoToOrderSearch: true });
    }

    GoToLabelAddressList() {
        this.setState({ toLabelAddressList: true });
    }

    toOrderForm() {
        this.setState({ toOrderForm: true });
    }

    GoToLogIn() {
        this.setState({ toLogIn: true });
    }

    GoToUsers() {
        this.setState({ toAdmin: true });
    }

    GoBackToProducts() {
        this.setState({ toProducts: true });
    }

    GoToBudget() {
        this.setState({ toBudgets: true });
    }

    GoToOrderForm() {
        this.setState({ toOrderForms: true });
    }

    GoToOrderSearch() {
        this.setState({ GoToOrderSearch: true });
    }

    GoToOrderApproval() {
        this.setState({ toOrderApproval: true });
    }

    GoToSavedCarts() {
        this.setState({ toSavedCarts: true });
    }

    GetUserDetails() {
        fetch('api/Login/GetUserDetails?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.json())
            .then(data => { this.setState({ userDetails: data }) })

    }

    CheckIfCanTrack() {
        fetch('/api/Login/canTrack?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => { this.setState({ canTrack: (data == 'Y' ? 'visible' : 'hidden') }) })
    }
}