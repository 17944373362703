import React, { Component } from 'react'; import { withRouter, Redirect } from 'react-router-dom';
import { Route } from 'react-router';
import { DocumentContent } from './DocumentContent';
import { ChangePassword } from './ChangePassword';

import { CreateOrderForm } from './CreateOrderForm';
import { CreateOrderFormContent } from './CreateOrderFormContent';
import { SessionTimedOut } from './SessionTimedOut';
import { Product } from './Products';
import { Login } from './Login';
import { CheckOut } from './CheckOut';
import { SavedCarts } from './SavedCarts';
import { InvoiceOrderSearch } from './InvoiceOrderSearch';
import { OrderSearch } from './OrderSearch'
import { Budget } from './Budget'
import { AdminUser } from './AdminUser'
import { Users } from './Users';
import { ClearCartPrompt } from './ClearCartPrompt';
import { OrderApproval } from './OrderApproval';
import { DocumentsManage } from './DocumentsManage';
import { AdminManage } from './AdminManage';

export class OrderFormList extends Component {
    displayName = OrderFormList.name

    constructor(props) {
        super(props);

        this.state = {
            imageLocation: '',
            userDetails: {},
            OrderFormHeaderVisibility: 'hidden',
            OrderFormContentVisibility: 'hidden',
            toChangePassword: false,
            menuVisibility: 'hidden',

            OrderFormHeaderWidth: '100%',
            OrderFormContentWidth: '0%',

            loadingOrderformtHeaders: false,
            loadingOrderform: false,

            orderform: [],
            orderformHeaders: [],

            ToDocument: false,
            ToDocumentContent: false,
            toOrderForm: false,
            toOrderFormContent: false,

            toCheckOut: false,
            toClearCartPrompt: false,
            toSaveCart: false,
            toManage: false,
            toDocuments: false,
            toOrderForm: false,
            toAdmin: false,
            toLogIn: false,
            toBudgets: false,
            toOrderSearch: false,
            toOrderApproval: false,
            toSavedCarts: false,
            canTrack: 'hidden',
            isAdminVisibile: 'hidden',
            toProducts: false,
            toSessionTimedOut: false,
            toDocumentsManage: false,

        };

        this.CheckSessionInitialize();
    }

    CheckSessionInitialize() {
        this.setState({ complete_update: false });

        fetch('api/Login/CheckSession?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });

                    this.GetUserDetails();
                    this.GetDefaultImageFolder();
                    this.CheckIfAdmin();
                    this.CheckIfCanTrack();
                    this.GetOrderFormHeaders();
                }
                else if (data == 'timedout') {
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }

    CheckSession() {
        this.setState({ complete_update: false });

        fetch('api/Login/CheckSession?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });
                }
                else if (data == 'timedout') {
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }


    GetDefaultImageFolder() {
        fetch('api/Global/GetDefaultImageLocation?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                this.setState({ imageLocation: data })
            })
    }

    ProductLink() {
        this.setState({ toProducts: true });
    }

    toOrderForm() {
        this.setState({ toOrderForm: true });
    }

    GetOrderFormHeaders() {

        this.setState({ loadingOrderformtHeaders: true });

        fetch('api/Orderform/GetOrderForms?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.json())
            .then(data => {
                this.setState({ orderformHeaders: data, loadingOrderformtHeaders: false });
            })
    }

    GetOrderForm(orderformId) {

        this.setState({ loadingOrderform: true });
        fetch('api/Orderform/GetOrderFormContent?orderformId=' + orderformId + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.json())
            .then(data => {
                this.setState({ orderform: data, loadingOrderform: false });
            });
    }

    LoadFormToProducts(orderformId) {
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            fetch('api/Orderform/LoadFormToProducts?orderformId=' + orderformId + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(() => { this.setState({ toProducts: true }) });
        }
    }

    DeleteOrderForm(orderformId) {
        this.setState({ loadingOrderformtHeaders: true });
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            fetch('api/Orderform/DeleteOrderForm?orderformId=' + orderformId + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(response => response.json())
                .then(data => {
                    this.setState({ orderformHeaders: data, loadingOrderformtHeaders: false });
                });
        }
    }

    EditOrderForm(orderformId) {
        this.setState({ loadingOrderformtHeaders: true });
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            fetch('api/Orderform/EditOrderFormID?orderformId=' + orderformId + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(() => {
                    this.setState({ loadingOrderformtHeaders: false });
                    this.setState({ toOrderFormContent: true })
                });
        }
    }

    renderOrderFormHeader() {

        if (this.state.loadingOrderformtHeaders) {
            return (<tr><td>Loading</td></tr>);
        }
        else {
            return (this.state.orderformHeaders.map(orderformHeader =>
                <tr style={{ backgroundColor: 'white' }}>
                    <td className="sideBorder" style={{ padding: '0 20px', color: 'blue', border: 'black', cursor: 'pointer' }} onClick={() => { this.GetOrderForm(orderformHeader.orderFormId) }}>{orderformHeader.orderFormId}</td>
                    <td className="sideBorder" style={{ padding: '0 20px' }}>{orderformHeader.name}</td>
                    <td className="sideBorder" style={{ padding: '0 20px' }}> {orderformHeader.customerId} </td>
                    <td className="sideBorder" style={{ padding: '0 20px', color: 'green', border: 'black', cursor: 'pointer' }} onClick={() => { this.LoadFormToProducts(orderformHeader.orderFormId) }}>load</td>
                    <td className="sideBorder" style={{ padding: '0 20px', color: 'blue', border: 'black', cursor: 'pointer' }} onClick={() => { this.EditOrderForm(orderformHeader.orderFormId) }}>edit</td>
                    <td className="sideBorder" style={{ padding: '0 20px', color: 'red', border: 'black', cursor: 'pointer' }} onClick={() => { this.DeleteOrderForm(orderformHeader.orderFormId) }}>delete</td>
                </tr>
            )
            );
        }

    }

    renderOrderform() {
        let altImageLocation = this.state.imageLocation;

        if (this.state.loadingProducts) {
            return (
                <tr>
                    <td>loading</td>
                </tr>
            )
        }
        else {
            let backcolor = 'white';

            return (this.state.orderform.map(form =>
                <tr style={{ lineHeight: '50px', borderBottom: 'solid 1px', backgroundColor: 'white' }}>
                    <td style={{ width: '60px' }}><img className="table_image_border" type="image" style={{ backgroundColor: 'white' }} onError={(e) => { e.target.onerror = null; e.target.src = altImageLocation }} id="imProd" src={form.imageUrl} /></td>
                    <td>{form.itemCode}</td>
                    <td>{form.description}</td>
                    <td>{form.uomdescription}</td>
                    <td>{form.quantityOnHand}</td>
                    <td>R {Number(form.unitPrice).toFixed(2)}</td>
                    <td> {form.quantityChosen}</td>
                </tr>
            ));
        }
    }


    ToChangePassword() {
        fetch('/api/Login/CanUpdatePassword' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(response => {

                if (response == 'true')
                    this.setState({ toChangePassword: true });
                else
                    alert('You currently do not have the required permissions to change your password.');

            });
    }

    MenuClick() {

        if (this.state.menuVisibility == 'hidden') {
            this.setState({ menuVisibility: 'visible' });
        }
        else if (this.state.menuVisibility == 'visible') {
            this.setState({ menuVisibility: 'hidden' });
        }
    }

    render() {

        //Route Changers
        if (this.state.toSessionTimedOut) {
            return <Route path='/' component={SessionTimedOut} />
        }

        if (this.state.toOrderForm) {
            return <Route path='/' component={CreateOrderForm} />
        }
        if (this.state.toOrderFormContent) {
            return <Route path='/' component={CreateOrderFormContent} />
        }

        if (this.state.toDocumentsManage) {
            return <Route path='/' component={DocumentsManage} />
        }

        if (this.state.toAdmin) {
            return <Route path='/' component={AdminManage} />
        }


        if (this.state.toChangePassword) {
            return <Route path='/' component={ChangePassword} />

        }


        if (this.state.GoToOrderSearch) {
            return <Route path='/' component={OrderSearch} />
        }

        if (this.state.toBudgets) {
            return <Route path='/' component={Budget} />
        }

        if (this.state.toSavedCarts) {
            return <Route path='/' component={SavedCarts} />
        }

        if (this.state.toCheckOut) {
            return <Route path='/' component={CheckOut} />
        }

        if (this.state.toDocuments) {
            return <Route path='/' component={InvoiceOrderSearch} />
        }

        if (this.state.toOrderApproval) {
            return <Route path='/' component={OrderApproval} />
        }

        if (this.state.toLogIn) {
            return <Route path='/' component={Login} />
        }

        if (this.state.toProducts) {
            return <Route path='/' component={Product} />
        }

        if (this.state.toClearCartPrompt) {
            return <Route path='/' component={ClearCartPrompt} />
        }

        //end

        if (this.state.ToDocument) {
            return <Route path='/' component={DocumentContent} />
        }

        let orderformHeaders = this.renderOrderFormHeader();
        let orderform = this.renderOrderform();

        return (
            <div style={{ top: '0', left: '0', height: '100%' }}>
                <div className="header" style={{ position: 'fixed', zIndex : '500'}}>
                    <div className="grid_container_header" style={{ msHyphens: 'auto', display: 'inline-grid', gridTemplateColumns: '30% 40% 30%', display: 'grid -ms-grid', msGridColumns: '30% 40% 30%', columnCount: '3' }}>
                        <div className="grid_header">
                            <div className="icon_home" style={{ width: '100%', height: '50%', marginTop: '30px', marginLeft: '10%', float: 'left', backgroundRepeat: 'no-repeat' }}></div>
                        </div>
                        <div className="grid_header" style={{ textAlign: 'left', overflow: 'hidden' }}>
                            <div className="header_container" style={{ width: '100%' }}>
                                <div>
                                    <div style={{ display: 'inline' }}>
                                        <a className="header_text" onClick={() => this.GoBackToProducts()}  >Place Order</a>|
                                        <a className="header_text" onClick={() => this.GoToDocumentsManage()} >Documents</a><span style={{ visibility: this.state.canTrack }}>|</span>
                                        <a className="header_text" onClick={() => this.GoToOrderApproval()} style={{ visibility: this.state.canTrack }} >Order Approval</a>
                                        <span style={{ visibility: 'visible' }}>|</span>
                                        <a className="header_text" onClick={() => this.GoToBudget()} style={{ visibility: 'visible' }}>Budgets</a>
                                        <span style={{ visibility: this.state.isAdminVisibile }}>|</span>
                                        <a className="header_text" onClick={() => this.GoToAdmin()} style={{ visibility: this.state.isAdminVisibile }} >Admin</a>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="grid_header">
                            <div className="header_container greeting" style={{ width: '100%' }}>
                                <div className="header_text grid_item_1" style={{ lineHeight: '40px', textAlign: 'right' }}>
                                    <div style={{ height: '28px' }} className="overflow_header"> {this.state.userDetails.greeting + ', ' + this.state.userDetails.fullname}</div>
                                    <div style={{ height: '25px' }} className="overflow_header">{this.state.userDetails.accountName}</div>
                                    <div style={{ height: '28px' }} className="overflow_header">Group : {this.state.userDetails.accountCode}</div>
                                </div> 
                                <div className="grid_item_2_reverse  h100">
                                    <div style={{ width: '100%', 'height': '100%' }}>
                                        <button id="btnMenu" onClick={() => this.MenuClick()} className="btn primary_btn header_btn twfont" style={{ lineHeight: '0.8', marginTop: '10px'  }}  ><i className="material-icons" style={{ width: '100%' }} >menu</i><label>Options</label></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header_menu_container" style={{ visibility: this.state.menuVisibility }}>
                    <div className="grid_item_1 max-height-menu">
                        <button onClick={() => { this.MenuClick() }} style={{ width: '100%', height: '100%', backgroundColor: 'transparent', border: 'none' }}></button>
                    </div>
                    <div className="header_menu grid_item_2 max-height-menu customer_background_foreground">
                        <hr />
                        <div>
                            <button id="canUpdateBtn" name="canUpdateBtn" className="btn  header_menu_item" onClick={() => { this.ToChangePassword() }}>Reset Password <i className="material-icons" style={{ position: 'relative', top: '6px', paddingLeft: '10px' }}>vpn_key</i></button>
                        </div>
                        <hr />
                        <div>
                            <button className="btn  header_menu_item" onClick={() => { this.GetCartState() }}>Logout <i className="material-icons" style={{ position: 'relative', top: '6px', paddingLeft: '10px' }}>exit_to_app</i></button>
                        </div>
                        <hr />
                    </div>
                </div>
                <div className=" sidenav customer_background_foreground" style={{ width: '100%', marginTop: '100px' }}>
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <br />
                    </div>
                    <div style={{ padding: '20px', height: '100%', width: '100%' }}>
                        <div style={{ height: '100%', width: '100%', minHeight: '1000px' }}>
                            <div >
                                <hr />
                                <div><label className="text customer_background_foreground" style={{ fontFamily: 'TW Cen MT', fontSize: 'large', width: '100%', textAlign: 'center' }}>Order Forms</label></div>
                                <hr />
                                <div style={{ width: '25%', marginLeft: '37.5%', marginRight: '37.5%' }}>
                                    <div className="input-group-prepend">
                                        <button id="btnOrderForms" className="btn primary_btn" style={{ fontFamily: 'Tw Cen MT', height: '38px', width: '100%', margin: '0 2%' }} onClick={() => this.toOrderForm()}>Add Order Form<i className="material-icons" style={{ position: 'absolute', paddingLeft: '10px' }}>description</i></button>
                                    </div>

                                </div>
                                <br />

                                <div style={{ width: '40%', marginLeft: '30%', marginRight: '30%' }}>
                                    <table className="table table-bordered scrollable-element" style={{ fontFamily: 'TW Cen MT', display: 'block', overflow: 'scroll', whiteSpace: 'nowrap' }}>
                                        <thead className="customer_base_background" >
                                            <tr>
                                                <th style={{ padding: '0 20px' }}> Form Id</th>
                                                <th style={{ padding: '0 20px' }}> Form Name</th>
                                                <th style={{ padding: '0 20px' }}> Customer Id</th>
                                                <th style={{ padding: '0 20px' }}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orderformHeaders}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div >
                                <hr />
                                <div><label className="text" style={{ fontFamily: 'TW Cen MT', fontSize: 'large', textAlign: 'center', width: '100%' }}>Content</label></div>
                                <hr />
                                <div style={{ width: '70%', marginLeft: '15%', marginRight: '15%', fontFamily: 'TW Cen MT' }}>
                                    <table className="table table-bordered" style={{ width: '97.5%' }}>
                                        <tbody>
                                            <tr className="customer_base_background">
                                                <th></th>
                                                <th>Item Code</th>
                                                <th>Description</th>
                                                <th>UOM</th>
                                                <th>Available</th>
                                                <th>Unit Price</th>
                                                <th>Quantity</th>
                                                <th></th>
                                            </tr>
                                         </tbody>
                                        {orderform}
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        );
    }

    //Class Methods

    GetCartState() {

        fetch('/api/Product/GetCartCount' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == "valued") {
                    this.setState({ toClearCartPrompt: true });
                } else {
                    window.location.href = '\\';
                }
            });
    }

    CheckIfAdmin() {
        fetch('/api/Login/isAdmin' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => { this.setState({ isAdminVisibile: (data == 'Y' ? 'visible' : 'hidden') }) })
    }

    GoToOrderSearch() {
        this.setState({ GoToOrderSearch: true });
    }

    GoToDocuments() {
        this.setState({ toDocuments: true });
    }

    toOrderForm() {
        this.setState({ toOrderForm: true });
    }

    GoToLogIn() {
        this.setState({ toLogIn: true });
    }

    GoToAdmin() {
        this.setState({ toAdmin: true });
    }

    GoBackToProducts() {
        this.setState({ toProducts: true });
    }

    GoToBudget() {
        this.setState({ toBudgets: true });
    }

    GoToOrderForm() {
        this.setState({ toOrderForms: true });
    }

    GoToOrderSearch() {
        this.setState({ GoToOrderSearch: true });
    }

    GoToOrderApproval() {
        this.setState({ toOrderApproval: true });
    }

    GoToSavedCarts() {
        this.setState({ toSavedCarts: true });
    }


    GetUserDetails() {
        fetch('api/Login/GetUserDetails' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.json())
            .then(data => { this.setState({ userDetails: data }) })
    }

    GoToDocumentsManage() {
        this.setState({ toDocumentsManage: true });
    }


    CheckIfCanTrack() {
        fetch('/api/Login/canTrack' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => { this.setState({ canTrack: (data == 'Y' ? 'visible' : 'hidden') }) })
    }
}