import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { Product } from './Products';
import { Route } from 'react-router';
import Login from './Login';
import { GetStylesheet } from '../registerServiceWorker';

export class SessionTimedOut extends Component {
    displayName = SessionTimedOut.name

    constructor(props) {
        super(props);
        this.state = {
            toLogIn: false,
            stylesheet: '',
        };

        this.GetStylesheet();
    }


    GetStylesheet() {
        this.setState({ complete_update: false });
        console.log('get stylesheet called');

        fetch('api/Stylesheet/GetStyleSheet?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                console.log('stylesheet : ');
                console.log(data);
                this.setState({ stylesheet: data }, this.setState({ complete_update: true }));
                console.log('load complete:' + this.state.stylesheet)

            });
    }

    GoBackToLogin() {
        this.setState({ toLogIn: true });
    }

    render() {

        if (this.state.toLogIn) {
            return <Route path='/' component={Login} />
        }

        else {
            return (
                <div className="outer-container customer_background" >
                    <link href={this.state.stylesheet} rel="stylesheet" />
                    <div className="header" style={{ position: 'fixed', zIndex : '500'}}>
                        <div className="grid_container_header" style={{ msHyphens: 'auto', display: 'inline-grid', gridTemplateColumns: '30% 40% 30%', display: 'grid -ms-grid', msGridColumns: '30% 40% 30%', columnCount: '3' }}>
                            <div className="grid_header">
                                <div className="icon_home" style={{ width: '100%', height: '50%', 'margin-top': '30px', 'margin-left': '10%', float: 'left', 'background-repeat': 'no-repeat' }}></div>
                            </div>
                            <div className="grid_header" style={{ 'text-align': 'left', overflow: 'hidden' }}>
                                <div className="header_container" style={{ width: '100%' }}>
                                    <div>
                                        <div style={{ display: 'inline' }}>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="grid_header">
                                <div className="header_container" style={{ width: '100%' }}>
                                    <div>
                                        <div style={{ display: 'inline' }}>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>.
                    <div className="inner-container">
                        <div className="centered-content  twfont customer_background_foreground" style={{ width: '60%', 'box-shadow': '2px 2px 8px #888888', border: 'none', 'border-radius': '2%', padding: '2%' }}>
                            <hr />
                            <label style={{ fontSize: 'x-large', fontFamily: 'TW Cen MT' }}>Your session has timed out due to long idle period, please click the button below to return to log in page, thank you.<br /><i className="material-icons" style={{ paddingLeft: '10px', color: 'red' }}>error</i> </label>
                            <br />
                            <br />
                            <hr />
                            <div style={{ width: '100%' }}>
                                <button ID="btnBack" className="btn primary_btn" Width="90%" style={{ width: '90%', 'font-family': 'Tw Cen MT', 'margin-left': '5%' }} onClick={() => this.GoBackToLogin()}>Go back to login</button >
                            </div >
                        </div >
                        
                    </div >
                </div >
            );
        }
    }
}

