import React, { Component, useMemo } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { BudgetCategories } from './BudgetCategories';
import { BudgetReport } from './BudgetReportCategory'
import { BudgetReportTotal } from './BudgetReportTotal'
import { ViewBudgetCategories } from './ViewBudgetCategories ';
import { Route } from 'react-router';
import { ChangePassword } from './ChangePassword';

import { Product } from './Products';
import { Login } from './Login';
import { CheckOut } from './CheckOut';
import { SavedCarts } from './SavedCarts';
import { InvoiceOrderSearch } from './InvoiceOrderSearch';
import { OrderSearch } from './OrderSearch'
import { AdminUser } from './AdminUser'
import { Users } from './Users';
import { ClearCartPrompt } from './ClearCartPrompt';
import { OrderApproval } from './OrderApproval'
import { SessionTimedOut } from './SessionTimedOut';
import { DocumentsManage } from './DocumentsManage';
import { AdminManage } from './AdminManage';

import usersData from "../data/users.json";

export class Budget extends Component {
    displayName = Budget.name

    constructor(props) {
        super(props);
        this.state = {
            toProducts: false,
            ToBudgetCategories: false,
            budgetList: [],
            loadingBudgets: false,
            listName: '',
            toClearCartPrompt: false,
            toChangePassword: false,
            menuVisibility: 'hidden',

            userDetails: {},
            toDate: '',
            fromDate: '',
            name: '',
            amount: '',
            toSessionTimedOut: false,
            ToBudgetDetails: false,
            ToBudgetReport: false,
            ToBudgetReportTotal: false,
            toDocumentsManage: false,

            canTrack: 'hidden',
            toCheckOut: false,
            toSaveCart: false,
            toManage: false,
            toDocuments: false,
            toOrderForm: false,
            toAdmin: false,
            toLogIn: false,
            toBudgets: false,
            toOrderSearch: false,
            toOrderApproval: false,
            toSavedCarts: false,
            isAdminVisibile: 'hidden',
            stylesheet: '',
            toSessionTimedOut: false,
            toDocumentsManage: false,
            invalidDateMessage: '',
            invalidDateModalVisibility: 'hidden',

            budgetType: '',
            accounts: [],
            loadingAccounts: true,
            accountborder: '#ced4da',
            selectedAccount: '',
            isAdminheight: '390px',
            isEditing: false,
            selectedAccountCode: '',
            selectedAccountName: '',
            browserType: 'none',
            editBudgetId: ''
        };
        this.CheckSessionInitialize();
        this.detectBrowser();


        const mycolumns = [
            { Header: "ID", accessor: "id" },
            { Header: "Name", accessor: "name" },
            { Header: "Username", accessor: "username" },
            { Header: "Email", accessor: "email" },
            { Header: "Phone", accessor: "phone" },
            { Header: "Website", accessor: "website" },
        ];

        //const dataSS = useMemo(() => usersData, []);
    }

    ExitModal() {
        this.setState({ invalidDateModalVisibility: 'hidden' });

    }

    detectBrowser() {
        let isFirefox = typeof InstallTrigger != 'undefined';
        let isIE = /*@cc_on!@*/false || !!document.documentMode;
        let isEdge = !isIE && !!window.StyleMedia;
        let isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

        let output = '';

        if (isIE == true)
            output = 'IE';

        if (isChrome == true)
            output = 'Chrome';

        if (isFirefox == true)
            output = 'Firefox';

        if (isEdge == true)
            output = 'IsEdge';


        console.log("output : " + output);
        this.setState({ browserType: output });
        console.log("browserType = " + this.state.browserType);
    }

    handleSelectedAccounts(e) {
        console.log('value : ' + e.target.value + '; text : ' + e.target.name);
        let { name, value } = e.target;

        this.setState({ selectedAccount: e.target.value }
            , () => {
                console.log(this.state.selectedAccount);

                if (this.state.selectedAccount === '') {
                    console.log('selected address is empty');
                }
                else {
                    console.log('getting products');
                }
            })
    }

    CheckSessionInitialize() {
        this.setState({ complete_update: false });
        console.log('get CheckSession called');

        fetch('api/Login/CheckSession?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });

                    this.GetUserDetails();
                    this.GetAccounts();
                    this.GetBudgets();
                    this.GetBudgetTypeCode();
                    this.CheckIfAdmin();
                    this.CheckIfCanTrack();
                }
                else if (data == 'timedout') {
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }

    CheckSession() {
        this.setState({ complete_update: false });
        console.log('get CheckSession called');

        fetch('api/Login/CheckSession?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });
                }
                else if (data == 'timedout') {
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }

    GetAccounts() {
        this.setState({ complete_update: false, loadingAccounts: true });
        console.log('Get Accounts called');

        fetch('api/Budget/GetChildrenAccounts?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.json())
            .then(data => {
                this.setState({ accounts: data, loadingAccounts: false });
            });


    }

    ToBudgetCategories() {
        this.setState({ ToBudgetCategories: true });
    }

    handleChangedName(e) {
        console.log('name' + e.target.value);
        let inputtext = e.target.value;
        this.setState({ name: inputtext });
    }

    handleamount(e) {
        console.log('amount ' + e.target.value);
        let inputtext = e.target.value;
        this.setState({ amount: inputtext });
    }

    handleFromDateChange(e) {
        console.log('from date' + e.target.value);
        let inputtext = e.target.value;
        this.setState({ fromDate: inputtext });
    }

    handleToDateChange(e) {
        console.log('todate ' + e.target.value);
        let inputtext = e.target.value;
        this.setState({ toDate: inputtext });
    }

    GetBudgetTypeCode() {

        this.setState({ loadingBudgets: true });
        console.log('loading budget list');

        fetch('/api/Budget/GetBudgetReportType' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                this.setState({ budgetType: data });
            })
    }

    GetBudgets() {
        this.setState({ loadingBudgets: true });
        console.log('loading budget list');

        fetch('/api/Budget/GetBudgets' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.json())
            .then(data => {
                this.setState({ budgetList: data },
                    () => {
                        this.setState({ loadingBudgets: false });
                        console.log('budget list :');
                        console.log(this.state.budgetList);
                    })
            });
    }

    SaveEditBudget() {
        if (this.state.isEditing) {
            this.EditBudget();
        }
        else {
            this.SaveBudget();
        }
    }

    SaveBudget() {


        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            console.log('saving budget');

            let fromdate = this.state.fromDate;
            let todate = this.state.toDate;
            let name = this.state.name;
            let amount = this.state.amount;
            let account = this.state.selectedAccount;

            fetch('/api/Budget/ValidateBudget?fromDate=' + fromdate + '&toDate=' + todate + '&name=' + name + '&account=' + account + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
                .then(response => response.text())
                .then(data => {

                    if (data == "Valid") {
                        fetch('/api/Budget/AddBudget?fromDate=' + fromdate + '&toDate=' + todate + '&name=' + name + '&amount=' + amount + '&account=' + account + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
                            .then(() => {
                                if (this.state.budgetType == 'total') {
                                    this.setState({
                                        name: '',
                                        fromdate: '',
                                        todate: '',
                                        amount: ''
                                    });
                                    this.GetBudgets();

                                }
                                else
                                    this.setState({ ToBudgetDetails: true }); console.log('budgetSaved');
                            });
                    }
                    else {
                        this.setState({ invalidDateMessage: data, invalidDateModalVisibility: 'visible' });
                    }
                });
        }
    }

    EditBudget() {


        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {

            let fromdate = this.state.fromDate;
            let todate = this.state.toDate;
            let name = this.state.name;
            let amount = this.state.amount;
            let account = this.state.selectedAccountCode;

            fetch('/api/Budget/EditBudget?fromDate=' + fromdate + '&toDate=' + todate + '&name=' + name + '&amount=' + amount + '&account=' + account + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
                .then(() => {
                    if (this.state.budgetType == 'total') {
                        this.setState({
                            name: '',
                            fromdate: '',
                            todate: '',
                            amount: '',
                            isEditing: false
                        });

                        this.GetBudgets();
                    }
                    else {
                        this.setState({ ToBudgetDetails: true });
                    }
                });
        }
    }

    GetEditBudget(id) {
        let isFirefox = typeof InstallTrigger != 'undefined';
        let isIE = /*@cc_on!@*/false || !!document.documentMode;
        let isEdge = !isIE && !!window.StyleMedia;
        let isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

        let output = '';

        if (isIE == true)
            output = 'IE';

        if (isChrome == true)
            output = 'Chrome';

        if (isFirefox == true)
            output = 'Firefox';

        if (isEdge == true)
            output = 'IsEdge';

        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            fetch('/api/Budget/GetBudgetById?budgetId=' + id + '&browserType=' + output + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
                .then(response => response.json())
                .then((data) => {
                    this.setState({
                        fromDate: data.fromDate,
                        toDate: data.toDate,
                        name: data.name,
                        amount: data.amount,
                        selectedAccountCode: data.account,
                        selectedAccountName: data.accountName,
                        isEditing: true
                    })
                });
        }

        this.SetBudgetId(id);
    }

    ToChangePassword() {
        fetch('/api/Login/CanUpdatePassword' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(response => {

                if (response == 'true')
                    this.setState({ toChangePassword: true });
                else
                    alert('You currently do not have the required permissions to change your password.');

            });
    }

    MenuClick() {

        if (this.state.menuVisibility == 'hidden') {
            this.setState({ menuVisibility: 'visible' });
        }
        else if (this.state.menuVisibility == 'visible') {
            this.setState({ menuVisibility: 'hidden' });
        }
    }

    SetBudgetId(id) {


        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            fetch('/api/Budget/SetBudgetId?id=' + id + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
        }
    }

    SetReportBudgetId(id) {


        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            fetch('/api/Budget/SetBudgetId?id=' + id + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
                .then(() => {
                    if (this.state.budgetType == 'category')
                        this.setState({ ToBudgetReport: true });
                    else if (this.state.budgetType == 'total')
                        this.setState({ ToBudgetReportTotal: true });
                });
        }
    }

    LoadAccountsToDropDown() {

        if (this.state.isEditing) {
            return (
                <option selected value={this.state.selectedAccountCode}>{this.state.selectedAccountName}  -  {this.state.selectedAccountCode}</option>
            );
        }
        if (this.state.loadingAccounts) {
            return (<option>loading...</option>);
        }
        else {
            return (this.state.accounts.map(account =>
                <option value={account.code}>{account.name}  -  {account.code}</option>
            ));
        }
    }

    DeleteBudget(id) {


        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            fetch('/api/Budget/DeleteBudget?Id=' + id + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
                .then(() => { this.GetBudgets() });
        }

    }

    AddCategory(code) {


        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            fetch('/api/Budget/CheckCategory?code=' + code + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' });
        }
    }

    LoadBudgetTable() {

        //test
        //const dataSS = useMemo(() => usersData, []);

        if (this.state.loadingBudgets) {
            return (
                <tr>
                    <td>loading...</td>
                    <td>loading...</td>
                    <td>loading...</td>
                    <td>loading...</td>
                    <td>loading...</td>
                    <td>loading...</td>
                </tr>
            );

        }
        else {
            return (
                this.state.budgetList.map(budget =>
                    <tr>

                        <td className="sideBorder" style={{ lineHeight: '1px' }}>{budget.name}</td>
                        <td className="sideBorder" style={{ lineHeight: '1px' }}>{budget.account}</td>
                        <td className="sideBorder" style={{ lineHeight: '1px' }}>{budget.fromDate}</td>
                        <td className="sideBorder" style={{ lineHeight: '1px' }}>{budget.toDate}</td>
                        <td className="sideBorder" style={{ lineHeight: '1px' }}>R {Number(budget.amount).toFixed(2)}</td>
                        <td className="sideBorder" style={{ lineHeight: '1px', color: 'green', border: 'black', cursor: 'pointer' }} onClick={() => this.SetReportBudgetId(budget.id)}>report</td>
                        <td className="sideBorder" style={{ lineHeight: '1px', color: 'blue', border: 'black', cursor: 'pointer', visibility: this.state.isEditing ? "hidden" : this.state.isAdminVisibile }} onClick={() => this.GetEditBudget(budget.id)}>edit</td>
                        <td className="sideBorder" style={{ lineHeight: '1px', color: 'red', border: 'black', cursor: 'pointer', visibility: this.state.isEditing ? "hidden" : this.state.isAdminVisibile }} onClick={() => this.DeleteBudget(budget.id)}>delete</td>
                    </tr>
                ));
        }

    }

    render() {

        //Header Redirect
        if (this.state.toSessionTimedOut) {
            return <Route path='/' component={SessionTimedOut} />
        }

        if (this.state.toAdmin) {
            return <Route path='/' component={AdminManage} />
        }


        if (this.state.GoToOrderSearch) {
            return <Route path='/' component={OrderSearch} />
        }

        if (this.state.toBudgets) {
            return <Route path='/' component={Budget} />
        }

        if (this.state.toSavedCarts) {
            return <Route path='/' component={SavedCarts} />
        }

        if (this.state.toCheckOut) {
            return <Route path='/' component={CheckOut} />
        }

        if (this.state.toChangePassword) {
            return <Route path='/' component={ChangePassword} />

        }

        if (this.state.toDocuments) {
            return <Route path='/' component={InvoiceOrderSearch} />
        }

        if (this.state.toDocumentsManage) {
            return <Route path='/' component={DocumentsManage} />
        }

        if (this.state.toOrderApproval) {
            return <Route path='/' component={OrderApproval} />
        }

        if (this.state.toLogIn) {
            return <Route path='/' component={Login} />
        }

        ////end

        if (this.state.ToBudgetDetails) {
            return <Route path='/' component={ViewBudgetCategories} />
        }

        if (this.state.ToBudgetCategories) {
            return <Route path='/' component={BudgetCategories} />
        }

        if (this.state.ToBudgetReport) {
            return <Route path='/' component={BudgetReport} />
        }

        if (this.state.ToBudgetReportTotal) {
            return <Route path='/' component={BudgetReportTotal} />
        }

        if (this.state.toProducts) {
            return <Route path='/' component={Product} />
        }
        if (this.state.toClearCartPrompt) {
            return <Route path='/' component={ClearCartPrompt} />
        }

        else {
            let budgets = this.LoadBudgetTable();
            let accounts = this.LoadAccountsToDropDown();

            return (
                <div className="outer-container customer_background" >
                    <div className="header" style={{ position: 'fixed', zIndex: '500' }}>
                        <div className="grid_container_header" style={{ msHyphens: 'auto', display: 'inline-grid', gridTemplateColumns: '30% 40% 30%', display: 'grid -ms-grid', msGridColumns: '30% 40% 30%', columnCount: '3' }}>
                            <div className="grid_header">
                                <div className="icon_home" style={{ width: '100%', height: '50%', marginTop: '30px', marginLeft: '10%', float: 'left', backgroundRepeat: 'no-repeat' }}></div>
                            </div>
                            <div className="grid_header" style={{ 'text-align': 'left', overflow: 'hidden' }}>
                                <div className="header_container" style={{ width: '100%' }}>
                                    <div>
                                        <div style={{ display: 'inline' }}>
                                            <a className="header_text" onClick={() => this.GoBackToProducts()}  >Place Order</a>|
                                            <a className="header_text" onClick={() => this.GoToDocumentsManage()} >Documents</a><span style={{ visibility: this.state.canTrack }}>|</span>
                                            <a className="header_text" onClick={() => this.GoToOrderApproval()} style={{ visibility: this.state.canTrack }} >Order Approval</a><span style={{ visibility: this.state.isAdminVisibile }}>|</span>
                                            <a className="header_text" >Budgets</a>
                                            <span style={{ visibility: this.state.isAdminVisibile }}>|</span>
                                            <a className="header_text" onClick={() => this.GoToAdmin()} style={{ visibility: this.state.isAdminVisibile }} >Admin</a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="grid_header">
                                <div className="header_container greeting" style={{ width: '100%' }}>
                                    <div className="header_text grid_item_1" style={{ lineHeight: '40px', textAlign: 'right' }}>
                                        <div style={{ height: '28px' }} className="overflow_header"> {this.state.userDetails.greeting + ', ' + this.state.userDetails.fullname}</div>
                                        <div style={{ height: '25px' }} className="overflow_header">{this.state.userDetails.accountName}</div>
                                        <div style={{ height: '28px' }} className="overflow_header">Group : {this.state.userDetails.accountCode}</div>
                                    </div>

                                    <div className="grid_item_2_reverse  h100">
                                        <div style={{ width: '100%', 'height': '100%' }}>
                                            <button id="btnMenu" onClick={() => this.MenuClick()} className="btn primary_btn header_btn twfont" style={{ lineHeight: '0.8', marginTop: '10px' }}  ><i className="material-icons" style={{ width: '100%' }} >menu</i><label>Options</label></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="header_menu_container" style={{ visibility: this.state.menuVisibility }}>
                        <div className="grid_item_1 max-height-menu">
                            <button onClick={() => { this.MenuClick() }} style={{ width: '100%', height: '100%', backgroundColor: 'transparent', backgroundColor: 'transparent', border: 'none' }}></button>
                        </div>
                        <div className="header_menu grid_item_2 max-height-menu customer_background_foreground">
                            <hr />
                            <div>
                                <button id="canUpdateBtn" name="canUpdateBtn" className="btn  header_menu_item" onClick={() => { this.ToChangePassword() }}>Reset Password <i className="material-icons" style={{ position: 'relative', top: '6px', paddingLeft: '10px' }}>vpn_key</i></button>
                            </div>
                            <hr />
                            <div>
                                <button className="btn  header_menu_item" onClick={() => { this.GetCartState() }}>Logout <i className="material-icons" style={{ position: 'relative', top: '6px', paddingLeft: '10px' }}>exit_to_app</i></button>
                            </div>
                            <hr />
                        </div>
                    </div>
                    <div className=" sidenav customer_background_foreground" style={{ width: '100%', minHeight: '1000px', marginTop: '100px' }}>
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <br />

                            <div className="customer_background_foreground" style={{ width: '50%', margin: '0 25%', padding: '20px', verticalAlign: 'middle', height: '300px' }}>
                                <div style={{ visibility: this.state.isAdminVisibile, maxHeight: this.state.isAdminheight }}>
                                    <div>
                                        <hr />
                                        <label className="customer_background_foreground" style={{ fontFamily: 'Tw Cen MT', fontSize: 'large', fontWeight: 'bold', width: '100%', textAlign: 'center' }}>Add Budget</label>
                                        <hr />
                                    </div>
                                    <div className="divGridColumn" style={{ margin: '0 9.5%', width: '81%', visibility: this.state.isAdminVisibile }}>

                                        <div className="row1 col1" >
                                            <label ID="lblDel" style={{ fontFamily: 'TW Cen MT', float: 'left' }} className="table_font">Name : </label>
                                        </div>
                                        <div className="row1 col2 gap10">
                                            <input type="text" ID="txtName" className="form-control twfont" value={this.state.name} onChange={this.handleChangedName.bind(this)} />
                                        </div>
                                        <div className="row1 col1" >
                                            <label ID="lblDel" style={{ fontFamily: 'TW Cen MT', float: 'left' }} className="table_font">Account : </label>
                                        </div>
                                        <div className="row1 col2 gap10" >
                                            <select ID="ddlAccounts" className="form-control twfont" style={{
                                                borderColor: this.state.accountborder, visibility: this.state.isEditing ? "hidden" : this.state.isAdminVisibile,
                                                position: this.state.isEditing ? "absolute" : "inherit"
                                            }} onChange={this.handleSelectedAccounts.bind(this)}>
                                                <option>Select Account</option>
                                                {accounts}
                                            </select>
                                            <select ID="ddlAccounts" disabled className="form-control twfont" style={{
                                                borderColor: this.state.accountborder, visibility: this.state.isEditing == false ? "hidden" : "visible",
                                                position: this.state.isEditing == false ? "absolute" : "inherit"
                                            }} onChange={this.handleSelectedAccounts.bind(this)}>
                                                <option>Select Account</option>
                                                {accounts}
                                            </select>
                                        </div>
                                        <div className="row2 col1">
                                            <label ID="lblDel" style={{ fontFamily: 'TW Cen MT', float: 'left' }} className="table_font">Amount : </label>
                                        </div>
                                        <div className="row2 col2 gap10">
                                            <input type="number" ID="txtAmount" className="form-control twfont" value={this.state.amount} onChange={this.handleamount.bind(this)} />
                                        </div >

                                        <div className="row3 col1">
                                            <label ID="lblDel" style={{ fontFamily: 'TW Cen MT', float: 'left' }} className="table_font">From : </label>
                                        </div>
                                        <div className="row3 col2 gap10">
                                            <div>
                                                <input type="date" ID="fromDate" className="form-control twfont" value={this.state.fromDate} onChange={this.handleFromDateChange.bind(this)} placeholder="day-month-year e.g. (25-10-2019)" />
                                                <input ID="fromdate2" className="form-control twfont" value={'Current : ' + this.state.fromDate} disabled style={{ visibility: this.state.isEditing ? "visible" : "hidden", position: this.state.isEditing ? "inherit" : "absolute" }} />
                                            </div>
                                        </div >

                                        <div className="row4 col1">
                                            <label ID="lblDel" style={{ fontFamily: 'TW Cen MT', float: 'left' }} className="table_font">To : </label>
                                        </div>
                                        <div className="row4 col2 gap10">
                                            <div>
                                                <input type="date" ID="toDate" className="form-control twfont" value={this.state.toDate} onChange={this.handleToDateChange.bind(this)} placeholder="day-month-year e.g. (25-10-2019)" />
                                                <input ID="toDate2" className="form-control twfont" value={'Current : ' + this.state.toDate} disabled style={{ visibility: this.state.isEditing ? "visible" : "hidden", position: this.state.isEditing ? "inherit" : "absolute" }} />
                                            </div>
                                        </div >
                                    </div >
                                    <br />
                                    <div className="footerButtons" style={{ margin: '0 9.5%', width: '81%', visibility: this.state.isAdminVisibile }}>
                                        <button ID="btnSubmit" className="btn primary_btn row1 col1" style={{ width: '100%', 'font-family': 'Tw Cen MT', 'margin-bottom': '2px' }} onClick={() => this.SaveEditBudget()}>Save Budget</button>
                                        <button ID="btnBack" className="btn primary_btn row1 col2" Width="100%" style={{ width: '100%', 'font-family': 'Tw Cen MT' }} onClick={() => this.GoBackToProducts()}> Return To Order</button >
                                        <br />
                                        <br />
                                    </div >
                                </div>
                                <br />
                                <hr />
                                <div style={{ visibility: this.state.isEditing ? "hidden" : "visible" }}>
                                    <div>
                                        <label className="customer_background_foreground" style={{ fontFamily: 'Tw Cen MT', fontSize: 'large', fontWeight: 'bold', width: '100%', textAlign: 'center' }}>Budget</label>
                                        <hr />
                                    </div>
                                    <div>
                                        <label ID="lblMessage" ></label><br />
                                    </div>

                                    <div>
                                        <br />
                                        <table disabled="false" className="table table-bordered" style={{ fontFamily: 'TW Cen MT', overflow: 'scroll', whiteSpace: 'nowrap', maxHeight: '300px', width: '100%' }}>
                                            <thead className="thead bg customer_base_background">
                                                <th style={{ textAlign: 'center', lineHeight: '1px' }}>Name</th>
                                                <th style={{ textAlign: 'center', lineHeight: '1px' }}>Account</th>
                                                <th style={{ textAlign: 'center', lineHeight: '1px' }}>Start</th>
                                                <th style={{ textAlign: 'center', lineHeight: '1px' }}>End</th>
                                                <th style={{ textAlign: 'center', lineHeight: '1px' }}>Amount</th>
                                                <th style={{ textAlign: 'center', lineHeight: '1px' }} colSpan="3"></th>
                                            </thead>
                                            {budgets}
                                        </table>
                                        <br />
                                    </div >

                                    <br />
                                </div>
                            </div>

                        </div>
                    </div >
                    <div style={{ visibility: this.state.invalidDateModalVisibility }}>
                        <div className="outer-container" style={{ top: '0', fontFamily: 'TW Cent', backgroundColor: 'rgba(10,10,10, 0.8)' }} >
                            <div className="inner-container" style={{ height: '1000px' }}>
                                <div className="centered-content twfont  customer_background_foreground"
                                    style={{ boxShadow: '2px 2px 8px #888888', border: 'none', width: '90%', maxWidth: '450px', backgroundColor: 'rgba(255,255,255,0.3)', fontFamily: 'TW Cen MT', height: '400px' }}>
                                    <table className="customer_background_foreground" style={{ width: '100%', margin: '0 auto', verticalAlign: 'middle', height: '250px', backgroundColor: 'rgba(255,255,255,0.3)' }}>
                                        <tbody>
                                            <tr><td><label style={{ width: '100%', textAlign: 'left', fontSize: 'larger', fontWeight: 'bolder' }}>Message:</label></td></tr>
                                            <tr>
                                                <td colSpan="2" style={{ width: '100%' }}>
                                                    <textarea id="user" name="user" className="form-control twfont" value={this.state.invalidDateMessage} disabled style={{ height: '250px' }} />
                                                </td>
                                            </tr>
                                            <tr><td><hr /></td></tr>
                                            <tr>
                                                <td colSpan="2" style={{ width: '80%' }}>
                                                    <button id="butLogin" className="btn twfont" style={{ border: 'solid 1px black', backgroundColor: 'red', color: 'black', fontWeight: 'bolder', width: '95%' }} type="button" onClick={() => { this.ExitModal() }}>OK</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }



    //// Route Change

    GetCartState() {

        fetch('/api/Product/GetCartCount' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == "valued") {
                    this.setState({ toClearCartPrompt: true });
                } else {
                    window.location.href = '\\';
                }
            });
    }

    CheckIfAdmin() {
        fetch('/api/Login/isAdmin' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                this.setState({ isAdminVisibile: (data == 'Y' ? 'visible' : 'hidden'), isAdminheight: (data == 'Y' ? '390px' : '0px') })
            })
    }

    GoToOrderSearch() {
        this.setState({ GoToOrderSearch: true });
    }

    GoToDocuments() {
        this.setState({ toDocuments: true });
    }

    GoToDocumentsManage() {
        this.setState({ toDocumentsManage: true });
    }

    GoToLogIn() {
        this.setState({ toLogIn: true });
    }

    GoToDocumentsManage() {
        this.setState({ toDocumentsManage: true });
    }

    GoToAdmin() {
        this.setState({ toAdmin: true });
    }

    GoBackToProducts() {
        this.setState({ toProducts: true });
    }

    GoToBudget() {
        this.setState({ toBudgets: true });
    }

    GoToOrderForm() {
        this.setState({ toOrderForms: true });
    }

    GoToOrderSearch() {
        this.setState({ GoToOrderSearch: true });
    }

    GoToOrderApproval() {
        this.setState({ toOrderApproval: true });
    }

    GoToSavedCarts() {
        this.setState({ toSavedCarts: true });
    }

    GetUserDetails() {
        fetch('api/Login/GetUserDetails' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.json())
            .then(data => { this.setState({ userDetails: data }) })
            .then(() => {
                console.log("user details");
                console.log(this.state.userDetails);
            })
    }



    CheckIfCanTrack() {
        fetch('/api/Login/canTrack' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => { this.setState({ canTrack: (data == 'Y' ? 'visible' : 'hidden') }, () => { console.log('visibility : data=' + data + ' value= ' + this.state.isAdminVisibile); }) })
    }
}

