import React, { Component } from 'react';
import { Route } from 'react-router';
import { withRouter, Redirect } from 'react-router-dom';
import { Product } from './Products';
import { Login } from './Login'
import { Registered } from './Registered'


export class Registration extends Component {
    displayName = Registration.name

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            customerAccounts: [],
            loadingCustomerAccounts: false,
            firstname: '',
            loginId: '',
            surname: '',
            password: '',
            email: '',
            cell: '',
            customer: '',
            account: '',
            toLogin: false,
            toRegistred: false,
        };

        //this.GetCustomerAccounts();
    }

    GotoRegistred() {
        this.setState({ toRegistred: true });
    }

    GotoLogin() {
        this.setState({ toLogin: true });
    }

    handleLoginIdChange(e) {
        this.setState({ loginId: e.target.value },
            console.log('loginId : ' + e.target.value));
    }

    handleNameChange(e) {
        this.setState({ firstname: e.target.value },
            console.log('firstname : ' + e.target.value));
    }

    handleSurnameChange(e) {
        this.setState({ surname: e.target.value },
            console.log('surname : ' + e.target.value));
    }

    handleEmailChange(e) {
        this.setState({ email: e.target.value },
            console.log('email : ' + e.target.value));
    }

    handleCellChange(e) {
        this.setState({ cell: e.target.value },
            console.log('cell : ' + e.target.value));
    }

    handleCustomerChange(e) {
        this.setState({ customer: e.target.value },
            console.log('customer : ' + e.target.value));
    }

    handleAccountChange(e) {
        this.setState({ account: e.target.value },
            console.log('account : ' + e.target.value));
    }

    handlePasswordChange(e) {
        this.setState({ password: e.target.value },
            console.log('password : ' + e.target.value));
    }

    GetCustomerAccounts() {

        this.state.loadingCustomerAccounts = true;

        fetch('api/Admin/GetAccountLists', { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.json())
            .then(data => {
                this.setState({ customerAccounts: data, loadingCustomerAccounts: false })
            });

    }

    AddUser() {
        let loginId = this.state.loginId;
        let firstname = this.state.firstname;
        let surname = this.state.surname;
        let password = this.state.password;
        let accountCode = this.state.account;
        let email = this.state.email;
        let cell = this.state.cell;
        let customer = this.state.customer;

        fetch('api/Login/AddNewUser?firstname=' + firstname + '&surname=' + surname + '&password=' + password + '&accountCode=' + accountCode + '&email=' + email + '&cell=' + cell + '&loginId=' + loginId, { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(() => { console.log('New User Registered') });

        this.GotoRegistred();
    }

    //getCustomerAccounts() {

    //    if (this.state.loadingCustomerAccounts) {
    //        <option>loading accounts</option>
    //    }
    //    else {
    //        return (this.state.customerAccounts.map(account =>
    //            <option value={account.code}>{account.name}</option>
    //        ));
    //    }
    //}

    render() {
        if (this.state.toRegistred) {
            return <Route path='/' component={Registered} />
        }

        if (this.state.toLogin) {
            return <Route path='/' component={Login} />
        }
        else {

            return (
                <div>
                    <div className="outer-container customer_background" style={{ fontFamily: 'TW Cent' }}>
                        <div className="header" style={{ position: 'fixed', zIndex : '500'}}>
                            <div className="grid_container_header" style={{ display: 'inline-grid', gridTemplateColumns: '30% 40% 30%', display: 'grid -ms-grid', msGridColumns: '30% 40% 30%', columnCount: '3' }}>
                                <div className="grid_header">
                                    <div className="icon_home" style={{ width: '100%', height: '50%', 'margin-top': '30px', 'margin-left': '10%', float: 'left', 'background-repeat': 'no-repeat' }}></div>
                                </div>
                                <div className="grid_header" style={{ 'text-align': 'left' }}>
                                    <div className="header_container" style={{ width: '100%' }}>
                                        <div>
                                            <div style={{ display: 'inline' }}>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="grid_header">
                                    <div className="header_container" style={{ width: '100%' }}>
                                        <div>
                                            <div style={{ display: 'inline' }}>
                                                <a className="header_text" onClick={() => this.GotoLogin()} id="A1">Cancel Registration</a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="inner-container">
                            <div className="centered-content  twfont customer_background_foreground"
                                style={{ boxShadow: '2px 2px 8px #888888', border: 'none', width: '450px', backgroundColor: 'rgba(255,255,255,0.3)', fontFamily: 'TW Cen MT' }}>

                                <table className="customer_background_foreground" style={{ width: '100%', margin: '0 auto', verticalAlign: 'middle', height: '200px', backgroundColor: 'rgba(255,255,255,0.3)' }}>
                                    <tr>
                                        <td>
                                            <label className="customer_background_foreground" style={{ fontFamily: 'TW Cen MT', 'font-size': 'xx-large', 'font-weight': 'bolder' }}>Register new user</label>
                                            <hr />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '100%' }}>
                                            <input id="loginId" name="loginId" type="text" defaultValue={this.state.loginId} onChange={this.handleLoginIdChange.bind(this)} className="form-control twfont" placeholder="Login Id" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '100%' }}>
                                            <input id="firstname" name="firstname" type="text" defaultValue={this.state.firstname} onChange={this.handleNameChange.bind(this)} className="form-control twfont" placeholder="First name" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '100%' }}>
                                            <input id="surname" name="surname" type="text" defaultValue={this.state.surname} onChange={this.handleSurnameChange.bind(this)} className="form-control twfont" placeholder="Surname" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '100%' }}>
                                            <input id="register_email" name="register_email" type="text" defaultValue={this.state.email} onChange={this.handleEmailChange.bind(this)} className="form-control twfont" placeholder="Email" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '100%' }}>
                                            <input id="cell" name="cell" type="text" defaultValue={this.state.cell} onChange={this.handleCellChange.bind(this)} className="form-control twfont" placeholder="Cell" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '100%' }}>
                                            <input id="register_password" name="register_password" type="password" defaultValue={this.state.password} onChange={this.handlePasswordChange.bind(this)} className="form-control twfont" placeholder="Password" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '100%' }}>
                                            <input id="account" name="account" type="text" defaultValue={this.state.account} onChange={this.handleAccountChange.bind(this)} className="form-control twfont" placeholder="Account Code" />

                                        </td>
                                    </tr>
                                    <tr><td><br /></td></tr>
                                    <tr>
                                        <td style={{ width: '100%' }}>
                                            <button id="register" onClick={() => this.AddUser()} className="form-control btn btn-dark">Register</button>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

    }
}



//<select id="account" onChange={this.handleAccountChange.bind(this)} className="form-control twfont">
//    <option>Select Account...</option>
//    {customer}
//</select>