import React, { Component } from 'react';
import { Route } from 'react-router';
import { withRouter, Redirect } from 'react-router-dom';

import { OrderSearch } from './OrderSearch'
import { Product } from './Products';
import { Login } from './Login';
import { CheckOut } from './CheckOut';
import { SavedCarts } from './SavedCarts';
import { InvoiceOrderSearch } from './InvoiceOrderSearch';
import { Budget } from './Budget'
import { AdminUser } from './AdminUser'
import { Users } from './Users';
import { ClearCartPrompt } from './ClearCartPrompt';
import { OrderApproval } from './OrderApproval'
import { SessionTimedOut } from './SessionTimedOut';
import { DocumentsManage } from './DocumentsManage';
import { AdminManage } from './AdminManage';


export class CreditContent extends Component {
    displayName = CreditContent.name

    constructor(props) {
        super(props);

        this.state = {
            creditList: [],
            loadingCredits: false,
            creditHeader: {},
            loadingHeader: false,
            orderDate: '',
            dateDelivered: '',

            ToList: false,

            ToOrderDocument: false,
            toCheckOut: false,
            toSaveCart: false,
            toManage: false,
            toDocuments: false,
            toOrderForm: false,
            toAdmin: false,
            toLogIn: false,
            toBudgets: false,
            toOrderSearch: false,
            toOrderApproval: false,
            toClearCartPrompt: false,
            toSavedCarts: false,
            isAdminVisibile: 'hidden',
            ApproveVisibility: 'hidden',
            canTrack: 'hidden',
            toProducts: false,
            userDetails: {},
            toSessionTimedOut: false,
            toDocumentsManage: false,

        };


    }



    CheckSessionInitialize() {
        this.setState({ complete_update: false });
        console.log('get CheckSession called');

        fetch('api/Login/CheckSession?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });

                    this.GetUserDetails();
                    this.CheckIfAdmin();
                    this.CheckIfCanTrack();

                    this.GetCredit();
                    this.GetCreditContentList();
                }
                else if (data == 'timedout') {
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }

    CheckSession() {
        this.setState({ complete_update: false });
        console.log('get CheckSession called');

        fetch('api/Login/CheckSession?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });
                }
                else if (data == 'timedout'){
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }

    InvoiceCredits() {
        this.setState({ ToList: true });
    }

    GetCredit() {
        this.setState({ loadingHeader: true });
        console.log('loading credits ');

        fetch('/api/InvoiceOrder/GetCurrentCredit' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.json())
            .then(data => { this.setState({ creditHeader: data, loadingHeader: false, orderDate: data.orderDate, dateDelivered: data.dateDelivered }, console.log(data)) })
            .then(() => {
                console.log("credit");
                console.log(this.state.creditHeader);
            })
    }

    GetCreditContentList() {
        this.setState({ loadingInvoice: true });
        console.log('loading credit item list');

        fetch('/api/InvoiceOrder/GetCreditLineItems' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.json())
            .then(data => { this.setState({ creditList: data, loadingCredits: false });});
            //.then(data => { this.setState({ creditList: data, loadingCredits: false }), console.log('credits'); console.log(this.state.creditList) });
    }

    LoadTableCreditItems() {

        if (this.state.loadingCredits) {
            return (
                <tr>
                    <td>loading...</td>
                    <td>loading...</td>
                    <td>loading...</td>
                    <td>loading...</td>
                    <td>loading...</td>
                    <td>loading...</td>
                    <td>loading...</td>
                </tr>
            );

        }
        else {
            return (
                this.state.creditList.map(credit =>
                    <tr style={{ lineHeight: '1px' }}>
                        <td>{credit.productItemCode}</td>
                        <td>{credit.description}</td>
                        <td>{credit.invUOM}</td>
                        <td>{credit.qtyInv}</td>
                        <td>{credit.brandRef}</td>
                        <td>R {Number(credit.unitPrice).toFixed(2)}</td>
                        <td>R {Number(credit.extendedPrice).toFixed(2)}</td>
                        <td>R {Number(credit.lineTax).toFixed(2)}</td>
                        <td>R {Number(credit.extendedPrice + credit.lineTax).toFixed(2)}</td>
                    </tr>
                ));
        }

    }


    LoadHeader() {



        if (this.state.loadingHeader) {
            return (
                <div>
                    <div style={{ backgroundColor: 'white', height: '100px', display: 'inline-grid', gridTemplateColumns: '20% 80%', width: '100%' }}>
                        <div style={{ wordBreak: 'break-all' }}>
                            Loading
                    </div>
                        <div>
                        </div>
                    </div>
                    <div style={{ backgroundColor: 'white', height: '12%', width: '100%' }}>
                        <table style={{ width: '100%' }}>
                            <tr>
                                <td>CustomerCode: Loading...</td>
                                <td>Invoice No :  Loading...</td>
                                <td>Order No : Loading...</td>
                            </tr>
                            <tr>
                                <td>inv Date : Loading...</td>
                                <td>Delivery Date : Loading...</td>
                                <td>Web/Order Ref: Loading...</td></tr>
                            <tr>
                                <td>Your Order Ref: Loading...</td>
                                <td></td>
                                <td></td>
                            </tr>
                        </table>
                    </div>
                </div>);
        }
        else {
            let credit = this.state.creditHeader;

            return (
                <div>
                    <div style={{ backgroundColor: 'white', height: '12%', width: '100%' }}>
                        <table style={{ width: '100%' }}>
                            <tr>
                                <td>Credit no: </td>
                                <td style={{ textAlign: 'right' }}>{credit.creditNo}</td>
                                <td style={{ textAlign: 'right' }}>Credit Date : </td>
                                <td style={{ textAlign: 'right' }}>{credit.creditDate}</td>
                                <td style={{ textAlign: 'right' }}>Total (Excluding VAT) : </td>
                                <td style={{ textAlign: 'right' }}>R {Number(credit.creditTotal).toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                            </tr>
                            <tr>
                                <td>Invoice No : </td>
                                <td style={{ textAlign: 'right' }}>{credit.invoiceNo}</td>
                                <td style={{ textAlign: 'right' }}>Web/Order Reference: </td>
                                <td style={{ textAlign: 'right' }}>{credit.ourRef}</td>
                                <td style={{ textAlign: 'right' }}>VAT : </td>
                                <td style={{ textAlign: 'right' }}>R {Number(credit.tax).toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                            </tr>
                            <tr>
                                <td>Order Reference: </td>
                                <td style={{ textAlign: 'right' }}>{credit.custRef}</td>
                                <td>CustomerCode: </td>
                                <td style={{ textAlign: 'right' }}>{credit.accountName}</td>
                                <td style={{ textAlign: 'right' }}>Total (Including VAT) : </td>
                                <td style={{ textAlign: 'right' }}>R {Number(credit.orderTotal + credit.tax).toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            );
        }
    }

    render() {

        if (this.state.toSessionTimedOut) {
            return <Route path='/' component={SessionTimedOut} />
        }

        if (this.state.toAdmin) {
            return <Route path='/' component={AdminManage} />
        }

        if (this.state.toDocumentsManage) {
            return <Route path='/' component={DocumentsManage} />
        }

        if (this.state.GoToOrderSearch) {
            return <Route path='/' component={OrderSearch} />
        }

        if (this.state.toBudgets) {
            return <Route path='/' component={Budget} />
        }

        if (this.state.toSavedCarts) {
            return <Route path='/' component={SavedCarts} />
        }

        if (this.state.toCheckOut) {
            return <Route path='/' component={CheckOut} />
        }

        if (this.state.toDocuments) {
            return <Route path='/' component={InvoiceOrderSearch} />
        }


        if (this.state.toLogIn) {
            return <Route path='/' component={Login} />
        }

        if (this.state.toProducts) {
            return <Route path='/' component={Product} />
        }

        if (this.state.ToCreditDocument) {
            return <Route path='/' component={CreditContent} />
        }


        if (this.state.toOrderApproval) {
            return <Route path='/' component={OrderApproval} />
        }

        if (this.state.toClearCartPrompt) {
            return <Route path='/' component={ClearCartPrompt} />
        }

        if (this.state.ToList) {
            return <Route path='/' component={InvoiceOrderSearch} />
        }

        let creditList = this.LoadTableCreditItems();
        let credit = this.LoadHeader();

        return (
            <div style={{ width: "100%", height: "100%", color: 'black' }}>
                <div className="header" style={{ position: 'fixed', zIndex : '500'}}>
                    <div className="grid_container_header" style={{ msHyphens: 'auto', display: 'inline-grid', gridTemplateColumns: '30% 40% 30%', display: 'grid -ms-grid', msGridColumns: '30% 40% 30%', columnCount: '3' }}>
                        <div className="grid_header">
                            <div className="icon_home" style={{ width: '100%', height: '50%', 'margin-top': '30px', 'margin-left': '10%', float: 'left', 'background-repeat': 'no-repeat' }}></div>
                        </div>
                        <div className="grid_header" style={{ 'text-align': 'left', overflow: 'hidden' }}>
                            <div className="header_container" style={{ width: '100%' }}>
                                <div>

                                    <div style={{ display: 'inline' }}>
                                        <a className="header_text" onClick={() => this.GoBackToProducts()}  >Place Order</a>|
                                                <a className="header_text" onClick={() => this.GoToDocumentsManage()} >Documents</a><span style={{ visibility: this.state.canTrack }}>|</span>
                                        <a className="header_text" onClick={() => this.GoToOrderApproval()} style={{ visibility: this.state.canTrack }} >Order Approval</a><span style={{ visibility: this.state.isAdminVisibile }}>|</span>
                                        <a className="header_text" onClick={() => this.GoToBudget()} style={{ visibility: 'hidden'}} >Budgets</a>
                                        <span style={{ visibility: this.state.isAdminVisibile }}>|</span>
                                        <a className="header_text" onClick={() => this.GoToAdmin()} style={{ visibility: this.state.isAdminVisibile }} >Admin</a>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="grid_header">
                            <div className="header_container greeting" style={{ width: '100%' }}>
                                <div className="header_text grid_item_1" style={{ lineHeight: '40px', textAlign: 'right' }}>
                                    <div style={{ height: '28px' }} className="overflow_header"> {this.state.userDetails.greeting + ', ' + this.state.userDetails.fullname}</div>
                                    <div style={{ height: '25px' }} className="overflow_header">{this.state.userDetails.accountName}</div>
                                    <div style={{ height: '28px' }} className="overflow_header">Group : {this.state.userDetails.accountCode}</div>
                                </div>
                                <div className="grid_item_2"><a className="header_text" onClick={() => { this.GetCartState() }} id="A1">LOG OUT</a></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="CssPageContatiner">
                    <div>
                        <button ID="btnBack" className="btn secondary_btn" style={{ width: '20%', 'font-family': 'Tw Cen MT' }} onClick={() => this.GoToDocuments()}>Back to Documents</button >
                        <br />
                        <br />
                    </div>
                    <div id="Page" className="CssPage">
                        <div className="pageContainer" style={{ display: 'inline-grid', gridTemplateColumns: '23% 1% 76%', padding: '10px', fontFamily: 'Tw Cen MT' }}>
                            <div className="pageContainer" style={{ padding: '20px' }}>
                                <div style={{ borderBottom: 'solid', marginBottom: '0' }}>
                                    <label style={{ fontSize: 'large', fontWeight: '800', paddingTop: '9px' }}>Address</label>
                                </div>
                                <div style={{ padding: '20px', fontSize: 'medium' }}>
                                    <table>

                                        <tr>
                                            <td style={{ wordBreak: 'break-all' }}>
                                                {this.state.creditHeader.invoiceTo1 + ' ' + this.state.creditHeader.invoiceTo2 + ' ' + this.state.creditHeader.invoiceTo3 + ' ' + this.state.creditHeader.invoiceTo4}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div className="pageContainer" style={{ backgroundColor: 'white' }}></div>
                            <div className="pageContainer" style={{ backgroundColor: 'white', paddingRight: '30px', height: '100%' }}>
                                <div style={{ borderBottom: 'solid', marginBottom: '0' }}>
                                    <label style={{ fontSize: 'xx-large', fontWeight: 'bolder', paddingTop: '15px', marginBottom: '0' }}>Computer Generated Copy Credit</label>
                                </div>
                                <div style={{ height: '87%', width: '100%', backgroundColor: 'white', marginTop: '20px' }}>
                                    {credit}
                                    <div style={{ backgroundColor: 'white', height: '1%' }}>
                                        <hr />
                                    </div>
                                    <div style={{ height: '55%', backgroundColor: 'white' }}>
                                        <table className="table " style={{ fontFamily: 'TW Cen MT', display: 'block', overflow: 'scroll', whiteSpace: 'nowrap', height: '350px' }}>
                                            <thead style={{ lineHeight: '1px', borderBottom: 'solid' }}>
                                                <th>Product ItemCode</th>
                                                <th>Description</th>
                                                <th>UOM</th>
                                                <th>Invoice QTY</th>
                                                <th>Brand ref</th>
                                                <th>Unit Price</th>
                                                <th>Extended Price (Excluding VAT)</th>
                                                <th>VAT</th>
                                                <th>TOTAL</th>
                                            </thead>
                                            {creditList}
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    ///Global methods

    GetCartState() {

        fetch('/api/Product/GetCartCount' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == "valued") {
                    this.setState({ toClearCartPrompt: true });
                } else {
                    window.location.href = '\\';
                }
            });
    }

    CheckIfAdmin() {
        fetch('/api/Login/isAdmin' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => { this.setState({ isAdminVisibile: (data == 'Y' ? 'visible' : 'hidden') }, () => { console.log('visibility : data=' + data + ' value= ' + this.state.isAdminVisibile); }) })
    }

    GoToOrderSearch() {
        this.setState({ GoToOrderSearch: true });
    }


    GoToOrderApproval() {
        this.setState({ toOrderApproval: true });
    }

    GoToDocuments() {
        this.setState({ toDocuments: true });
    }

    toOrderForm() {
        this.setState({ toOrderForm: true });
    }

    GoToLogIn() {
        this.setState({ toLogIn: true });
    }

    GoToAdmin() {
        this.setState({ toAdmin: true });
    }

    GoBackToProducts() {
        this.setState({ toProducts: true });
    }

    GoToBudget() {
        this.setState({ toBudgets: true });
    }

    GoToOrderForm() {
        this.setState({ toOrderForms: true });
    }

    GoToOrderSearch() {
        this.setState({ GoToOrderSearch: true });
    }


    GoToSavedCarts() {
        this.setState({ toSavedCarts: true });
    }

    GetUserDetails() {
        fetch('api/Login/GetUserDetails' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.json())
            .then(data => { this.setState({ userDetails: data }) })
            .then(() => {
                console.log("user details");
                console.log(this.state.userDetails);
            })
    }

    CheckIfCanTrack() {
        fetch('/api/Login/canTrack' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => { this.setState({ canTrack: (data == 'Y' ? 'visible' : 'hidden') }, () => { console.log('visibility : data=' + data + ' value= ' + this.state.isAdminVisibile); }) })
    }

    GoToDocumentsManage() {
        this.setState({ toDocumentsManage: true });
    }

}



//<tr>
//    <td>CustomerCode: {credit.custRef}</td>
//    <td>Invoice No :  ....</td>
//    <td>Order No : {credit.orderNo}</td>
//</tr>
//    <tr>
//        <td>Order Date : {credit.orderDate}</td>
//        <td>Delivery Date : {credit.dateDelivered}</td>
//        <td>Web/Order Ref: {credit.brandRef}</td></tr>
//    <tr>
//        <td>Your Order Ref: {credit.ourRef}</td>
//        <td></td>
//        <td></td>
//    </tr>