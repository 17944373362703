import React, { Component } from 'react'; import { withRouter, Redirect } from 'react-router-dom';
import { BudgetCategories } from './BudgetCategories';
import { ViewBudgetCategories } from './ViewBudgetCategories ';
import { Route } from 'react-router';

//imports
import { Product } from './Products';
import { Login } from './Login';
import { CheckOut } from './CheckOut';
import { SavedCarts } from './SavedCarts';
import { InvoiceOrderSearch } from './InvoiceOrderSearch';
import { OrderSearch } from './OrderSearch'
import { Budget } from './Budget'
import { AdminUser } from './AdminUser'
import { ClearCartPrompt } from './ClearCartPrompt';
import { OrderApproval } from './OrderApproval';
import { SessionTimedOut } from './SessionTimedOut';
import { DocumentsManage } from './DocumentsManage';
import { ChangePassword } from './ChangePassword';
import { AdminManage } from './AdminManage';
//end

export class Users extends Component {
    displayName = Users.name

    constructor(props) {
        super(props);
        this.state = {
            userList: [],
            loadingUsers: false,
            toEdit: false,
            userDetails: {},
            toSessionTimedOut: false,
            toProducts: false,
            toCheckOut: false,
            toSaveCart: false,
            toManage: false,
            toChangePassword: false,
            menuVisibility: 'hidden',
            toClearCartPrompt: false,
            toDocuments: false,
            toOrderForm: false,
            toAdmin: false,
            toLogIn: false,
            toBudgets: false,
            toOrderSearch: false,
            toOrderApproval: false,
            toSavedCarts: false,
            isAdminVisibile: 'hidden',
            canTrack: 'hidden',
            toSessionTimedOut: false,
            toDocumentsManage: false,

        };

        this.CheckSessionInitialize();
    }

    CheckSessionInitialize() {
        this.setState({ complete_update: false });
        console.log('get CheckSession called');

        fetch('api/Login/CheckSession?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });
                    this.GetUserDetails();
                    this.GetUsers();
                    this.CheckIfCanTrack();
                }
                else if (data == 'timedout'){
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }

    CheckSession() {
        this.setState({ complete_update: false });
        console.log('get CheckSession called');

        fetch('api/Login/CheckSession?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });
                }
                else if (data == 'timedout'){
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }

    GoBackToProducts() {
        this.setState({ toProducts: true });
    }


    EditUser(id) {
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            fetch('/api/Admin/SetEditUser?id=' + id + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(() => {
                    this.setState({ toEdit: true });
                })
        }
    }

    handleIsDeletedChecked(e) {
        let checked = e.target.checked;
        let deleted = 2;
        let value = e.target.value;

        console.log('checked : ' + checked);
        console.log('value : ' + value);

        if (checked)
            deleted = 1;
        else
            deleted = 0;

        this.DeleteUser(value, deleted);
    }

    handleIsBlockedChecked(e) {
        let checked = e.target.checked;
        let value = e.target.value;

        console.log('checked : ' + checked);
        console.log('value : ' + value);

        this.BlockUser(value, checked);
    }

    IsChecked(value) {
        let result = '';

        if (value == 1)
            result = true;
        else if (value == 0)
            result = false;

        console.log('checkbox value is : ' + result);
        return result;
    }

    DeleteUser(id, deleted) {
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            console.log('loading user list');

            fetch('/api/Admin/DeleteUser?id=' + id + '&deleted=' + deleted + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(() => { console.log(id + ' deleted') });
        }
    }

    BlockUser(id, blocked) {
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            console.log('loading user list');

            fetch('/api/Admin/BlockUser?id=' + id + '&blocked=' + blocked + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(() => { console.log(id + ' blocked') });
        }
    }

    GetUsers() {

        this.setState({ loadingUsers: true });
        console.log('loading user list');

        fetch('/api/Admin/GetUsers' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.json())
            .then(data => {
                this.setState({ userList: data, loadingUsers: false })
            })
    }

    renderUserTable() {

        if (this.state.loadingUsers) {
            return (
                <tr>
                    <td>loading...</td>
                    <td>loading...</td>
                    <td>loading...</td>
                    <td>loading...</td>
                    <td>loading...</td>
                    <td>loading...</td>
                </tr>
            );

        }
        else {
            return (
                this.state.userList.map(user =>
                    <tr>
                        <td className="sideBorder" style={{ lineHeight: '1px', maxWidth: '171px', height: '19.2px', textOverflow: 'clip' }}>{user.loginID}</td>
                        <td className="sideBorder" style={{ lineHeight: '1px', maxWidth: '171px', maxHeight: '19.2px', textOverflow: 'ellipsis', overflow: 'hidden' }}><span style={{ maxWidth: '169px', maxHeight: '19.2px', textOverflow: 'clip' }} >{user.firstname + ' ' + user.surname}</span></td>
                        <td className="sideBorder" style={{ lineHeight: '1px', maxWidth: '171px', height: '19.2px', textOverflow: 'clip' }}>{user.accountCode}</td>
                        <td className="sideBorder" style={{ lineHeight: '1px', maxWidth: '171px', maxHeight: '19.2px', textOverflow: 'ellipsis', overflow: 'hidden' }}><span style={{ maxWidth: '169px', maxHeight: '19.2px', textOverflow: 'clip' }} >{user.email}</span></td>
                        <td className="sideBorder" style={{ lineHeight: '1px', maxWidth: '171px', height: '19.2px', textOverflow: 'clip' }}>{user.cell}</td>
                        <td className="sideBorder" style={{ lineHeight: '1px', maxWidth: '171px', height: '19.2px' }}>
                            {React.createElement('input', { type: 'checkbox', defaultChecked: this.IsChecked(user.isDeleted), value: user.userID, onClick: this.handleIsDeletedChecked.bind(this) })}
                        </td>
                        <td className="sideBorder" style={{ lineHeight: '1px', overflowX: "hidden", width: '177px' }}>
                            {React.createElement('input', { type: 'checkbox', defaultChecked: this.IsChecked(user.isBlocked), value: user.userID, onClick: this.handleIsBlockedChecked.bind(this) })}
                        </td>
                        <td className="sideBorder" style={{ lineHeight: '1px', width: '177px', color: 'blue', overflowX: "hidden", cursor: 'pointer' }} onClick={() => { this.EditUser(user.userID) }}>edit</td>
                    </tr>
                ));
        }

    }

    ToChangePassword() {
        fetch('/api/Login/CanUpdatePassword' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(response => {

                if (response == 'true')
                    this.setState({ toChangePassword: true });
                else
                    alert('You currently do not have the required permissions to change your password.');

            });
    }

    MenuClick() {

        if (this.state.menuVisibility == 'hidden') {
            this.setState({ menuVisibility: 'visible' });
        }
        else if (this.state.menuVisibility == 'visible') {
            this.setState({ menuVisibility: 'hidden' });
        }
    }


    render() {
        //Route Change
        if (this.state.toSessionTimedOut) {
            return <Route path='/' component={SessionTimedOut} />
        }


        if (this.state.toDocumentsManage) {
            return <Route path='/' component={DocumentsManage} />
        }

        if (this.state.GoToOrderSearch) {
            return <Route path='/' component={OrderSearch} />
        }

        if (this.state.toBudgets) {
            return <Route path='/' component={Budget} />
        }

        if (this.state.toSavedCarts) {
            return <Route path='/' component={SavedCarts} />
        }

        if (this.state.toCheckOut) {
            return <Route path='/' component={CheckOut} />
        }

        if (this.state.toDocuments) {
            return <Route path='/' component={InvoiceOrderSearch} />
        }

        if (this.state.toOrderApproval) {
            return <Route path='/' component={OrderApproval} />
        }

        if (this.state.toChangePassword) {
            return <Route path='/' component={ChangePassword} />

        }


        if (this.state.toAdmin) {
            return (<Route path='/' component={AdminManage} />);
        }

        if (this.state.toLogIn) {
            return <Route path='/' component={Login} />
        }

        if (this.state.toProducts) {
            return <Route path='/' component={Product} />
        }

        ///end

        if (this.state.toEdit) {
            return <Route path='/' component={AdminUser} />
        }

        let users = this.renderUserTable();

        return (
            <div className="outer-container customer_background">
                <div className="header" style={{ position: 'fixed', zIndex : '500'}}>
                    <div className="grid_container_header" style={{ msHyphens: 'auto', display: 'inline-grid', gridTemplateColumns: '30% 40% 30%', display: 'grid -ms-grid', msGridColumns: '30% 40% 30%', columnCount: '3' }}>
                        <div className="grid_header">
                            <div className="icon_home" style={{ width: '100%', height: '50%', 'margin-top': '30px', 'margin-left': '10%', float: 'left', 'background-repeat': 'no-repeat' }}></div>
                        </div>
                        <div className="grid_header" style={{ 'text-align': 'left', overflow: 'hidden' }}>
                            <div className="header_container" style={{ width: '100%' }}>
                                <div>
                                    <div style={{ display: 'inline' }}>
                                        <a className="header_text" onClick={() => this.GoBackToProducts()}  >Place Order</a>|
                                        <a className="header_text" onClick={() => this.GoToDocumentsManage()} >Documents</a><span style={{ visibility: this.state.canTrack }}>|</span>
                                        <a className="header_text" onClick={() => this.GoToOrderApproval()} style={{ visibility: this.state.canTrack }} >Order Approval</a><span style={{ visibility: this.state.isAdminVisibile }}>|</span>
                                        <a className="header_text" onClick={() => this.GoToBudget()}  >Budgets</a>
                                        <span style={{ visibility: this.state.isAdminVisibile }}>|</span>
                                        <a className="header_text" onClick={() => this.GoToAdmin()}  >Admin</a>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="grid_header">
                            <div className="header_container greeting" style={{ width: '100%' }}>
                                <div className="header_text grid_item_1" style={{ lineHeight: '40px', textAlign: 'right' }}>
                                    <div style={{ height: '28px' }} className="overflow_header"> {this.state.userDetails.greeting + ', ' + this.state.userDetails.fullname}</div>
                                    <div style={{ height: '25px' }} className="overflow_header">{this.state.userDetails.accountName}</div>
                                    <div style={{ height: '28px' }} className="overflow_header">Group : {this.state.userDetails.accountCode}</div>
                                </div>
                                <div className="grid_item_2_reverse  h100">
                                    <div style={{ width: '100%', 'height': '100%' }}>
                                        <button id="btnMenu" onClick={() => this.MenuClick()} className="btn primary_btn header_btn twfont" style={{ lineHeight: '0.8', marginTop: '10px'  }}  ><i className="material-icons" style={{ width: '100%' }} >menu</i><label>Options</label></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header_menu_container" style={{ visibility: this.state.menuVisibility }}>
                    <div className="grid_item_1 max-height-menu">
                        <button onClick={() => { this.MenuClick() }} style={{ width: '100%', height: '100%', backgroundColor: 'transparent', border: 'none' }}></button>
                    </div>
                    <div className="header_menu grid_item_2 max-height-menu customer_background_foreground">
                        <hr />
                        <div>
                            <button id="canUpdateBtn" name="canUpdateBtn" className="btn  header_menu_item" onClick={() => { this.ToChangePassword() }}>Reset Password <i className="material-icons" style={{ position: 'relative', top: '6px', paddingLeft: '10px' }}>vpn_key</i></button>
                        </div>
                        <hr />
                        <div>
                            <button className="btn  header_menu_item" onClick={() => { this.GetCartState() }}>Logout <i className="material-icons" style={{ position: 'relative', top: '6px', paddingLeft: '10px' }}>exit_to_app</i></button>
                        </div>
                        <hr />
                    </div>
                </div>
                <div className=" sidenav customer_background_foreground" style={{ width: '100%', minHeight: '1000px', marginTop: '100px' }}>
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <br />
                        <div className="customer_background_foreground" style={{ width: '90%', margin: '0 5%', padding: '20px', verticalAlign: 'middle', height: '300px' }}>
                            <div>
                                <label className="customer_background_foreground" style={{ fontFamily: 'Tw Cen MT', fontSize: 'large', fontWeight: 'bold', width: '100%', textAlign: 'center' }}>Users</label>
                                <hr />
                            </div>
                            <div>
                                <br />

                                <table className="table table-bordered" style={{ fontFamily: 'TW Cen MT', display: 'block', overflow: 'scroll', whiteSpace: 'nowrap', height: '600px', margin: '0 9.5%', width: '81%' }}>
                                    <thead className="thead customer_base_background">
                                        <th style={{ textAlign: 'center', lineHeight: '1px', width: '177px' }}>Login Id</th>
                                        <th style={{ textAlign: 'center', lineHeight: '1px', width: '177px' }}>Name</th>
                                        <th style={{ textAlign: 'center', lineHeight: '1px', width: '177px' }}>Account</th>
                                        <th style={{ textAlign: 'center', lineHeight: '1px', width: '177px' }}>Email</th>
                                        <th style={{ textAlign: 'center', lineHeight: '1px', width: '177px' }}>Cell</th>
                                        <th style={{ textAlign: 'center', lineHeight: '1px', width: '177px' }}>IsDeleted</th>
                                        <th style={{ textAlign: 'center', lineHeight: '1px', width: '177px' }}>IsBlocked</th>
                                        <th style={{ textAlign: 'center', lineHeight: '1px', width: '177px' }}>Edit</th>
                                    </thead>
                                    {users}
                                </table>
                                <br />
                            </div >
                        </div>
                    </div>
                </div >
            </div>
        );
    }

    GoToDocumentsManage() {
        this.setState({ toDocumentsManage: true });
    }


    GetCartState() {

        fetch('/api/Product/GetCartCount' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == "valued") {
                    this.setState({ toClearCartPrompt: true });
                } else {
                    window.location.href = '\\';
                }
            });
    }

    CheckIfAdmin() {
        fetch('/api/Login/isAdmin' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => { this.setState({ isAdminVisibile: (data == 'Y' ? 'visible' : 'hidden') }, () => { console.log('visibility : data=' + data + ' value= ' + this.state.isAdminVisibile); }) })
    }

    GoToOrderSearch() {
        this.setState({ GoToOrderSearch: true });
    }

    GoToDocuments() {
        this.setState({ toDocuments: true });
    }

    toOrderForm() {
        this.setState({ toOrderForm: true });
    }

    GoToLogIn() {
        this.setState({ toLogIn: true });
    }

    GoToAdmin() {
        this.setState({ toAdmin: true });
    }

    GoBackToProducts() {
        this.setState({ toProducts: true });
    }

    GoToBudget() {
        this.setState({ toBudgets: true });
    }

    GoToOrderForm() {
        this.setState({ toOrderForms: true });
    }

    GoToOrderSearch() {
        this.setState({ GoToOrderSearch: true });
    }

    GoToOrderApproval() {
        this.setState({ toOrderApproval: true });
    }

    GoToSavedCarts() {
        this.setState({ toSavedCarts: true });
    }

    GetUserDetails() {
        fetch('api/Login/GetUserDetails' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.json())
            .then(data => { this.setState({ userDetails: data }) })
            .then(() => {
                console.log("user details");
                console.log(this.state.userDetails);
            })
    }

    CheckIfCanTrack() {
        fetch('/api/Login/canTrack' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => { this.setState({ canTrack: (data == 'Y' ? 'visible' : 'hidden') }, () => { console.log('visibility : data=' + data + ' value= ' + this.state.isAdminVisibile); }) })
    }
}

