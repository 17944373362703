import React, { Component } from 'react'; import { withRouter, Redirect } from 'react-router-dom';
import { BudgetCategories } from './BudgetCategories';
import { ViewBudgetCategories } from './ViewBudgetCategories ';
import { Route } from 'react-router';

//imports
import { Product } from './Products';
import { Login } from './Login';
import { CheckOut } from './CheckOut';
import { SavedCarts } from './SavedCarts';
import { InvoiceOrderSearch } from './InvoiceOrderSearch';
import { OrderSearch } from './OrderSearch'
import { Budget } from './Budget'
import { AdminUser } from './AdminUser'
import { ClearCartPrompt } from './ClearCartPrompt';
import { OrderApproval } from './OrderApproval';
import { SessionTimedOut } from './SessionTimedOut';
import { DocumentsManage } from './DocumentsManage';
import { ChangePassword } from './ChangePassword';
import { AdminManage } from './AdminManage';
import { ReactDOM } from "react-dom";
//import { ReactDataGrid } from "react-data-grid";


//end

export class LabelAddressList extends Component {
    displayName = LabelAddressList.name



    constructor(props) {
        super(props);
        this.state = {
            userList: [],
            loadingAddresses: false,
            toEdit: false,
            userDetails: {},
            toSessionTimedOut: false,
            toProducts: false,
            toCheckOut: false,
            toSaveCart: false,
            toManage: false,
            toChangePassword: false,
            menuVisibility: 'hidden',
            toClearCartPrompt: false,
            toDocuments: false,
            toOrderForm: false,
            toAdmin: false,
            toLogIn: false,
            toBudgets: false,
            toOrderSearch: false,
            toOrderApproval: false,
            toSavedCarts: false,
            isAdminVisibile: 'hidden',
            canTrack: 'hidden',
            toSessionTimedOut: false,
            toDocumentsManage: false,
            toAdminManage: false,


            accountCode: '',
            contactName: '',
            contactNo: '',
            addrName: '',
            street: '',
            city: '',
            postalCode: '',
            province: '',
            dsvLocationID: '',
            businessUnitComplexName: '',
            townSuburb: '',
            addressType: 'collect',
            requestModalVisibility: 'hidden',
            dsvlabelVisiblity: 'hidden',
            orderHasOrangeLabels: false,
            loadingCollectFromAddresses: true,
            loadingDeliverToAddresses: true,
            updatingAddresses: false,

            addressId: '',
            accountCode: '',
            contactName: '',
            contactNo: '',
            addrName: '',
            street: '',
            city: '',
            postalCode: '',
            province: '',
            dsvLocationID: '',
            businessUnitComplexName: '',
            townSuburb: '',
            collectActive: false,
            deliverActive: false,


            requestModalVisibility: 'hidden',
            assignAddressModalVisibility: 'hidden',
            dsvlabelVisiblity: 'hidden',
            orderHasOrangeLabels: false,
            loadingCollectFromAddresses: true,
            loadingDeliverToAddresses: true,
            loadingAddressStates: true,
            updatingAddresses: false,
            addresses: [],
            accounts: [],
            accountsAndStates: [],
            selectedAccount: '',
            selectedStateAccount: '',
            searchText: '',
            deliverToValue: '',
            collectFromValue: '',
            addrType: -1,
            initAddrType: -1,
            isNewAddress: false,

            searchAddress: '0',
            searchAccount: '0',
            updateAllType: 'unset',
            updateAllState: 'unset',
            updateAllModalVisibility: 'hidden',
            lastUpdatedType: '',
            accountSet: true,
            modalMessage: '',
            rejectionReason: '',
            createdByDesc: '',
            updateAllState: '0',
            isUpdateAllLoading: false
        };

        this.CheckSessionInitialize();
    }

    onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
        this.setState(state => {
            const rows = state.rows.slice();
            for (let i = fromRow; i <= toRow; i++) {
                rows[i] = { ...rows[i], ...updated };
            }
            return { rows };
        });
    };

    CheckSessionInitialize() {
        this.setState({ complete_update: false });

        fetch('api/Login/CheckSession?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });
                    this.GetUserDetails();
                    this.GetAccounts();
                    this.GetAddressesFiltered();
                    this.CheckIfCanTrack();
                }
                else if (data == 'timedout') {
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }

    CheckSession() {
        this.setState({ complete_update: false });

        fetch('api/Login/CheckSession?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });
                }
                else if (data == 'timedout') {
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }

    GoBackToProducts() {
        this.setState({ toProducts: true });
    }

    handleSearchTextChanged(e) {
        this.setState({ searchText: e.target.value });
    }

    handleIsBlockedChecked(e) {
        let checked = e.target.checked;
        let value = e.target.value;
        this.BlockUser(value, checked);
    }

    DisplayRejectionModal(e) {

        this.setState({ updateAllModalVisibility: 'visible' });
    }




    hideUpdateModal() {
        this.setState({ updateAllModalVisibility: 'hidden', rejectionReason: '' });

    }

    SetAllCoumnsState() {
        this.setState({ updateAllModalVisibility: 'hidden' });
        var chkbx = document.getElementById(this.state.lastUpdatedType);
    }

    UpdateAddressState(e) {

        let addressId = e.target.id;
        let isActive = e.target.checked;
        let type = e.target.id.includes('col') ? 'collect' : 'deliver';
        this.setState({ loadingAddresses: true });


        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            this.setState({ loadingAddresses: true });
            fetch('/api/Label/UpdateStateAndGetAddresses?id=' + addressId
                + '&addressType=' + type
                + '&isActive=' + isActive
                + '&sText=' + this.state.searchText
                + '&sType=' + this.state.searchAddress
                + '&sAccount=' + this.state.searchAccount
                + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
                .then(response => response.json())
                .then(data => {
                    this.setState({ addresses: data, loadingAddresses: false });
                });

        }
    }

    SaveAddress() {
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            if (this.state.toSessionTimedOut == false) {

                let addressId = this.state.addressId;
                let contactName = this.state.contactName;
                let contactNo = this.state.contactNo;
                let addrName = this.state.addrName;
                let street = this.state.street;
                let city = this.state.city;
                let postalCode = this.state.postalCode;
                let province = this.state.province;
                let dsvLocationID = this.state.dsvLocationID;
                let addressType = 4;
                let businessUnitComplexName = this.state.businessUnitComplexName;
                let townSuburb = this.state.townSuburb;
                let deliverToValue = this.state.deliverToValue;
                let collectFromValue = this.state.collectFromValue;
                let apiName = 'UpdateAddress';

                if (this.state.isNewAddress) {
                    apiName = 'RequestNewAddress';
                    addressId = '?';
                }
                else {
                    addressId = '?id=' + addressId + '&';
                }

                var updateAll = this.state.searchAccount == '0';

                this.setState({ loadingAddresses: true });

                if (contactName == '' && updateAll)
                    alert('Please provide contact name');
                else if (contactNo == '' && updateAll)
                    alert('Please provide contact number');
                else if (addrName == '' && updateAll)
                    alert('Please provide the address name');
                else if (street == '' && updateAll)
                    alert('Please provide the street name');
                else if (city == '' && updateAll)
                    alert('Please provide the city name');
                else if (postalCode == '' && updateAll)
                    alert('Please provide the postal code');
                else if (province == '' && updateAll)
                    alert('Please provide the province');
                else if (businessUnitComplexName == '' && updateAll)
                    alert('Please provide the businessUnitComplexName');
                else if (townSuburb == '' && updateAll)
                    alert('Please provide the Town or Suburb');
                else if (dsvLocationID == null | (dsvLocationID.replace(' ', '') == '' && updateAll))
                    alert('Please provide the Location ID');
                //else if (addressType == 4)
                //    alert('Please select address type');
                else {
                    fetch('/api/Label/ValidateLocationId?id=' + this.state.addressId
                        + '&dsvLocationId=' + dsvLocationID
                        + '&updateAll=' + updateAll
                        + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
                        .then(response => response.text())
                        .then(data => {
                            if (data == 'INVALID') {
                                alert('The requested DSVLocationID already exists, please provide another.');
                            }
                            else {

                                fetch('/api/Label/' + apiName
                                    + addressId
                                    + 'contactName=' + contactName
                                    + '&contactNo=' + contactNo
                                    + '&address_name=' + addrName
                                    + '&street=' + street
                                    + '&city=' + city
                                    + '&postal=' + postalCode
                                    + '&province=' + province
                                    + '&businessUnitComplexName=' + businessUnitComplexName
                                    + '&townSuburb=' + townSuburb
                                    + '&dsvLocationId=' + dsvLocationID
                                    + '&addressType=' + addressType
                                    + '&isNewAddress=' + this.state.isNewAddress
                                    + '&updateAll=' + updateAll
                                    + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
                                    .then(response => response.text())
                                    .then(data => {
                                        if (data == 'complete') {
                                            alert('Address updated.');
                                            this.setState({ requestModalVisibility: 'hidden' });
                                        }
                                        else {
                                            alert('An issue occured please try again later.');
                                            this.setState({ requestModalVisibility: 'hidden' });
                                        }

                                        this.ClearAddressDetails();
                                    });



                            }
                        });
                }

                this.GetAddressesFiltered();
            }
        }
    }

    ClearAddressDetails() {
        this.setState({
            addressId: ''
            , contactName: ''
            , contactNo: ''
            , addrName: ''
            , street: ''
            , city: ''
            , postalCode: ''
            , province: ''
            , dsvLocationID: ''
            , addrType: -1
            , businessUnitComplexName: ''
            , townSuburb: ''
            , deliverToValue: ''
            , collectFromValue: ''
            , reatedByDesc: ''
            , createdByDesc : ''
        });
    }

    GetAddresses() {

        this.setState({ loadingAddresses: true });
        fetch('/api/Label/GetAddressList?searchText=' + this.state.searchText + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.json())
            .then(data => {
                this.setState({ addresses: data, loadingAddresses: false })
            })
    }

    SendRejectionReason() {

        fetch('/api/Label/SendRejectionReason?id=' + this.state.addressId
            + '&rejectionReason=' + this.state.rejectionReason
            + '&searchAccount=' + this.state.searchAccount
            + '&addressName=' + this.state.addrName
            + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'complete') {
                    alert('Rejection successfull, mail sent.');
                    this.setState({ updateAllModalVisibility: 'hidden', requestModalVisibility: 'hidden', rejectionReason: '' });
                }
                else {
                    alert('An error occured contact support. Thank you');
                    this.setState({ updateAllModalVisibility: 'hidden', requestModalVisibility: 'hidden', rejectionReason: '' });
                }

                this.setState({ rejectionReason: '' });
                this.GetAddressesFiltered();

            })
    }

    GetAddressesFiltered() {

        this.setState({ loadingAddresses: true });
        fetch('/api/Label/GetAddressListFiltered?searchText=' + this.state.searchText
            + '&addressType=' + this.state.searchAddress
            + '&account=' + this.state.searchAccount
            + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.json())
            .then(data => {
                this.setState({ addresses: data, loadingAddresses: false, accountSet: true })
            })
    }


    GetAccounts() {

        fetch('/api/Label/GetDSVAccounts?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.json())
            .then(data => {
                this.setState({ accounts: data })
            })
    }

    SaveAndGetAssignments(dsvlocationId) {
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            if (this.state.toSessionTimedOut == false) {

                let addressId = this.state.addressId;
                let contactName = this.state.contactName;
                let contactNo = this.state.contactNo;
                let addrName = this.state.addrName;
                let street = this.state.street;
                let city = this.state.city;
                let postalCode = this.state.postalCode;
                let province = this.state.province;
                let dsvLocationID = this.state.dsvLocationID;
                let addressType = 4;
                let businessUnitComplexName = this.state.businessUnitComplexName;
                let townSuburb = this.state.townSuburb;
                let deliverToValue = this.state.deliverToValue;
                let collectFromValue = this.state.collectFromValue;
                let apiName = 'UpdateAddress';

                if (this.state.isNewAddress) {
                    apiName = 'RequestNewAddress';
                    addressId = '?';
                }
                else {
                    addressId = '?id=' + addressId + '&';
                }

                var updateAll = this.state.searchAccount == '0';

                this.setState({ loadingAddresses: true });

                if (contactName == '' && updateAll)
                    alert('Please provide contact name');
                else if (contactNo == '' && updateAll)
                    alert('Please provide contact number');
                else if (addrName == '' && updateAll)
                    alert('Please provide the address name');
                else if (street == '' && updateAll)
                    alert('Please provide the street name');
                else if (city == '' && updateAll)
                    alert('Please provide the city name');
                else if (postalCode == '' && updateAll)
                    alert('Please provide the postal code');
                else if (province == '' && updateAll)
                    alert('Please provide the province');
                else if (businessUnitComplexName == '' && updateAll)
                    alert('Please provide the businessUnitComplexName');
                else if (townSuburb == '' && updateAll)
                    alert('Please provide the Town or Suburb');
                else if (dsvLocationID == null | (dsvLocationID.replace(' ', '') == '' && updateAll))
                    alert('Please provide the Location ID');
                else if (addressType == -1)
                    alert('Please select address type');
                else {
                    fetch('/api/Label/ValidateLocationId?id=' + this.state.addressId
                        + '&dsvLocationId=' + dsvLocationID
                        + '&updateAll=' + updateAll
                        + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
                        .then(response => response.text())
                        .then(data => {
                            if (data == 'INVALID') {
                                alert('The requested DSVLocationID already exists, please provide another.');
                            }
                            else {

                                fetch('/api/Label/' + apiName
                                    + addressId
                                    + 'contactName=' + contactName
                                    + '&contactNo=' + contactNo
                                    + '&address_name=' + addrName
                                    + '&street=' + street
                                    + '&city=' + city
                                    + '&postal=' + postalCode
                                    + '&province=' + province
                                    + '&businessUnitComplexName=' + businessUnitComplexName
                                    + '&townSuburb=' + townSuburb
                                    + '&dsvLocationId=' + dsvLocationID
                                    + '&addressType=' + addressType
                                    + '&isNewAddress=' + this.state.isNewAddress
                                    + '&updateAll=' + updateAll
                                    + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
                                    .then(response => response.text())
                                    .then(data => {
                                        if (data == 'complete') {
                                            this.GetDSVAccountsAndStates(dsvlocationId);

                                            let chkbx = document.getElementById(dsvLocationID);
                                            chkbx.value = "0";
                                        }
                                        else {
                                            alert('An issue occured please try again later.');
                                            this.setState({ requestModalVisibility: 'hidden' });
                                        }

                                    });



                            }
                        });
                }


                this.GetAddressesFiltered();
            }
        }
    }

    GetDSVAccountsAndStates(dsvLocationId) {

        this.setState({ accountsAndStates: []});
        fetch('/api/Label/GetDSVAccountsAndStates?dsvLocationId=' + dsvLocationId + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.json())
            .then(data => {
                this.setState({ assignAddressModalVisibility: 'visible', accountsAndStates: data, loadingAddressStates: false });
            });
    }

    CloseAssignModal() {
        this.setState({ assignAddressModalVisibility: 'hidden' });
    }

    handleAddressTypeChange(e) {
        let inputValue = e.target.value;
        this.setState({ searchAddress: inputValue },() => { this.GetAddressesFiltered() });
        
    }

    handleAccountTypeChange(e) {
        let inputValue = e.target.value;
        this.setState({ searchAccount: inputValue, accountSet: false });

        this.GetAddressesFiltered();
    }

    handleAssignAccountChange(e) {
        let details = e.target.id.split('_');
        let account = details[1];
        let dsvlocationid = details[0];
        let state = e.target.value;

        fetch('/api/Label/UpdateAddressStateByAccount?dsvlocationId=' + dsvlocationid + '&account=' + account + '&state=' + state
            + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.json())
            .then(data => {
                this.setState({ assignAddressModalVisibility: 'visible', accountsAndStates: data, loadingAddressStates: false });
            });
    }

    handleAssignAccountAllChange(e) {
        let details = e.target.id.split('_');
        let dsvlocationid = e.target.id;
        let state = e.target.value;
        let stateDescription = '';

        switch (state) {
            case '1': stateDescription = 'Collect From'; break;
            case '2': stateDescription = 'Deliver To'; break;
            case '3': stateDescription = 'Collect From & Deliver To'; break;
            case '4': stateDescription = 'Inactive'; break;
            default: stateDescription = 'Unset';
        }

        if (state != '0') {
            this.setState({ isUpdateAllLoading: true, accountsAndStates: [] });
            document.getElementById(e.target.id).setAttribute('disabled', 'disabled');
            document.getElementById('btnCloseAssign').setAttribute('disabled', 'disabled');

            let output = window.confirm('Update all accounts to "' + stateDescription + '"?');

            if (output) {
                fetch('/api/Label/UpdateAllAddressStates?dsvlocationId=' + dsvlocationid + '&state=' + state
                    + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
                    .then(response => response.json())
                    .then(data => {
                        this.setState({ accountsAndStates: data });
                        alert('Addresses have been updated.');
                        this.enableAssignComponents(dsvlocationid);
                    });
            }
            else {
                document.getElementById(e.target.id).value = '0';
                this.GetDSVAccountsAndStates(dsvlocationid);
                this.enableAssignComponents(dsvlocationid);
            }

        }
    }

    enableAssignComponents(id) {

        document.getElementById(id).removeAttribute('disabled');
        document.getElementById('btnCloseAssign').removeAttribute('disabled');
        this.setState({ isUpdateAllLoading: false });
    }

    handleSelectedDeliChanged(e) {
        this.setState({ deliverToValue: e.target.value, deliverToActive: e.target.value == '1' });
    }

    handleSelectedCollChanged(e) {
        this.setState({ collectFromValue: e.target.value, collectFromActive: e.target.value == '1' });
    }

    handleSelectedAddrTypeChanged(e) {
        this.setState({ addrType: e.target.value });
    }

    DisplayRequestModal() {
        this.setState({ requestModalVisibility: 'visible' });
    }




    RequestNewAddress() {
        this.ClearAddressDetails();
        this.setState({ requestModalVisibility: 'visible', isNewAddress: true, addrType: '-1' });
    }

    CloseRequestModal() {
        this.setState({ requestModalVisibility: 'hidden' });
    }

    GetAddress(id) {

        fetch('/api/Label/GetAddressById?id=' + id
            + '&account=' + this.state.searchAccount
            + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.json())
            .then(data => {

                var type = '-1';

                if (this.state.searchAccount == '0')
                    type = 4;
                else if (data.collectFromActive & data.deliverToActive)
                    type = 2;
                else if (!data.collectFromActive & !data.deliverToActive)
                    type = 3;
                else if (data.collectFromActive & !data.deliverToActive)
                    type = 0;
                else if (!data.collectFromActive & data.deliverToActive)
                    type = 1;


                this.setState({
                    addressId: id,
                    contactName: data.contactName,
                    contactNo: data.contactNo,
                    addrName: data.addrName,
                    street: data.addrStreet,
                    city: data.addrCity,
                    postalCode: data.addrPostalCode,
                    province: data.addrProvince,
                    dsvLocationID: data.dsvLocationID,
                    businessUnitComplexName: data.businessUnitComplexName,
                    townSuburb: data.townSuburb,
                    createdByDesc: data.createdByDesc,
                    collectFromActive: data.collectFromActive,
                    deliverToActive: data.deliverToActive,
                    collectFromValue: data.collectFromActive ? '1' : '0',
                    deliverToValue: data.deliverToActive ? '1' : '0',
                    requestModalVisibility: 'visible',
                    isNewAddress: false,
                    addrType: type,
                    initAddrType: type
                });

                console.log('account : "' + this.state.searchAccount + '" (type : ' + this.state.addrType + ') ');

                if (this.state.searchAccount != '0' && this.state.accountSet)
                    this.setState({ modalMessage: 'Address details cannot be updated if you filter by account' });
                else
                    this.setState({ modalMessage: '' });


                this.SetInputState();

            })
    }

    SetInputState() {

        var isdisabled = false;

        if (this.state.searchAccount != '0' && this.state.accountSet)
            isdisabled = true;

        document.getElementById('txtContactName').disabled = isdisabled;
        document.getElementById('txtContactNo').disabled = isdisabled;
        document.getElementById('txtAddrName').disabled = isdisabled;
        document.getElementById('txtStreet').disabled = isdisabled;
        document.getElementById('txtCity').disabled = isdisabled;
        document.getElementById('txtPostalCode').disabled = isdisabled;
        document.getElementById('txtProvince').disabled = isdisabled;
        document.getElementById('txtBusinessUnitComplexName').disabled = isdisabled;
        document.getElementById('txtTownSuburb').disabled = isdisabled;
        document.getElementById('txtDsvLocationId').disabled = isdisabled;

    }



    renderAddressTable() {

        if (this.state.loadingAddresses) {
            return (
                <tr>
                    <td>loading...</td>
                    <td>loading...</td>
                    <td>loading...</td>
                    {/*<td>loading...</td>*/}
                    {/*<td>loading...</td>*/}
                    {/*<td>loading...</td>*/}
                </tr>
            );

        }
        else {
            return (
                this.state.addresses.map(item =>
                    <tr key={'tr_' + item.id}>
                        <td className="sideBorder" style={{ maxWidth: '171px', height: '19.2px', textOverflow: 'clip' }}>{item.dsvLocationID}</td>
                        {/*<td className="sideBorder" style={{ maxWidth: '171px', height: '19.2px', textOverflow: 'clip' }}>{item.accountCode}</td>*/}
                        <td className="sideBorder" style={{ maxWidth: '171px', maxHeight: '19.2px', textOverflow: 'ellipsis', overflow: 'hidden' }}><span style={{ maxWidth: '169px', maxHeight: '19.2px', textOverflow: 'clip' }} >{item.addrName}</span></td>
                        {/*<td className="sideBorder" style={{ maxWidth: '171px', height: '19.2px', textOverflow: 'clip' }}>{this.renderCheckbox(item.id + 'col', item.collectFromActive, item.dsvLocationID)} </td>*/}
                        {/*<td className="sideBorder" style={{ maxWidth: '171px', height: '19.2px', textOverflow: 'clip' }}>{this.renderCheckbox(item.id + 'del', item.deliverToActive, item.dsvLocationID)} </td>*/}
                        <td className="sideBorder" style={{ width: '177px', color: 'blue', overflowX: 'hidden', cursor: 'pointer' }} onClick={() => { this.GetAddress(item.id) }}>edit</td>
                    </tr>
                ));
        }

    }

    renderAddressStateTable(dsvLocationId) {

        if (this.state.loadingAddressStates) {
            return (
                <tr>
                    <td>loading...</td>
                    <td>loading...</td>
                </tr>
            );

        }
        else {
            return (
                this.state.accountsAndStates.map(account =>
                    <tr key={'key_' + account.code}>
                        <td className="sideBorder" style={{ width: '60%', height: '19.2px', textOverflow: 'clip', textAlign: 'left' }}>{account.description}</td>
                        <td style={{ width: '40%' }}>
                            <select id={dsvLocationId + '_' + account.code} className="form-control twfont" defaultValue={account.state} onChange={this.handleAssignAccountChange.bind(this)} >
                                <option value="1">Collect From</option>
                                <option value="2">Deliver To</option>
                                <option value="3">Collect From & Deliver To</option>
                                <option value="4">Inactive</option>
                            </select>
                        </td>
                    </tr>
                ));
        }

    }

    renderCheckbox(id, state, value) {
        if (state) {
            return (<input id={id} type="checkbox" defaultChecked="true" value={value} name={id} onChange={this.UpdateAddressState.bind(this)} />);
        }
        else
            return (<input id={id} type="checkbox" name={id} value={value} onChange={this.UpdateAddressState.bind(this)} />)
    }

    renderAccounts() {
        return (
            this.state.accounts.map(account =>
                <option value={account.code} key={account.code}>
                    {account.description}
                </option>
            ));
    }

    renderAssignAccounts() {
        return (
            this.state.accounts.map(account =>
                <tr>
                    <td className="sideBorder" style={{ width: '60%', height: '19.2px', textOverflow: 'clip', textAlign: 'left' }}>{account.description}</td>
                    <td style={{ width: '40%' }}>
                        <select id={'ddl_' + account.code} className="form-control twfont">
                            <option value="1">Collect From</option>
                            <option value="2">Deliver To</option>
                            <option value="3">Collect From & Deliver To</option>
                            <option value="4">Inactive</option>
                        </select>
                    </td>
                </tr>
            ));
    }

    ToChangePassword() {
        fetch('/api/Login/CanUpdatePassword?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(response => {

                if (response == 'true')
                    this.setState({ toChangePassword: true });
                else
                    alert('You currently do not have the required permissions to change your password.');

            });
    }

    MenuClick() {

        if (this.state.menuVisibility == 'hidden') {
            this.setState({ menuVisibility: 'visible' });
        }
        else if (this.state.menuVisibility == 'visible') {
            this.setState({ menuVisibility: 'hidden' });
        }
    }






    render() {
        //Route Change
        if (this.state.toSessionTimedOut) {
            return <Route path='/' component={SessionTimedOut} />
        }


        if (this.state.toDocumentsManage) {
            return <Route path='/' component={DocumentsManage} />
        }

        if (this.state.GoToOrderSearch) {
            return <Route path='/' component={OrderSearch} />
        }

        if (this.state.toBudgets) {
            return <Route path='/' component={Budget} />
        }

        if (this.state.toSavedCarts) {
            return <Route path='/' component={SavedCarts} />
        }

        if (this.state.toCheckOut) {
            return <Route path='/' component={CheckOut} />
        }

        if (this.state.toDocuments) {
            return <Route path='/' component={InvoiceOrderSearch} />
        }


        if (this.state.toAdminManage) {
            return <Route path='/' component={AdminManage} />
        }

        if (this.state.toOrderApproval) {
            return <Route path='/' component={OrderApproval} />
        }

        if (this.state.toChangePassword) {
            return <Route path='/' component={ChangePassword} />

        }


        if (this.state.toAdmin) {
            return (<Route path='/' component={AdminManage} />);
        }

        if (this.state.toLogIn) {
            return <Route path='/' component={Login} />
        }

        if (this.state.toProducts) {
            return <Route path='/' component={Product} />
        }

        ///end

        if (this.state.toEdit) {
            return <Route path='/' component={AdminUser} />
        }

        let addresses = this.renderAddressTable();
        let accounts = this.renderAccounts();

        let accountCode = this.state.accountCode;
        let contactName = this.state.contactName;
        let contactNo = this.state.contactNo;
        let addrName = this.state.addrName;
        let street = this.state.street;
        let city = this.state.city;
        let postalCode = this.state.postalCode;
        let province = this.state.province;
        let businessUnitComplexName = this.state.businessUnitComplexName;
        let townSuburb = this.state.townSuburb;
        let dsvLocationID = this.state.dsvLocationID;
        let collActive = this.state.collectFromActive;
        let deliActive = this.state.deliverToActive;
        let rejectionReason = this.state.rejectionReason;
        let accountTable = this.renderAddressStateTable(dsvLocationID);

        return (
            <div className="outer-container customer_background">
                <div className="header" style={{ position: 'fixed', zIndex: '500' }}>
                    <div className="grid_container_header" style={{ msHyphens: 'auto', display: 'inline-grid', gridTemplateColumns: '30% 40% 30%', display: 'grid -ms-grid', msGridColumns: '30% 40% 30%', columnCount: '3' }}>
                        <div className="grid_header">
                            <div className="icon_home" style={{ width: '100%', height: '50%', marginTop: '30px', marginLeft: '10%', float: 'left', backgroundRepeat: 'no-repeat' }}></div>
                        </div>
                        <div className="grid_header" style={{ textAlign: 'left', overflow: 'hidden' }}>
                            <div className="header_container" style={{ width: '100%' }}>
                                <div>
                                    <div style={{ display: 'inline' }}>
                                        <a className="header_text" onClick={() => this.GoBackToProducts()}  >Place Order</a>|
                                        <a className="header_text" onClick={() => this.GoToDocumentsManage()} >Documents</a><span style={{ visibility: this.state.canTrack }}>|</span>
                                        <a className="header_text" onClick={() => this.GoToOrderApproval()} style={{ visibility: this.state.canTrack }} >Order Approval</a><span style={{ visibility: this.state.isAdminVisibile }}>|</span>
                                        <a className="header_text" onClick={() => this.GoToBudget()}  >Budgets</a>
                                        <span style={{ visibility: this.state.isAdminVisibile }}>|</span>
                                        <a className="header_text" onClick={() => this.GoToAdmin()}  >Admin</a>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="grid_header">
                            <div className="header_container greeting" style={{ width: '100%' }}>
                                <div className="header_text grid_item_1" style={{ lineHeight: '40px', textAlign: 'right' }}>
                                    <div style={{ height: '28px' }} className="overflow_header"> {this.state.userDetails.greeting + ', ' + this.state.userDetails.fullname}</div>
                                    <div style={{ height: '25px' }} className="overflow_header">{this.state.userDetails.accountName}</div>
                                    <div style={{ height: '28px' }} className="overflow_header">Group : {this.state.userDetails.accountCode}</div>
                                </div>
                                <div className="grid_item_2_reverse  h100">
                                    <div style={{ width: '100%', 'height': '100%' }}>
                                        <button id="btnMenu" onClick={() => this.MenuClick()} className="btn primary_btn header_btn twfont" style={{ lineHeight: '0.8', marginTop: '10px' }}  ><i className="material-icons" style={{ width: '100%' }} >menu</i><label>Options</label></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header_menu_container" style={{ visibility: this.state.menuVisibility }}>
                    <div className="grid_item_1 max-height-menu">
                        <button onClick={() => { this.MenuClick() }} style={{ width: '100%', height: '100%', backgroundColor: 'transparent', border: 'none' }}></button>
                    </div>
                    <div className="header_menu grid_item_2 max-height-menu customer_background_foreground">
                        <hr />
                        <div>
                            <button id="canUpdateBtn" name="canUpdateBtn" className="btn  header_menu_item" onClick={() => { this.ToChangePassword() }}>Reset Password <i className="material-icons" style={{ position: 'relative', top: '6px', paddingLeft: '10px' }}>vpn_key</i></button>
                        </div>
                        <hr />
                        <div>
                            <button className="btn  header_menu_item" onClick={() => { this.GetCartState() }}>Logout <i className="material-icons" style={{ position: 'relative', top: '6px', paddingLeft: '10px' }}>exit_to_app</i></button>
                        </div>
                        <hr />
                    </div>
                </div>
                <div className=" sidenav customer_background_foreground" style={{ width: '100%', minHeight: '1000px', marginTop: '10px' }}>
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <br />
                        <div className="customer_background_foreground" style={{ width: '90%', margin: '0 5%', padding: '20px', verticalAlign: 'middle', height: '300px' }}>
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                <br />
                                <div className="customer_background_foreground" style={{ width: '80%', margin: '0 10%', padding: '20px', verticalAlign: 'middle' }}>
                                    <br />
                                    <table style={{ width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '100%' }}>
                                                    <button id="btnAdmin" className="btn secondary_btn twfont" onClick={() => this.GoToAdminManage()} style={{ width: '100%' }} >Back To Admin</button>
                                                </td>
                                            </tr>
                                            <tr><td><hr /></td></tr>
                                            <tr>
                                                <td>
                                                    <label className="customer_background_foreground" style={{ fontFamily: 'Tw Cen MT', fontSize: 'large', fontWeight: 'bold', width: '100%', textAlign: 'center' }}>Address Search</label>
                                                </td>
                                            </tr>
                                            <tr className="default_background">
                                                <td colSpan="2" style={{ width: '400px', alignContent: 'center', textAlign: 'center' }}>
                                                    <div>
                                                        <div className="ContentSplit twfont">
                                                            <div><label>Search Text</label></div>
                                                            <div><label>Address Type</label></div>
                                                            <div><label>Account</label></div>
                                                        </div>
                                                        <div className="ContentSplit" style={{ paddingBottom: '3px' }}>
                                                            <input id="refno" name="refno" type="text" className="form-control twfont" onChange={this.handleSearchTextChanged.bind(this)} placeholder="Search Text" />
                                                            <select id="AddressType" className="form-control twfont" onChange={this.handleAddressTypeChange.bind(this)}>
                                                                <option value="0">All Addresses</option>
                                                                <option value="1">Collect From</option>
                                                                <option value="2">Deliver To</option>
                                                                <option value="3">Collect From & Deliver To</option>
                                                                <option value="4">Disabled & Pending</option>
                                                            </select>
                                                            <select id="customerAccount" className="form-control twfont" onChange={this.handleAccountTypeChange.bind(this)}>
                                                                <option value="0">All</option>
                                                                {accounts}
                                                            </select>
                                                        </div>
                                                        <div style={{ paddingBottom: '3px' }}>
                                                        </div>
                                                        <div style={{ display: 'inline-grid', gridTemplateColumns: '50% 50%', gridColumnGap: '1%', width: '100%' }}>
                                                            <div><button id="btnRequest" className="btn secondary_btn twfont" onClick={() => this.RequestNewAddress()} style={{ width: '100%' }} >Add New Address</button></div>
                                                            <div><button id="butSearch" className="btn primary_btn twfont" onClick={() => this.GetAddressesFiltered()} style={{ width: '100%' }} >Search</button></div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr><td><hr /></td></tr>
                                        </tbody>
                                    </table >
                                </div>
                            </div>
                            <div>
                                <table className="table table-bordered" style={{ fontFamily: 'TW Cen MT', display: 'block', overflow: 'scroll', whiteSpace: 'nowrap', height: '600px', margin: '0 9.5%', width: '81%' }}>
                                    <thead className="thead customer_base_background">
                                        <tr>
                                            <th style={{ textAlign: 'center', lineHeight: '1px', width: '15%' }}>DSVLocationId</th>
                                            {/*<th style={{ textAlign: 'center', lineHeight: '1px', width: '15%' }}>Account Code</th>*/}
                                            <th style={{ textAlign: 'center', lineHeight: '1px', width: '45%' }}>Address Name</th>
                                            {/*<th style={{ textAlign: 'center', lineHeight: '1px', width: '15%' }}>Active (Collect From) <input id="col" type="checkbox" onChange={this.ValidateUpdateAll.bind(this)} /></th>*/}
                                            {/*<th style={{ textAlign: 'center', lineHeight: '1px', width: '15%' }}>Active (Deliver To) <input id="del" type="checkbox" onChange={this.ValidateUpdateAll.bind(this)} /></th>*/}
                                            <th style={{ textAlign: 'center', lineHeight: '1px', width: '10%' }}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {addresses}
                                    </tbody>
                                </table>
                                <br />
                            </div >
                        </div>
                    </div>
                </div >
                <div style={{ visibility: this.state.requestModalVisibility }}>
                    <div className="outer-container" style={{ top: '0', fontFamily: 'TW Cent', backgroundColor: 'rgba(10,10,10, 0.8)' }} >
                        <div className="inner-container" style={{ height: '1000px', paddingTop: '100px', verticalAlign: 'inherit' }}>
                            <div className="centered-content twfont  customer_background_foreground"
                                style={{ boxShadow: '2px 2px 8px #888888', border: 'none', width: '90%', maxWidth: '800px', backgroundColor: 'rgba(255,255,255,0.3)', fontFamily: 'TW Cen MT', marginTop: '20px' }}>
                                <table className="customer_background_foreground" style={{ width: '100%', margin: '0 auto', verticalAlign: 'middle', height: '250px', backgroundColor: 'rgba(255,255,255,0.3)' }}>
                                    <tbody>
                                        <tr><td colSpan="2"><label style={{ width: '100%', textAlign: 'conter', fontSize: 'larger', fontWeight: 'bolder' }}>Enter Address Details</label></td></tr>
                                        <tr><td colSpan="2"><hr /></td></tr>
                                        <tr><td colSpan="2"><label style={{ color: 'red', fontWeight: 'bold', fontSize: 'large' }}>{this.state.modalMessage}</label></td></tr>
                                        <tr>
                                            <td style={{ width: '30%' }} ><label id="label2" className="table_font twfont">Contact Name :<span style={{ color: 'red' }}>*</span></label></td>
                                            <td>
                                                <input type="text" id="txtContactName" maxLength="50" value={contactName} className="form-control twfont" onChange={(e) => { this.setState({ contactName: e.target.value }); }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label id="label3" className="table_font twfont">Contact #:<span style={{ color: 'red' }}>*</span></label></td>
                                            <td>
                                                <input type="text" id="txtContactNo" maxLength="12" value={contactNo} className="form-control twfont" onChange={(e) => { this.setState({ contactNo: e.target.value }); }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label id="label4" className="table_font twfont">Address Name :<span style={{ color: 'red' }}>*</span></label></td>
                                            <td>
                                                <input type="text" id="txtAddrName" maxLength="50" value={addrName} className="form-control twfont" onChange={(e) => { this.setState({ addrName: e.target.value }); }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label id="label5" className="table_font twfont">Street :<span style={{ color: 'red' }}>*</span></label></td>
                                            <td>
                                                <input type="text" id="txtStreet" maxLength="40" value={street} className="form-control twfont" onChange={(e) => { this.setState({ street: e.target.value }); }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label id="label6" className="table_font twfont">City :<span style={{ color: 'red' }}>*</span></label></td>
                                            <td>
                                                <input type="text" id="txtCity" maxLength="40" value={city} className="form-control twfont" onChange={(e) => { this.setState({ city: e.target.value }); }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label id="label7" className="table_font twfont">Postal Code :<span style={{ color: 'red' }}>*</span></label></td>
                                            <td>
                                                <input type="text" id="txtPostalCode" maxLength="10" value={postalCode} className="form-control twfont" onChange={(e) => { this.setState({ postalCode: e.target.value }); }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label id="label8" className="table_font twfont">Controlling Location (Province) :<span style={{ color: 'red' }}>*</span></label></td>
                                            <td>
                                                <input type="text" id="txtProvince" maxLength="50" value={province} className="form-control twfont" onChange={(e) => { this.setState({ province: e.target.value }); }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: 'right' }}><label id="label9" className="table_font twfont">Business Unit Complex Name :<span style={{ color: 'red' }}>*</span></label></td>
                                            <td>
                                                <input type="text" id="txtBusinessUnitComplexName" maxLength="40" value={businessUnitComplexName} className="form-control twfont" onChange={(e) => { this.setState({ businessUnitComplexName: e.target.value }); }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: 'right' }}><label id="label9" className="table_font twfont">Town/Suburb :<span style={{ color: 'red' }}>*</span></label></td>
                                            <td>
                                                <input type="text" id="txtTownSuburb" maxLength="40" value={townSuburb} className="form-control twfont" onChange={(e) => { this.setState({ townSuburb: e.target.value }); }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: 'right' }}><label id="label13" className="table_font twfont">Location ID :<span style={{ color: 'red' }}>*</span></label></td>
                                            <td>
                                                <input type="text" id="txtDsvLocationId" maxLength="4" value={dsvLocationID} className="form-control twfont" onChange={(e) => { this.setState({ dsvLocationID: e.target.value }); }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: 'right' }}><label id="label13" className="table_font twfont">Requested By :<span style={{ color: 'red' }}>*</span></label></td>
                                            <td>
                                                <input type="text" id="txtCreatedByDesc" maxLength="4" value={this.state.createdByDesc} disabled className="form-control twfont" onChange={(e) => { this.setState({ createdByDesc: e.target.value }); }} />
                                            </td>
                                        </tr>
                                        <tr><td colSpan="2"><hr /></td></tr>
                                        <tr>
                                            <td colSpan="2">
                                                <div className="footerButtons2">
                                                    <button id="btnAssign" className="btn primary_btn" style={{
                                                        marginLeft: '5%', width: '90%', fontFamily: 'Tw Cen MT', visibility: ( this.state.requestModalVisibility == 'visible' && (dsvLocationID != null && (this.state.dsvLocationID.replace(' ', '') != '')) != '' ? 'visible' : 'hidden') }}
                                                        onClick={() => this.SaveAndGetAssignments(dsvLocationID)}>Save & Assign Address</button >

                                                    <button id="btnReject" className="btn btn-danger" style={{
                                                        marginLeft: '5%', width: '90%', fontFamily: 'Tw Cen MT', visibility: (this.state.requestModalVisibility == 'visible'
                                                                                                                            && (dsvLocationID == null || (this.state.dsvLocationID.replace(' ', '') == '')) ? 'visible' : 'hidden')}}
                                                        onClick={() => this.DisplayRejectionModal()}>Reject & Send mail to requestor</button >
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2">
                                                <div className="footerButtons2">
                                                    <button id="btnClose" className="btn primary_btn" style={{ width: '90%', fontFamily: 'Tw Cen MT', marginLeft: '5%' }} onClick={() => this.CloseRequestModal()}>Close</button >
                                                    <button id="btnRequest" className="btn secondary_btn" style={{ width: '90%', fontFamily: 'Tw Cen MT', marginLeft: '5%' }} onClick={() => this.SaveAddress()}>Save</button >
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table >
                            </div>
                        </div>
                    </div>
                </div >

                <div style={{ visibility: this.state.updateAllModalVisibility }}>
                    <div className="outer-container" style={{ top: '0', fontFamily: 'TW Cent', backgroundColor: 'rgba(10,10,10, 0.8)' }} >
                        <div className="inner-container" style={{ height: '1000px', paddingTop: '100px', verticalAlign: 'inherit' }}>
                            <div className="centered-content twfont  customer_background_foreground"
                                style={{ boxShadow: '2px 2px 8px #888888', border: '4px solid orangered', width: '90%', maxWidth: '800px', backgroundColor: 'rgba(255,255,255,0.3)', fontFamily: 'TW Cen MT', marginTop: '60px' }}>
                                <table className="customer_background_foreground" style={{ width: '100%', margin: '0 auto', verticalAlign: 'middle', backgroundColor: 'rgba(255,255,255,0.3)' }}>
                                    <tbody>
                                        <tr>
                                            <td colSpan="2">
                                                <label style={{ width: '100%', textAlign: 'conter', fontSize: 'larger', fontWeight: 'bolder' }}> Please enter a reason for the rejection (Mail will be sent to user)</label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2">
                                                <input type="text" id="txtRejectionReason"
                                                    maxLength="100"
                                                    className="form-control twfont"
                                                    value={rejectionReason}
                                                    onChange={(e) => { this.setState({ rejectionReason: e.target.value }); }} />
                                            </td>
                                        </tr>
                                        <tr><td colSpan="2"><hr /></td></tr>
                                        <tr>
                                            <td colSpan="2">
                                                <div className="footerButtons2">
                                                    <button id="btnClose" className="btn primary_btn" style={{ width: '90%', fontFamily: 'Tw Cen MT', marginLeft: '5%' }} onClick={() => this.hideUpdateModal()}>Cancel</button>
                                                    <button id="btnRequest" className="btn secondary_btn" style={{ width: '90%', fontFamily: 'Tw Cen MT', marginLeft: '5%', visibility: (this.state.rejectionReason != '' ? 'visible' : 'hidden') }} onClick={() => this.SendRejectionReason()}>Save & Send</button >
                                                </div>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table >
                            </div>
                        </div>
                    </div>
                </div >
                <div style={{ visibility: this.state.assignAddressModalVisibility }}>
                    <div className="outer-container" style={{ top: '0', fontFamily: 'TW Cent', backgroundColor: 'rgba(10,10,10, 0.8)' }} >
                        <div className="inner-container" style={{ height: '1000px', paddingTop: '100px', verticalAlign: 'inherit' }}>
                            <div className="centered-content twfont  customer_background_foreground"
                                style={{
                                    boxShadow: '2px 2px 8px #888888', border: '4px solid orangered', width: '90%', maxWidth: '800px',
                                    backgroundColor: 'rgba(255,255,255,0.3)', fontFamily: 'TW Cen MT', marginTop: '30px'
                                }}>

                                <div style={{ width: '100%', textAlign: 'center', display: 'inline-grid', gridTemplateColumns: '50% 50%' }} >
                                    <div><label style={{ fontSize: 'larger', fontWeight: 'bolder' }}> Assign Label ({dsvLocationID})</label></div>
                                    <div style={{ display: 'inline-grid', gridTemplateColumns: '50% 50%', borderLeft: 'solid silver 1px' }}>
                                        <label style={{ margin: 'auto' }}>Update all</label>
                                        <select id={dsvLocationID} className="form-control twfont" defaultValue="0" onChange={this.handleAssignAccountAllChange.bind(this)} >
                                            <option value="0">Unset</option>
                                            <option value="1">Collect From</option>
                                            <option value="2">Deliver To</option>
                                            <option value="3">Collect From & Deliver To</option>
                                            <option value="4">Inactive</option>
                                        </select>
                                    </div>
                                </div>
                                <hr />
                                <table className="customer_background_foreground" style={{
                                    width: '100%', margin: '0 auto', verticalAlign: 'middle', backgroundColor: 'rgba(255,255,255,0.3)',
                                    maxHeight: (!this.state.isUpdateAllLoading && this.state.assignAddressModalVisibility == 'visible' ? '550px' : '0px'), overflow: 'scroll', display: 'block', visibility: (!this.state.isUpdateAllLoading && this.state.assignAddressModalVisibility == 'visible' ? 'visible' : 'collapse')
                                }}>
                                    <thead style={{ position: 'sticky', top: '0', backgroundColor: '#F5F5F5' }}>
                                        <tr>
                                            <th>Account</th>
                                            <th>Address State</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {accountTable}
                                    </tbody>
                                </table>
                                <div style={{ visibility: (this.state.isUpdateAllLoading && this.state.assignAddressModalVisibility == 'visible' ? 'visible' : 'hidden') }}>
                                    <div>
                                        <label className="twfont" style={{ fontSize: 'larger', fontWeight: 'bolder' }}>Updating please wait</label>
                                    </div>
                                    <div>
                                        <div class="spinner-grow" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <div class="spinner-grow" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <div class="spinner-grow" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <button id="btnCloseAssign" className="btn secondary_btn" style={{ width: '100%', fontFamily: 'Tw Cen MT', }} onClick={() => this.CloseAssignModal()}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        );
    }

    GoToDocumentsManage() {
        this.setState({ toDocumentsManage: true });
    }


    GetCartState() {

        fetch('/api/Product/GetCartCount' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == "valued") {
                    this.setState({ toClearCartPrompt: true });
                } else {
                    window.location.href = '\\';
                }
            });
    }

    CheckIfAdmin() {
        fetch('/api/Login/isAdmin' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => { this.setState({ isAdminVisibile: (data == 'Y' ? 'visible' : 'hidden') }) })
    }

    GoToOrderSearch() {
        this.setState({ GoToOrderSearch: true });
    }

    GoToDocuments() {
        this.setState({ toDocuments: true });
    }

    GoToAdminManage() {
        this.setState({ toAdminManage: true });
    }

    toOrderForm() {
        this.setState({ toOrderForm: true });
    }

    GoToLogIn() {
        this.setState({ toLogIn: true });
    }

    GoToAdmin() {
        this.setState({ toAdmin: true });
    }

    GoBackToProducts() {
        this.setState({ toProducts: true });
    }

    GoToBudget() {
        this.setState({ toBudgets: true });
    }

    GoToOrderForm() {
        this.setState({ toOrderForms: true });
    }

    GoToOrderSearch() {
        this.setState({ GoToOrderSearch: true });
    }

    GoToOrderApproval() {
        this.setState({ toOrderApproval: true });
    }

    GoToSavedCarts() {
        this.setState({ toSavedCarts: true });
    }

    GetUserDetails() {
        fetch('api/Login/GetUserDetails' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.json())
            .then(data => { this.setState({ userDetails: data }) })
    }

    CheckIfCanTrack() {
        fetch('/api/Login/canTrack' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => { this.setState({ canTrack: (data == 'Y' ? 'visible' : 'hidden') }) })
    }
}

//// <label style={{ width: '100%', textAlign: 'conter', fontSize: 'larger', fontWeight: 'bolder' }}> You have decided to set all listed "{this.state.updateAllType + '" addresses to an "' + this.state.updateAllState}" state.</label>
//<label style={{ width: '100%', textAlign: 'conter', fontSize: 'larger', fontWeight: 'bolder' }}> Are you sure?</label>


//<tr>
//    <td><label id="label10" className="table_font twfont">Delivery Address Type :<span style={{ color: 'red' }}>*</span></label></td>
//    <td>
//        <select id="ddlAddrType" value={this.state.addrType} className="form-control twfont" onChange={this.handleSelectedAddrTypeChanged.bind(this)}>
//            <option value="-1">Please select type</option>
//            <option value="0">Collect From</option>
//            <option value="1">Deliver To</option>
//            <option value="2">Collect From & Deliver To</option>
//            <option value="3">Disable Address</option>
//            <option value="4">Dont Update</option>
//        </select>
//    </td>
//</tr>