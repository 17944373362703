import React, { ReactPropTypes, Component } from 'react';
import { Route } from 'react-router';
import { withRouter, Redirect } from 'react-router-dom';
import { Product } from './Products';

export class ChangePassword extends Component {
    displayName = ChangePassword.name

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            stylesheet: '',
            password1: '',
            password2: '',
            password3: '',
            passwordIsValid: true,
            passwordMessage: '',
            updatePasswordMessage: '',
            updateBtnDisabled: true,
            toProducts: false,
        };

        this.handlePassword1Change = this.handlePassword1Change.bind(this);
        this.handlePassword2Change = this.handlePassword2Change.bind(this);
        this.handlePassword3Change = this.handlePassword3Change.bind(this);
        this.UpdatePassword = this.UpdatePassword.bind(this);

    }

    GoBackToProducts() {
        this.setState({ toProducts: true });
    }

    UpdatePassword() {
        console.log('method called');
        this.ValidatePasswords();

        if (this.state.passwordIsValid) {

            var pass1 = document.getElementById('password1');
            pass1.style.borderColor = "blue";
            pass1.style.borderWidth = "medium";

            var pass2 = document.getElementById('password2');
            pass2.style.borderColor = "blue";
            pass2.style.borderWidth = "medium";

            fetch('api/Login/UpdatePassword?currentPassword=' + this.state.password1 + '&newPassword=' + this.state.password2 + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(response => response.json())
                .then(response => {
                    console.log(response);
                    if (response.isValid) {
                        this.setState({ updatePasswordMessage: 'Password has been updated' });

                        var passLabel = document.getElementById('updateMessage');
                        passLabel.style.color = "green";
                        passLabel.style.backgroundColor = "rgba(255,255,255,0.6)";
                    }
                    else if (!response.isValid) {
                        this.setState({ updatePasswordMessage: response.message });

                        var passLabel = document.getElementById('updateMessage');
                        passLabel.style.color = "red";
                        passLabel.style.backgroundColor = "rgba(255,255,255,0.6)";
                    }
                });

        }

    }

    ValidatePasswords() {
        var passwordValid = true;
        var passMessage = '';


        var pass1input = document.getElementById('password1');
        var pass2input = document.getElementById('password2');
        var pass3input = document.getElementById('password3');

        if (this.state.password1 == '') {
            pass1input.style.borderColor = "orange";
            pass1input.style.borderWidth = "medium";

            passwordValid = false;
            passMessage = 'Please enter your current password. ';
        }
        else {
            pass1input.style.borderColor = "blue";
            pass1input.style.borderWidth = "medium";
        }

        if (this.state.password2 == '') {
            pass2input.style.borderColor = "orange";
            pass2input.style.borderWidth = "medium";

            passwordValid = false;
            passMessage += 'Please enter your new password. ';
        }
        else {
            pass2input.style.borderColor = "blue";
            pass2input.style.borderWidth = "medium";
        }

        if (this.state.password3 == '') {
            pass3input.style.borderColor = "orange";
            pass3input.style.borderWidth = "medium";

            passwordValid = false;
            passMessage += 'Please enter your confirmation password. ';
        }
        else {
            pass3input.style.borderColor = "blue";
            pass3input.style.borderWidth = "medium";
        }


        if (this.state.password2 != this.state.password3) {
                
            
            pass3input.style.borderColor = "red";
            pass3input.style.borderWidth = "medium";

            passwordValid = false;
            passMessage += 'New password and confirmation password do not match. ';
            
        }

        if (!passwordValid) {
            var passLabel = document.getElementById('updateMessage');
            passLabel.style.color = "red";
            passLabel.style.backgroundColor = "rgba(255,255,255,0.6)";
        }

        this.setState({ passwordIsValid: passwordValid, updatePasswordMessage: passMessage });
    }

    BackToLogin() {
        window.location.href = '\\';
    }

    handlePassword1Change(e) {
        this.setState({ password1: e.target.value })
    }

    handlePassword2Change(e) {
        this.setState({ password2: e.target.value, password3: '' });

    }

    handlePassword3Change(e) {
        this.setState({ password3: e.target.value });
    }

    render() {

        if (this.state.toProducts) {
            return <Route path='/' component={Product} />
        }
        var password1 = this.state.password1;
        var password2 = this.state.password2;
        var password3 = this.state.password3;

        var message = this.state.updatePasswordMessage

        if (this.state.loading) {
            return (
                <div className="outer-container customer_background" style={{ fontFamily: 'TW Cent', backgroundColor: 'white' }}>
                    <div className="inner-container">
                        <div className="centered-content  twfont customer_background_foreground" style={{ boxShadow: 'none', border: 'none' }}>
                            <div className="spinner-border" style={{ color: 'rgb(8, 15, 83)', height: '3rem', width: '3rem', borderWidth: '0.5em' }} role="status">
                                <span className="sr-only" >Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>);
        }
        else {
            return (
                <div className="outer-container customer_background" style={{ fontFamily: 'TW Cent' }} >
                    <div className="header" style={{ position: 'fixed', zIndex : '500'}}>

                        <div className="grid_container_header" style={{ display: 'inline-grid', gridTemplateColumns: '30% 40% 30%', display: 'grid -ms-grid', msGridColumns: '30% 40% 30%', columnCount: '3' }}>
                            <div className="grid_header">
                                <div className="icon_home" style={{ width: '100%', height: '50%', marginTop: '30px', marginLeft: '10%', float: 'left', backgroundRepeat: 'no-repeat' }}></div>
                            </div>
                            <div className="grid_header" style={{ textAlign: 'left' }}>

                            </div>
                            <div className="grid_header">
                                <div className="header_container" style={{ width: '100%', paddingRight: '15%' }}>
                                    <div className="input-group mb-3" style={{ marginTop: '9%', marginRight: '2%' }}>
                                        <div className="input-group-prepend">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="inner-container">
                        <div className="centered-content twfont  customer_background_foreground"
                            style={{ boxShadow: '2px 2px 8px #888888', border: 'none', width: '90%', maxWidth: '450px', backgroundColor: 'rgba(255,255,255,0.3)', fontFamily: 'TW Cen MT', marginTop: '100px' }}>
                            <table className="customer_background_foreground" style={{ width: '100%', margin: '0 auto', verticalAlign: 'middle', height: '200px', backgroundColor: 'rgba(255,255,255,0.3)' }}>
                                <tbody>
                                    <hr />
                                    <label style={{ fontFamily: 'Tw Cen MT', fontSize: 'large', fontWeight: 'bold', width: '100%', textAlign: 'center' }}>Change Password</label>

                                    <hr />
                                    <tr>
                                        <td colSpan="2" style={{ width: '100%' }}>
                                            <input id="password1" name="password1" type="password" autoComplete="off" value={password1} onChange={this.handlePassword1Change.bind(this)} className="form-control twfont" placeholder="Current Password" />
                                        </td>
                                    </tr>
                                    <hr />
                                    <tr>
                                        <td colSpan="2"><label className="twfont">Password needs to be a length of 6 characters with 1 uppercase letter and 1 lower case letter.</label></td>
                                    </tr>
                                    <hr />
                                    <tr>
                                        <td colSpan="2" style={{ width: '100%' }}>
                                            <input id="password2" name="password2" type="password" autoComplete="off" value={password2} onChange={this.handlePassword2Change.bind(this)} className="form-control twfont" placeholder="New Password" />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colSpan="2" style={{ width: '100%' }}>
                                            <input id="password3" name="password3" autoComplete="off" type="password" value={password3} onChange={this.handlePassword3Change.bind(this)} className="form-control twfont" placeholder="Confirm Password" />
                                        </td>
                                    </tr>
                                    <hr />
                                    <tr>
                                        <td colSpan="2"><label id="updateMessage" name="updateMessage" className="twfont">{message}</label></td>
                                    </tr>
                                    <tr><td><br /></td></tr>
                                    <tr>
                                        <td colSpan="2" style={{ width: '100%' }}>
                                            <button id="btnUpdate" name="btnUpdate" className="btn primary_btn twfont" type="button" onClick={() => { this.UpdatePassword() }} style={{ width: '100%' }}>Update Password</button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" style={{ width: '100%' }}>
                                            <button id="btnBack" className="btn primary_btn twfont" type="button" onClick={() => { this.GoBackToProducts() }} style={{ width: '100%' }}>Back</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table >
                        </div>
                    </div>
                </div >
            );
        }


    }
}


    //< td style = {{ width: '50%' }}> <a style={{ color: '#007bff', cursor: 'pointer' }} onClick={() => this.ToRegister()}>Register</a></td >
