import React, { Component } from 'react';
import { Route } from 'react-router';
import { DocumentContent } from './DocumentContent';
import { OrderContent } from './OrderContent';
import { CreditContent } from './CreditContent';
import { OrderSearch } from './OrderSearch';
import { Product } from './Products';
import { Login } from './Login';
import { CheckOut } from './CheckOut';
import { SavedCarts } from './SavedCarts';
import { InvoiceOrderSearch } from './InvoiceOrderSearch';
import { Budget } from './Budget'
import { AdminUser } from './AdminUser'
import { Users } from './Users';
import { OrderApproval } from './OrderApproval'
import { withRouter, Redirect } from 'react-router-dom';
import { SessionTimedOut } from './SessionTimedOut';
import { ClearCartPrompt } from './ClearCartPrompt';
import { AdminManage } from './AdminManage';

export class SuccessPayment extends Component {
    displayName = SuccessPayment.name

    constructor(props) {
        super(props);

        this.state = {
            valid: false,
            userDetails: {},
            referenceNumber: '',
            startDate: '',
            endDate: '',
            documentType: 'Orders',
            ApproveVisibility: 'hidden',

            orders: [],
            loadingOrders: false,
            ordersHeight: '500px',
            ordersVisibility: 'visible',

            ToDocument: false,
            ToOrderDocument: false,
            ToCreditDocument: false,

            toCheckOut: false,
            toSaveCart: false,
            toManage: false,
            toClearCartPrompt: false,
            toDocuments: false,
            toOrderForm: false,
            toAdmin: false,
            toLogIn: false,
            toBudgets: false,
            toOrderSearch: false,
            toOrderApproval: false,
            toSavedCarts: false,
            isAdminVisibile: 'hidden',
            toProducts: false,
            canTrack: 'hidden',
            toSessionTimedOut: false,
            invalidDateMessage: '',
            invalidDateModalVisibility: 'hidden',
            invalidDateModalVisibility: 'hidden',
            invalidDateMessage: '',
            invalidDateModalVisibility: 'hidden',

            status: '',
            description: '',
            transactionType :'',
            amount :0 ,
            authorisationCode: '',
            merchantReference: '',
        };

        this.CheckSessionInitialize();
    }


    CheckSessionInitialize() {
        //this.setState({ complete_update: false });
        //console.log('get CheckSession called');

        //fetch('api/Login/CheckSession?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
        //    .then(response => response.text())
        //    .then(data => {
        //        if (data == 'running') {
        //            this.setState({ toSessionTimedOut: false });
        //            this.GetUserDetails();
        //            this.CheckIfApprover();
        //            this.CheckIfAdmin();
        //            this.CheckIfCanTrack();
        //        }
        //        else {
        //            this.setState({ toSessionTimedOut: true });
        //        }
        //    });
    }

    submitForm() {
        //<![CDATA[
        //formatCurrency(document.getElementById('Lite_Order_Amount'), 0, "", "");
        //]]>
        var oAmount = document.getElementById("Lite_Order_Amount");
        document.getElementById("Lite_Order_LineItems_Amount_1").value = oAmount.value;
    }
    //<script type="text/javascript">
    //    //<![CDATA[
    //    formatCurrency(document.getElementById('Lite_Order_Amount'), 0, "", "");
    //    //]]>
    //</script>


    CheckSession() {
        this.setState({ complete_update: false });
        console.log('get CheckSession called');

        fetch('api/Login/CheckSession?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });
                }
                else if (data == 'timedout') {
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }


    CheckIfApprover() {
        fetch('/api/Login/isApprover' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => { this.setState({ ApproveVisibility: (data == 'Y' ? 'visible' : 'hidden') }, () => { console.log('visibility : data=' + data + ' value= ' + this.state.ApproveVisibility); }) })
    }

    ProductLink() {
        this.setState({ toProductsPage: true });
    }

    handleReferenceNumberChange(e) {
        this.setState({ referenceNumber: e.target.value },
            () => {
                console.log('reference : ' + this.state.referenceNumber);
            }
        );
    }

    handleStartDateChange(e) {
        this.setState({ startDate: e.target.value },
            () => {
                console.log('Start Date : ' + this.state.startDate);
            }
        );
    }

    handleEndDateChange(e) {
        this.setState({ endDate: e.target.value },
            () => {
                console.log('End Date : ' + this.state.endDate);
            }
        );
    }


    renderOrders() {
        console.log('loading orders');
        if (this.state.loadingOrders) {
            return (<tr><td>Loading</td></tr>);
        }
        else {
            return (this.state.orders.map(order =>
                <tr style={{ backgroundColor: 'white' }}>
                    <td className="sideBorder" style={{ padding: '0 1%', color: 'blue', border: 'black', cursor: 'pointer' }} onClick={() => this.SetOrderIdentifier(order.orderID)}>L{order.orderID}</td>
                    <td className="sideBorder" style={{ padding: '0 1%' }}> {order.orderDate} </td>
                    <td className="sideBorder" style={{ padding: '0 1%' }}> {order.deliveryDate}  </td>
                    <td className="sideBorder overflow_table" style={{ padding: '0 1%' }}> {order.orderNotes}  </td>
                    <td className="sideBorder" style={{ padding: '0 1%' }}> {order.approvalStatus}  </td>
                    <td className="sideBorder" style={{ padding: '0 1%' }}> {order.orderStatus}  </td>
                    <td className="sideBorder overflow_table" style={{ padding: '0 1%' }}> {order.customerRef}  </td>
                    <td className="sideBorder" style={{ padding: '0 1%' }}> {order.sysproRef}  </td>
                    <td className="sideBorder" style={{ padding: '0 1%' }}> {order.placedBy}  </td>
                </tr>
            )
            );
        }

    }

 

    render() {

        //Route Change
        if (this.state.toSessionTimedOut) {
            return <Route path='/' component={SessionTimedOut} />
        }

        if (this.state.toClearCartPrompt) {
            return <Route path='/' component={ClearCartPrompt} />
        }

        if (this.state.toAdmin) {
            return <Route path='/' component={AdminManage} />
        }

        if (this.state.ToOrderDocument) {
            return <Route path='/' component={OrderContent} />
        }

        if (this.state.GoToOrderSearch) {
            return <Route path='/' component={OrderSearch} />
        }

        if (this.state.toBudgets) {
            return <Route path='/' component={Budget} />
        }

        if (this.state.toSavedCarts) {
            return <Route path='/' component={SavedCarts} />
        }


        if (this.state.toCheckOut) {
            return <Route path='/' component={CheckOut} />
        }

        if (this.state.toDocuments) {
            return <Route path='/' component={InvoiceOrderSearch} />
        }

        if (this.state.toOrderApproval) {
            return <Route path='/' component={OrderApproval} />
        }

        if (this.state.toLogIn) {
            return <Route path='/' component={Login} />
        }

        if (this.state.toProducts) {
            return <Route path='/' component={Product} />
        }

        if (this.state.toClearCartPrompt) {
            return <Route path='/' component={ClearCartPrompt} />
        }

        //end

        if (this.state.ToCreditDocument) {
            return <Route path='/' component={CreditContent} />
        }

        if (this.state.ToDocument) {
            return <Route path='/' component={DocumentContent} />
        }

        if (this.state.ToOrderDocument) {
            return <Route path='/' component={OrderContent} />
        }

        let orderRows = this.renderOrders();

        return (
            <div>
                <div style={{ top: '0', left: '0', height: '100%' }}>
                    <div className="header" style={{ position: 'fixed', zIndex : '500'}}>
                        <div className="grid_container_header" style={{ msHyphens: 'auto', display: 'inline-grid', gridTemplateColumns: '30% 40% 30%', display: 'grid -ms-grid', msGridColumns: '30% 40% 30%', columnCount: '3' }}>
                            <div className="grid_header">
                                <div className="icon_home" style={{ width: '100%', height: '50%', 'margin-top': '30px', 'margin-left': '10%', float: 'left', 'background-repeat': 'no-repeat' }}></div>
                            </div>
                            <div className="grid_header" style={{ 'text-align': 'left', overflow: 'hidden' }}>
                                <div className="header_container" style={{ width: '100%' }}>
                                    <div>
                                        <div style={{ display: 'inline' }}>
                                            <a className="header_text" onClick={() => this.GoBackToProducts()}  >Place Order</a>|
                                                <a className="header_text" onClick={() => this.GoToOrderSearch()} >Documents</a><span style={{ visibility: this.state.canTrack }}>|</span>
                                            <a className="header_text" onClick={() => this.GoToOrderApproval()} style={{ visibility: this.state.canTrack }} >Order Approval</a><span style={{ visibility: this.state.isAdminVisibile }}>|</span>
                                            <a className="header_text" onClick={() => this.GoToBudget()} style={{ visibility: 'hidden' }}>Budgets</a>
                                            <span style={{ visibility: this.state.isAdminVisibile }}>|</span>
                                            <a className="header_text" onClick={() => this.GoToAdmin()} style={{ visibility: this.state.isAdminVisibile }} >Admin</a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="grid_header">
                                <div className="header_container greeting" style={{ width: '100%' }}>
                                    <div className="header_text grid_item_1" style={{ lineHeight: '40px', textAlign: 'right' }}>
                                        <div style={{ height: '28px' }} className="overflow_header"> {this.state.userDetails.greeting + ', ' + this.state.userDetails.fullname}</div>
                                        <div style={{ height: '25px' }} className="overflow_header">{this.state.userDetails.accountName}</div>
                                        <div style={{ height: '28px' }} className="overflow_header">Group : {this.state.userDetails.accountCode}</div>
                                    </div>
                                    <div className="grid_item_2"><a className="header_text" onClick={() => { this.GetCartState() }} id="A1">LOG OUT</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" sidenav customer_background_foreground" style={{ width: '100%', minHeight: '1000px', marginTop: '100px' }}>
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <div id="divContent" className="clsContent" align="center"><br />
                                <form method="post" action="Result.asp" id="Form1">
                                    <table id="tableSuccess" className="clsQuery" cellspacing="0" align="Center" border="0" style={{ width: '600px', borderCollapse: 'collapse' }}>

                                    </table>
                                </form>
                            </div>
                        </div>
                        <br />
                    </div>
                </div>
            </div>
                );
    }

    //Class Methods
    GetCartState() {

        fetch('/api/Product/GetCartCount', { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == "valued") {
                    this.setState({ toClearCartPrompt: true });
                } else {
                    window.location.href = '\\';
                }
            });
    }

    CheckIfAdmin() {
        fetch('/api/Login/isAdmin?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => { this.setState({ isAdminVisibile: (data == 'Y' ? 'visible' : 'hidden') }, () => { console.log('visibility : data=' + data + ' value= ' + this.state.isAdminVisibile); }) })
    }

    GoToOrderSearch() {
        this.setState({ GoToOrderSearch: true });
    }

    GoToDocuments() {
        this.setState({ toDocuments: true });
    }

    toOrderForm() {
        this.setState({ toOrderForm: true });
    }

    GoToLogIn() {
        this.setState({ toLogIn: true });
    }

    GoToAdmin() {
        this.setState({ toAdmin: true });
    }

    GoBackToProducts() {
        this.setState({ toProducts: true });
    }

    GoToBudget() {
        this.setState({ toBudgets: true });
    }

    GoToOrderForm() {
        this.setState({ toOrderForms: true });
    }

    GoToOrderSearch() {
        this.setState({ GoToOrderSearch: true });
    }

    GoToOrderApproval() {
        this.setState({ toOrderApproval: true });
    }

    GoToSavedCarts() {
        this.setState({ toSavedCarts: true });
    }

    GetUserDetails() {
        fetch('api/Login/GetUserDetails?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.json())
            .then(data => { this.setState({ userDetails: data }) })
            .then(() => {
                console.log("user details");
                console.log(this.state.userDetails);
            })
    }

    CheckIfCanTrack() {
        fetch('/api/Login/canTrack?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => { this.setState({ canTrack: (data == 'Y' ? 'visible' : 'hidden') }, () => { console.log('visibility : data=' + data + ' value= ' + this.state.isAdminVisibile); }) })
    }
}