import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { OrderPlaced } from './OrderPlaced';
import { OrderProcessing } from './OrderProcessing'
import { PaymentScreen } from './PaymentScreen'
import { Route } from 'react-router';
import { ChangePassword } from './ChangePassword';
import { AdminManage } from './AdminManage';
import { Product } from './Products';
import { Login } from './Login';
import { SavedCarts } from './SavedCarts';
import { Budget } from './Budget'
import { InvoiceOrderSearch } from './InvoiceOrderSearch';
import { OrderSearch } from './OrderSearch'
import { AdminUser } from './AdminUser'
import { Users } from './Users';
import { OrderApproval } from './OrderApproval'
import { ClearCartPrompt } from './ClearCartPrompt';
import { SessionTimedOut } from './SessionTimedOut';
import { DocumentsManage } from './DocumentsManage';
import { PaymentPortalDisabled } from './PaymentPortalDisabled';
import { CheckOut } from './CheckOut';

export class LabelCheckOut extends Component {
    displayName = LabelCheckOut.name

    constructor(props) {
        super(props);
        this.state = {
            toProducts: false,
            toClearCartPrompt: false,
            userDetails: {},
            toChangePassword: false,
            menuVisibility: 'hidden',
            orderLimitMessage: '',
            toOrderPlaced: false,
            toOrderProcessing: false,
            ToLogin: false,
            collect_addresses: [],
            deliver_addresses: [],
            loadingAddresses: false,
            orderList: [],
            loadingOrders: false,
            total: {},
            totalValue: 0,
            totalText: '',
            selectedAddress: '',
            addrborder: '#ced4da',
            deliveryNote: '',
            emailAddress: '',
            orderDetails: {},
            emailBorder: '#ced4da',
            customerRef: '',
            customerRefBorder: '#ced4da',

            orderButtonClicked: '',
            goBackVisibility: 'visible',
            contentHeight: 'unset',
            loaderHeight: '0',


            toCheckOut: false,
            toClearCartPrompt: false,
            toSaveCart: false,
            toManage: false,
            toDocuments: false,
            toOrderForm: false,
            toAdmin: false,
            toLogIn: false,
            toBudgets: false,
            toOrderSearch: false,
            toOrderApproval: false,
            toSavedCarts: false,
            toInactivePaymentPortal: false,

            canTrack: 'hidden',
            isAdminVisibile: 'hidden',
            toSessionTimedOut: false,
            toDocumentsManage: false,
            budgetMessage: '',

            accountPaymentActive: false,
            cardPaymentActive: false,
            eftPaymentActive: false,
            paymentTypesCount: 0,
            isPaymentPortalActive: 'false',
            cardPaymentModalVisibility: 'hidden',

            selectedAddress_deliver: '0|0',
            selectedAddress_collect: '0|0',
            message: '',

            footerClass: '',

            accountCode: '',
            contactName: '',
            contactNo: '',
            addrName: '',
            addrType: -1,
            street: '',
            city: '',
            postalCode: '',
            province: '',
            dsvLocationID: '',
            businessUnitComplexName: '',
            townSuburb: '',
            addressType: 'collect',
            requestModalVisibility: 'hidden',
            dsvlabelVisiblity: 'hidden',
            orderHasOrangeLabels: false,
            loadingCollectFromAddresses: true,
            loadingDeliverToAddresses: true,
            updatingAddresses: false,

            collectActive: false,
            deliverActive: false,
            deliverToValue: '',
            collectFromValue: '',
            addressType: -1
        };


        this.CheckSessionInitialize();
    }


    HasOrangeLabels() {
        fetch('api/Label/HasOrangeLabels?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => this.setState({ orderHasOrangeLabels: data == "true" }));
    }

    CheckSessionInitialize() {
        this.setState({ complete_update: false });
        console.log('get CheckSession called');

        fetch('api/Login/CheckSession?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });

                    this.InitializeLabels();
                    this.GetUserDetails();
                    this.CheckIfAdmin();
                    this.CheckIfCanTrack();
                    this.setState({ loadingCollectFromAddresses: true, loadingDeliverToAddresses: true });
                    this.GetCollectFromAddresses();
                    this.GetDeliverToAddresses();
                    this.ValidateLabelOrder();
                    //this.HasOrangeLabels();
                }
                else if (data == 'timedout') {
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }

    CheckSession() {
        this.setState({ complete_update: false });
        console.log('get CheckSession called');

        fetch('api/Login/CheckSession?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });
                }
                else if (data == 'timedout') {
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }

    GoBackToProducts() {
        this.setState({ toProducts: true });
    }

    LogUserOut() {

        this.setState({ ToLogin: true });

    }

    handleSelectedAddress(e) {
        console.log('value : ' + e.target.value + '; text : ' + e.target.name);
        let { name, value } = e.target;
        this.setState({ selectedAddress: e.target.value }
            , () => {
                console.log(this.state.selectedAddress);

                if (this.state.selectedAddress === '') {
                    console.log('selected address is empty');
                }
                else {
                    console.log('getting products');
                }
            })
    }

    handleDeliveryNote(e) {
        console.log('delevery note value' + e.target.value);
        let inputtext = e.target.value;
        this.setState({ deliveryNote: inputtext });
    }

    handleEmailChange(e) {
        console.log('email address' + e.target.value);
        let inputtext = e.target.value;
        this.setState({ emailAddress: inputtext });
    }

    HidePaymentModal() {
        this.setState({ cardPaymentModalVisibility: 'hidden' });
    }

    GetDeliverToAddresses() {
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            fetch('/api/Label/GetDeliverToAddresses?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
                .then(response => response.json())
                .then(data => { this.setState({ deliver_addresses: data, loadingDeliverToAddresses: false }); });
        }
    }

    GetCollectFromAddresses() {
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            fetch('/api/Label/GetCollectFromAddresses?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
                .then(response => response.json())
                .then(data => { this.setState({ collect_addresses: data, loadingCollectFromAddresses: false }); });
        }
    }

    DisplayRequestModal() {
        this.setState({ requestModalVisibility: 'visible' });
    }

    CloseRequestModal() {
        this.setState({ requestModalVisibility: 'hidden' });
    }

    RequestNewAddress() {
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            let contactName = this.state.contactName;
            let contactNo = this.state.contactNo;
            let addrName = this.state.addrName;
            let street = this.state.street;
            let city = this.state.city;
            let postalCode = this.state.postalCode;
            let province = this.state.province;
            //let dsvLocationID = this.state.dsvLocationID;
            let addressType = this.state.addrType;
            let businessUnitComplexName = this.state.businessUnitComplexName;
            let townSuburb = this.state.townSuburb;
            let deliverToValue = this.state.deliverToValue;
            let collectFromValue = this.state.collectFromValue;

            console.log('address type :' + addressType);

            if (contactName == '')
                alert('Please provide contact name');
            else if (contactNo == '')
                alert('Please provide contact number');
            else if (addrName == '')
                alert('Please provide the address name');
            else if (street == '')
                alert('Please provide the street name');
            else if (city == '')
                alert('Please provide the city name');
            else if (postalCode == '')
                alert('Please provide the postal code');
            else if (province == '')
                alert('Please provide the province');
            else if (businessUnitComplexName == '')
                alert('Please provide the businessUnitComplexName');
            else if (townSuburb == '')
                alert('Please provide the Town or Suburb');
            else if (addressType == -1)
                alert('Please select an address type');
            else {

                fetch('/api/Label/RequestNewAddress'
                    + '?contactName=' + contactName
                    + '&contactNo=' + contactNo
                    + '&address_name=' + addrName
                    + '&street=' + street
                    + '&city=' + city
                    + '&postal=' + postalCode
                    + '&province=' + province
                    + '&businessUnitComplexName=' + businessUnitComplexName
                    + '&townSuburb=' + townSuburb
                    //+ '&collectFromActive=' + collectFromValue
                    //+ '&deliverToActive=' + deliverToValue
                    + '&addressType=' + addressType
                    + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
                    .then(response => response.text())
                    .then(data => {
                        if (data == 'complete') {
                            alert('New address requested.');
                            this.setState({ requestModalVisibility: 'hidden' });
                        }
                        else {
                            alert('An issue occured please try again later.');
                        }
                    });


            }
        }
    }

    SetDeliverToAddresses(e) {
        this.setState({ selectedAddress_deliver: e.target.value });
    }

    SetCollectFromAddresses(e) {
        console.log('collect value: ' + e.target.value);
        this.setState({ selectedAddress_collect: e.target.value });
    }

    ValidateAddresses() {
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            this.setState({ updatingAddresses: true });

            let collect = this.state.selectedAddress_collect;
            let deliver = this.state.selectedAddress_deliver;

            fetch('/api/Label/UpdateAddressDetails?collect=' + collect.replace('&', '@@@') + '&deliver=' + deliver.replace('&', '@@@') + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
                .then(response => response.text())
                .then(response => {
                    if (response == 'complete')
                        this.setState({ message: '', toCheckOut: true });
                    else
                        this.setState({ message: response, updatingAddresses: false });

                });
        }
    }

    InitializeLabels() {
        fetch('/api/Label/GetLabelDetails' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' }, { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.json())
            .then(response => {
                if (response.length == 0)
                    this.setState({ toCheckOut: true });
                else
                    this.setState({ dsvlabelVisiblity: 'visible' });
            });
    }

    ValidateLabelOrder() {
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            fetch('/api/Label/HasLabels?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' }, { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
                .then(response => response.text())
                .then(data => {
                    if (data == 'false')
                        this.setState({ toCheckOut: true });
                    else
                        this.setState({ dsvlabelVisiblity: 'visible' });
                });
        }
    }

    LoadDeliverAddreses() {

        if (this.state.loadingAddresses) {
            return (<option>loading...</option>);
        }
        else {
            return (this.state.deliver_addresses.map(item => {
                if (item.dsvLocationID == 'M777' && item.selected)
                    return (<option selected value={item.addrName + ' ' + item.townSuburb + ' ' + item.addrProvince + ' (' + item.dsvLocationID + '|' + item.id + ')'}></option>);
                else
                    return (<option value={item.addrName + ' ' + item.townSuburb + ' ' + item.addrProvince + ' (' + item.dsvLocationID + '|' + item.id + ')'}></option>);
                    
            }
            ));
        }

    }

    LoadCollectAddreses() {

        if (this.state.loadingAddresses) {
            return (<option>loading...</option>);
        }
        else {
            return (this.state.collect_addresses.map(item =>
                <option value={item.addrName + ' ' + item.townSuburb + ' ' + item.addrProvince + ' (' + item.dsvLocationID + '|' + item.id + ')'}></option>
            ));
        }
    }

    ToChangePassword() {
        fetch('/api/Login/CanUpdatePassword' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(response => {

                if (response == 'true')
                    this.setState({ toChangePassword: true });
                else
                    alert('You currently do not have the required permissions to change your password.');

            });
    }

    GetDeliverToComponent() {
        let deliver = this.LoadDeliverAddreses();

        console.log('deliver_length:' + this.state.deliver_addresses.length);
        if (this.state.deliver_addresses.length == 1) {
            return (
                <select ID="datalst_deliver" className="form-control twfont" disabled style={{ visibility: this.state.dsvlabelVisiblity }} onChange={this.SetDeliverToAddresses.bind(this)}>
                    <option value="0|0">Blank Label (M777)</option>
                </select>);
        }
        else {
            return (
                <div>
                    <input list="datalst_deliver" name="myBrowser" placeholder={this.state.loadingDeliverToAddresses ? "Loading addresses please wait..." : "Type to search address, or click on this to show the list of available addresses..."} className="form-control twfont" onChange={this.SetDeliverToAddresses.bind(this)} />
                    <datalist ID="datalst_deliver" style={{ visibility: this.state.dsvlabelVisiblity }} >
                        {deliver}
                    </datalist>
                </div>
            );
        }
    }

    GetFooterContent() {
        if (this.state.updatingAddresses) {
            return (
                <div>
                    <div>
                        <div className="spinner-border" style={{ color: 'rgb(8, 15, 83)' }} role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                    <br />

                    <label style={{ fontFamily : 'tw cen mt', fontWeight: 'bold'}} >Confirming please wait</label>
                </div>

                );
        }
        else {
            return (
                <div id="footerContainer" className="footerButtons3" style={{ height: '21px' }}>
                    <button ID="btnBack" className="btn primary_btn" style={{ width: '90%', fontFamily: 'Tw Cen MT', marginLeft: '5%' }} onClick={() => this.GoBackToProducts()}>Back</button >
                    <button ID="btnRequestNew" className="btn secondary_btn" style={{ width: '90%', fontFamily: 'Tw Cen MT', marginLeft: '5%' }} onClick={() => this.DisplayRequestModal()}>Request New Address</button >
                    <button ID="btnNext" className="btn secondary_btn" style={{ width: '90%', fontFamily: 'Tw Cen MT', marginLeft: '5%' }} onClick={() => this.ValidateAddresses()}>Confirm and Proceed</button>
                </div>
                
                );
        }
    }

    handleSelectedDeliChanged(e) {
        this.setState({ deliverToValue: e.target.value, deliverToActive: e.target.value == '1' });
    }

    handleSelectedCollChanged(e) {
        this.setState({ collectFromValue: e.target.value, collectFromActive: e.target.value == '1' });
    }

    handleSelectedAddrTypeChanged(e) {
        this.setState({ addrType : e.target.value});
    }

    MenuClick() {

        if (this.state.menuVisibility == 'hidden') {
            this.setState({ menuVisibility: 'visible' });
        }
        else if (this.state.menuVisibility == 'visible') {
            this.setState({ menuVisibility: 'hidden' });
        }
    }

    render() {

        //Header Redirect

        if (this.state.toSessionTimedOut) {
            return <Route path='/' component={SessionTimedOut} />
        }

        if (this.state.toClearCartPrompt) {
            return <Route path='/' component={ClearCartPrompt} />
        }

        if (this.state.toAdmin) {
            return <Route path='/' component={AdminManage} />
        }

        if (this.state.toDocumentsManage) {
            return <Route path='/' component={DocumentsManage} />
        }

        if (this.state.toChangePassword) {
            return <Route path='/' component={ChangePassword} />

        }


        if (this.state.GoToOrderSearch) {
            return <Route path='/' component={OrderSearch} />
        }

        if (this.state.toBudgets) {
            return <Route path='/' component={Budget} />
        }

        if (this.state.toSavedCarts) {
            return <Route path='/' component={SavedCarts} />
        }

        if (this.state.toCheckOut) {
            return <Route path='/' component={CheckOut} />
        }

        if (this.state.toDocuments) {
            return <Route path='/' component={InvoiceOrderSearch} />
        }

        if (this.state.toOrderApproval) {
            return <Route path='/' component={OrderApproval} />
        }

        if (this.state.toLogIn) {
            return <Route path='/' component={Login} />
        }

        if (this.state.toProducts) {
            return <Route path='/' component={Product} />
        }

        if (this.state.toClearCartPrompt) {
            return <Route path='/' component={ClearCartPrompt} />
        }


        ////end

        if (this.state.ToLogin) {
            return <Route path='/' component={Login} />
        }

        if (this.state.toOrderPlaced) {
            return <Route path='/' component={OrderPlaced} />
        }

        if (this.state.toOrderProcessing) {
            return <Route path='/' component={PaymentScreen} />
            //return <Route path='/' component={OrderProcessing} />
        }

        if (this.state.toInactivePaymentPortal) {
            return <Route path='/' component={PaymentPortalDisabled} />
        }

        else if (this.state.toProducts) {
            return <Route path='/' component={Product} />
        }

        else {

            let accountCode = this.state.accountCode;
            let contactName = this.state.contactName;
            let contactNo = this.state.contactNo;
            let addrName = this.state.addrName;
            let street = this.state.street;
            let city = this.state.city;
            let postalCode = this.state.postalCode;
            let province = this.state.province;
            let businessUnitComplexName = this.state.businessUnitComplexName;
            let townSuburb = this.state.townSuburb;
            //let dsvLocationID = this.state.dsvLocationID;
            let collActive = this.state.collectFromActive;
            let deliActive = this.state.deliverToActive;
            let addressType = this.state.addressType;

            let deliver = this.GetDeliverToComponent();
            let collect = this.LoadCollectAddreses();
            let footer = this.GetFooterContent();

            return (
                <div className="outer-container customer_background">
                    <div className="header" style={{ position: 'fixed', zIndex: '500' }}>
                        <div className="grid_container_header" style={{ msHyphens: 'auto', display: 'inline-grid', gridTemplateColumns: '30% 40% 30%', display: 'grid -ms-grid', msGridColumns: '30% 40% 30%', columnCount: '3' }}>
                            <div className="grid_header">
                                <div className="icon_home" style={{ width: '100%', height: '50%', marginTop: '30px', marginLeft: '10%', float: 'left', backgroundRepeat: 'no-repeat' }}></div>
                            </div>
                            <div className="grid_header" style={{ textAlign: 'left', overflow: 'hidden' }}>
                                <div className="header_container" style={{ width: '100%' }}>
                                    <div>
                                        <div style={{ display: 'inline' }}>
                                            <a className="header_text" onClick={() => this.GoBackToProducts()}  >Place Order</a>|
                                                <a className="header_text" onClick={() => this.GoToDocumentsManage()} >Documents</a><span style={{ visibility: this.state.canTrack }}>|</span>
                                            <a className="header_text" onClick={() => this.GoToOrderApproval()} style={{ visibility: this.state.canTrack }} >Order Approval</a>
                                            <span style={{ visibility: 'visible' }}>|</span>
                                            <a className="header_text" onClick={() => this.GoToBudget()} style={{ visibility: 'visible' }}>Budgets</a>
                                            <span style={{ visibility: this.state.isAdminVisibile }}>|</span>
                                            <a className="header_text" onClick={() => this.GoToAdmin()} style={{ visibility: this.state.isAdminVisibile }} >Admin</a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="grid_header">
                                <div className="header_container greeting" style={{ width: '100%' }}>
                                    <div className="header_text grid_item_1" style={{ lineHeight: '40px', textAlign: 'right' }}>
                                        <div style={{ height: '28px' }} className="overflow_header"> {this.state.userDetails.greeting + ', ' + this.state.userDetails.fullname}</div>
                                        <div style={{ height: '25px' }} className="overflow_header">{this.state.userDetails.accountName}</div>
                                        <div style={{ height: '28px' }} className="overflow_header">Group : {this.state.userDetails.accountCode}</div>
                                    </div>
                                    <div className="grid_item_2_reverse  h100">
                                        <div style={{ width: '100%', 'height': '100%' }}>
                                            <button id="btnMenu" onClick={() => this.MenuClick()} className="btn primary_btn header_btn twfont" style={{ lineHeight: '0.8', marginTop: '10px' }}  ><i className="material-icons" style={{ width: '100%' }} >menu</i><label>Options</label></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="header_menu_container" style={{ visibility: this.state.menuVisibility, marginTop: '100px' }}>
                        <div className="grid_item_1 max-height-menu">
                            <button onClick={() => { this.MenuClick() }} style={{ width: '100%', height: '100%', backgroundColor: 'transparent', border: 'none' }}></button>
                        </div>
                        <div className="header_menu grid_item_2 max-height-menu customer_background_foreground">
                            <hr />
                            <div>
                                <button id="canUpdateBtn" name="canUpdateBtn" className="btn  header_menu_item" onClick={() => { this.ToChangePassword() }}>Reset Password <i className="material-icons" style={{ position: 'relative', top: '6px', paddingLeft: '10px' }}>vpn_key</i></button>
                            </div>
                            <hr />
                            <div>
                                <button className="btn  header_menu_item" onClick={() => { this.GetCartState() }}>Logout <i className="material-icons" style={{ position: 'relative', top: '6px', paddingLeft: '10px' }}>exit_to_app</i></button>
                            </div>
                            <hr />
                        </div>
                    </div>
                    <div className=" sidenav customer_background_foreground" style={{ width: '100%', minHeight: '1000px', marginTop: '100px', visibility: this.state.dsvlabelVisiblity }}>
                        <div style={{ width: '100%', textAlign: 'center', visibility: this.state.dsvlabelVisiblity }}>
                            <br />
                            <div className="customer_background_foreground" style={{ width: '60%', margin: '0 20%', padding: '20px', verticalAlign: 'middle', height: '300px', visibility: this.state.dsvlabelVisiblity }}>
                                <div>
                                    <label className="customer_background_foreground twfont" style={{ fontSize: 'xx-large', fontWeight: 'bolder', fontFamily: 'TW Cen MT' }}>Select addresses to be printed on your labels</label>
                                    <br />
                                    <label className="customer_background_foreground twfont" style={{ fontFamily: 'TW Cen MT', textAlign: 'left', width: '100%', paddingLeft: '16%' }}>The addresses selected below will be printed on your labels</label>
                                    <hr />
                                    <div style={{ textAlign: 'left', fontFamily: 'TW Cen MT' }}>
                                        <ul>
                                            <li>Selecting a "Collect from address" is mandatory for all labels.</li>
                                            <li>For Orange labels, selecting a "Deliver to address" is mandatory. For all other label colours this can be left as “None”.</li>
                                            <li>Cant find an address? Click "Request New Address" to have it added for you.</li>
                                        </ul>
                                    </div>
                                    <hr />
                                </div>
                                <div>
                                    <label id="lblMessage" ></label><br />
                                </div>
                                <div className="divGridColumn" style={{ visibility: this.state.goBackVisibility, height: this.state.contentHeight }}>
                                    <div className="row2 col1">
                                        <label id="lbColl" className="table_font twfont" style={{ visibility: this.state.dsvlabelVisiblity, fontWeight: 'inherit', fontSize: 'medium', textAlign: 'left', width: '100%' }} ><span style={{ color: 'red' }}>*</span> The collect from address: </label>
                                    </div>
                                    <div className="row2 col2" style={{ marginBottom: '10px' }}>
                                        <div style={{ visibility: this.state.dsvlabelVisiblity }}>
                                            <input list="datalst_collect" name="myBrowserc" onChange={this.SetCollectFromAddresses.bind(this)} placeholder={this.state.loadingCollectFromAddresses ? "Loading addresses please wait..." : "Type to search address, or click on this to show the list of available addresses..."} className="form-control twfont" />
                                            <datalist id="datalst_collect" >
                                                {collect}
                                            </datalist>
                                        </div>
                                    </div>
                                    <div className="row1 col1">
                                        <label id="lblDel" className="table_font twfont" style={{ visibility: this.state.dsvlabelVisiblity, fontWeight: 'inherit', fontSize: 'medium', textAlign: 'left', width: '100%' }} ><span style={{ color: 'red' }}>*</span> The deliver to address: </label>
                                    </div>
                                    <div className="row1 col2" style={{ marginBottom: '10px' }}>
                                        {deliver}
                                    </div>

                                </div>
                                <br />
                                <div>
                                    <label style={{ fontFamily: 'TW Cen MT', color: 'orangered', fontSize: 'x-large', fontWeight: 'bolder' }}>{this.state.message}</label>
                                </div>
                                <hr />
                                <div id="footerBtnContainer">
                                    { footer}
                                </div>
                            </div>
                        </div >
                    </div >
                    <div style={{ visibility: this.state.requestModalVisibility }}>
                        <div className="outer-container" style={{ top: '0', fontFamily: 'TW Cent', backgroundColor: 'rgba(10,10,10, 0.8)' }} >
                            <div className="inner-container" style={{ height: '1000px', paddingTop: '100px', verticalAlign: 'inherit' }}>
                                <div className="centered-content twfont  customer_background_foreground"
                                    style={{ boxShadow: '2px 2px 8px #888888', border: 'none', width: '90%', maxWidth: '800px', backgroundColor: 'rgba(255,255,255,0.3)', fontFamily: 'TW Cen MT', marginTop: '20px' }}>
                                    <table className="customer_background_foreground" style={{ width: '100%', margin: '0 auto', verticalAlign: 'middle', height: '250px', backgroundColor: 'rgba(255,255,255,0.3)' }}>
                                        <tbody>
                                            <tr><td colSpan="2"><label style={{ width: '100%', textAlign: 'conter', fontSize: 'larger', fontWeight: 'bolder' }}>Enter Address Details</label></td></tr>
                                            <tr><td colSpan="2"><hr /></td></tr>
                                            <tr>
                                                <td style={{ width: '30%' }} ><label ID="label2" className="table_font twfont">Contact Name :<span style={{ color: 'red' }}>*</span></label></td>
                                                <td>
                                                    <input type="text" ID="txtContactName" maxLength="50" value={contactName} className="form-control twfont" onChange={(e) => { this.setState({ contactName: e.target.value }); }} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><label ID="label3" className="table_font twfont">Contact #:<span style={{ color: 'red' }}>*</span></label></td>
                                                <td>
                                                    <input type="text" ID="txtContactNo" maxLength="12" value={contactNo} className="form-control twfont" onChange={(e) => { this.setState({ contactNo: e.target.value }); }} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><label ID="label4" className="table_font twfont">Address Name :<span style={{ color: 'red' }}>*</span></label></td>
                                                <td>
                                                    <input type="text" ID="txtAddrName" maxLength="50" value={addrName} className="form-control twfont" onChange={(e) => { this.setState({ addrName: e.target.value }); }} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><label ID="label5" className="table_font twfont">Street :<span style={{ color: 'red' }}>*</span></label></td>
                                                <td>
                                                    <input type="text" ID="txtStreet" maxLength="40" value={street} className="form-control twfont" onChange={(e) => { this.setState({ street: e.target.value }); }} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><label ID="label6" className="table_font twfont">City :<span style={{ color: 'red' }}>*</span></label></td>
                                                <td>
                                                    <input type="text" ID="txtCity" maxLength="30" value={city} className="form-control twfont" onChange={(e) => { this.setState({ city: e.target.value }); }} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><label ID="label7" className="table_font twfont">Postal Code :<span style={{ color: 'red' }}>*</span></label></td>
                                                <td>
                                                    <input type="text" ID="txtPostalCode" maxLength="10" value={postalCode} className="form-control twfont" onChange={(e) => { this.setState({ postalCode: e.target.value }); }} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><label ID="label8" className="table_font twfont">Province :<span style={{ color: 'red' }}>*</span></label></td>
                                                <td>
                                                    <input type="text" ID="txtProvince" maxLength="20" value={province} className="form-control twfont" onChange={(e) => { this.setState({ province: e.target.value }); }} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: 'right' }}><label ID="label9" className="table_font twfont">Business Unit Complex Name :<span style={{ color: 'red' }}>*</span></label></td>
                                                <td>
                                                    <input type="text" ID="txtBusinessUnitComplexName" maxLength="40" value={businessUnitComplexName} className="form-control twfont" onChange={(e) => { this.setState({ businessUnitComplexName: e.target.value }); }} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: 'right' }}><label ID="label9" className="table_font twfont">Town/Suburb :<span style={{ color: 'red' }}>*</span></label></td>
                                                <td>
                                                    <input type="text" ID="txtTownSuburb" maxLength="30" value={townSuburb} className="form-control twfont" onChange={(e) => { this.setState({ townSuburb: e.target.value }); }} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><label ID="label10" className="table_font twfont">Delivery Address Type :<span style={{ color: 'red' }}>*</span></label></td>
                                                <td>
                                                    <select id="ddlAddrType" defaultValue={addressType} className="form-control twfont" onChange={this.handleSelectedAddrTypeChanged.bind(this)}>
                                                        <option value="-1">Please select type</option>
                                                        <option value="0">Collect From</option>
                                                        <option value="1">Deliver To</option>
                                                        <option value="2">Collect From & Deliver To</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr><td colSpan="2"><hr /></td></tr>
                                            <tr>
                                                <td colSpan="2">
                                                    <div className="footerButtons2">
                                                        <button ID="btnClose" className="btn primary_btn" style={{ width: '90%', fontFamily: 'Tw Cen MT', marginLeft: '5%' }} onClick={() => this.CloseRequestModal()}>Close</button >
                                                        <button ID="btnRequest" className="btn secondary_btn" style={{ width: '90%', fontFamily: 'Tw Cen MT', marginLeft: '5%' }} onClick={() => this.RequestNewAddress()}>Request New Address</button >
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table >
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            );
        }
    }

    //// Route Change

    GetCartState() {

        fetch('/api/Product/GetCartCount' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == "valued") {
                    this.setState({ toClearCartPrompt: true });
                } else {
                    window.location.href = '\\';
                }
            });
    }

    GoToDocumentsManage() {
        this.setState({ toDocumentsManage: true });
    }

    CheckIfAdmin() {
        fetch('/api/Login/isAdmin' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                this.setState({ isAdminVisibile: (data == 'Y' ? 'visible' : 'hidden') });
            });
    }

    GoToOrderSearch() {
        this.setState({ GoToOrderSearch: true });
    }

    GoToDocuments() {
        this.setState({ toDocuments: true });
    }

    GoToLogIn() {
        if (this.state.orderList.length > 0) {
            this.setState({ toClearCartPrompt: true });
        } else { this.setState({ toLogIn: true }); }
    }

    GoToAdmin() {
        this.setState({ toAdmin: true });
    }

    GoBackToProducts() {
        this.setState({ toProducts: true });
    }

    GoToBudget() {
        this.setState({ toBudgets: true });
    }

    GoToOrderForm() {
        this.setState({ toOrderForms: true });
    }

    GoToOrderSearch() {
        this.setState({ GoToOrderSearch: true });
    }

    GoToOrderApproval() {
        this.setState({ toOrderApproval: true });
    }

    GoToSavedCarts() {
        this.setState({ toSavedCarts: true });
    }

    GetUserDetails() {
        fetch('api/Login/GetUserDetails' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.json())
            .then(data => { this.setState({ userDetails: data }) });
    }

    CheckIfCanTrack() {
        fetch('/api/Login/canTrack' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                this.setState({ canTrack: (data == 'Y' ? 'visible' : 'hidden') })
            });
    }
}


//<tr>
//    <td><label ID="label10" className="table_font twfont">Collect From Active :<span style={{ color: 'red' }}>*</span></label></td>
//    <td>
//        <select id="ddlCollectFrom" value={collActive ? '1' : '0'} className="form-control twfont" onChange={this.handleSelectedCollChanged.bind(this)}>
//            <option value="1">True</option>
//            <option value="0">False</option>
//        </select>
//    </td>
//</tr>
//<tr>
//    <td><label ID="label11" className="table_font twfont">Deliver To Active:<span style={{ color: 'red' }}>*</span></label></td>
//    <td>
//        <select id="ddlDelivTo" value={deliActive ? '1' : '0'} className="form-control twfont" onChange={this.handleSelectedDeliChanged.bind(this)}>
//            <option value="1">True</option>
//            <option value="0">False</option>
//        </select>
//    </td>
//</tr>