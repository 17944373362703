
import React, { Component, ReactPropTypes } from 'react';
import { Route, Switch } from 'react-router';
import { Layout } from './components/Layout';
import { Login } from './components/Login';
import { FetchData } from './components/FetchData';
import { Product } from './components/Products';
import { CheckOut } from './components/CheckOut'
import { InvoiceOrderSearch } from './components/InvoiceOrderSearch'
import { SaveCart } from './components/SaveCart'
import { SaveCarts, SavedCarts } from './components/SavedCarts'
import { OrderPlaced } from './components/OrderPlaced'
import { DocumentContent } from './components/DocumentContent'
import { Ad, AdminUser } from './components/AdminUser'
import { Manage } from './components/Manage'
import { BudgetCategories } from './components/BudgetCategories'
import { GetStylesheet } from './registerServiceWorker';
import { Users } from './components/Users';
import { BudgetReport } from './components/BudgetReportCategory'
import { ClearCartPrompt } from './components/ClearCartPrompt'
import { Budget } from './components/Budget';
import { OrderSearch } from './components/OrderSearch';
import { OrderApproval } from './components/OrderApproval';
import { SessionTimedOut } from './components/SessionTimedOut';
import { Test } from './components/Test';
import { PaymentPortal } from './components/PaymentPortal';
import { SuccessPayment } from './components/SuccessPayment';
import { OrderProcessing } from './components/OrderProcessing';
import { PaymentPortalDisabled } from './components/PaymentPortalDisabled';
import { ForgotPassword } from './components/ForgotPassword';
import { ConnectionError } from './components/ConnectionError';
import { BudgetReportTotal } from './components/BudgetReportTotal';
import { DocumentsManage } from './components/DocumentsManage';
import { AdminManage } from './components/AdminManage';
import ReactTable from "react-table";  

export default class App extends Component {
    displayName = App.name

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            stylesheet: ''
        }


    }

    GetStylesheet() {
        console.log('get stylesheet called');

        fetch('api/Stylesheet/GetStyleSheet', { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                console.log('stylesheet : ');
                console.log(data);
                this.setState({ stylesheet: data });
            });


    }


    log(text) {
        console.log('printed : ' + text);
    }

    render() {
        return (
            <Switch>
                <Route exact path="/" component={Login} />
            </Switch>
        );
    }
}


