import React, { Component, ReactDOM, Ref, createRef, browserHistory } from 'react';
import { Login } from './Login';
import { CheckOut } from './CheckOut';
import { Route } from 'react-router';
import { SaveCart } from './SaveCart';
import { SavedCarts } from './SavedCarts';
import { InvoiceOrderSearch } from './InvoiceOrderSearch';
import { Manage } from './Manage';
import { OrderForm } from './Orderform';
import { CreateOrderForm } from './CreateOrderForm';
import { OrderFormList } from './OrderFormList'
import { OrderSearch } from './OrderSearch'
import { Budget } from './Budget'
import { GetDefaultImageFolder } from '../registerServiceWorker';
import { AdminUser } from './AdminUser'
import { Users } from './Users';
import { OrderApproval } from './OrderApproval'
import { ClearCartPrompt } from './ClearCartPrompt';
import { withRouter, Redirect } from 'react-router-dom';
import { SessionTimedOut } from './SessionTimedOut';
import { DocumentsManage } from './DocumentsManage';
import { AdminManage } from './AdminManage';
import { ChangePassword } from './ChangePassword';
import { LabelCheckOut } from './LabelCheckOut';

export class Product extends Component {
    displayName = Product.name

    //_isMountedp = false;

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            userDetails: {},
            cartState: '',
            loading: true,
            logout: false,
            productDisplayType: 2,
            isAdminVisibile: 'hidden',
            canTrack: 'hidden',
            settingQuantity: false,
            products: [],
            brands: [],
            productlines: [],
            cartItems: [],
            itemQuantity: 0,
            selectedBrand: '',
            searchedText: '',
            loadingBrands: true,
            loadingProductlines: true,
            loadingCart: false,
            availableCartItems: false,
            loadingProducts: false,
            productCount: 0,

            displayBrands: 'block',
            secondCategoryVisible: 'none',
            secondCategoryClass: 'category_fullcontainer',

            shoppingCartWidth: '100%',
            productListWidth: '100%',

            closeCartButtonWidth: '50px',
            closeCartButtonPadding: '6px 12px',
            closeCartButtonVisibility: 'visible',

            openCartButtonWidth: '0',
            openCartButtonPadding: '0',
            openCartButtonVisibility: 'hidden',

            toCheckOut: false,
            toSaveCart: false,
            toManage: false,
            toInvoiceCredits: false,
            toOrderForm: false,
            toAdmin: false,
            toProducts: false,

            toBudgets: false,
            toOrderSearch: false,
            toOrderApproval: false,
            toSavedCarts: false,
            toClearCartPrompt: false,
            imagelocation: '',
            sessionExists: true,
            toSessionTimedOut: false,
            toDocumentsManage: false,
            toChangePassword: false,
            menuVisibility: 'hidden',
            isActiveStatus: true,

            pendingOrderApprovalCount: '0',
            finalizeActive: false,
            loadToCartVisibility: 'hidden',
            loadToCartHeight: '0px',

            enforceMaxOrderQty: false


        };


        this.CheckSessionAndInitialize();

    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    ToChangePassword() {
        fetch('/api/Login/CanUpdatePassword' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(response => {

                if (response == 'true')
                    this.setState({ toChangePassword: true });
                else
                    alert('You currently do not have the required permissions to change your password.');

            });
    }

    IsSecondCategoryVisible() {
        fetch('/api/Product/IsSecondCategoryVisible' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(response => {
                if (response == '1')
                    this.setState({ secondCategoryVisible: 'block' });
                this.setState({ secondCategoryClass: 'category_container'  })
            });
    }

    SetActiveStatusState() {
        fetch('/api/Product/IsProductStatusActive' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(response => {

                if (response == 'true')
                    this.setState({ isActiveStatus: true });
                else
                    this.setState({ isActiveStatus: false });

            });
    }

    MenuClick() {


        if (this.state.menuVisibility == 'hidden') {
            this.setState({ menuVisibility: 'visible' });
        }
        else if (this.state.menuVisibility == 'visible') {
            this.setState({ menuVisibility: 'hidden' });
        }
    }

    LoadStylesheet() {
        this.setState({ complete_update: false });

        fetch('api/Stylesheet/GetStyleSheet?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                this.setState({ stylesheet: data }, this.setState({ complete_update: true }));
            });
    }

    RunAllInitializers() {
        this.setState({ complete_update: false });

        fetch('api/Stylesheet/GetStyleSheet?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                this.setState({ stylesheet: data }, this.setState({ complete_update: true }));

                this.GetOrderApprovalCount();
                this.GetUserDetails();
                this.GetDefaultImageFolder();
                this.CheckIfAdmin();
                this.CheckIfCanTrack();
                this.RunInitializers();
                this.GetEnforceMaxOrderQty();
            });
    }

    CheckSessionAndInitialize() {


        fetch('api/Login/CheckSessionProduct?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });
                    this.RunAllInitializers();
                }
                else if (data == 'timedout') {
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }

    GetOrderApprovalCount() {

        fetch('api/InvoiceOrder/GetOrderApprovalCount?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                this.setState({ pendingOrderApprovalCount: data });
            });
    }

    CheckSession() {
        this.setState({ complete_update: false });

        fetch('api/Login/CheckSessionProductSearch?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });
                }
                else if (data == 'timedout') {
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }

    GetCartState() {

        fetch('/api/Product/GetCartCount?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == "valued") {
                    this.setState({ toClearCartPrompt: true });
                } else {
                    window.location.href = '\\';
                }
            });
    }

    GetUserDetails() {
        fetch('api/Login/GetUserDetails?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.json())
            .then(data => { this.setState({ userDetails: data }) });
    }

    GetDefaultImageFolder() {
        fetch('api/Global/GetDefaultImageLocation', { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                this.setState({ imagelocation: data });
            })
    }

    GetEnforceMaxOrderQty() {
        fetch('api/Product/GetEnforceMaxOrderQty', { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                this.setState({ enforceMaxOrderQty: data });
            })
    }

    CheckIfAdmin() {
        fetch('/api/Login/isAdmin?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                this.setState({ isAdminVisibile: (data == 'Y' ? 'visible' : 'hidden') });
            });
    }

    CheckIfCanTrack() {
        fetch('/api/Login/canTrack?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                this.setState({ canTrack: (data == 'Y' ? 'visible' : 'hidden') });
            });
    }

    RunInitializers() {

        this.IsSecondCategoryVisible();
        this.GetCartItems();
        this.Getbrands();
        this.Getproductline();
        this.GetInitialProducts();
        this.SetActiveStatusState();
        this.IsLoadingOrderForm();
        //this.showLoadButton();
    }

    GoToBudget() {
        this.setState({ toBudgets: true });
    }

    GoToOrderSearch() {
        this.setState({ GoToOrderSearch: true });
    }

    openShoppingCart() {


        this.setState({
            shoppingCartWidth: '30%',
            productListWidth: '70%',

            closeCartButtonWidth: '50px',
            closeCartButtonPadding: '6px 12px',
            closeCartButtonVisibility: 'visible',

            openCartButtonWidth: '0',
            openCartButtonPadding: '0',
            openCartButtonVisibility: 'hidden'
        })
    }

    closeShoppingCart() {


        this.setState({ shoppingCartWidth: '0%' });
        this.setState({ productListWidth: "100%" });

        this.setState({ openCartButtonWidth: "50px" });
        this.setState({ openCartButtonPadding: "6px 12px" });
        this.setState({ openCartButtonVisibility: "visible" });

        this.setState({ closeCartButtonWidth: "0" });
        this.setState({ closeCartButtonPadding: "0" });
        this.setState({ closeCartButtonVisibility: "hidden" });

    }

    handleSelectedBrand(e) {
        let { name, value } = e.target;
        this.setState({ selectedBrand: e.target.value }
            , () => {

                if (this.state.selectedBrand != '') {
                    this.GetProducts();
                }
            })
    }

    SelectBrand(value) {
        this.setState({ selectedBrand: value },
            () => { this.GetProducts() });

    }

    handleSearchBrand(e) {
        let inputtext = e.target.value;
        this.setState({ searchedText: inputtext });
    }

    SearchKeyPress(e) {
        var keypressed;
        keypressed = e.charCode;
    }

    Getbrands() {
        fetch('/api/Product/GetBrands?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.json())
            .then(data => { this.setState({ brands: data }) })
            .then(() => {
                this.setState({ loadingBrands: false });
            })
    }

    //GetSpecificbrands(brnd) {
    //    fetch('/api/Product/GetBrands?brand=' + brnd + 'buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
    //        .then(response => response.json())
    //        .then(data => { this.setState({ brands: data }) })
    //        .then(() => {
    //            this.setState({ loadingBrands: false });
    //        })
    //}

    Getproductline() {
        fetch('/api/Product/GetProductLines?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.json())
            .then(data => { this.setState({ productlines: data }) })
            .then(() => {
                this.setState({ loadingProductlines: false });
            })
    }

    GetInitialProducts() {

        this.setState({ loadingProducts: true });

        fetch('/api/Product/GetInitialProducts' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.json())
            .then(data => { this.setState({ products: data, loadingProducts: true }); })
            .then(() => {
                this.setState({ loadingProducts: false, loading: false });

            });

    }

    IsLoadingOrderForm() {

        fetch('/api/Product/isLoadingOrderForm' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.json())
            .then(data => {

                if (data)
                    this.setState({ loadToCartVisibility : 'visible', loadToCartHeight : 'auto' });
                else
                    this.setState({ loadToCartVisibility: 'collapse', loadToCartHeight: '0px' });

                console.log('states (height:' + this.state.loadToCartHeight + ', visibility: ' + this.state.loadToCartVisibility + ')');
            });

    }


    GetProducts() {

        this.setState({ loadingProducts: true });
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            fetch('/api/Product/GetProducts?brandcode=' + this.state.selectedBrand + '&buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
                .then(response => response.json())
                .then(data => { this.setState({ products: data, loadingProducts: true }); })
                .then(() => {
                    this.setState({ loadingProducts: false });

                });
        }

    }

    GetAutoForm() {

        this.setState({ loadingProducts: true });
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            fetch('/api/Product/GetAutoOrderForm' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
                .then(response => response.json())
                .then(data => { this.setState({ products: data, loadingProducts: true }); })
                .then(() => {
                    this.setState({
                        loadingProducts: false, loadToCartVisibility: 'collapse', loadToCartHeight: '0px' });

                });
        }
    }

    SearchAll() {

        this.setState({ products: [], loadingProducts: true, loadToCartVisibility: 'collapse', loadToCartHeight: '0px' });

        fetch('/api/Product/SearchProducts?search=' + ' ' + '&buster=' + new Date().getTime(), {
            credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache'
        })
            .then(response => response.json())
            .then(data => { this.setState({ products: data, loadingProducts: true }); })
            .then(() => {
                this.setState({ loadingProducts: false, loadToCartVisibility: 'collapse', loadToCartHeight: '0px' });

            });
    }

    InitializeProducts() {
        this.setState({ products: [], loadingProducts: true });

        fetch('/api/Product/InitializeProducts?search=' + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.json())
            .then(data => { this.setState({ products: data, loadingProducts: true }); })
            .then(() => {
                this.setState({ loadingProducts: false });

            });
    }

    SearchProducts() {
        this.setState({ products: [], loadingProducts: true, loadToCartVisibility: 'collapse', loadToCartHeight: '0px' });
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            fetch('/api/Product/SearchProducts?search=' + this.state.searchedText + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
                .then(response => response.json())
                .then(data => { this.setState({ products: data, loadingProducts: true }); })
                .then(() => {
                    this.setState({ loadingProducts: false });

                });
        }
    }

    AddCartItem(id, min, max, customerProductID) {

        this.setState({ loadingCart: true });
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            fetch('/api/Product/ValidateQuantity?id=' + id + "&min=" + min + "&max=" + max + '&buster =' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
                .then(response => response.text())
                .then((data) => {
                    if (data == 'complete') {
                        fetch('/api/Product/AddToCart?id=' + id + '&customerProductID=' + customerProductID + '&buster =' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
                            .then(response => response.json())
                            .then(data => {
                                this.setState({ cartItems: data, loadingCart: false });
                            });
                    }
                    else {
                        alert(data);
                        //this.GetInitialProducts();
                    }
                });


        }

        this.setState({ loadingCart: false });

    }

    AddOrderTemplateToCart() {

        this.setState({ loadingCart: true });
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            fetch('/api/Product/AddOrderTemplateToCart?buster =' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
                .then(response => response.json())
                .then(data => {
                    this.setState({ cartItems: data, loadingCart: false });
                });
        }


    }

    SetNewQuantityProd_2(e) {

        this.setState({ settingQuantity: true });
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            let id = e.target.id;
            let quantity = e.target.value;

            if (quantity == '') {
            }
            else if (!isNaN(quantity) && quantity > 0 && !(quantity.indexOf(".") > -1) && !(quantity.indexOf(",") > -1)) {
                fetch('/api/Product/SetNewQuantityProd?itemCode=' + id + '&quantity=' + quantity + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
                    .then(() => {
                        this.setState({ settingQuantity: false });
                    });
            }
        }


    }


    SetNewQuantityProd(e) {

        this.setState({ settingQuantity: true });
        this.CheckSession();


        if (this.state.toSessionTimedOut == false) {
            let id = e.target.id;
            let quantity = e.target.value;

            if (quantity == '') {
            }
            else if (!isNaN(quantity) && quantity > 0 && !(quantity.indexOf(".") > -1) && !(quantity.indexOf(",") > -1)) {
                fetch('/api/Product/SetNewQuantityProd?itemCode=' + id + '&quantity=' + quantity + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
                    .then(() => {
                        this.setState({ settingQuantity: false });

                    });
            }
            else {
                e.target.value = 1;
                alert('Please enter a valid value. Only values bigger than 0 are allowed. No decimals allowed');
            }
        }


    }

    SetNewQuantityCart(e) {

        this.setState({ settingQuantity: true });

        let id = e.target.id;
        let quantity = e.target.value;

        fetch('/api/Product/SetNewQuantityCart?itemCode=' + id + '&quantity=' + quantity)
            .then(() => {
                this.setState({ settingQuantity: false });
            })

    }

    SetNewQuantityCartItem(e) {
        //!@!
        //modify to get the EnforceMaxOrderQty from the shop.customersettings table
        //if 1, then do not allow user to exceed max qty
        //added by am 20240302
        this.setState({ settingQuantity: true });
        var maxQty = e.target.name;
        let id = e.target.id;
        let quantity = e.target.value;

        var qty = Number(quantity);
        var mxqty = Number(maxQty);

        //!@! modified on 20240416 by AM
        //If the item has a max qty of zero and the customer  enforceMaxOrderQty = true, 
        //then allow the user to add the product
        if (quantity == '') {
        }
        else if ((this.state.enforceMaxOrderQty == true) && (qty > mxqty) && (mxqty > 0)) {
                alert('You are not allowed to exceed the maximum order quantity.');
        }
        else if (!isNaN(quantity) && quantity > 0 && !(quantity.indexOf(".") > -1) && !(quantity.indexOf(".") > -1)) {
            fetch('/api/Product/UpdateOrderItemQuantity?productId=' + id + '&quantity=' + quantity + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
                .then(() => {
                    this.setState({ settingQuantity: false });
                    this.GetCartItems();
                });
        }
        else {
            e.target.value = 1;
            alert('Please enter a valid value. Only values bigger than 0 are allowed. No decimals allowed');
        }

    }

    Checkout() {


        if (this.state.cartItems.length > 0) {
            fetch('/api/Product/ValidateCart?buster =' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
                .then(response => response.text())
                .then(data => {
                    if (data == "true")
                        this.setState({ toCheckOut: true });
                    else
                        alert('There are items in the cart which are above and or below their allowed quantities please look at the description of the highlighted(red) cart items.');
                });
        }
        else {
            alert('Please add items to cart before going to checkout');
        }
    }

    SaveCart() {
        fetch('/api/Product/PlaceOrder' + '?buster =' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(() => { this.setState({ toSaveCart: true }); })
    }

    InvoiceCredits() {
        this.setState({ toInvoiceCredits: true });
    }

    Manage() {
        this.setState({ toManage: true });
    }

    toOrderForm() {
        this.setState({ toOrderForm: true });
    }

    RemoveCartItem(id) {

        this.setState({ loadingCart: true });
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            fetch('/api/Product/RemoveFromCart?id=' + id + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
                .then(response => response.json())
                .then(data => { this.setState({ cartItems: data }) })
                .then(() => {
                    this.setState({ loadingCart: false });
                });
        }
    }

    RemoveAllCartItems() {
        this.setState({ loadingCart: true });
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            fetch('/api/Product/ClearCart' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
                .then(response => response.json())
                .then(data => { this.setState({ cartItems: data }) })
                .then(() => {
                    this.setState({ loadingCart: false });
                });
        }
    }

    LoadBrandToDropDown() {

        if (this.state.loadingBrands) {
            return (<option>loading...</option>);
        }
        else {
            return (this.state.brands.map(brand =>
                <div id={'div' + brand.value} key={'div' + brand.value} style={{ width: '100%' }}>
                    <button id={'btn' + brand.value} key={'btn' + brand.value} className="icon_add_to_cart" style={{ width: '95%', textAlign: 'left', fontWeight: '300', padding: '2px 2px', marginBottom: '2px' }} onClick={() => this.SelectBrand(brand.value)}>{brand.text}</button>
                </div>
            ));
        }
    }

    LoadProductLinesToDropDown() {

        if (this.state.loadingProductlines) {
            return (<option>loading...</option>);
        }
        else {
            return (this.state.productlines.map(productlines =>
                <div id={'div' + productlines.value} key={'div' + productlines.value} style={{ width: '100%' }}>
                    <button id={'btn' + productlines.value} key={'btn' + productlines.value} className="icon_add_to_cart" style={{ width: '95%', textAlign: 'left', fontWeight: '200', padding: '2px 2px', marginBottom: '2px'}} onClick={() => this.ProductLineClick(productlines.value)}>{productlines.text}</button>
                </div>
            ));
        }
    }

    ProductLineClick(e) {


        //if (this.state.displayBrands == 'none') {
        //    this.setState({ displayBrands: 'block' });
        //}
        //else if (this.state.displayBrands == 'block') {
        //    this.setState({ displayBrands: 'none' });
        //}

        this.LoadSpecificBrandToDropDown(e);

    }
    //need code here to select the requested brand
    LoadSpecificBrandToDropDown(prodLine) {
        fetch('/api/Product/GetSpecificBrand?prodLine=' + prodLine + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.json())
            .then(data => { this.setState({ brands: data }) })
            .then(() => {
                this.setState({ loadingBrands: false });
            })

        if (this.state.loadingBrands) {
            return (<option>loading...</option>);
        }
        else {
            return (this.state.brands.map(brand =>
                <div id={'div' + brand.value} key={'div' + brand.value} style={{ width: '100%' }}>
                    <button id={'btn' + brand.value} key={'btn' + brand.value} className="icon_add_to_cart" style={{ width: '85%', textAlign: 'left', padding: '2px 2px', marginBottom: '2px' }} onClick={() => this.SelectBrand(brand.value)}>{brand.text}</button>
                </div>
            ));
        }
    }

    LoadProductsGrid() {

        let altImageLocation = this.state.imagelocation;

        if (this.state.loadingProducts) {
            return (<div className="inner-container">
                <div className="centered-content  twfont customer_background_foreground">
                    <div className="spinner-border" style={{ color: 'rgb(8, 15, 83)' }} role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </div>)
        }
        else {
            return (
                this.state.products.map(product =>
                    <div className="grid">
                        <div className="cardContainer #343A40 ">
                            <div id={product.itemCode} className="card customer_border_color" style={{ width: '100%', height: '100%' }}>
                                <div id="top" className="card-img-top customer_background_foreground" style={{ paddingTop: '5px', backgroundColor: 'whitesmoke', borderRadius: '0' }}>
                                    <div className="item_image underline-from-center">
                                        <div className="cardTop customer_background_foreground">
                                            <div style={{ 'padding-left': '10px', 'padding-top': '2px', 'width': '240px' }}>
                                                <label ID="imghotel" className="horizontal_overflow">{product.description}</label>
                                            </div>
                                            <a onClick={() => { this.AddCartItem(product.sysproProductCode, product.minQuantity, product.maxQuantity, product.itemCode) }}>
                                                <i className="icon_add_to_cart material-icons" >add</i>
                                            </a>
                                        </div>

                                        <img type="image" style={{ backgroundColor: 'white' }} onError={(e) => { e.target.onerror = null; e.target.src = altImageLocation }} id="imProd" src={product.imageUrl} className="image_border border_bottom customer_tertiary_background" onClick={() => { this.AddCartItem(product.sysproProductCode, product.minQuantity, product.maxQuantity, product.itemCode) }} />
                                    </div>
                                </div>
                                <div className="card-body customer_background_foreground" style={{ 'background-color': 'whitesmoke', width: '100%', display: 'inline-block', 'vertical-align': 'bottom', 'padding-bottom': '5px', 'padding-top': '5%', 'border-radius': '0' }}>
                                    <table className="cardTable" style={{ width: '100%', 'text-align': 'center', 'vertical-align': 'central' }}>
                                        <tr>
                                            <td style={{ 'font-weight': 'bold' }}>
                                                <label>R</label><label ID="lblPrice">{product.unitPrice}</label>
                                            </td>
                                            <td style={{ 'font-weight': 'bold' }}>
                                                <label style={{ 'margin-right': '3px' }}>QTY </label>
                                                <label id="lblPrice">{product.quantityOnHand}</label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label>Item Code</label>
                                            </td>
                                            <td>
                                                <label>{product.itemCode}</label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label>Unit Of Measure</label>
                                            </td>
                                            <td>
                                                <label>{product.uomdescription}</label>
                                            </td>
                                        </tr>
                                    </table >
                                </div >
                            </div >
                        </div>
                    </div>
                ));
        }

    }

    IncreaseSize(e) {
        if (e.target.height != 200)
            e.currentTarget.style.height = '200px';
        else
            e.currentTarget.style.height = '50px';
    }

    LoadProductsTable() {
        let altImageLocation = this.state.imagelocation;

        if (this.state.loadingProducts) {
            return (
                <div>
                    <div>
                        <div className="spinner-border" style={{ color: 'black' }} role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            let backcolor = 'white';

            if (this.state.isActiveStatus) {
                return (this.state.products.map(product =>
                    <tr id={'tr' + product.itemCode} key={'tr' + product.itemCode} style={{ lineHeight: '50px', borderBottom: 'solid 1px' }}>
                        <td style={{ width: '60px' }}><img id={'img' + product.itemCode} type="image" className="table_image_border" onClick={this.IncreaseSize.bind(this)} onError={(e) => { e.target.onerror = null; e.target.src = altImageLocation }} id="imProd" src={product.imageUrl} /></td>
                        <td style={{ textAlign: 'left' }}>{product.itemCode.replace('-AndSign-', '&')}</td>
                        <td style={{ textAlign: 'left' }}>{product.description}</td>
                        <td style={{ textAlign: 'left' }}>{product.quantityOnHand}</td>
                        <td style={{ textAlign: 'left' }}>{product.uomdescription}</td>
                        <td style={{ textAlign: 'left' }}>R {Number(product.unitPrice).toFixed(2)}</td>
                        <td style={{ width: '0', padding: '0', display: 'none' }}>{product.sysproProductCode.replace('-AndSign-', '&')}</td>
                        <td>
                            <input id={product.sysproProductCode} style={{ width: '90px', textAlign: 'right' }} className="form-control" type="number" min="1" onChange={this.SetNewQuantityProd.bind(this)} defaultValue={product.quantityChosen} />
                        </td>
                        <td><button className="btn customer_background_foreground" onClick={() => this.AddCartItem(product.sysproProductCode, product.minQuantity, product.maxQuantity, product.itemCode)} style={{ cursor: 'pointer', height: '38px' }}><i className="icon_add_to_cart material-icons tertiary_btn">add</i></button></td>
                    </tr>
                ));
            }
            else if (this.state.enforceMaxOrderQty == true) {
                return (this.state.products.map(product =>
                    <tr id={'tr' + product.itemCode} key={'tr' + product.itemCode} style={{ lineHeight: '50px', borderBottom: 'solid 1px' }}>
                        <td style={{ width: '60px' }}><img id={'img' + product.itemCode} type="image" className="table_image_border" onClick={this.IncreaseSize.bind(this)} onError={(e) => { e.target.onerror = null; e.target.src = altImageLocation }} id="imProd" src={product.imageUrl} /></td>
                        <td style={{ textAlign: 'left' }}>{product.itemCode}</td>
                        <td style={{ textAlign: 'left' }}>{product.description}</td>
                        <td style={{ textAlign: 'left' }}>{product.uomdescription}</td>
                        <td style={{ textAlign: 'left' }}>R {Number(product.unitPrice).toFixed(2)}</td>
                        <td style={{ textAlign: 'left' }}>{product.maxQuantity}</td>
                        <td width='1' className='noclass' style={{ fontSize: '0px', padding: '0', margin: '0', width: '0%'}}>test</td>
                        {/*<td style={{ textAlign: 'left', visibility: 'hidden' }}>{product.sysproProductCode.replace('-AndSign-', '&')}</td>*/}
                        <td>
                            <input id={product.sysproProductCode} style={{ width: '90px', textAlign: 'right' }} className="form-control" type="number" min="1" onChange={this.SetNewQuantityProd.bind(this)} defaultValue={product.quantityChosen} />
                        </td>
                        <td><button className="btn customer_background_foreground" onClick={() => this.AddCartItem(product.sysproProductCode, product.minQuantity, product.maxQuantity, product.itemCode)} style={{ cursor: 'pointer', height: '38px' }}><i className="icon_add_to_cart material-icons tertiary_btn">add</i></button></td>
                    </tr>
                ));
            }
            else {
                return (this.state.products.map(product =>
                    <tr id={'tr' + product.itemCode} key={'tr' + product.itemCode} style={{ lineHeight: '50px', borderBottom: 'solid 1px' }}>
                        <td style={{ width: '60px' }}><img id={'img' + product.itemCode} type="image" className="table_image_border" onClick={this.IncreaseSize.bind(this)} onError={(e) => { e.target.onerror = null; e.target.src = altImageLocation }} id="imProd" src={product.imageUrl} /></td>
                        <td style={{ textAlign: 'left' }}>{product.itemCode}</td>
                        <td style={{ textAlign: 'left' }}>{product.description}</td>
                        <td style={{ textAlign: 'left' }}>{product.uomdescription}</td>
                        <td style={{ textAlign: 'left' }}>R {Number(product.unitPrice).toFixed(2)}</td>
                        <td style={{ fontSize: '1', visibility: 'hidden' }}>{product.sysproProductCode.replace('-AndSign-', '&')}</td>
                        <td>
                            <input id={product.sysproProductCode} style={{ width: '90px', textAlign: 'right' }} className="form-control" type="number" min="1" onChange={this.SetNewQuantityProd.bind(this)} defaultValue={product.quantityChosen} />
                        </td>
                        <td><button className="btn customer_background_foreground" onClick={() => this.AddCartItem(product.sysproProductCode, product.minQuantity, product.maxQuantity, product.itemCode)} style={{ cursor: 'pointer', height: '38px' }}><i className="icon_add_to_cart material-icons tertiary_btn">add</i></button></td>
                    </tr>
                ));
            }
        }
    }

    LoadProducts() {
        if (this.state.productDisplayType == 0) {
            let productsGrid = this.LoadProductsGrid();

            return (
                <div id="GridContainer" className="gridContainer customer_secondary_background">
                    {productsGrid}
                </div>
            );
        }
        else if (this.state.productDisplayType == 1) {

        }
        else if (this.state.productDisplayType == 2) {
            if (this.state.loadingProducts) {
                return (
                    <div>
                        <div>
                            <div className="spinner-border" style={{ color: 'red' }} role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                )
            }
            else {
                let tableGrid = this.LoadProductsTable();


                if (this.state.isActiveStatus) {
                    return (
                        <table className="customer_background_foreground table" id="tblProducts" style={{ width: '100%' }}>
                            <tbody>
                                <tr className="customer_background_foreground_reverse">
                                    <th></th>
                                    <th style={{ textAlign: 'left' }}>Item Code</th>
                                    <th style={{ textAlign: 'left' }}>Description</th>
                                    <th style={{ textAlign: 'left' }}>Status</th>
                                    <th style={{ textAlign: 'left' }}>UOM</th>
                                    <th style={{ textAlign: 'left' }}>Unit Price</th>
                                    <th style={{ width: '0', padding: '0', display: 'none' }}></th>
                                    <th style={{ textAlign: 'left' }}>Quantity</th>
                                    <th></th>
                                </tr>
                                <tr><td colSpan="8"><hr /></td></tr>
                                {tableGrid}
                            </tbody>
                        </table>
                    );
                }
                else if (this.state.enforceMaxOrderQty == true)
                {
                    return (
                        <table className="customer_background_foreground table" id="tblProducts" style={{ width: '100%' }}>
                            <tbody>
                                <tr className="customer_background_foreground_reverse">
                                    <th></th>
                                    <th style={{ textAlign: 'left' }}>Item Code</th>
                                    <th style={{ textAlign: 'left' }}>Description</th>
                                    <th style={{ textAlign: 'left' }}>UOM</th>
                                    <th style={{ textAlign: 'left' }}>Unit Price</th>
                                    <th style={{ textAlign: 'left' }}>Max Qty</th>
                                    <th style={{ width: '0', padding: '0', margin: '0' }}></th>
                                    {/*<th style={{ width: '0', padding: '0', display: 'none' }}></th>*/}
                                    <th style={{ textAlign: 'left' }}>Quantity</th>
                                    <th></th>
                                </tr>
                                <tr><td colSpan="8"><hr /></td></tr>
                                {tableGrid}
                            </tbody>
                        </table>
                    );
                }
                else {
                    return (
                        <table className="customer_background_foreground" style={{ width: '100%' }}>
                            <tbody>
                                <tr className="customer_background_foreground_reverse">
                                    <th></th>
                                    <th style={{ textAlign: 'left' }}>Item Code</th>
                                    <th style={{ textAlign: 'left' }}>Description</th>
                                    <th style={{ textAlign: 'left' }}>UOM</th>
                                    <th style={{ textAlign: 'left' }}>Unit Price</th>
                                    <th style={{ width: '0', padding: '0', visibility: 'collapse' }}></th>
                                    <th style={{ textAlign: 'left' }}>Quantity</th>
                                    <th></th>
                                </tr>
                                <tr><td colSpan="8"><hr /></td></tr>
                                {tableGrid}
                            </tbody>
                        </table>
                    );
                }
            }
        }
    }

    GetCartItems() {

        fetch('/api/Product/IsAvailableItems' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.json())
            .then(data => {
                this.setState({ availableCartItems: data },
                    () => {


                        if (this.state.availableCartItems == true) {
                            this.setState({ loadingCart: true });

                            fetch('/api/Product/GetCartItems?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
                                .then(response => response.json())
                                .then(data => { this.setState({ cartItems: data }) })
                                .then(() => {
                                    this.setState({ loadingCart: false });
                                })
                        }
                    })
            });
    }


    showLoadButton() {
        //visibility: collapse;
        //height: 0;
        var btnLoad = document.getElementById('btnLoad');

        if (btnLoad != null) {
            btnLoad.style.visibility = 'visible';
            btnLoad.style.height = 'auto';
        }


    }

    LoadCartItems() {
        if (this.state.loadingCart) {
            return (<div>
                <div>
                    <div className="spinner-border" style={{ color: 'rgb(8, 15, 83)' }} role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </div>);

        }
        else {
            return (
                this.state.cartItems.map(cartItem =>
                    <div id={'cid' + cartItem.id} key={'ckey' + cartItem.id}>
                        <div className="info-box" style={{ fontFamily: 'TW Cen MT', margin: '2% 3% 0 3%', width: '90%', height: '140px', border: cartItem.isvalid ? 'none' : 'solid red' }}>
                            <div className="content" style={{ float: 'left', width: '70%', margin: '0' }}>
                                <div >
                                    <div>
                                        <div className="text" style={{ fontWeight: 'bold' }}>{cartItem.id}</div>
                                        <div className="text" style={{ width: '171px', height: '19.2px', overflow: 'hidden', textOverflow: 'ellipsis', fontWeight: 'bold' }}>{cartItem.description}</div>
                                        <div className="text" style={{ fontWeight: 'bold' }}>
                                            R {Number(cartItem.price).toFixed(2)} : {cartItem.uom}  </div>
                                        <div className="number count-to" style={{ marginTop: '1px' }} data-from="0" data-to="125" data-speed="1000" data-fresh-interval="20">
                                            Quantity : <input id={cartItem.sysproProductID} style={{ width: '70px', height: '25px' }} type="number" name={cartItem.maxQty} onChange={this.SetNewQuantityCartItem.bind(this)} key={`${Math.floor((Math.random() * 1000))}-min`} placeholder={'#' + cartItem.quantity} defaultValue={cartItem.quantity} />
                                        </div>
                                        <div className="number count-to" style={{ marginTop: '1px', overflow: 'overlay', maxHeight: '24px', fontSize: 'inherit' }} data-from="0" data-to="125" data-speed="1000" data-fresh-interval="20">Ext Price: R {Number(cartItem.price * cartItem.quantity).toFixed(2)}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="icon" style={{ backgroundColor: 'white', float: 'right', width: '30%' }}>
                                <a style={{ padding: '0', cursor: 'pointer' }} onClick={() => { this.RemoveCartItem(cartItem.sysproProductID) }} >
                                    <div _ngcontent-orw-c22="" className="icon-item-inner-container">
                                        <icons-image><i className="material-icons" style={{ color: 'red', fontSize: '25px' }}>delete</i></icons-image>
                                        <span _ngcontent-orw-c22="" className="icon-item-title">delete</span>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                )
            );
        }
    }


    GoToAdmin() {
        this.setState({ toAdmin: true });
    }

    GoBackToProducts() {
        this.setState({ toProducts: true });
    }

    GoToBudget() {
        this.setState({ toBudgets: true });
    }

    GoToOrderForm() {
        this.setState({ toOrderForms: true });
    }

    GoToOrderSearch() {
        this.setState({ GoToOrderSearch: true });
    }

    GoToOrderApproval() {
        this.setState({ toOrderApproval: true });
    }

    GoToSavedCarts() {
        this.setState({ toSavedCarts: true });
    }

    GoToDocumentsManage() {
        this.setState({ toDocumentsManage: true });
    }

    render() {


        if (this.state.toProducts) {
            return <Route path='/' component={Product} />
        }

        if (this.state.toSessionTimedOut) {
            return (<Route path='/' component={SessionTimedOut} />);
        }

        if (this.state.toClearCartPrompt) {
            return <Route path='/' component={ClearCartPrompt} />
        }
        if (this.state.toAdmin) {
            return (<Route path='/' component={AdminManage} />);
        }

        if (this.state.GoToOrderSearch) {
            return (<Route path='/' component={OrderSearch} />);
        }

        if (this.state.toBudgets) {
            return <Route path='/' component={Budget} />
        }

        if (this.state.toSavedCarts) {
            return <Route path='/' component={SavedCarts} />
        }

        if (this.state.toOrderForm) {
            return <Route path='/' component={OrderFormList} />

        }

        if (this.state.toChangePassword) {
            return <Route path='/' component={ChangePassword} />

        }

        if (this.state.toSaveCart) {
            return <Route path='/' component={SaveCart} />
        }

        if (this.state.toDocumentsManage) {
            //return <Redirect to="/Documents" />
            return <Route path='/' component={DocumentsManage} />
        }
        if (this.state.toManage) {
            return <Route path='/' component={Manage} />
        }

        if (this.state.toCheckOut) {
            return <Route path='/' component={LabelCheckOut} />
        }

        if (this.state.toInvoiceCredits) {
            return <Route path='/' component={InvoiceOrderSearch} />
        }
        if (this.state.toOrderApproval) {
            return <Route path='/' component={OrderApproval} />
        }
        else {
            if (this.state.loading) {
                return (
                    <div>
                        <link href={this.state.stylesheet} rel="stylesheet" type="text/css" id="pageStylesheet" />
                        <div className="outer-container customer_background" style={{ fontFamily: 'TW Cent', backgroundColor: 'white' }}>
                            <div className="inner-container">
                                <div className="centered-content  twfont customer_background_foreground" style={{ boxShadow: 'none', border: 'none' }}>
                                    <div className="spinner-border" style={{ color: 'rgb(8, 15, 83)', height: '3rem', width: '3rem', borderWidth: '0.5em' }} role="status">
                                        <span className="sr-only" >Loading...</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>);
            }
            else if (this.state.logout) {
            }
            else {

                let brandsDropDown = this.LoadBrandToDropDown();
                let productlinesDropDown = this.LoadProductLinesToDropDown();
                let products = this.LoadProducts();
                let cartItems = null;
                cartItems = this.LoadCartItems();

                return (
                    <div>
                        <link href={this.state.stylesheet} rel="stylesheet" />
                        <div onKeyPress={this.SearchKeyPress(this)}>
                            <div className="outer-container customer_background">
                                <div className="header" style={{ position: 'fixed', zIndex: '500' }}>
                                    <div className="grid_container_header" style={{ display: 'inline-grid', msHyphens: 'auto', gridTemplateColumns: '30% 40% 30%', display: 'grid -ms-grid', msGridColumns: '30% 40% 30%', columnCount: '3' }}>
                                        <div className="grid_header">
                                            <div className="icon_home" style={{ width: '100%', height: '50%', marginTop: '30px', marginLeft: '10%', float: 'left', backgroundRepeat: 'no-repeat' }}></div>
                                        </div>
                                        <div className="grid_header" style={{ textAlign: 'left', overflow: 'hidden' }}>
                                            <div className="header_container" style={{ width: '100%' }}>
                                                <div>
                                                    <div style={{ display: 'inline', display: '-ms-grid' }}>
                                                        <a className="header_text" onClick={() => this.GoBackToProducts()} >Place Order</a>|
                                                        <a className="header_text" onClick={() => this.GoToDocumentsManage()} >Documents</a>
                                                        <span style={{ visibility: this.state.canTrack }}>|</span>
                                                        <a className="header_text" onClick={() => this.GoToOrderApproval()} style={{ visibility: this.state.canTrack }} >Order Approval <span style={{ lineHeight: 'inherit' }}><span className="notification secondary_btn">{this.state.pendingOrderApprovalCount}</span></span></a>

                                                        <span style={{ visibility: 'visible' }}>|</span>
                                                        <a className="header_text" onClick={() => this.GoToBudget()} style={{ visibility: 'visible' }}>Budgets</a>

                                                        <span style={{ visibility: this.state.isAdminVisibile }}>|</span>
                                                        <a className="header_text" onClick={() => this.GoToAdmin()} style={{ visibility: this.state.isAdminVisibile }} >Admin</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid_header">
                                            <div className="header_container greeting" style={{ width: '100%' }}>
                                                <div className="header_text grid_item_1" style={{ lineHeight: '40px', textAlign: 'right' }}>
                                                    <div style={{ height: '28px' }} className="overflow_header"> {this.state.userDetails.greeting + ', ' + this.state.userDetails.fullname}</div>
                                                    <div style={{ height: '30px' }} className="overflow_header">{this.state.userDetails.accountName}</div>
                                                    <div style={{ height: '28px' }} className="overflow_header">Group : {this.state.userDetails.accountCode}</div>
                                                </div>
                                                <div className="grid_item_2_reverse  h100">
                                                    <div style={{ width: '100%', 'height': '100%' }}>
                                                        <button id="btnMenu" onClick={() => this.MenuClick()} className="btn primary_btn header_btn twfont" style={{ lineHeight: '0.8', marginTop: '10px' }}  ><i className="material-icons" style={{ width: '100%' }} >menu</i><label>Options</label></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="header_menu_container" style={{ visibility: this.state.menuVisibility, position: 'absolute' }}>
                                    <div className="grid_item_1 max-height-menu">
                                        <button onClick={() => { this.MenuClick() }} style={{ width: '100%', height: '100%', backgroundColor: 'transparent', border: 'none' }}></button>
                                    </div>
                                    <div className="header_menu grid_item_2 max-height-menu customer_background_foreground">
                                        <hr />
                                        <div>
                                            <button id="canUpdateBtn" name="canUpdateBtn" className="btn  header_menu_item" onClick={() => { this.ToChangePassword() }}>Reset Password <i className="material-icons" style={{ position: 'relative', top: '6px', paddingLeft: '10px' }}>vpn_key</i></button>
                                        </div>
                                        <hr />
                                        <div>
                                            <button className="btn  header_menu_item" onClick={() => { this.GetCartState() }}>Logout <i className="material-icons" style={{ position: 'relative', top: '6px', paddingLeft: '10px' }}>exit_to_app</i></button>
                                        </div>
                                        <hr />
                                    </div>
                                </div>
                                <div className=" sidenav customer_background_foreground" style={{ height: '100%', width: '100%', minHeight: '1000px', textAlign: 'center', marginTop: '100px' }}>
                                    <div className="product_container" >
                                        {/*product new category start*/}
                                        <div className={ this.state.secondCategoryClass } >
                                            {/*product line start*/}
                                            <div style={{ display: this.state.secondCategoryVisible }} className="ProductLineList">
                                            <br />
                                            <div id="Filters" style={{ borderColor: '#2C3160', height: '100%', fontFamily: 'TW Cen MT' }}>
                                                <div style={{ height: '100%' }}>
                                                    <label style={{ fontFamily: 'TW Cen MT', fontSize: 'small', padding: '2' }}>Categories</label>
                                                    <div style={{ height: '100%', marginLeft: '2', paddingBottom: '10px' }}>
                                                        <br />
                                                        <div style={{ height: '100%', paddingLeft: '2px' }}>
                                                            {productlinesDropDown}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*product line end*/}

                                        <div style={{ display: this.state.displayBrands }} className="BrandList customer_base_background grid_item_1">
                                            <br />
                                            <div  id="Filters" style={{ borderColor: '#2C3160', height: '100%', fontFamily: 'TW Cen MT' }}>
                                                <div style={{ height: '100%' }}>
                                                    <label  style={{ fontFamily: 'TW Cen MT', fontSize: 'small', padding: '2px' }}>Sub Categories</label>
                                                    <div style={{ height: '100%', marginLeft: '2px', paddingBottom: '10px' }} className="customer_secondary_background">
                                                        <br />
                                                        <div style={{ height: '100%', paddingLeft: '2px' }}>
                                                            {brandsDropDown}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        {/*product new category end*/}

                                        <div id="productList" className="main_content_container customer_base_background grid_item_2"  >
                                            <br />

                                            <div className="search_grid">
                                                <div className="input-group mb-3 grid_item_1" >
                                                    <input type="text" id="txtMainSearch" style={{ fontFamily: 'TW Cen MT' }} className="form-control" onKeyPress={this.SearchKeyPress(this)} onChange={this.handleSearchBrand.bind(this)} />
                                                    <div className="input-group-prepend">
                                                        <button id="btnMainSearch" onClick={() => this.SearchProducts()} className="btn primary_btn" style={{ height: '38px' }} ><i className="material-icons">search</i></button>
                                                    </div>
                                                </div>
                                                <div className="grid_item_2">
                                                    <div className="input-group-prepend ">
                                                        <button id="btnAutoOrderForms" className="btn primary_btn" style={{ fontFamily: 'Tw Cen MT', height: '38px', width: '100%', margin: '0 2%', marginLeft: '4%' }} onClick={() => this.GetAutoForm()}>Auto Order Template<i className="material-icons" style={{ position: 'absolute', paddingLeft: '10px' }}>history</i></button>
                                                    </div>

                                                </div>
                                                <div className="grid_item_3">
                                                    <div className="input-group-prepend">
                                                        <button id="btnAutoOrderForms" className="btn primary_btn" style={{ fontFamily: 'Tw Cen MT', height: '38px', width: '100%', margin: '0 2%' }} onClick={() => this.toOrderForm()}>Order Template<i className="material-icons" style={{ position: 'absolute', paddingLeft: '10px' }}>description</i></button>
                                                    </div>

                                                </div>
                                            </div>
                                            <hr />
                                            <div style={{ backgroundColor: 'white', height: '100%', fontFamily: 'Tw Cen MT' }}>
                                                {products}
                                            </div >
                                        </div>
                                        <div id="shoppingCart" className="sidenav customer_background_foreground grid_item_3" style={{ width: '100%', border: 'none', marginTop: '5px' }}>
                                            <div id="divRight" className="cart_container customer_secondary_background " style={{ padding: '10px', textAlign: 'left' }}>
                                                <br />
                                                <div>
                                                    <table style={{ width: '100%' }}>
                                                        <tbody>
                                                            <tr><td><button id="btnLoad" name="btnLoad" onClick={() => this.AddOrderTemplateToCart()} className="btn btn-success shopping_cart_btn"
                                                                        style={{ visibility: this.state.loadToCartVisibility, height: this.state.loadToCartHeight }}>Load Template<i className="material-icons" style={{ position: 'relative', top: '6px', paddingLeft: '10px' }}>file_download</i>
                                                                    </button></td></tr>
                                                            <tr><td><button id="btnSave" onClick={() => this.Checkout()} className="btn tertiary_btn shopping_cart_btn"  >Finalise Order<i className="material-icons" style={{ position: 'relative', top: '6px', paddingLeft: '10px' }}>shopping_cart</i></button></td></tr>
                                                            <tr><td><button id="btnCancel" onClick={() => this.RemoveAllCartItems()} className="btn secondary_btn shopping_cart_btn">Clear<i className="material-icons" style={{ position: 'relative', top: '6px', paddingLeft: '10px' }}>block</i></button></td></tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <hr />
                                                <div style={{ paddingTop: '2px' }}>
                                                    <div>
                                                        {cartItems}
                                                    </div>
                                                </div >
                                                <div style={{ position: 'fixed', bottom: '0', padding: '10px', width: '17%' }}>
                                                    <button id="btnCheckout" onClick={() => this.Checkout()} className="btn tertiary_btn finalize_btn" style={{ fontFamily: 'Tw Cen MT', margin: '3% 0 3%', border: 'solid 1px' }} >Finalise Order<i className="material-icons" style={{ position: 'absolute', paddingLeft: '10px' }}>shopping_cart</i></button>
                                                </div>
                                            </div>
                                        </div >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );

            }
        }

    }
}

//<button id="filter_btn" className="customer_btn btn btn-outline-dark default_btn" style={{ width: '20%', 'font-family': 'Tw Cen MT' }} data-toggle="collapse" data-target="#Filters">Filters</button>

//    <button ID="GridButton" className="btn customer_background_foreground customer_btn" style={{ padding: '6px', height: '38px' }}>
//        <i className="material-icons">grid_on</i>
//    </button>

//    <button ID="ListButton" className="btn customer_background_foreground customer_btn" style={{ padding: '6px', height: '38px' }}>
//        <i className="material-icons">view_list</i>
//    </button >


//    <hr />

//<span>|</span>
//<a className="header_text" onClick={() => this.GoToBudget()} >Budgets</a>

//<div>
//    <button onClick={() => { this.MenuClick() }} style={{ width: '100%', height: '100%', backgroundColor:'transparent' }}></button>
//</div>

//status 

//    < td style = {{ textAlign: 'left' }}> { product.quantityOnHand }</td >