import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { Product } from './Products';
import { Route } from 'react-router';
import Login from './Login';
import { SessionTimedOut } from './SessionTimedOut';
import { OrderContent } from './OrderContent'

export class OrderProcessing extends Component {
    displayName = OrderProcessing.name

    constructor(props) {
        super(props);
        this.state = {
            toProducts: false,
            toLogIn: false,
            orderProcessingMessage: 'Processing...',
            toSessionTimedOut: false,
            toOrderContent: false,
            footer3Visbile: "hidden",
            footer4Visbile: "hidden"
        };

        this.TryPaymentAgainLaterEnabled();
        this.OpenPaymentPage();
        this.ValidatePopup();
        this.validatePayment();
    }

    async validatePayment() {
        var paymentStatus = 0;

        while (paymentStatus != 1) {
            const response = await fetch('api/Login/PaymentComplete?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(response => response.text());
            paymentStatus = response;

            if (paymentStatus == 1)
                this.ValidatePaymentComplete();
            else if (paymentStatus == 11)
                this.setState({ orderProcessingMessage: 'There has been a card failure during payments, please select try again to repeat the process.' });
            else if (paymentStatus == 12 || paymentStatus == 99)
                this.setState({ orderProcessingMessage: 'The transaction has been cancelled. Please click the "Try again" button to repeat the process or the "Place new Order" button to start a new order.' });

            console.log('Payment status = ' + paymentStatus);
        }
    }


    PlaceNewOrder() {
        fetch('/api/Paypal/ClearOrderDetails?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(() => {
                this.setState({ toProducts: true });
            });

    }

    TryPaymentAgainLaterEnabled() {
        fetch('/api/Paypal/TryAgainLaterEnabled?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == "true")
                    this.setState({ footer3Visbile: "hidden", footer4Visbile: "visible" });
                else
                    this.setState({ footer3Visbile: "visible", footer4Visbile: "hidden" });
            });
    }

    GoBackToProducts() {
        this.setState({ toProducts: true });
    }

    GoBackToLogin() {
        this.setState({ toLogIn: true });
    }

    GetOrderProcessingMessage() {
        fetch('/api/Paypal/GetOrderProcessingMessage?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                this.setState({ orderProcessingMessage: data }, () => { console.log('order message is : ' + data) });
            })

    }


    ValidatePaymentComplete() {
        fetch('/api/Paypal/ValidatePaymentComplete?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data.toLowerCase() == 'complete') {
                    fetch('/api/Paypal/ClearOrderDetails?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                        .then(() => {
                            this.SetOrderIdentifierSession();
                            this.setState({ toProducts: true });
                        });
                }
                else
                    alert(data);
            })

    }

    SetOrderIdentifierSession() {
        this.CheckSession();
        fetch('api/Paypal/SetOrderIdentifierSession?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(() => { console.log("Session set"); });
    }

    ValidatePopup() {

        fetch('/api/Paypal/GetPopupState?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'false') {
                    this.setState({ orderProcessingMessage: 'Popup for this site has not been set, please click the "Try Again" button and allow pop ups for this site.' });
                }
                else {
                    this.GetOrderProcessingMessage();
                }
            })

    }

    TryPaymentAgain() {
        fetch('/api/Paypal/isSessionValid?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {

                if (data == 'true') {
                    fetch('/api/Paypal/GetPortalLink?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                        .then(response => response.text())
                        .then(data => {
                            var popUp = window.open(data);
                            console.log('popup ->');
                            console.log(popUp);
                            if (popUp == null || typeof (popUp) == 'undefined') {
                                alert('Please disable your pop-up blocker or click enable pop-up, once complete click the "Place Order" button again.');
                            }
                        });
                }
                else {
                    alert('Payment has already been made');
                    this.GoBackToProducts();
                }
            });

        return false;
    }

    SetSession(data) {

        var popUp = window.open(data);

        if (popUp == null || typeof (popUp) == 'undefined') {
            fetch('/api/Paypal/SetPopupState?isallowed=false&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(() => {
                    this.ValidatePopup();
                });
            alert('Please disable your pop-up blocker or click enable pop-up, once complete click the "Place Order" button again.');
        }
        else {
            fetch('/api/Paypal/SetPopupState?isallowed=true&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(() => {
                    this.ValidatePopup();
                });
        }

    }

    CheckSession() {
        this.setState({ complete_update: false });
        console.log('get CheckSession called');

        fetch('api/Login/CheckSessionProductSearch?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });
                }
                else if (data == 'timedout') {
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }

    OpenPaymentPage() {
        fetch('/api/Paypal/GetPortalLink?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                this.SetSession(data);
            })
    }

    render() {
        if (this.state.toSessionTimedOut) {
            return <Route path='/' component={SessionTimedOut} />
        }

        if (this.state.toProducts) {
            return <Route path='/' component={Product} />
        }

        if (this.state.toOrderContent) {
            return <Route path='/' component={OrderContent} />
        }

        if (this.state.toLogIn) {
            return <Route path='/' component={Login} />
        }

        else {
            return (
                <div className="outer-container customer_background" >
                    <div className="header" style={{ position: 'fixed', zIndex : '500'}}>
                        <div className="grid_container_header" style={{ msHyphens: 'auto', display: 'inline-grid', gridTemplateColumns: '30% 40% 30%', display: 'grid -ms-grid', msGridColumns: '30% 40% 30%', columnCount: '3' }}>
                            <div className="grid_header">
                                <div className="icon_home" style={{ width: '100%', height: '50%', 'margin-top': '30px', 'margin-left': '10%', float: 'left', 'background-repeat': 'no-repeat' }}></div>
                            </div>
                            <div className="grid_header" style={{ 'text-align': 'left', overflow: 'hidden' }}>
                                <div className="header_container" style={{ width: '100%' }}>
                                    <div>
                                        <div style={{ display: 'inline' }}>
                                            <a className="header_text" onClick={() => this.GoBackToProducts()}>Go Back</a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="grid_header">
                                <div className="header_container" style={{ width: '100%' }}>
                                    <div>
                                        <div style={{ display: 'inline' }}>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="inner-container">
                        <div className="centered-content  twfont customer_background_foreground" style={{ width: '60%', 'box-shadow': '2px 2px 8px #888888', border: 'none', 'border-radius': '2%', padding: '2%' }}>
                            <hr />
                            <label style={{ fontSize: 'x-large', fontFamily: 'TW Cen MT' }}>{this.state.orderProcessingMessage}<br /> </label>
                            <br />
                            <br />
                            <hr />
                            <div className="footerButtons3" style={{ visibility: this.state.footer3Visbile, height: (this.state.footer3Visbile == 'visible' ? '21.6px' : '0px') }} >
                                <button ID="btnBack" className="btn primary_btn row1 col1" style={{ width: '90%', 'font-family': 'Tw Cen MT', 'margin-left': '5%' }} onClick={() => this.PlaceNewOrder()}>Place new order</button >
                                <button ID="btnSubmitPaypal" className="btn secondary_btn row1 col2" style={{ width: '90%', 'font-family': 'Tw Cen MT' }} onClick={() => { this.TryPaymentAgain() }}>Try Again</button>
                                <button ID="btnViewOrder" className="btn secondary_btn row1 col3" style={{ width: '90%', 'font-family': 'Tw Cen MT' }} onClick={() => { this.ValidatePaymentComplete() }}>View Order</button>
                            </div>
                            <div style={{ visibility: this.state.footer4Visbile}} >
                                <div className="footerButtons2">
                                    <button ID="btnBack" className="btn primary_btn row1 col1" style={{ width: '90%', 'font-family': 'Tw Cen MT', 'margin-left': '5%' }} onClick={() => this.PlaceNewOrder()}>Place new order</button >
                                    <button ID="btnBack" className="btn primary_btn row1 col1" style={{ width: '90%', 'font-family': 'Tw Cen MT', 'margin-left': '5%' }} onClick={() => this.GoBackToProducts()}>Try again later</button >
                                </div>
                                <div style={{height: '15px'}}></div>
                                <div className="footerButtons2">
                                    <button ID="btnSubmitPaypal" className="btn secondary_btn row1 col2" style={{ width: '90%', 'font-family': 'Tw Cen MT', 'margin-left': '5%' }} onClick={() => { this.TryPaymentAgain() }}>Try Again</button>
                                    <button ID="btnViewOrder" className="btn secondary_btn row1 col3" style={{ width: '90%', 'font-family': 'Tw Cen MT', 'margin-left': '5%' }} onClick={() => { this.ValidatePaymentComplete() }}>View Order</button>
                                </div>
                            </div>
                        </div >

                    </div >
                </div >
            );
        }
    }
}

//<i className="material-icons" style={{ paddingLeft: '10px' }}>thumb_up</i>