import React, { Component } from 'react';
import { Product } from './Products';
import { SavedCarts } from './SavedCarts';
import { Budget } from './Budget';
import { Route } from 'react-router';
import { OrderFormList } from './OrderFormList'
import { OrderSearch } from './OrderSearch'
import { ClearCartPrompt } from './ClearCartPrompt';

export class Manage extends Component {
    displayName = Manage.name

    constructor(props) {
        super(props);
        this.state = {
            toProducts: false,
            toSavedCarts: false,
            toBudgets: false,
            toOrderForms: false,
            toOrderSearch : false
        };
    }


    GoBackToProducts() {
        this.setState({ toProducts: true });
    }

    GoToBudget() {
        this.setState({ toBudgets: true });
    }

    GoToOrderForm() {
        this.setState({ toOrderForms: true });
    }

    GoToOrderSearch() {
        this.setState({ GoToOrderSearch: true });
    }


    GoToSavedCarts() {
        this.setState({ toSavedCarts: true });
    }

    render() {

        if (this.state.toProducts) {
            return <Route path='/' component={Product} />
        }

        if (this.state.GoToOrderSearch) {
            return <Route path='/' component={OrderSearch} />
        }

        if (this.state.toBudgets) {
            return <Route path='/' component={Budget} />
        }

        if (this.state.toSavedCarts) {
            return <Route path='/' component={SavedCarts} />
        }

        
        return (
            <div className="outer-container customer_background" >
                <div className="inner-container">
                    <div className="centered-content  twfont customer_background_foreground" style={{ width: '50%', 'box-shadow': '2px 2px 8px #888888', border: 'none', 'border-radius': '2%', padding: '2%' }}>
                        <div>
                            <label className="customer_background_foreground" style={{ fontFamily: 'TW Cen MT', 'font-size': 'xx-large', 'font-weight': 'bolder' }}>Manage</label>
                            <hr />
                        </div>
                        <div style={{ 'display': 'grid', 'grid-template-columns': '20% 80%', 'grid-row-gap': '10px', margin: '0 9.5%', width: '81%' }}>
                        </div >
                        <div>
                            <br />
                            <table className="table table-bordered" style={{ fontFamily: 'TW Cen MT', textAlign: 'center', overflow: 'scroll', whiteSpace: 'nowrap',  height: '300px', margin: '0 9.5%', width: '81%' }}>
                                <tr><td onClick={() => { this.GoToSavedCarts() }}>Saved Carts</td></tr>
                                <tr><td onClick={() => { this.GoToBudget() }} style={{ visibility: 'hidden' }}>Budgets</td></tr>
                                <tr><td onClick={() => { this.GoToOrderSearch() }}>Orders</td></tr>
                            </table>
                        </div >
                    </div >
                </div >
            </div >
        );

    }
}

