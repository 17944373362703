import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { Product } from './Products';
import { OrderFormList } from './OrderFormList';
import { Route } from 'react-router';
import { OrderForm } from './Orderform';
import { ClearCartPrompt } from './ClearCartPrompt';
import { SessionTimedOut } from './SessionTimedOut';
import { CreateOrderFormContent } from './CreateOrderFormContent';

export class CreateOrderForm extends Component {
    displayName = CreateOrderForm.name

    constructor(props) {
        super(props);
        this.state = {
            toProducts: false,
            name: '',
            AddProductsVisibility: 'hidden',
            ToOrderForm: false,
            OrderFormList: false,
            toSessionTimedOut: false,
        };

        this.CheckSessionInitialize(); 
    }


    CheckSessionInitialize() {
        this.setState({ complete_update: false });
        console.log('get CheckSession called');

        fetch('api/Login/CheckSession?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });
                }
                else if (data == 'timedout'){
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }

    CheckSession() {
        this.setState({ complete_update: false });
        console.log('get CheckSession called');

        fetch('api/Login/CheckSession?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });
                }
                else if (data == 'timedout'){
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }


    GoBackToProducts() {
        this.setState({ toProducts: true });
    }

    ToOrderForm() {
        this.setState({ ToOrderForm: true });
    }

    ToOrderFormList() {
        this.setState({ OrderFormList: true });
    }

    handleChangedName(e) {
        console.log('name' + e.target.value);
        let inputtext = e.target.value;
        this.setState({ name: inputtext });

        if (inputtext !== '')
            this.setState({ AddProductsVisibility: 'visible' });
        else {
            this.setState({ AddProductsVisibility: 'hidden' });
        }
    }


    CreateOrderForm() {

        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            console.log('saving order form name');
            let name = this.state.name;

            fetch('/api/Orderform/CreateOrderForm?name=' + name + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(() => { this.ToOrderForm(); console.log('order form created') });
        }
    }



    render() {
        if (this.state.toSessionTimedOut) {
            return <Route path='/' component={SessionTimedOut} />
        }

        if (this.state.ToOrderForm) {
            return <Route path='/' component={CreateOrderFormContent} />
        }

        if (this.state.OrderFormList) {
            return <Route path='/' component={OrderFormList} />
        }

        if (this.state.toProducts) {
            return <Route path='/' component={Product} />
        }
        else {
            return (
                <div className="outer-container customer_background">
                    <div className="header" style={{ position: 'fixed', zIndex : '500'}}>
                        <div className="grid_container_header" style={{ display: 'inline-grid', gridTemplateColumns: '30% 40% 30%', display: 'grid -ms-grid', msGridColumns: '30% 40% 30%', columnCount: '3' }}>
                            <div className="grid_header">
                                <div className="icon_home" style={{ width: '100%', height: '50%', 'margin-top': '30px', 'margin-left': '10%', float: 'left', 'background-repeat': 'no-repeat' }}></div>
                            </div>
                            <div className="grid_header" style={{ 'text-align': 'left' }}>
                                <div className="header_container" style={{ width: '100%' }}>
                                    <div>
                                        <div style={{ display: 'inline' }}>
                                            <a className="header_text"  onClick={() => this.GoBackToProducts()}>Return to order form List</a>|
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="grid_header">
                                <div className="header_container" style={{ width: '100%' }}>
                                    <div>
                                        <div style={{ display: 'inline' }}>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="inner-container">
                        <div className="centered-content  twfont customer_background_foreground" style={{ width: '50%', 'box-shadow': '2px 2px 8px #888888', border: 'none', 'border-radius': '2%', padding: '2%', marginTop: '100px' }}>
                            <div>
                                <label className="customer_background_foreground" style={{ fontFamily: 'TW Cen MT', 'font-size': 'xx-large', 'font-weight': 'bolder' }}>Create Order Form</label>
                                <hr />
                            </div>
                            <div>
                                <label ID="lblMessage" ></label><br />
                            </div>
                            <div className="divGridColumn" style={{ margin: '0 9.5%', width: '81%' }}>
                                <div className="row1 col1">
                                    <label ID="lblDel" style={{ fontFamily: 'TW Cen MT', float: 'left' }} className="table_font">Name : </label>
                                </div>
                                <div className="row1 col2">
                                    <input type="text" ID="txtName" className="form-control" onChange={this.handleChangedName.bind(this)} />
                                </div >

                            </div >
                            <hr />
                            <br />
                            <div className="footerButtons" style={{ margin: '0 9.5%', width: '81%' }}>
                                <button ID="btnSubmit" className="btn primary_btn row1 col1" style={{ width: '90%', 'font-family': 'Tw Cen MT', visibility: this.state.AddProductsVisibility }} onClick={() => this.CreateOrderForm()}>Add Products</button>
                                <button ID="btnBack" className="btn primary_btn row1 col2" Width="90%" style={{ width: '90%', 'font-family': 'Tw Cen MT', marginTop: '3px'  }} onClick={() => this.ToOrderFormList()}>Back</button >
                            </div >

                        </div>
                    </div >
                </div>
            );
        }
    }
}

