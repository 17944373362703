import React, { ReactPropTypes, Component } from 'react';
import { Route } from 'react-router';
import { withRouter, Redirect } from 'react-router-dom';

export class ForgotPassword extends Component {
    displayName = ForgotPassword.name

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            stylesheet: '',
            completeLoad: false,
            userName: "",
            password: "",
            valid: false,
            loading: false,
            emailSentVisibility: 'hidden',
            tooManyAttemptsCountVisibility: 'hidden',
            passwordMessage: '',
            passwordResendCount: 1,
            toProductsPage: false,
            enterKeyPressed: false,
            toRegister: false,
            complete_update: false,
            browserIsIE: 'start',
            savePassword: false,
            inputBorder: ''
        };


    }

    SendMail() {

        if (this.state.userName == '') {
            var loginid = document.getElementById('user');
            loginid.style.borderColor = "red";
            loginid.style.borderWidth = "medium";
        }
        else {

            var loginid = document.getElementById('user');
            loginid.style.borderColor = "limegreen";
            loginid.style.borderWidth = "medium";

            fetch('api/Login/forgotPassword?userName=' + this.state.userName + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(data => {
                    console.log('email sent to : ' + this.state.userName)
                    this.setState({ emailSentVisibility: 'visible' });
                })

            this.setState({ emailSentVisibility: 'visible' });
        }
    }

    BackToLogin() {
        window.location.href = '\\';
    }

    handleUsernameChange(e) {
        this.setState({ userName: e.target.value })
    }

    //sendEmail() {

    //    var user = document.getElementById('user');
    //    console.log('"' + this.state.userName + '"');
    //    var username = this.state.userName;

    //    if (username == '') {
    //        user.style.borderColor = "red";

    //        fetch('api/Login/SetPasswordState?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
    //            .then(response => response.text())
    //            .then(data => {
    //                alert('Please fill in your login ID field to send password to the users registered email, if you do not remember your login ID please contact support');
    //            });


    //    }
    //    else {
    //        let emailsentcount = this.state.passwordResendCount + 1;

    //        fetch('api/Login/forgotPassword?userName=' + this.state.userName + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
    //            .then(data => {
    //                console.log('email sent to : ' + this.state.userName)
    //                this.setState({ passwordMessage: 'Email sent. resend?', passwordResendCount: emailsentcount, emailSentVisibility: 'visible' });
    //            })

    //        this.setState({ emailSentVisibility: 'visible' });
    //    }
    //}

    //handleUsernameChange(e) {
    //    this.setState({ userName: e.target.value })
    //}


    render() {

        if (this.state.loading) {
            return (
                <div className="outer-container customer_background" style={{ fontFamily: 'TW Cent', backgroundColor: 'white' }}>
                    <div className="inner-container">
                        <div className="centered-content  twfont customer_background_foreground" style={{ boxShadow: 'none', border: 'none' }}>
                        <div className="spinner-border" style={{ color: 'rgb(8, 15, 83)', height: '3rem', width: '3rem', borderWidth: '0.5em' }} role="status">
                            <span className="sr-only" >Loading...</span>
                        </div>
                    </div>
                </div>
            </div>);
        }
        else {
            return (
                <div className="outer-container customer_background" style={{ fontFamily: 'TW Cent' }} >
                    <div className="header" style={{ position: 'fixed', zIndex : '500'}}>

                        <div className="grid_container_header" style={{ display: 'inline-grid', gridTemplateColumns: '30% 40% 30%', display: 'grid -ms-grid', msGridColumns: '30% 40% 30%', columnCount: '3' }}>
                            <div className="grid_header">
                                <div className="icon_home" style={{ width: '100%', height: '50%', marginTop: '30px', marginLeft: '10%', float: 'left', backgroundRepeat: 'no-repeat' }}></div>
                            </div>
                            <div className="grid_header" style={{ textAlign: 'left' }}>

                            </div>
                            <div className="grid_header">
                                <div className="header_container" style={{ width: '100%', paddingRight: '15%' }}>
                                    <div className="input-group mb-3" style={{ marginTop: '9%', marginRight: '2%' }}>
                                        <div className="input-group-prepend">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="inner-container">
                        <div className="centered-content twfont  customer_background_foreground"
                            style={{ boxShadow: '2px 2px 8px #888888', border: 'none', width: '90%', maxWidth: '450px', backgroundColor: 'rgba(255,255,255,0.3)', fontFamily: 'TW Cen MT' }}>
                            <table className="customer_background_foreground" style={{ width: '100%', margin: '0 auto', verticalAlign: 'middle', height: '200px', backgroundColor: 'rgba(255,255,255,0.3)' }}>
                                <tbody>

                                    <tr>
                                        <td colSpan="2"><label className="twfont">Enter your login id and click the send email button. An email containing your password will be sent to your registered email address</label></td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" style={{ width: '100%' }}>
                                            <input id="user" name="user" type="text" style={{  }} value={this.state.userName} onChange={this.handleUsernameChange.bind(this)} className="form-control twfont" placeholder="Please enter your login Id" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2"><a style={{ color: 'green', visibility: this.state.emailSentVisibility, fontWeight: 'bold' }}>Email will be sent shortly</a></td>
                                    </tr>
                                    <tr><td><br /></td></tr>
                                    <tr>
                                        <td colSpan="2" style={{ width: '100%' }}>
                                            <button id="butLogin" className="btn primary_btn twfont" type="button" onClick={() => { this.SendMail() }} style={{ width: '100%' }}>Send Email</button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" style={{ width: '100%' }}>
                                            <button id="butLogin" className="btn primary_btn twfont" type="button" onClick={() => { this.BackToLogin() }} style={{ width: '100%' }}>Login</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table >
                        </div>
                    </div>
                </div>
            );
        }


    }
}


    //< td style = {{ width: '50%' }}> <a style={{ color: '#007bff', cursor: 'pointer' }} onClick={() => this.ToRegister()}>Register</a></td >
