import React, { Component } from 'react';  import { withRouter, Redirect } from 'react-router-dom';
import { Product } from './Products';
import { Route } from 'react-router';

export class SaveCart extends Component {
    displayName = SaveCart.name

    constructor(props) {
        super(props);
        this.state = {
            toProducts: false,
            cartList: [],
            loadingCart: false,
            listName: '',
            savedCartNotification : 'hidden'
        };

        this.GetCartList();
    }


    GoBackToProducts() {
        this.setState({ toProducts: true });
    }


    handleChangedName(e) {
        console.log('cart name' + e.target.value);
        let inputtext = e.target.value;
        this.setState({ listName: inputtext });
    }

    GetCartList() {

        this.setState({ loadingCart: true });
        console.log('loading saved cart list');

        fetch('/api/SaveCart/GetCart', { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.json())
            .then(data => {
                this.setState({ cartList: data },
                    () => {
                        this.setState({ loadingCart: false });
                        console.log('cart list :');
                        console.log(this.state.cartList);
                    })
            })
    }

    SaveCart() {
        console.log('saving cart');

        fetch('/api/SaveCart/SaveCartItems?cartName=' + this.state.listName, { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(() => {
                console.log('cart saved');
                this.setState({ savedCartNotification: 'visible' });
            });
    }

    LoadCartTable() {

        if (this.state.loadingCart) {
            return (
                <tr>
                    <td>loading...</td>
                    <td>loading...</td>
                    <td>loading...</td>
                    <td>loading...</td>
                    <td>loading...</td>
                    <td>loading...</td>
                </tr>
            );

        }
        else {
            return (
                this.state.cartList.map(cartItem =>
                    <tr>
                        <td className="sideBorder" style={{ lineHeight: '1px' }}>{cartItem.productID}</td>
                        <td className="sideBorder" style={{ lineHeight: '1px' }}>{cartItem.itemDescription}</td>
                        <td className="sideBorder" style={{ lineHeight: '1px' }}>{cartItem.qty}</td>
                        <td className="sideBorder" style={{ lineHeight: '1px' }}>R {cartItem.price}</td>
                        <td className="sideBorder" style={{ lineHeight: '1px' }}>R {cartItem.extendedPrice}</td>
                    </tr>
                ));
        }

    }

    render() {

        if (this.state.toProducts) {
            return <Route path='/' component={Product} />
        }
        else {
            let cartItems = this.LoadCartTable();

            return (
                <div className="outer-container customer_background" >
                    <div className="inner-container">
                        <div className="centered-content twfont  customer_background_foreground" style={{ width: '50%', 'box-shadow': '2px 2px 8px #888888', border: 'none', 'border-radius': '2%', padding: '2%' }}>
                            <div>
                                <label className="customer_background_foreground" style={{ fontFamily: 'TW Cen MT', 'font-size': 'xx-large', 'font-weight': 'bolder' }}>Save Cart</label>
                                <hr />
                            </div>
                            <div>
                                <label ID="lblMessage" ></label><br />
                            </div>
                            <div style={{ 'display': 'grid', 'grid-template-columns': '20% 80%', 'grid-row-gap': '10px', margin: '0 9.5%', width: '81%' }}>
                                <div>
                                    <label ID="lblDel" style={{ fontFamily: 'TW Cen MT', float: 'left' }} className="table_font">Cart Name : </label>
                                </div>
                                <div>
                                    <input type="text" ID="txtName" className="form-control" onChange={this.handleChangedName.bind(this)} />
                                </div >
                            </div >
                            <div>
                                <br />
                                <table className="table table-bordered" style={{ fontFamily: 'TW Cen MT', display: 'block', overflow: 'scroll', whiteSpace: 'nowrap',  height: '300px', margin: '0 9.5%', width: '81%' }}>
                                    <thead className="thead customer_background_foreground_reverse">
                                        <th style={{ textAlign: 'center', lineHeight: '1px' }}>Product Code</th>
                                        <th style={{ textAlign: 'center', lineHeight: '1px' }}>Product</th>
                                        <th style={{ textAlign: 'center', lineHeight: '1px' }}>Quantity</th>
                                        <th style={{ textAlign: 'center', lineHeight: '1px' }}>Unit Price</th>
                                        <th style={{ textAlign: 'center', lineHeight: '1px' }}>Extended Price</th>
                                    </thead>
                                    {cartItems}
                                </table>
                                <br />
                                <label style={{ color: 'green', fontFamily: 'TW Cen MT', visibility: this.state.savedCartNotification }}>Cart Saved</label>
                                <br /> 
                                <div style={{ width: '100%', display: 'grid', 'grid-template-columns': '50% 50%', margin: '0 9.5%', width: '81%' }}>
                                    <button ID="btnSubmit" className="btn btn-dark" style={{ width: '90%', 'font-family': 'Tw Cen MT' }} onClick={() => this.SaveCart()}>Save Cart</button>
                                    <button ID="btnBack" className="btn btn-dark" Width="90%" style={{ width: '90%', 'font-family': 'Tw Cen MT', 'margin-left': '5%' }} onClick={() => this.GoBackToProducts()}> Return To Order</button >
                                </div >
                            </div >
                        </div >
                    </div >
                </div >
            );
        }
    }
}

