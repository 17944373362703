import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { Product } from './Products';
import { CheckOut } from './CheckOut';
import { Route } from 'react-router';
import Login from './Login';
import { SessionTimedOut } from './SessionTimedOut';
import { OrderContent } from './OrderContent'

export class PaymentScreen extends Component {
    displayName = PaymentScreen.name

    constructor(props) {
        super(props);
        this.state = {
            toProducts: false,
            toLogIn: false,
            orderProcessingMessage: 'Processing...',
            toSessionTimedOut: false,
            toOrderContent: false,
            footer3Visbile: "hidden",
            footer4Visbile: "hidden",
            paymentlink: '',
            reloadPage: false,
            toCheckOut: false
        };

        //this.TryPaymentAgainLaterEnabled();
        this.OpenPaymentPage();
        //this.ValidatePopup();
        this.validatePayment();
    }

    async validatePayment() {
        var paymentStatus = 0;

        while (paymentStatus != 1) {
            const response = await fetch('api/Login/PaymentComplete?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(response => response.text());
            paymentStatus = response;

            if (paymentStatus == 1)
                this.ValidatePaymentComplete();
            else if (paymentStatus == 11)
                this.setState({ orderProcessingMessage: 'There has been a card failure during payments, please select try again to repeat the process.' });
            else if (paymentStatus == 12 || paymentStatus == 99)
                this.setState({ orderProcessingMessage: 'The transaction has been cancelled. Please click the "Try again" button to repeat the process or the "Place new Order" button to start a new order.' });

            console.log('Payment status = ' + paymentStatus);
        }
    }

    BackToCheckout() {
        this.setState({ toCheckOut: true });
    }

    PlaceNewOrder() {
        fetch('/api/Paypal/ClearOrderDetails?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(() => {
                this.setState({ toProducts: true });
            });

    }

    TryPaymentAgainLaterEnabled() {
        fetch('/api/Paypal/TryAgainLaterEnabled?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == "true")
                    this.setState({ footer3Visbile: "hidden", footer4Visbile: "visible" });
                else
                    this.setState({ footer3Visbile: "visible", footer4Visbile: "hidden" });
            });
    }

    GoBackToProducts() {
        this.setState({ toProducts: true });
    }

    GoBackToLogin() {
        this.setState({ toLogIn: true });
    }

    GetOrderProcessingMessage() {
        fetch('/api/Paypal/GetOrderProcessingMessage?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                this.setState({ orderProcessingMessage: data }, () => { console.log('order message is : ' + data) });
            })

    }

    ValidatePaymentComplete() {
        fetch('/api/Paypal/ValidatePaymentComplete?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data.toLowerCase() == 'complete') {
                    fetch('/api/Paypal/ClearOrderDetails?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                        .then(() => {
                            this.SetOrderIdentifierSession();
                            alert('Payment Complete, thank you!');
                            this.setState({ toProducts: true });
                        });
                }
                else
                    alert(data);
            })

    }

    SetOrderIdentifierSession() {
        this.CheckSession();
        fetch('api/Paypal/SetOrderIdentifierSession?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(() => { console.log("Session set"); });
    }

    ValidatePopup() {

        fetch('/api/Paypal/GetPopupState?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'false') {
                    this.setState({ orderProcessingMessage: 'Popup for this site has not been set, please click the "Try Again" button and allow pop ups for this site.' });
                }
                else {
                    this.GetOrderProcessingMessage();
                }
            })

    }

    TryPaymentAgain() {

        this.setState({ reloadPage: true });

    }

    SetSession(data) {

        var popUp = window.open(data);

        if (popUp == null || typeof (popUp) == 'undefined') {
            fetch('/api/Paypal/SetPopupState?isallowed=false&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(() => {
                    this.ValidatePopup();
                });
            alert('Please disable your pop-up blocker or click enable pop-up, once complete click the "Place Order" button again.');
        }
        else {
            fetch('/api/Paypal/SetPopupState?isallowed=true&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(() => {
                    this.ValidatePopup();
                });
        }

    }

    CheckSession() {
        this.setState({ complete_update: false });
        console.log('get CheckSession called');

        fetch('api/Login/CheckSessionProductSearch?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });
                }
                else if (data == 'timedout') {
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }

    OpenPaymentPage() {
        fetch('/api/Paypal/GetPortalLink?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                this.setState({ paymentlink: data });
            })
    }

    render() {
        if (this.state.toSessionTimedOut) {
            return <Route path='/' component={SessionTimedOut} />
        }

        if (this.state.toProducts) {
            return <Route path='/' component={Product} />
        }

        if (this.state.toOrderContent) {
            return <Route path='/' component={OrderContent} />
        }

        if (this.state.toLogIn) {
            return <Route path='/' component={Login} />
        }

        if (this.state.reloadPage) {
            return <Route path='/' component={PaymentScreen} />
        }

        if (this.state.toCheckOut) {
            return <Route path='/' component={CheckOut} />
        }

        else {
            return (
                <div className="outer-container customer_background" >
                    <div className="header" style={{ position: 'fixed', zIndex : '500'}}>
                        <div className="grid_container_header" style={{ msHyphens: 'auto', display: 'inline-grid', gridTemplateColumns: '30% 40% 30%', display: 'grid -ms-grid', msGridColumns: '30% 40% 30%', columnCount: '3' }}>
                            <div className="grid_header">
                                <div className="icon_home" style={{ width: '100%', height: '50%', 'margin-top': '30px', 'margin-left': '10%', float: 'left', 'background-repeat': 'no-repeat' }}></div>
                            </div>
                            <div className="grid_header" style={{ 'text-align': 'left', overflow: 'hidden' }}>
                                <div className="header_container" style={{ width: '100%' }}>
                                    <div>
                                        <div style={{ display: 'inline' }}>
                                            <a className="header_text" onClick={() => this.GoBackToProducts()}>Go Back</a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="grid_header">
                                <div className="header_container" style={{ width: '100%' }}>
                                    <div>
                                        <div style={{ display: 'inline' }}>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" sidenav customer_background_foreground" style={{ width: '100%', minHeight: '1000px', position: 'fixed'}}>
                        <div style={{ width: '100%', height: '100%', textAlign: 'center', position: 'fixed' }}>
                            <br />
                            <iframe src={this.state.paymentlink} style={{ height: '60%', width: '90%', marginLeft : '5%', marginRight: '5%', border: 'none' }}></iframe>
                            <div style={{ color: 'red', fontWeight: 'bold', fontFamily: 'TW Cen MT'}}>Please scroll down on the items if you dont see the 'Pay Now' button.<div></div></div>
                            <hr />
                            <br />
                            <div className="footerButtons3" style={{ height: '21.6px', width: '90%', marginLeft: '5%' }} >
                                <button ID="btnViewOrder" className="btn secondary_btn row1 col3" style={{ width: '90%', 'font-family': 'Tw Cen MT' }} onClick={() => { this.PlaceNewOrder() }}>Place New Order</button>
                                <button ID="btnSubmitPaypal" className="btn secondary_btn row1 col2" style={{ width: '90%', 'font-family': 'Tw Cen MT' }} onClick={() => { this.TryPaymentAgain() }}>Try Again</button>
                                <button ID="btnBack" className="btn primary_btn row1 col1" style={{ width: '90%', 'font-family': 'Tw Cen MT', 'margin-left': '5%' }} onClick={() => this.BackToCheckout()}>Back</button >
                            </div>
                        </div >

                    </div >
                </div >
            );
        }
    }
}

//<i className="material-icons" style={{ paddingLeft: '10px' }}>thumb_up</i>