import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { Route } from 'react-router';
import App from '../App';

import { OrderSearch } from './OrderSearch'
import { Product } from './Products';
import { Login } from './Login';
import { CheckOut } from './CheckOut';
import { SavedCarts } from './SavedCarts';
import { InvoiceOrderSearch } from './InvoiceOrderSearch';
import { Budget } from './Budget'
import { AdminUser } from './AdminUser'
import { Users } from './Users';
import { ClearCartPrompt } from './ClearCartPrompt';
import { OrderApproval } from './OrderApproval';
import { SessionTimedOut } from './SessionTimedOut';
import { DocumentsManage } from './DocumentsManage';
import { AdminManage } from './AdminManage';

export class BudgetReportTotal extends Component {
    displayName = BudgetReportTotal.name

    constructor(props) {
        super(props);

        this.state = {
            budgetList: [],
            loadingReport: false,
            budgetHeader: {},
            loadingHeader: false,
            startDate: '',
            endDate: '',
            stylesheet: '',
            ToList: false,
            toSessionTimedOut: false,

            ToOrderDocument: false,
            toCheckOut: false,
            toSaveCart: false,
            toManage: false,
            toDocuments: false,
            toOrderForm: false,
            toAdmin: false,
            toLogIn: false,
            toBudgets: false,
            toOrderSearch: false,
            toOrderApproval: false,
            toClearCartPrompt: false,
            toSavedCarts: false,
            isAdminVisibile: 'hidden',
            ApproveVisibility: 'hidden',
            toProducts: false,
            userDetails: {},
            canTrack: 'hidden',
            toDocumentsManage: false,
            IsNotAdminBudgetMessage : ''
        };

        this.CheckSessionInitialize();

    }

    CheckSessionInitialize() {
        this.setState({ complete_update: false });
        console.log('get CheckSession called');

        fetch('api/Login/CheckSession?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });
                    this.GetUserDetails();
                    this.CheckIfAdmin();
                    this.GetIsNotAdminBudgetMessage();
                    this.CheckIfCanTrack();
                    this.GetBudget();
                    this.GetBudgetReport();
                }
                else if (data == 'timedout') {
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }

    CheckSession() {
        this.setState({ complete_update: false });
        console.log('get CheckSession called');

        fetch('api/Login/CheckSession?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });
                }
                else if (data == 'timedout') {
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }


    ToBudgetList() {
        this.setState({ ToList: true });
    }

    GetBudget() {
        this.setState({ loadingReport: true });
        console.log('loading budget');

        fetch('/api/Budget/GetBudget' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.json())
            .then(data => { this.setState({ budgetHeader: data, loadingHeader: false, startDate: data.fromDate, endDate: data.toDate }, console.log(data)) })
            .then(() => {
                console.log("budget");
                console.log(this.state.budgetHeader);
            })
    }

    GetBudgetReport() {
        this.setState({ loadingReport: true });

        console.log('loading budget report');

        fetch('/api/Budget/GetBudgetReport' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.json())
            .then(data => { this.setState({ budgetList: data, loadingReport: false }) });
    }

    GetIsNotAdminBudgetMessage() {
        fetch('/api/Budget/GetIsNotAdminBudgetMessage' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => this.setState({ IsNotAdminBudgetMessage : data }) );
    }

    LoadReport() {
        let budget = this.state.budgetHeader;

        if (budget.amountSpentWithVat == 0) {
            return (<tr><td colSpan="9">No data available yet</td></tr>)
        }
        else {
            if (this.state.loadingReport) {
                return (
                    <tr>
                        <td>loading...</td>
                        <td>loading...</td>
                        <td>loading...</td>
                        <td>loading...</td>
                    </tr>
                );

            }
            else {
                return (
                    this.state.budgetList.map(reportItem =>
                        <tr>
                            <td>{reportItem.category}</td>
                            <td>{reportItem.categoryDescription}</td>
                            <td>{reportItem.orderedBy}</td>
                            <td></td>
                            <td style={{ textAlign: 'end' }}>R {Number(reportItem.total).toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                            <td></td>
                            <td style={{ textAlign: 'end' }}>R {Number(reportItem.vat).toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                            <td></td>
                            <td style={{ textAlign: 'end' }}>R {Number(reportItem.totalWithVAT).toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        </tr>
                    )
                );
            }
        }

    }

    GetCartState() {

        fetch('/api/Product/GetCartCount?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == "valued") {
                    this.setState({ toClearCartPrompt: true });
                } else {
                    window.location.href = '\\';
                }
            });
    }

    LoadHeader() {


        if (this.state.loadingHeader) {
            return (
                <div>
                    <div style={{ backgroundColor: 'white', height: '100px', display: 'inline-grid', gridTemplateColumns: '20% 80%', width: '100%' }}>
                        <div style={{ wordBreak: 'break-all' }}>
                            Loading
                        </div>
                        <div>
                        </div>
                    </div>
                    <div style={{ backgroundColor: 'white', height: '12%', width: '100%' }}>
                        <table style={{ width: '100%' }}>
                            <tr>
                                <td>Name: Loading...</td>
                                <td>Account :  Loading...</td>
                                <td>Budget : R Loading...</td>
                            </tr>
                            <tr>
                                <td>Start Date : Loading...</td>
                                <td>End Date : Loading...</td>
                                <td>Total Spent: Loading...</td></tr>
                        </table>
                    </div>
                </div>);
        }
        else {
            let budget = this.state.budgetHeader;
            let startDate = this.state.startDate;
            let endDate = this.state.endDate;
            let budgetColor = 'green';
            let budgetPercentage = (budget.amountSpentWithVat / budget.amount) * 100;
            if (budgetPercentage > 100)
                budgetColor = 'red';
            else if (budgetPercentage < 100)
                budgetColor = 'green';
            else if (budgetPercentage == 100)
                budgetColor = 'orange';

            return (

                <div>
                    <div style={{ backgroundColor: 'white', width: '100%' }}>
                        <table className="report-tbl" style={{ width: '50%' }}>
                            <tr>
                                <td className="label">Name</td>
                                <td>{budget.name}</td>
                            </tr>
                            <tr>
                                <td className="label">Account</td>
                                <td>{budget.account}</td>
                            </tr>
                            <tr>
                                <td className="label">Budget: </td>
                                <td>R {Number(budget.amount).toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                            </tr>
                            <tr>
                                <td className="label">Timespan:</td>
                                <td>{startDate} :: {endDate}</td>
                            </tr>
                            <tr>
                                <td className="label">Percentage Used: * </td>
                                <td style={{ color: budgetColor }}>{Number(budgetPercentage).toFixed(2)} %</td>
                            </tr>
                            <tr>
                                <td className="label">Total spent (Excluding VAT): </td>
                                <td>R {Number(budget.amountSpent).toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                            </tr>
                            <tr>
                                <td className="label">VAT : </td>
                                <td>R {Number(budget.vat).toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                            </tr>
                            <tr>
                                <td className="label">Total spent (Including VAT): </td>
                                <td>R {Number(budget.amountSpentWithVat).toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                            </tr>

                        </table>
                    </div>
                </div >
            );
        }
    }

    render() {
        if (this.state.toSessionTimedOut) {
            return <Route path='/' component={SessionTimedOut} />
        }

        if (this.state.toAdmin) {
            return <Route path='/' component={AdminManage} />
        }


        if (this.state.GoToOrderSearch) {
            return <Route path='/' component={OrderSearch} />
        }

        if (this.state.toBudgets) {
            return <Route path='/' component={Budget} />
        }

        if (this.state.toSavedCarts) {
            return <Route path='/' component={SavedCarts} />
        }

        if (this.state.toCheckOut) {
            return <Route path='/' component={CheckOut} />
        }

        if (this.state.toDocuments) {
            return <Route path='/' component={InvoiceOrderSearch} />
        }

        if (this.state.toDocumentsManage) {
            return <Route path='/' component={DocumentsManage} />
        }

        if (this.state.toLogIn) {
            return <Route path='/' component={Login} />
        }

        if (this.state.toProducts) {
            return <Route path='/' component={Product} />
        }


        if (this.state.toOrderApproval) {
            return <Route path='/' component={OrderApproval} />
        }

        if (this.state.toClearCartPrompt) {
            return <Route path='/' component={ClearCartPrompt} />
        }

        if (this.state.ToList) {
            return <Route path='/' component={Budget} />
        }

        let report = this.LoadReport();
        let budget = this.LoadHeader();

        return (
            <div style={{ width: "100%", height: "100%", color: 'black' }}>
                <div className="header" style={{ position: 'fixed', zIndex: '500' }}>
                    <div className="grid_container_header" style={{ msHyphens: 'auto', display: 'inline-grid', gridTemplateColumns: '30% 40% 30%', display: 'grid -ms-grid', msGridColumns: '30% 40% 30%', columnCount: '3' }}>
                        <div className="grid_header">
                            <div className="icon_home" style={{ width: '100%', height: '50%', 'margin-top': '30px', 'margin-left': '10%', float: 'left', 'background-repeat': 'no-repeat' }}></div>
                        </div>
                        <div className="grid_header" style={{ 'text-align': 'left', overflow: 'hidden' }}>
                            <div className="header_container" style={{ width: '100%' }}>
                                <div>

                                    <div style={{ display: 'inline' }}>
                                        <a className="header_text" onClick={() => this.GoBackToProducts()}  >Place Order</a>|
                                        <a className="header_text" onClick={() => this.GoToDocumentsManage()} >Documents</a><span style={{ visibility: this.state.canTrack }}>|</span>
                                        <a className="header_text" onClick={() => this.GoToOrderApproval()} style={{ visibility: this.state.canTrack }} >Order Approval</a><span style={{ visibility: this.state.isAdminVisibile }}>|</span>
                                        <a className="header_text" onClick={() => this.GoToBudget()}>Budgets</a>
                                        <span style={{ visibility: this.state.isAdminVisibile }}>|</span>
                                        <a className="header_text" onClick={() => this.GoToAdmin()} style={{ visibility: this.state.isAdminVisibile }} >Admin</a>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="grid_header">
                            <div className="header_container greeting" style={{ width: '100%' }}>
                                <div className="header_text grid_item_1" style={{ lineHeight: '40px', textAlign: 'right' }}>
                                    <div style={{ height: '28px' }} className="overflow_header"> {this.state.userDetails.greeting + ', ' + this.state.userDetails.fullname}</div>
                                    <div style={{ height: '25px' }} className="overflow_header">{this.state.userDetails.accountName}</div>
                                    <div style={{ height: '28px' }} className="overflow_header">Group : {this.state.userDetails.accountCode}</div>
                                </div>
                                <div className="grid_item_2"><a className="header_text" onClick={() => { this.GetCartState() }} id="A1">LOG OUT</a></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="CssPageContatiner" style={{ marginTop: '100px' }}>
                    <div>
                        <button ID="btnBack" className="btn secondary_btn" style={{ width: '20%', 'font-family': 'Tw Cen MT' }} onClick={() => this.GoToBudget()}>Back to Budgets</button >
                        <br />
                        <br />
                    </div>
                    <div id="Page" className="CssPage">
                        <div className="pageContainer" style={{ display: 'inline-grid', gridTemplateColumns: '100%', padding: '30px', fontFamily: 'Tw Cen MT' }}>
                            <div className="pageContainer" style={{ backgroundColor: 'white', paddingRight: '30px', height: '100%' }}>
                                <div style={{ borderBottom: 'solid', marginBottom: '0' }}>
                                    <label style={{ fontSize: 'xx-large', fontWeight: 'bolder', paddingTop: '15px', marginBottom: '0' }}>Budget Details</label>
                                </div>
                                <div style={{ height: '87%', width: '100%', backgroundColor: 'white', marginTop: '20px' }}>
                                    <div style={{ backgroundColor: 'white', height: '1%' }}>
                                        <hr />
                                    </div>
                                    {budget}
                                    <div style={{ backgroundColor: 'white', height: '1%' }}>
                                        <br />
                                        <br />
                                        <br />
                                        <label style={{ fontSize: 'large', color: 'darkorange', fontStyle: 'italic' }}>{this.state.IsNotAdminBudgetMessage}</label>
                                        <table style={{ width: '100%' }}>
                                            <tr><td colSpan="9"><hr /></td></tr>
                                            <tr>
                                                <th>Category</th>
                                                <th>Description</th>
                                                <th>Ordered By</th>
                                                <th></th>
                                                <th style={{ textAlign: 'end' }}>Total</th>
                                                <th></th>
                                                <th style={{ textAlign: 'end' }}>VAT</th>
                                                <th></th>
                                                <th style={{ textAlign: 'end' }}>Total With VAT</th>
                                            </tr>
                                            <tr><td colSpan="9"><hr /></td></tr>
                                            {report}
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    CheckIfAdmin() {
        fetch('/api/Login/isAdmin' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => { this.setState({ isAdminVisibile: (data == 'Y' ? 'visible' : 'hidden') }, () => { console.log('visibility : data=' + data + ' value= ' + this.state.isAdminVisibile); }) })
    }

    GoToOrderSearch() {
        this.setState({ GoToOrderSearch: true });
    }


    GoToOrderApproval() {
        this.setState({ toOrderApproval: true });
    }

    GoToDocuments() {
        this.setState({ toDocuments: true });
    }

    toOrderForm() {
        this.setState({ toOrderForm: true });
    }

    GoToLogIn() {
        this.setState({ toLogIn: true });
    }

    GoToAdmin() {
        this.setState({ toAdmin: true });
    }

    GoBackToProducts() {
        this.setState({ toProducts: true });
    }

    GoToBudget() {
        this.setState({ toBudgets: true });
    }

    GoToOrderForm() {
        this.setState({ toOrderForms: true });
    }

    GoToOrderSearch() {
        this.setState({ GoToOrderSearch: true });
    }


    GoToSavedCarts() {
        this.setState({ toSavedCarts: true });
    }

    GoToDocumentsManage() {
        this.setState({ toDocumentsManage: true });
    }

    GetUserDetails() {
        fetch('api/Login/GetUserDetails' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.json())
            .then(data => { this.setState({ userDetails: data }) })
            .then(() => {
                console.log("user details");
                console.log(this.state.userDetails);
            })
    }



    CheckIfCanTrack() {
        fetch('/api/Login/canTrack' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => { this.setState({ canTrack: (data == 'Y' ? 'visible' : 'hidden') }, () => { console.log('visibility : data=' + data + ' value= ' + this.state.isAdminVisibile); }) })
    }
}
