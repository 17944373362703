import React, { Component } from 'react';
import { Route } from 'react-router';
import { withRouter, Redirect } from 'react-router-dom';
import { ChangePassword } from './ChangePassword';

import { OrderSearch } from './OrderSearch'
import { CreditContent } from './CreditContent';
import { Product } from './Products';
import { Login } from './Login';
import { CheckOut } from './CheckOut';
import { SavedCarts } from './SavedCarts';
import { InvoiceOrderSearch } from './InvoiceOrderSearch';
import { Budget } from './Budget'
import { AdminUser } from './AdminUser'
import { Users } from './Users';
import { ClearCartPrompt } from './ClearCartPrompt';
import { OrderApproval } from './OrderApproval'
import { SessionTimedOut } from './SessionTimedOut';
import { DocumentsManage } from './DocumentsManage';
import { AdminManage } from './AdminManage';

export class DocumentContent extends Component {
    displayName = DocumentContent.name

    constructor(props) {
        super(props);

        this.state = {
            invoiceList: [],
            loadingInvoice: false,
            invoiceHeader: {},
            loadingHeader: false,
            dateDelivered: '',
            toChangePassword: false,
            menuVisibility: 'hidden',

            ToList: false,

            ToOrderDocument: false,
            toCheckOut: false,
            toSaveCart: false,
            toManage: false,
            toDocuments: false,
            toOrderForm: false,
            toAdmin: false,
            toLogIn: false,
            toBudgets: false,
            toOrderSearch: false,
            toOrderApproval: false,
            toClearCartPrompt: false,
            toSavedCarts: false,
            isAdminVisibile: 'hidden',
            ApproveVisibility: 'hidden',
            toProducts: false,
            userDetails: {},
            canTrack: 'hidden',
            toSessionTimedOut: false,
            toDocumentsManage: false,

        };
        this.CheckSessionInitialize();
    }

    CheckSessionInitialize() {
        this.setState({ complete_update: false });
        console.log('get CheckSession called');

        fetch('api/Login/CheckSession?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });

                    this.GetUserDetails();
                    this.CheckIfAdmin();
                    this.CheckIfCanTrack();
                    this.GetInvoice();
                    this.GetInvoiceContentList();
                }
                else if (data == 'timedout'){
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }

    CheckSession() {
        this.setState({ complete_update: false });
        console.log('get CheckSession called');

        fetch('api/Login/CheckSession?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });
                }
                else if (data == 'timedout'){
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }

    ToChangePassword() {
        fetch('/api/Login/CanUpdatePassword' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(response => {

                if (response == 'true')
                    this.setState({ toChangePassword: true });
                else
                    alert('You currently do not have the required permissions to change your password.');

            });
    }

    MenuClick() {

        if (this.state.menuVisibility == 'hidden') {
            this.setState({ menuVisibility: 'visible' });
        }
        else if (this.state.menuVisibility == 'visible') {
            this.setState({ menuVisibility: 'hidden' });
        }
    }

    InvoiceCredits() {
        this.setState({ ToList: true });
    }

    GetInvoice() {
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            this.setState({ loadingHeader: true });
            console.log('loading invoice ');

            fetch('/api/InvoiceOrder/GetCurrentInvoice' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(response => response.json())
                .then(data => { this.setState({ invoiceHeader: data, loadingHeader: false, dateDelivered: data.dateDelivered }, console.log(data)) })
                .then(() => {
                    console.log("invoice");
                    console.log(this.state.invoiceHeader);
                });
        }
    }

    GetInvoiceContentList() {
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            this.setState({ loadingInvoice: true });
            console.log('loading invoice item list');

            fetch('/api/InvoiceOrder/GetInvoiceLineItems' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(response => response.json())
                .then(data => { this.setState({ invoiceList: data, loadingInvoice: false }) });
        }
    }

    LoadTableInvoiceItems() {

        if (this.state.loadingInvoice) {
            return (
                <tr>
                    <td>loading...</td>
                    <td>loading...</td>
                    <td>loading...</td>
                    <td>loading...</td>
                    <td>loading...</td>
                    <td>loading...</td>
                    <td>loading...</td>
                    <td>loading...</td>
                    <td>loading...</td>
                    <td>loading...</td>
                </tr>
            );

        }
        else {
            return (
                this.state.invoiceList.map(invoiceItem =>
                    <tr style={{ lineHeight: '1px' }}>
                        <td>{invoiceItem.itemCode}</td>
                        <td>{invoiceItem.description}</td>
                        <td>{invoiceItem.brandRef}</td>
                        <td>{invoiceItem.invUOM}</td>
                        <td>{invoiceItem.qtyOrdered}</td>
                        <td>R {Number(invoiceItem.unitPrice).toFixed(2)}</td>
                        <td>R {Number(invoiceItem.unitPrice * invoiceItem.qtyOrdered).toFixed(2)}</td>
                        <td>R {Number(invoiceItem.lineTax).toFixed(2)}</td>
                        <td>R {Number((invoiceItem.unitPrice * invoiceItem.qtyOrdered) + invoiceItem.lineTax).toFixed(2)}</td>

                    </tr>
                ));
        }

    }

    LoadInvoice() {


        if (this.state.loadingHeader) {
            return (
                <div style={{ color: 'black' }}>
                    <div style={{ backgroundColor: 'white', height: '100px', display: 'inline-grid', gridTemplateColumns: '20% 80%', width: '100%' }}>
                        <div style={{ wordBreak: 'break-all' }}>
                            Loading
                    </div>
                        <div>
                        </div>
                    </div>
                    <div style={{ backgroundColor: 'white', height: '12%', width: '100%' }}>
                        <table style={{ width: '100%' }}>
                            <tr>
                                <td>CustomerCode: Loading...</td>
                                <td>Invoice No :  Loading...</td>
                                <td>Order No : Loading...</td>
                            </tr>
                            <tr>
                                <td>inv Date : Loading...</td>
                                <td>Delivery Date : Loading...</td>
                                <td>Web/Order Ref: Loading...</td></tr>
                            <tr>
                                <td>Your Order Ref: Loading...</td>
                                <td></td>
                                <td></td>
                            </tr>
                        </table>
                    </div>
                </div>);
        }
        else {
            let invoiceItem = this.state.invoiceHeader;
            let datedelivered = this.state.dateDelivered;
            return (
                <div style={{ color: 'black' }}>
                    <div style={{ backgroundColor: 'white', height: '12%', width: '100%' }}>
                        <table style={{ width: '100%' }}>
                            <tr>
                                <td>CustomerCode: </td>
                                <td style={{ textAlign: 'right' }}>{invoiceItem.accountCode}</td>
                                <td style={{ textAlign: 'right' }}>Delivery Date : </td>
                                <td style={{ textAlign: 'right' }}>{datedelivered}</td>
                                <td style={{ textAlign: 'right' }}>Total (Excluding VAT) : </td>
                                <td style={{ textAlign: 'right' }}>R {Number(invoiceItem.invoiceTotal).toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                            </tr>
                            <tr>
                                <td>Invoice No : </td>
                                <td style={{ textAlign: 'right' }}>{invoiceItem.invoiceNo}</td>
                                <td style={{ textAlign: 'right' }}>Web/Order Reference: </td>
                                <td style={{ textAlign: 'right' }}>{invoiceItem.ourRef}</td>
                                <td style={{ textAlign: 'right' }}>VAT : </td>
                                <td style={{ textAlign: 'right' }}>R {Number(invoiceItem.tax).toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                            </tr>
                            <tr>
                                <td>Order Reference: </td>
                                <td style={{ textAlign: 'right' }}>{invoiceItem.custRef}</td>
                                <td></td>
                                <td></td>
                                <td style={{ textAlign: 'right' }}>Total (Including VAT) : </td>
                                <td style={{ textAlign: 'right' }}>R {Number(invoiceItem.invoiceTotal + invoiceItem.tax).toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            );
        }
    }
    render() {
        if (this.state.toSessionTimedOut) {
            return <Route path='/' component={SessionTimedOut} />
        }

        if (this.state.toAdmin) {
            return <Route path='/' component={AdminManage} />
        }


        if (this.state.GoToOrderSearch) {
            return <Route path='/' component={OrderSearch} />
        }

        if (this.state.toBudgets) {
            return <Route path='/' component={Budget} />
        }

        if (this.state.toSavedCarts) {
            return <Route path='/' component={SavedCarts} />
        }

        if (this.state.toCheckOut) {
            return <Route path='/' component={CheckOut} />
        }

        if (this.state.toChangePassword) {
            return <Route path='/' component={ChangePassword} />

        }

        if (this.state.toDocuments) {
            return <Route path='/' component={InvoiceOrderSearch} />
        }

        if (this.state.toDocumentsManage) {
            return <Route path='/' component={DocumentsManage} />
        }

        if (this.state.toLogIn) {
            return <Route path='/' component={Login} />
        }

        if (this.state.toProducts) {
            return <Route path='/' component={Product} />
        }

        if (this.state.ToCreditDocument) {
            return <Route path='/' component={CreditContent} />
        }

        if (this.state.ToDocument) {
            return <Route path='/' component={DocumentContent} />
        }



        if (this.state.toOrderApproval) {
            return <Route path='/' component={OrderApproval} />
        }

        if (this.state.toClearCartPrompt) {
            return <Route path='/' component={ClearCartPrompt} />
        }

        if (this.state.ToList) {
            return <Route path='/' component={InvoiceOrderSearch} />
        }

        let invoiceList = this.LoadTableInvoiceItems();
        let invoice = this.LoadInvoice();
        let invoiceItem = this.state.invoiceHeader;

        return (
            <div style={{ width: "100%", height: "100%", color: 'black' }}>
                <div className="header" style={{ position: 'fixed', zIndex : '500'}}>
                    <div className="grid_container_header" style={{ msHyphens: 'auto', display: 'inline-grid', gridTemplateColumns: '30% 40% 30%', display: 'grid -ms-grid', msGridColumns: '30% 40% 30%', columnCount: '3' }}>
                        <div className="grid_header">
                            <div className="icon_home" style={{ width: '100%', height: '50%', 'margin-top': '30px', 'margin-left': '10%', float: 'left', 'background-repeat': 'no-repeat' }}></div>
                        </div>
                        <div className="grid_header" style={{ 'text-align': 'left', overflow: 'hidden' }}>
                            <div className="header_container" style={{ width: '100%' }}>
                                <div>

                                    <div style={{ display: 'inline' }}>
                                        <a className="header_text" onClick={() => this.GoBackToProducts()}  >Place Order</a>|
                                                <a className="header_text" onClick={() => this.GoToDocumentsManage()} >Documents</a><span style={{ visibility: this.state.canTrack }}>|</span>
                                        <a className="header_text" onClick={() => this.GoToOrderApproval()} style={{ visibility: this.state.canTrack }} >Order Approval</a><span style={{ visibility: this.state.isAdminVisibile }}>|</span>
                                        <a className="header_text" onClick={() => this.GoToBudget()} style={{ visibility: 'hidden' }}>Budgets</a>
                                        <span style={{ visibility: this.state.isAdminVisibile }}>|</span>
                                        <a className="header_text" onClick={() => this.GoToAdmin()} style={{ visibility: this.state.isAdminVisibile }} >Admin</a>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="grid_header">
                            <div className="header_container greeting" style={{ width: '100%' }}>
                                <div className="header_text grid_item_1" style={{ lineHeight: '40px', textAlign: 'right' }}>
                                    <div style={{ height: '28px' }} className="overflow_header"> {this.state.userDetails.greeting + ', ' + this.state.userDetails.fullname}</div>
                                    <div style={{ height: '25px' }} className="overflow_header">{this.state.userDetails.accountName}</div>
                                    <div style={{ height: '28px' }} className="overflow_header">Group : {this.state.userDetails.accountCode}</div>
                                </div>

                                <div className="grid_item_2_reverse  h100">
                                    <div style={{ width: '100%', 'height': '100%' }}>
                                        <button id="btnMenu" onClick={() => this.MenuClick()} className="btn primary_btn header_btn twfont" style={{ lineHeight: '0.8', marginTop: '10px'  }}  ><i className="material-icons" style={{ width: '100%' }} >menu</i><label>Options</label></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header_menu_container" style={{ visibility: this.state.menuVisibility }}>
                    <div className="grid_item_1 max-height-menu">
                        <button onClick={() => { this.MenuClick() }} style={{ width: '100%', height: '100%', backgroundColor: 'transparent', backgroundColor: 'transparent', border: 'none' }}></button>
                    </div>
                    <div className="header_menu grid_item_2 max-height-menu customer_background_foreground">
                        <hr />
                        <div>
                            <button id="canUpdateBtn" name="canUpdateBtn" className="btn  header_menu_item" onClick={() => { this.ToChangePassword() }}>Reset Password <i className="material-icons" style={{ position: 'relative', top: '6px', paddingLeft: '10px' }}>vpn_key</i></button>
                        </div>
                        <hr />
                        <div>
                            <button className="btn  header_menu_item" onClick={() => { this.GetCartState() }}>Logout <i className="material-icons" style={{ position: 'relative', top: '6px', paddingLeft: '10px' }}>exit_to_app</i></button>
                        </div>
                        <hr />
                    </div>
                </div>
                <div className="CssPageContatiner">
                    <div>
                        <button ID="btnBack" className="btn secondary_btn" style={{ width: '20%', 'font-family': 'Tw Cen MT' }} onClick={() => this.GoToDocuments()}>Back to Documents</button >
                        <br />
                        <br />
                    </div>
                    <div id="Page" className="CssPage">
                        <div className="pageContainer divGridReport" style={{ padding: '10px', fontFamily: 'Tw Cen MT' }}>
                            <div className="pageContainer row1 col1" style={{ padding: '20px' }}>
                                <div style={{ borderBottom: 'solid', marginBottom: '0' }}>
                                    <label style={{ fontSize: 'large', fontWeight: '800', paddingTop: '9px' }}>Address</label>
                                </div>
                                <div style={{ padding: '20px', fontSize: 'medium' }}>
                                    <table>
                                        <tr>
                                            <td style={{ wordBreak: 'break-all' }}>
                                                {invoiceItem.deliverTo1 + ' ' + invoiceItem.deliverTo2 + ' ' + invoiceItem.deliverTo3 + ' ' + invoiceItem.deliverTo4}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div className="pageContainer row1 col2" style={{ backgroundColor: 'white' }}></div>
                            <div className="pageContainer row1 col3" style={{ backgroundColor: 'white', paddingRight: '30px', height: '100%' }}>
                                <div style={{ borderBottom: 'solid', marginBottom: '0' }}>
                                    <label style={{ fontSize: 'xx-large', fontWeight: 'bolder', paddingTop: '15px', marginBottom: '0' }}>Computer Generated Copy Tax Invoice</label>
                                </div>
                                <div style={{ height: '87%', width: '100%', backgroundColor: 'white', marginTop: '20px' }}>
                                    {invoice}
                                    <div style={{ backgroundColor: 'white', height: '1%' }}>
                                        <hr />
                                    </div>
                                    <div style={{ height: '55%', backgroundColor: 'white' }}>
                                        <table className="table " style={{ fontFamily: 'TW Cen MT', display: 'block', overflow: 'scroll', whiteSpace: 'nowrap', height: '350px' }}>
                                            <thead style={{ lineHeight: '1px', borderBottom: 'solid' }}>
                                                <th>ITEM CODE</th>
                                                <th style={{ width: 'inherit' }} >DESCRIPTION</th>
                                                <th>BRAND</th>
                                                <th>UOM</th>
                                                <th>ORDERED QTY</th>
                                                <th>Unit Price</th>
                                                <th>Extended Price (Excluding VAT)</th>
                                                <th>VAT</th>
                                                <th>TOTAL</th>
                                            </thead>
                                            {invoiceList}
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }



    ///Global methods

    GetCartState() {

        fetch('/api/Product/GetCartCount' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == "valued") {
                    this.setState({ toClearCartPrompt: true });
                } else {
                    window.location.href = '\\';
                }
            });
    }

    CheckIfAdmin() {
        fetch('/api/Login/isAdmin' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => { this.setState({ isAdminVisibile: (data == 'Y' ? 'visible' : 'hidden') }, () => { console.log('visibility : data=' + data + ' value= ' + this.state.isAdminVisibile); }) })
    }

    GoToOrderSearch() {
        this.setState({ GoToOrderSearch: true });
    }


    GoToOrderApproval() {
        this.setState({ toOrderApproval: true });
    }

    GoToDocuments() {
        this.setState({ toDocuments: true });
    }

    toOrderForm() {
        this.setState({ toOrderForm: true });
    }

    GoToLogIn() {
        this.setState({ toLogIn: true });
    }

    GoToAdmin() {
        this.setState({ toAdmin: true });
    }

    GoBackToProducts() {
        this.setState({ toProducts: true });
    }

    GoToBudget() {
        this.setState({ toBudgets: true });
    }

    GoToOrderForm() {
        this.setState({ toOrderForms: true });
    }

    GoToOrderSearch() {
        this.setState({ GoToOrderSearch: true });
    }


    GoToSavedCarts() {
        this.setState({ toSavedCarts: true });
    }

    GetUserDetails() {
        fetch('api/Login/GetUserDetails' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.json())
            .then(data => { this.setState({ userDetails: data }) })
            .then(() => {
                console.log("user details");
                console.log(this.state.userDetails);
            })
    }

    CheckIfCanTrack() {
        fetch('/api/Login/canTrack' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => { this.setState({ canTrack: (data == 'Y' ? 'visible' : 'hidden') }, () => { console.log('visibility : data=' + data + ' value= ' + this.state.isAdminVisibile); }) })
    }

    GoToDocumentsManage() {
        this.setState({ toDocumentsManage: true });
    }

}


//<td className="sideBorder">{invoiceItem.invoiceLineNo}</td>
//    <td className="sideBorder">{invoiceItem.itemCode}</td>
//    <td className="sideBorder">{invoiceItem.description}</td>
//    <td className="sideBorder">{invoiceItem.invUOM}</td>
//    <td className="sideBorder">{invoiceItem.qtyOrdered}</td>
//    <td className="sideBorder">{invoiceItem.qtyInvoiced}</td>
//    <td className="sideBorder">{invoiceItem.qtyBackOrdered}</td>
//    <td className="sideBorder">{invoiceItem.unitPrice}</td>
//    <td className="sideBorder">{invoiceItem.extendedPrice}</td>
//    <td className="sideBorder">{invoiceItem.lineTax}</td>
//    <td className="sideBorder">{invoiceItem.brandRef}</td>
//    <td className="sideBorder">{invoiceItem.packSizeRef}</td>
//    <td className="sideBorder">{invoiceItem.orderUOM}</td>
//    <td className="sideBorder">{invoiceItem.orderUOMQty}</td>
//    <td className="sideBorder">{invoiceItem.orderUOMUnitPrice}</td>
//    <td className="sideBorder">{invoiceItem.ediOrderLineRef}</td>
//    <td className="sideBorder">{invoiceItem.warehouseCode}</td>