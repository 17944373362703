import React, { ReactPropTypes, Component } from 'react';
import { Route } from 'react-router';
import { withRouter, Redirect } from 'react-router-dom';
import { Product } from './Products';
import { PaymentPortal } from './PaymentPortal';
import { Registration } from './Registration'
import { GetStylesheet } from '../registerServiceWorker';
import { App } from '../App';
import { AdminUser } from './AdminUser'
import { unregister } from '../registerServiceWorker';
import { Link } from 'react-router-dom'
import { PropTypes } from 'react'
import { OrderApprovalHistory } from './OrderApprovalHistory';
import { ForgotPassword } from './ForgotPassword';
import { ConnectionError } from './ConnectionError';
import { LinkContainer } from 'react-router-bootstrap';

export class Login extends Component {
    displayName = Login.name
    _isMounted = false;


    constructor(props) {
        super(props);
        this.state = {
            data: [],
            stylesheet: '',
            completeLoad: false,
            userName: "",
            password: "",
            valid: false,
            loading: false,
            emailSentVisibility: 'hidden',
            tooManyAttemptsCountVisibility: 'hidden',
            passwordMessage: '',
            passwordResendCount: 1,
            toProductsPage: false,
            enterKeyPressed: false,
            toRegister: false,
            complete_update: false,
            browserIsIE: 'hidden',
            savePassword: false,
            toForgotPassword: false,
            connectionActive: true
        };

    }

    componentDidMount() {

        console.log(window.location.href);

        if (window.location.href == 'https://unitrans.online.lithotech.co.za/' || window.location.href == 'http://unitrans.online.lithotech.co.za/')
        {
            window.location = 'https://cfao.online.lithotech.co.za';
        }

        this._isMounted = true;
        this.GetStylesheet();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    ForgotPasswordState() {

        //this.detectBrowser();
        fetch('api/Login/GetPasswordState?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                this.setState({ passwordMessage: data });
            });
    }

    checkConnection() {
        var username = this.state.userName;

        this.setState({ loading: true });
        fetch('api/Login/CheckConnection?loginId=' + username + '&buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == "true") {
                    this.setState({ connectionActive: true });
                    this.Validatelogin();
                }
                else if (data == "false") {
                    this.setState({ connectionActive: false });
                }
            });
    }

    detectBrowser() {

        var isFirefox = typeof InstallTrigger != 'undefined';
        var isIE = false || !!document.documentMode;
        var isEdge = !isIE && !!window.StyleMedia;
        var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

        //disabled on 20240221 by am based on request from Mmabatho
        //if (isIE) {
        //    this.setState({ browserIsIE: 'visibile' });
        //    this.populateCredentials();
        //}
        //else {
        //    this.setState({ browserIsIE: 'hidden' });
        //}
        //now only using this to hide the text:
        this.setState({ browserIsIE: 'hidden' });


    }

    GetStylesheet() {
        this.setState({ complete_update: false });
        fetch('api/Stylesheet/GetStyleSheet?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                this.setState({ stylesheet: data, complete_update: true });
            });
    }


    ToForgotPassword() {
        this.setState({ toForgotPassword: true });
    }

    ToRegister() {
        this.setState({ toRegister: true });
    }

    handleUsernameChange(e) {
        this.setState({ userName: e.target.value })
    }

    handlePasswordChange(e) {
        this.setState({ password: e.target.value });
    }

    handleSavePasswordChange(e) {
        this.setState({ savePassword: e.target.checked });
    }

    Validatelogin() {

        //if (window.PasswordCredential) {
        //    var c = new PasswordCredential(e.target);
        //    return navigator.credentials.store(c);
        //} else {
        //    return Promise.resolve(profile);
        //}

        var validLogin = false;
        var username = this.state.userName;
        var password = this.state.password;
        var savePassword = this.state.savePassword;


        fetch('api/Login/ValidateLogin?username=' + username + '&password=' + password + '&savePassword=' + savePassword + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.json())
            .then(data => {
                console.log(data.headers);
                this.setState({ valid: data });
                this.LoadLoginContent();
                data = null;
            });


    }

    LoadLoginContent() {
        this.setState({ loading: false });

        if (this.state.valid === false) {
            this.isTooManyAttempts();
            var password = document.getElementById('password');
            password.style.borderColor = "red";
            password.style.borderWidth = "medium";

        }

        if (this.state.valid === true) {
            var password = document.getElementById('password');
            password.style.borderColor = "limegreen";
            password.style.borderWidth = "medium";
            this.setState({ toProductsPage: true });
        }
    }

    isTooManyAttempts() {

        fetch('api/Login/GetFailedAttemptCount' + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.json())
            .then(data => {

                this.setState({ passwordResendCount: data }, () => {
                    if (data < 3) {
                        this.setState({ tooManyAttemptsCountVisibility: 'hidden' })
                    }
                    else {
                        this.setState({ tooManyAttemptsCountVisibility: 'visible' })
                    }
                });
            })
    }


    render() {

        if (this.state.connectionActive == false) {
            return (<div>
                <link href={this.state.stylesheet} rel="stylesheet" type="text/css" id="pageStylesheet" />
                <Route path='/' component={ConnectionError} />
            </div>);
        }

        let message = this.state.passwordMessage;



        if (this.state.enterKeyPressed) {
        }

        if (this.state.toProductsPage) {
            return (
                <div>
                    <link href={this.state.stylesheet} rel="stylesheet" type="text/css" id="pageStylesheet" />
                    <Route path='/' component={Product} />
                </div>
                //<Redirect to="/Home" />
            )
        }

        if (this.state.toForgotPassword) {
            return (
                <div>
                    <link href={this.state.stylesheet} rel="stylesheet" type="text/css" id="pageStylesheet" />
                    <Route path='/' component={ForgotPassword} />
                </div>
            )
        }

        if (this.state.toRegister) {
            return (
                <div>
                    <link href={this.state.stylesheet} rel="stylesheet" />
                    <Route path='/' component={Registration} />
                </div>);
        }

        let content;

        if (this.state.loading) {
            return (<div className="outer-container customer_background" style={{ fontFamily: 'TW Cent', backgroundColor: 'white' }}>
                <div className="inner-container">
                    <div className="centered-content  twfont customer_background_foreground" style={{ boxShadow: 'none', border: 'none' }}>
                        <div className="spinner-border" style={{ color: 'rgb(8, 15, 83)', height: '3rem', width: '3rem', borderWidth: '0.5em' }} role="status">
                            <span className="sr-only" >Loading...</span>
                        </div>
                    </div>
                </div>
            </div>);
        }
        else {
            return (
                <div>
                    <link href={this.state.stylesheet} rel="stylesheet" />
                    <div ref='loginForm' autoComplete="on">
                        <div className="outer-container customer_background" style={{ fontFamily: 'TW Cent' }} >
                            <div className="header" style={{ position: 'fixed', zIndex : '500'}}>

                                <div className="grid_container_header" style={{ display: 'inline-grid', gridTemplateColumns: '30% 40% 30%', display: 'grid -ms-grid', msGridColumns: '30% 40% 30%', columnCount: '3' }}>
                                    <div className="grid_header">
                                        <div className="icon_home" style={{ width: '100%', height: '50%', marginTop: '30px', marginLeft: '10%', float: 'left', backgroundRepeat: 'no-repeat' }}></div>
                                    </div>
                                    <div className="grid_header" style={{ textAlign: 'left' }}>

                                    </div>
                                    <div className="grid_header">
                                        <div className="header_container" style={{ width: '100%', paddingRight: '15%' }}>
                                            <div className="input-group mb-3" style={{ marginTop: '9%', marginRight: '2%' }}>
                                                <div className="input-group-prepend">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="inner-container">
                                <div className="centered-content twfont  customer_background_foreground"
                                    style={{ boxShadow: '2px 2px 8px #888888', border: 'none', width: '90%', maxWidth: '450px', backgroundColor: 'rgba(255,255,255,0.3)', fontFamily: 'TW Cen MT' }}>
                                    <table className="customer_background_foreground" style={{ width: '100%', margin: '0 auto', verticalAlign: 'middle', height: '200px', backgroundColor: 'rgba(255,255,255,0.3)' }}>
                                        <tbody><tr>
                                            <td colSpan="2" style={{ width: '100%' }}>
                                                <input id="user" name="user" type="text" value={this.state.userName} onChange={this.handleUsernameChange.bind(this)} className="form-control twfont" placeholder="Login Id" autoComplete="on" />
                                            </td>
                                        </tr>
                                            <tr>
                                                <td colSpan="2" style={{ height: '23px' }}>
                                                    <input id="password" name="password" type="password" value={this.state.password} className="form-control twfont" placeholder="Password" onChange={this.handlePasswordChange.bind(this)} autoComplete="on" /></td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2"><a style={{ color: 'green', visibility: this.state.emailSentVisibility }}>Email sent</a></td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2"><a style={{ color: 'red', visibility: this.state.tooManyAttemptsCountVisibility }}>Too many attempts you have been blocked</a></td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '50%' }}><a href="#" onClick={() => { this.ToForgotPassword() }}>forgot password?</a></td>
                                                <td>
                                                    <div style={{ visibility: this.state.browserIsIE }}>
                                                        <label className='twfont' style={{ color: 'red' }}>save1 password is not supported for your browser.</label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr><td><br /></td></tr>
                                            <tr>
                                                <td colSpan="2" style={{ width: '100%' }}>
                                                    <button id="btnSubmit" onClick={() => { this.checkConnection() }} className="btn primary_btn twfont" style={{ width: '100%' }} >Login</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }


    }

}

export default Login

    //< button id = "butLogin" className = "btn primary_btn twfont" type = "button" onClick = {() => { this.checkConnection() }} style = {{ width: '100%' }}> Login</button >

    //< input type = "submit" value = "Login" className = "btn primary_btn twfont" style = {{ width: '100%' }} />

    //< td style = {{ width: '50%' }}> <a style={{ color: '#007bff', cursor: 'pointer' }} onClick={() => this.ToRegister()}>Register</a></td >


    //< div style = {{ visibility: this.state.browserIsIE }}>
    //    <input id="savepassword" type="checkbox" onChange={this.handleSavePasswordChange.bind(this)} /> save password
    //                                            </div >
