import React, { Component } from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import { NavMenu } from './NavMenu';

export class Layout extends Component {
    displayName = Layout.name

    render() {
        return (
            <div>
                <div>
                    {this.props.children}
                </div>
            </div >
        );
    }
}

//<div>
//    <div style={{ width: '100%', top: '0', left: '0' }}>
//        <NavMenu />
//    </div>
//    <div>
//        {this.props.children}
//    </div>
//</div >