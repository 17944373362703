
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';


const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

//CHECKS IF TWO VERSION OF REACT ARE RUNNING
//window.React1 = require('react');

ReactDOM.render(
    <BrowserRouter basename={baseUrl} style={{ fontFamily: 'Tw Cent' }}>
        <App />
    </BrowserRouter>,
    rootElement);

registerServiceWorker();
