import React, { Component } from 'react';
import { Route } from 'react-router';
import { DocumentContent } from './DocumentContent';
import { OrderContent } from './OrderContent';
import { CreditContent } from './CreditContent';
import { ChangePassword } from './ChangePassword';

import { Product } from './Products';
import { Login } from './Login';
import { CheckOut } from './CheckOut';
import { SavedCarts } from './SavedCarts';
import { InvoiceOrderSearch } from './InvoiceOrderSearch';
import { Budget } from './Budget'
import { AdminUser } from './AdminUser'
import { Users } from './Users';
import { OrderApproval } from './OrderApproval'
import { withRouter, Redirect } from 'react-router-dom';
import { SessionTimedOut } from './SessionTimedOut';
import { ClearCartPrompt } from './ClearCartPrompt';
import { DocumentsManage } from './DocumentsManage';
import { OrderApprovalHistory } from './OrderApprovalHistory';
import { AdminManage } from './AdminManage';

export class OrderSearch extends Component {
    displayName = OrderSearch.name

    constructor(props) {
        super(props);

        this.state = {
            valid: false,
            userDetails: {},
            referenceNumber: '',
            toChangePassword: false,
            menuVisibility: 'hidden',
            startDate: '',
            endDate: '',
            documentType: 'Orders',
            ApproveVisibility: 'hidden',

            orders: [],
            loadingOrders: false,
            ordersHeight: '500px',
            ordersVisibility: 'visible',

            ToDocument: false,
            ToOrderDocument: false,
            ToCreditDocument: false,

            toCheckOut: false,
            toSaveCart: false,
            toManage: false,
            toClearCartPrompt: false,
            toDocuments: false,
            toOrderForm: false,
            toAdmin: false,
            toLogIn: false,
            toBudgets: false,
            toOrderSearch: false,
            toOrderApproval: false,
            toSavedCarts: false,
            isAdminVisibile: 'hidden',
            toProducts: false,
            canTrack: 'hidden',
            toSessionTimedOut: false,
            invalidDateMessage: '',
            invalidDateModalVisibility: 'hidden',
            invalidDateModalVisibility: 'hidden',
            invalidDateMessage: '',
            invalidDateModalVisibility: 'hidden',
            toDocumentsManage: false,
            toOrderApprovalHistory: false,
            documentType: 'History',
            historyHeight: '500px',
            historyVisibility: 'Visible',
            failedHeight: '500px',
            failedVisibility: 'Visible'
        };

        this.CheckSessionInitialize();
    }

    setCheckedTab() {

        fetch('api/InvoiceOrder/GetPageContentOrderSearch?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.json())
            .then(data => {
                console.log(data);



                this.setState({
                    referenceNumber: data.searchText,
                    startDate: data.startDateValue,
                    endDate: data.endDateValue
                });

                if (data.radioValue == 'failed') {
                    document.getElementById('failedRadioButton').checked = true;
                    this.GetFailedOrdersByRef();
                    this.setState({ documentType: 'Failed' });
                }
                else {
                    document.getElementById('historyRadioButton').checked = true;
                    this.GetOrderHistoryByRef();
                    this.setState({ documentType: 'History' });
                }
            });


    }

    ExitModal() {
        this.setState({ invalidDateModalVisibility: 'hidden' });

    }

    handleDocumentTypeChange(e) {
        this.setState({ documentType: e.target.value },
            () => {
                console.log('Document Type : ' + this.state.documentType);

                if (this.state.documentType == 'History') {
                    this.setState({
                        historyHeight: '500px',
                        historyVisibility: 'Visible',
                        failedHeight: '0px',
                        failedVisibility: 'hidden'
                    });
                }
                else if (this.state.documentType == 'Failed') {
                    this.setState({
                        failedHeight: '500px',
                        failedVisibility: 'Visible',
                        historyHeight: '0',
                        historyVisibility: 'hidden',
                    });
                }

                this.GetDocumentsByReference();
            }
        );
    }

    GetDocumentsByReference() {

        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            console.log('Getting Documents By Reference');
            if (this.state.documentType == 'History') {
                this.GetOrderHistoryByRef();
            }
            else if (this.state.documentType == 'Failed') {
                this.GetFailedOrdersByRef();
            }
        }
    }

    GetDocumentsByDate() {

        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            console.log('Getting Documents By Reference');
            if (this.state.documentType == 'History') {
                this.GetOrderHistoryByDate();
            }
            else if (this.state.documentType == 'Failed') {
                this.GetFailedOrdersByDate();
            }
        }
    }

    CheckSessionInitialize() {
        this.setState({ complete_update: false });
        console.log('get CheckSession called');

        fetch('api/Login/CheckSession?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });
                    this.setCheckedTab();
                    this.GetUserDetails();
                    this.CheckIfApprover();
                    this.CheckIfAdmin();
                    this.CheckIfCanTrack();
                    //this.GetOrdersByRef();
                    this.GetDocumentsByReference();
                }
                else {
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }

    CheckSession() {
        this.setState({ complete_update: false });
        console.log('get CheckSession called');

        fetch('api/Login/CheckSession?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });
                }
                else if (data == 'timedout') {
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }


    CheckIfApprover() {
        fetch('/api/Login/isApprover' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => { this.setState({ ApproveVisibility: (data == 'Y' ? 'visible' : 'hidden') }, () => { console.log('visibility : data=' + data + ' value= ' + this.state.ApproveVisibility); }) })
    }

    ProductLink() {
        this.setState({ toProductsPage: true });
    }

    handleReferenceNumberChange(e) {
        this.setState({ referenceNumber: e.target.value },
            () => {
                console.log('reference : ' + this.state.referenceNumber);
            }
        );
    }

    handleStartDateChange(e) {
        this.setState({ startDate: e.target.value },
            () => {
                console.log('Start Date : ' + this.state.startDate);
            }
        );
    }

    handleEndDateChange(e) {
        this.setState({ endDate: e.target.value },
            () => {
                console.log('End Date : ' + this.state.endDate);
            }
        );
    }

    //GetDocumentsByReference() {
    //    this.GetOrdersByRef();
    //}

    //GetDocuments() {
    //    this.GetOrdersByDate();
    //}

    //Get Orders By Date

    GetOrdersByDate() {

        this.setState({ loadingOrders: true });
        this.CheckSession();


        fetch('api/InvoiceOrder/ValidateDates?startDate=' + this.state.startDate + '&endDate=' + this.state.endDate + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'Available') {
                    this.setState({ isValidDate: true });

                    if (this.state.toSessionTimedOut == false) {
                        fetch('api/InvoiceOrder/GetPlacedOrdersByDate?startDate=' + this.state.startDate + '&endDate=' + this.state.endDate + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                            .then(response => response.json())
                            .then(data => {
                                this.setState({ orders: data, loadingOrders: false },
                                    () => { console.log('orders'); console.log(this.state.orders) });
                            })
                            .then(() => {

                            });
                    }
                }
                else {
                    this.setState({ invalidDateMessage: data, invalidDateModalVisibility: 'visible' });
                }
            });



    }

    GetOrderHistoryByDate() {

        this.setState({ loadingOrders: true });
        this.CheckSession();


        fetch('api/InvoiceOrder/ValidateDates?startDate=' + this.state.startDate + '&endDate=' + this.state.endDate + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'Available') {
                    this.setState({ isValidDate: true });

                    if (this.state.toSessionTimedOut == false) {
                        fetch('api/InvoiceOrder/GetPlacedOrdersByDate_History?startDate=' + this.state.startDate + '&endDate=' + this.state.endDate + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                            .then(response => response.json())
                            .then(data => {
                                this.setState({ orders: data, loadingOrders: false },
                                    () => { console.log('orders'); console.log(this.state.orders) });
                            })
                            .then(() => {

                            });
                    }
                }
                else {
                    this.setState({ invalidDateMessage: data, invalidDateModalVisibility: 'visible' });
                }
            });



    }

    GetFailedOrdersByDate() {

        this.setState({ loadingOrders: true });
        this.CheckSession();


        fetch('api/InvoiceOrder/ValidateDates?startDate=' + this.state.startDate + '&endDate=' + this.state.endDate + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'Available') {
                    this.setState({ isValidDate: true });

                    if (this.state.toSessionTimedOut == false) {
                        fetch('api/InvoiceOrder/GetPlacedOrdersByDate_Failed?startDate=' + this.state.startDate + '&endDate=' + this.state.endDate + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                            .then(response => response.json())
                            .then(data => {
                                this.setState({ orders: data, loadingOrders: false },
                                    () => { console.log('orders'); console.log(this.state.orders) });
                            })
                            .then(() => {

                            });
                    }
                }
                else {
                    this.setState({ invalidDateMessage: data, invalidDateModalVisibility: 'visible' });
                }
            });



    }

    //Done

    //Get Orders By Reference

    GetOrdersByRef() {

        this.setState({ loadingOrders: true });
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            fetch('api/InvoiceOrder/GetPlacedOrdersByReference?referenceNo=' + this.state.referenceNumber + '&startDate=' + this.state.startDate + '&endDate=' + this.state.endDate +'&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(response => response.json())
                .then(data => {
                    this.setState({ orders: data, loadingOrders: false });
                })
                .then(() => { console.log('orders'); console.log(this.state.orders); });
        }
    }

    GetOrderHistoryByRef() {
        console.log('history ref');
        this.setState({ loadingOrders: true });
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            fetch('api/InvoiceOrder/GetOrderHistoryByReference?referenceNo=' + this.state.referenceNumber + '&startDate=' + this.state.startDate + '&endDate=' + this.state.endDate+ '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(response => response.json())
                .then(data => {
                    this.setState({ orders: data, loadingOrders: false });
                })
                .then(() => { console.log('orders'); console.log(this.state.orders); });
        }
    }

    GetFailedOrdersByRef() {
        console.log('failed');
        this.setState({ loadingOrders: true });
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            fetch('api/InvoiceOrder/GetFailedOrdersByReference?referenceNo=' + this.state.referenceNumber + '&startDate=' + this.state.startDate + '&endDate=' + this.state.endDate + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(response => response.json())
                .then(data => {
                    this.setState({ orders: data, loadingOrders: false });
                })
                .then(() => { console.log('orders'); console.log(this.state.orders); });
        }
    }

    //Done

    SetOrderIdentifier(orderId) {
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            fetch('api/InvoiceOrder/SetOrderIdentifier?orderId=' + orderId + '&forApprover=' + 0 + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(() => {
                    this.SetOrderPredecessor();
                });
        }

    }

    SetApprovalHistoryOrderIdentifier(orderId) {
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            fetch('api/InvoiceOrder/SetApprovalHistoryOrderId?orderId=' + orderId + '&forApprover=' + 0 + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(() => {
                    this.SetOrderPredecessorApprovalHistory();
                });
        }

    }

    SetOrderPredecessor() {
        fetch('api/InvoiceOrder/SetOrderContentPredecessor?predecessor=O&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(() => { this.setState({ ToOrderDocument: true }) });
    }

    SetOrderPredecessorApprovalHistory() {
        fetch('api/InvoiceOrder/SetOrderContentPredecessor?predecessor=O&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(() => {
                this.setState({ toOrderApprovalHistory: true });
            });
    }



    ApproveOrder(id) {
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            fetch('api/InvoiceOrder/ApproveOrder?orderId=' + id + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(response => response.json())
                .then(data => {
                    this.setState({ orders: data, loadingOrders: false });
                })
                .then(() => { console.log('orders'); console.log(this.state.orders); });
        }

    }

    DeclineOrder(id) {
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            fetch('api/InvoiceOrder/DeclineOrder?orderId=' + id + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(response => response.json())
                .then(data => {
                    this.setState({ orders: data, loadingOrders: false });
                })
                .then(() => { console.log('orders'); console.log(this.state.orders); });
        }
    }

    renderOrders() {
        console.log('loading orders');
        if (this.state.loadingOrders) {
            return (<tr>
                <td colspan="11">
                    <div style={{ textAlign: 'center' }}>
                        <div>
                            <div className="spinner-border" style={{ color: 'black' }} role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>);
        }
        else {
            return (this.state.orders.map(order =>
                <tr style={{ backgroundColor: 'white' }}>
                    <td className="sideBorder" style={{ padding: '0 1%', color: 'blue', border: 'black', cursor: 'pointer' }} onClick={() => this.SetOrderIdentifier(order.orderID)}>L{order.orderID}</td>
                    <td className="sideBorder" style={{ padding: '0 1%', color: 'blue', border: 'black', cursor: 'pointer', textAlign: 'center', fontWeight: '600' }} onClick={() => this.SetApprovalHistoryOrderIdentifier(order.orderID)}>View</td>
                    <td className="sideBorder" style={{ padding: '0 1%' }}> {order.orderDate} </td>
                    <td className="sideBorder" style={{ padding: '0 1%' }}> {order.deliveryDate}  </td>
                    <td className="sideBorder overflow_table" style={{ padding: '0 1%' }}> {order.orderNotes}  </td>
                    <td className="sideBorder" style={{ padding: '0 1%' }}> {order.approvalStatus}  </td>
                    <td className="sideBorder" style={{ padding: '0 1%' }}> {order.orderStatus}  </td>
                    <td className="sideBorder overflow_table" style={{ padding: '0 1%' }}> {order.customerRef}  </td>
                    <td className="sideBorder" style={{ padding: '0 1%' }}> {order.placedBy}  </td>
                </tr>
            )
            );
        }

    }

    ToChangePassword() {
        fetch('/api/Login/CanUpdatePassword' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(response => {

                if (response == 'true')
                    this.setState({ toChangePassword: true });
                else
                    alert('You currently do not have the required permissions to change your password.');

            });
    }

    MenuClick() {

        if (this.state.menuVisibility == 'hidden') {
            this.setState({ menuVisibility: 'visible' });
        }
        else if (this.state.menuVisibility == 'visible') {
            this.setState({ menuVisibility: 'hidden' });
        }
    }

    render() {

        //Route Change
        if (this.state.toSessionTimedOut) {
            return <Route path='/' component={SessionTimedOut} />
        }

        if (this.state.toDocumentsManage) {
            return <Route path='/' component={DocumentsManage} />
        }

        if (this.state.toChangePassword) {
            return <Route path='/' component={ChangePassword} />

        }


        if (this.state.toClearCartPrompt) {
            return <Route path='/' component={ClearCartPrompt} />
        }

        if (this.state.toAdmin) {
            return <Route path='/' component={AdminManage} />
        }

        if (this.state.ToOrderDocument) {
            return <Route path='/' component={OrderContent} />
        }

        if (this.state.toOrderApprovalHistory) {
            return <Route path='/' component={OrderApprovalHistory} />
        }

        if (this.state.GoToOrderSearch) {
            return <Route path='/' component={OrderSearch} />
        }

        if (this.state.toBudgets) {
            return <Route path='/' component={Budget} />
        }

        if (this.state.toSavedCarts) {
            return <Route path='/' component={SavedCarts} />
        }


        if (this.state.toCheckOut) {
            return <Route path='/' component={CheckOut} />
        }

        if (this.state.toDocuments) {
            return <Route path='/' component={InvoiceOrderSearch} />
        }

        if (this.state.toOrderApproval) {
            return <Route path='/' component={OrderApproval} />
        }

        if (this.state.toLogIn) {
            return <Route path='/' component={Login} />
        }

        if (this.state.toProducts) {
            return <Route path='/' component={Product} />
        }

        if (this.state.toClearCartPrompt) {
            return <Route path='/' component={ClearCartPrompt} />
        }

        //end

        if (this.state.ToCreditDocument) {
            return <Route path='/' component={CreditContent} />
        }

        if (this.state.ToDocument) {
            return <Route path='/' component={DocumentContent} />
        }

        if (this.state.ToOrderDocument) {
            return <Route path='/' component={OrderContent} />
        }

        let orderRows = this.renderOrders();

        return (
            <div>
                <div style={{ top: '0', left: '0', height: '100%' }}>
                    <div className="header" style={{ position: 'fixed', zIndex : '500'}}>
                        <div className="grid_container_header" style={{ msHyphens: 'auto', display: 'inline-grid', gridTemplateColumns: '30% 40% 30%', display: 'grid -ms-grid', msGridColumns: '30% 40% 30%', columnCount: '3' }}>
                            <div className="grid_header">
                                <div className="icon_home" style={{ width: '100%', height: '50%', 'margin-top': '30px', 'margin-left': '10%', float: 'left', 'background-repeat': 'no-repeat' }}></div>
                            </div>
                            <div className="grid_header" style={{ 'text-align': 'left', overflow: 'hidden' }}>
                                <div className="header_container" style={{ width: '100%' }}>
                                    <div>
                                        <div style={{ display: 'inline' }}>
                                            <a className="header_text" onClick={() => this.GoBackToProducts()}  >Place Order</a>|
                                                <a className="header_text" onClick={() => this.GoToDocumentsManage()} >Documents</a><span style={{ visibility: this.state.canTrack }}>|</span>
                                            <a className="header_text" onClick={() => this.GoToOrderApproval()} style={{ visibility: this.state.canTrack }} >Order Approval</a>
                                            <span style={{ visibility: 'visible' }}>|</span>
                                            <a className="header_text" onClick={() => this.GoToBudget()} style={{ visibility: 'visible' }}>Budgets</a>
                                            <span style={{ visibility: this.state.isAdminVisibile }}>|</span>
                                            <a className="header_text" onClick={() => this.GoToAdmin()} style={{ visibility: this.state.isAdminVisibile }} >Admin</a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="grid_header">
                                <div className="header_container greeting" style={{ width: '100%' }}>
                                    <div className="header_text grid_item_1" style={{ lineHeight: '40px', textAlign: 'right' }}>
                                        <div style={{ height: '28px' }} className="overflow_header"> {this.state.userDetails.greeting + ', ' + this.state.userDetails.fullname}</div>
                                        <div style={{ height: '25px' }} className="overflow_header">{this.state.userDetails.accountName}</div>
                                        <div style={{ height: '28px' }} className="overflow_header">Group : {this.state.userDetails.accountCode}</div>
                                    </div>
                                    <div className="grid_item_2_reverse  h100">
                                        <div style={{ width: '100%', 'height': '100%' }}>
                                            <button id="btnMenu" onClick={() => this.MenuClick()} className="btn primary_btn header_btn twfont" style={{ lineHeight: '0.8', marginTop: '10px'  }}  ><i className="material-icons" style={{ width: '100%' }} >menu</i><label>Options</label></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="header_menu_container" style={{ visibility: this.state.menuVisibility }}>
                        <div className="grid_item_1 max-height-menu">
                            <button onClick={() => { this.MenuClick() }} style={{ width: '100%', height: '100%', backgroundColor: 'transparent', border: 'none' }}></button>
                        </div>
                        <div className="header_menu grid_item_2 max-height-menu customer_background_foreground">
                            <hr />
                            <div>
                                <button id="canUpdateBtn" name="canUpdateBtn" className="btn  header_menu_item" onClick={() => { this.ToChangePassword() }}>Reset Password <i className="material-icons" style={{ position: 'relative', top: '6px', paddingLeft: '10px' }}>vpn_key</i></button>
                            </div>
                            <hr />
                            <div>
                                <button className="btn  header_menu_item" onClick={() => { this.GetCartState() }}>Logout <i className="material-icons" style={{ position: 'relative', top: '6px', paddingLeft: '10px' }}>exit_to_app</i></button>
                            </div>
                            <hr />
                        </div>
                    </div>
                    <div className=" sidenav customer_background_foreground" style={{ width: '100%', minHeight: '1000px', marginTop: '100px' }}>
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <br />
                            <div className="customer_background_foreground" style={{ width: '30%', margin: '0 35%', padding: '20px', verticalAlign: 'middle', height: '350px' }}>
                                <table style={{ width: '-webkit-fill-available' }}>

                                    <br />
                                    <tr>
                                        <td style={{ width: '100%' }}>
                                            <button ID="butSearch" className="btn secondary_btn twfont" onClick={() => this.GoToDocumentsManage()} style={{ width: '100%' }} >Back To Select Documents</button>
                                        </td>
                                    </tr>
                                    <tr><td><hr /></td></tr>
                                    <tr>
                                        <td>
                                            <label className="customer_background_foreground" style={{ fontFamily: 'Tw Cen MT', fontSize: 'large', fontWeight: 'bold', width: '100%', textAlign: 'center' }}>Order Search</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '100%', fontSize: 'large', padding: '0px 20px' }}>
                                            <div className="divGridRadio2">
                                                <div className="radio row1 col1">
                                                    <label className="checkbox-inline " ><input type="radio" style={{ cursor: 'pointer' }} name="optradio" id="historyRadioButton" value="History" onChange={this.handleDocumentTypeChange.bind(this)} /> Processed</label>
                                                </div>
                                                <div className="radio row1 col2">
                                                    <label className="checkbox-inline "><input type="radio" style={{ cursor: 'pointer' }} name="optradio" id="failedRadioButton" value="Failed" onChange={this.handleDocumentTypeChange.bind(this)} /> Failed</label>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2"><hr /></td>
                                    </tr>
                                    <tr className="default_background">
                                        <td colspan="2" style={{ width: '400px', alignContent: 'center', textAlign: 'center' }}>
                                            <div >
                                                <div>
                                                    <input id="refno" name="refno" type="text" onChange={this.handleReferenceNumberChange.bind(this)} className="form-control twfont" value={this.state.referenceNumber} placeholder="Search Text" />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="default_background">
                                        <td colspan="2" style={{ width: '400px', alignContent: 'center', textAlign: 'center' }}>
                                            <div>
                                                <div><input id="startDate" name="startDate" type="date" data-date="" data-date-format="DD MM YYYY" placeholder="day-month-year e.g. (25-10-2019)" onChange={this.handleStartDateChange.bind(this)} value={this.state.startDate} className="form-control twfont" /></div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="default_background">
                                        <td colspan="2" style={{ width: '400px', alignContent: 'center', textAlign: 'center' }}>
                                            <div>
                                                <div><input id="endDate" name="endDate" type="date" data-date="" data-date-format="DD MM YYYY" placeholder="day-month-year e.g. (25-10-2019)" onChange={this.handleEndDateChange.bind(this)} value={this.state.endDate} className="form-control twfont" /></div>
                                            </div>
                                        </td>
                                    </tr>

                                    <br />
                                    <tr>
                                        <td style={{ width: '100%' }}>
                                            <button ID="butSearch" className="btn primary_btn twfont" onClick={() => this.GetDocumentsByReference()} style={{ width: '100%' }} >Search</button>
                                        </td>
                                    </tr>
                                    <tr><td><hr /></td></tr>

                                </table >
                            </div>
                        </div>
                        <div style={{ padding: '20px', height: '100%', width: '100%' }}>
                            <div style={{ height: '100%', width: '100%' }}>
                                <div style={{ visibility: this.state.ordersVisibility, height: this.state.ordersHeight, width: '100%' }}>
                                    <hr />
                                    <br />
                                    <br />
                                    <table className="table table-bordered" style={{ fontFamily: 'TW Cen MT', overflow: 'scroll', whiteSpace: 'nowrap', overflow: 'scroll', maxHeight: '400px' }}>
                                        <thead className="customer_base_background">
                                            <th style={{ padding: '0 20px' }}> Order ID</th>
                                            <th style={{ padding: '0 20px' }}> Approval History</th>
                                            <th style={{ padding: '0 20px' }}> Order Date</th>
                                            <th style={{ padding: '0 20px' }}> Delivery Date</th>
                                            <th style={{ padding: '0 20px' }}> Notes \ Instructions</th>
                                            <th style={{ padding: '0 20px' }}> Approval Status</th>
                                            <th style={{ padding: '0 20px' }}> Order Status</th>
                                            <th style={{ padding: '0 20px' }}> Customer Reference </th>
                                            <th style={{ padding: '0 20px' }}> Ordered By</th>
                                        </thead>
                                        {orderRows}
                                    </table>
                                    <hr />
                                </div>
                            </div>
                        </div>

                        <div style={{ visibility: this.state.invalidDateModalVisibility }}>
                            <div className="outer-container" style={{ top: '0', fontFamily: 'TW Cent', backgroundColor: 'rgba(10,10,10, 0.8)' }} >
                                <div className="inner-container" style={{ height: '1000px' }}>
                                    <div className="centered-content twfont  customer_background_foreground"
                                        style={{ boxShadow: '2px 2px 8px #888888', border: 'none', width: '90%', maxWidth: '450px', backgroundColor: 'rgba(255,255,255,0.3)', fontFamily: 'TW Cen MT' }}>
                                        <table className="customer_background_foreground" style={{ width: '100%', margin: '0 auto', verticalAlign: 'middle', height: '150px', backgroundColor: 'rgba(255,255,255,0.3)' }}>
                                            <tbody>
                                                <tr><td><label style={{ width: '100%', textAlign: 'left', fontSize: 'larger', fontWeight: 'bolder' }}>Message:</label></td></tr>
                                                <tr>
                                                    <td colSpan="2" style={{ width: '100%' }}>
                                                        <textarea id="user" name="user" className="form-control twfont" value={this.state.invalidDateMessage} disabled style={{ height: '100px' }} />
                                                    </td>
                                                </tr>
                                                <tr><td><hr /></td></tr>
                                                <tr>
                                                    <td colSpan="2" style={{ width: '80%' }}>
                                                        <button id="butLogin" className="btn twfont" style={{ border: 'solid 1px black', backgroundColor: 'red', color: 'black', fontWeight: 'bolder', width: '95%' }} type="button" onClick={() => { this.ExitModal() }}>OK</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        );
    }

    //Class Methods
    GetCartState() {

        fetch('/api/Product/GetCartCount', { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == "valued") {
                    this.setState({ toClearCartPrompt: true });
                } else {
                    window.location.href = '\\';
                }
            });
    }

    CheckIfAdmin() {
        fetch('/api/Login/isAdmin?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => { this.setState({ isAdminVisibile: (data == 'Y' ? 'visible' : 'hidden') }, () => { console.log('visibility : data=' + data + ' value= ' + this.state.isAdminVisibile); }) })
    }

    GoToOrderSearch() {
        this.setState({ GoToOrderSearch: true });
    }

    GoToDocuments() {
        this.setState({ toDocuments: true });
    }

    toOrderForm() {
        this.setState({ toOrderForm: true });
    }

    GoToLogIn() {
        this.setState({ toLogIn: true });
    }

    GoToAdmin() {
        this.setState({ toAdmin: true });
    }

    GoBackToProducts() {
        this.setState({ toProducts: true });
    }

    GoToBudget() {
        this.setState({ toBudgets: true });
    }

    GoToOrderForm() {
        this.setState({ toOrderForms: true });
    }

    GoToOrderSearch() {
        this.setState({ GoToOrderSearch: true });
    }

    GoToOrderApproval() {
        this.setState({ toOrderApproval: true });
    }

    GoToSavedCarts() {
        this.setState({ toSavedCarts: true });
    }

    GetUserDetails() {
        fetch('api/Login/GetUserDetails?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.json())
            .then(data => { this.setState({ userDetails: data }) })
            .then(() => {
                console.log("user details");
                console.log(this.state.userDetails);
            })
    }

    CheckIfCanTrack() {
        fetch('/api/Login/canTrack?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => { this.setState({ canTrack: (data == 'Y' ? 'visible' : 'hidden') }, () => { console.log('visibility : data=' + data + ' value= ' + this.state.isAdminVisibile); }) })
    }

    GoToDocumentsManage() {
        this.setState({ toDocumentsManage: true });
    }
}