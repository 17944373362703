import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { Product } from './Products';
import { Route } from 'react-router';
import Login from './Login';
import { SessionTimedOut } from './SessionTimedOut';

export class ClearCartPrompt extends Component {
    displayName = ClearCartPrompt.name

    constructor(props) {
        super(props);
        this.state = {
            toProducts: false,
            toLogIn: false,
            orderPlacedMessage: 'Order placed',
            toSessionTimedOut: false,
        };
        this.CheckSessionInitialize();
    }


    CheckSessionInitialize() {
        this.setState({ complete_update: false });
        console.log('get CheckSession called');

        fetch('api/Login/CheckSession?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });
                }
                else if (data == 'timedout'){
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }

    CheckSession() {
        this.setState({ complete_update: false });
        console.log('get CheckSession called');

        fetch('api/Login/CheckSession?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });
                }
                else if (data == 'timedout'){
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }

    RemoveAllCartItems() {

        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            this.setState({ loadingCart: true });

            fetch('/api/Product/ClearCart' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(response => response.json())
                .then(data => { this.setState({ cartItems: data }) })
                .then(() => {
                    this.setState({ loadingCart: false });
                    console.log(this.state.cartItems);
                });
        }
    }

    GoBackToProducts() {
        this.setState({ toProducts: true });
    }

    ClearCart() {
        this.RemoveAllCartItems();
        this.setState({ toLogIn: true });
    }

    KeepCart() {
        this.setState({ toLogIn: true });
    }

    render() {

        if (this.state.toProducts) {
            return <Route path='/' component={Product} />
        }

        if (this.state.toLogIn) {
            return <Route path='/' component={Login} />
        }

        else {
            return (
                <div className="outer-container customer_background" >
                    <div className="header" style={{ position: 'fixed', zIndex : '500'}}>
                        <div className="grid_container_header" style={{ msHyphens: 'auto', display: 'inline-grid', gridTemplateColumns: '30% 40% 30%', display: 'grid -ms-grid', msGridColumns: '30% 40% 30%', columnCount: '3' }}>
                            <div className="grid_header">
                                <div className="icon_home" style={{ width: '100%', height: '50%', 'margin-top': '30px', 'margin-left': '10%', float: 'left', 'background-repeat': 'no-repeat' }}></div>
                            </div>
                            <div className="grid_header" style={{ 'text-align': 'left', overflow: 'hidden' }}>
                                <div className="header_container" style={{ width: '100%' }}>
                                    <div>
                                        <div style={{ display: 'inline' }}>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="grid_header">
                                <div className="header_container" style={{ width: '100%' }}>
                                    <div>
                                        <div style={{ display: 'inline' }}>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="inner-container">
                        <div className="centered-content  twfont customer_background_foreground" style={{ width: '60%', 'box-shadow': '2px 2px 8px #888888', border: 'none', 'border-radius': '2%', padding: '2%' }}>
                            <hr />
                            <label style={{ fontSize: 'x-large', fontFamily: 'TW Cen MT' }}><i className="material-icons" style={{ paddingLeft: '10px', fontSize: 'xx-large' }}>warning</i><br />You have items in your cart, do you want them to be cleared?</label>
                            <br />
                            <br />
                            <hr />
                            <div className="footerButtons3">
                                <button ID="btnBack" className="btn primary_btn row1 col1" Width="90%" style={{ width: '90%', 'font-family': 'Tw Cen MT', 'margin-left': '5%' }} onClick={() => this.ClearCart()}>Yes</button >
                                <button ID="btnBack" className="btn primary_btn row1 col2" Width="90%" style={{ width: '90%', 'font-family': 'Tw Cen MT', 'margin-left': '5%' }} onClick={() => this.KeepCart()}>No</button >
                                <button ID="btnBack" className="btn secondary_btn row1 col3" Width="90%" style={{ width: '90%', 'font-family': 'Tw Cen MT', 'margin-left': '5%' }} onClick={() => this.GoBackToProducts()}>Cancel</button >
                            </div >
                        </div >
                        
                    </div >
                </div >
            );
        }
    }
}

