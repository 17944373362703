import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { Route } from 'react-router';
import { Product } from './Products';
import { Login } from './Login'
import { Users } from './Users'


import { CheckOut } from './CheckOut';
import { SaveCart } from './SaveCart';
import { SavedCarts } from './SavedCarts';
import { InvoiceOrderSearch } from './InvoiceOrderSearch';
import { Manage } from './Manage';
import { OrderForm } from './Orderform';
import { CreateOrderForm } from './CreateOrderForm';
import { OrderFormList } from './OrderFormList'
import { OrderSearch } from './OrderSearch'
import { Budget } from './Budget'
import { GetStylesheet } from '../registerServiceWorker';
import { ClearCartPrompt } from './ClearCartPrompt';
import { SessionTimedOut } from './SessionTimedOut';


export class AdminUser extends Component {
    displayName = AdminUser.name

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            customerAccounts: [],
            loadingCustomerAccounts: false,

            user: {},

            firstname: '',
            static_firstname: '',
            surname: '',
            static_surname: '',
            email: '',
            cell: '',
            password: '',
            customer: '',
            loginId: '',
            account: '',
            isAdmin: 0,
            isDeleted: 0,
            IsApprover: 0,
            approvalLimit: 0,
            orderLimit: 0,
            removed: 0,
            isBlocked: false,

            toLogin: false,

            toCheckOut: false,
            toSaveCart: false,
            toManage: false,
            toInvoiceCredits: false,
            toOrderForm: false,
            toAdmin: false,

            toBudgets: false,
            toOrderSearch: false,
            toSavedCarts: false,
            toProducts: false,
            toUsers: false,
            stylesheet: '',
            toSessionTimedOut: false,
            loadingApprovers: true,
            approvers: [],

            firstApprovers: [],
            secondApprovers: [],
            thirdApprovers: [],

            firstApproverId: 0,
            secondApproverId: 0,
            thirdApproverId: 0
        };

        this.CheckSessionInitialize();
    }


    //Customer
    CheckSessionInitialize() {
        this.setState({ complete_update: false });
        console.log('get CheckSession called');

        fetch('api/Login/CheckSession?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });
                    this.GetUser();
                    this.GetApproverLists();
                    this.GetCustomerAccounts();
                }
                else if (data == 'timedout') {
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }

    CheckSession() {
        this.setState({ complete_update: false });
        console.log('get CheckSession called');

        fetch('api/Login/CheckSession?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });
                }
                else if (data == 'timedout') {
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }

    GetApproverLists() {
        this.setState({ loadingApprovers: true });

        fetch('api/Admin/GetApproverList', { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.json())
            .then(data => {
                this.setState({ approvers: data });
            }).then(() => {
                console.log('approvers:');
                console.log(this.state.approvers);
                this.state.approvers.forEach((item) => {
                    console.log('item ---');
                    console.log(item);
                    if (item.approvalCode == 1 ) {
                        var fa_concat = this.state.firstApprovers.concat(item);
                        this.setState({ firstApprovers: fa_concat });
                    }
                    else if (item.approvalCode == 2) {
                        var sa_concat = this.state.firstApprovers.concat(item);
                        this.setState({ secondApprovers: sa_concat });
                    }
                    else if (item.approvalCode == 3) {
                        var ta_concat = this.state.firstApprovers.concat(item);
                        this.setState({ thirdApprovers: ta_concat });
                    }
                    else if (item.approvalCode == 4) {
                        var fa_concat = this.state.firstApprovers.concat(item);
                        var sa_concat = this.state.secondApprovers.concat(item);
                        var ta_concat = this.state.thirdApprovers.concat(item);

                        this.setState({
                            firstApprovers: fa_concat,
                            secondApprovers: sa_concat,
                            thirdApprovers: ta_concat
                        });
                    }
                });

                console.log('first approvers: ');
                console.log(this.state.firstApprovers);
                console.log('second approvers: ');
                console.log(this.state.secondApprovers);
                console.log('third approvers: ');
                console.log(this.state.thirdApprovers);

                this.setState({ loadingApprovers: false });
            });


    }

    GetStylesheet() {
        console.log('get stylesheet called');

        fetch('api/Stylesheet/GetStyleSheet', { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.text())
            .then(data => {
                console.log('stylesheet : ');
                console.log(data);
                this.setState({ stylesheet: data });
            });
    }

    GetCustomerAccounts() {
        console.log('get customer accounts');

        this.setState({ loadingCustomerAccounts: true });

        fetch('api/Admin/GetAccountLists' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
            .then(response => response.json())
            .then(data => {
                this.setState({ customerAccounts: data, loadingCustomerAccounts: false })
            });

    }

    renderFirstApprovers() {
        if (this.state.loadingApprovers) {
            return (<option>loading accounts</option>);
        }
        else {
            return (this.state.firstApprovers.map(approver =>
                <option value={approver.id} selected={approver.id === this.state.firstApproverId}>{approver.fullname + ' ' + approver.loginId}</option>
            ));
        }
    }

    renderSecondApprovers() {
        if (this.state.loadingApprovers) {
            return (<option>loading accounts</option>);
        }
        else {
            return (this.state.secondApprovers.map(approver =>
                <option value={approver.id} selected={approver.id === this.state.secondApproverId}>{approver.fullname + ' ' + approver.loginId}</option>
            ));
        }
    }

    renderThirdApprovers() {
        if (this.state.loadingApprovers) {
            return ( <option> loading accounts</option>);
        }
        else {
            return (this.state.thirdApprovers.map(approver =>
                <option value={approver.id} selected={approver.id === this.state.thirdApproverId}>{approver.fullname + ' ' + approver.loginId}</option>
            ));
        }
    }

    renderCustomerAccounts() {

        if (this.state.loadingCustomerAccounts) {
            return (<option>loading accounts</option>);
        }
        else {
            return (this.state.customerAccounts.map(account =>
                <option value={account.code} selected={account.code === this.state.account}>{account.name}</option>
            ));
        }
    }

    //handles
    handleLoginIdChange(e) {
        const input = e.target.value;
        this.setState({ loginId: input },
            console.log('loginId : ' + e.target.value));
    }

    handleNameChange(e) {
        const input = e.target.value;
        this.setState({ firstname: input },
            console.log('firstname : ' + e.target.value));
    }

    handleSurnameChange(e) {
        const input = e.target.value;
        this.setState({ surname: input },
            console.log('surname : ' + e.target.value));
    }

    handleEmailChange(e) {
        this.setState({ email: e.target.value },
            console.log('email : ' + e.target.value));
    }

    handleCellChange(e) {
        this.setState({ cell: e.target.value },
            console.log('cell : ' + e.target.value));
    }

    handleCustomerChange(e) {
        this.setState({ customer: e.target.value },
            console.log('customer : ' + e.target.value));
    }

    handleAccountChange(e) {
        this.setState({ account: e.target.value },
            console.log('account : ' + e.target.value));
    }

    //approvers

    handleFirstApproverChange(e) {
        this.setState({ firstApproverId: e.target.value },
            console.log('fapproverId : ' + e.target.value));
    }

    handleSecondApproverChange(e) {
        this.setState({ secondApproverId: e.target.value },
            console.log('sapproverId : ' + e.target.value));
    }

    handleThirdApproverChange(e) {
        this.setState({ thirdApproverId: e.target.value },
            console.log('tapproverId : ' + e.target.value));
    }

    ///////////////////////////////////////////////
    handlePasswordChange(e) {
        this.setState({ password: e.target.value },
            console.log('password : ' + e.target.value));
    }

    handleApprovalLimitChange(e) {
        this.setState({ approvalLimit: e.target.value },
            console.log('approvalLimit : ' + e.target.value));
    }

    handleOrderLimitChange(e) {
        this.setState({ orderLimit: e.target.value },
            console.log('orderLimit : ' + e.target.value));
    }

    handleIsApproverChecked(e) {

        if (e.target.checked) {
            this.setState({ IsApprover: 1 })
        }
        else {
            this.setState({ IsApprover: 0 })
        }

        console.log('isapprover : ' + this.state.IsApprover);
    }

    handleIsDeletedChecked(e) {

        if (e.target.checked) {
            this.setState({ isDeleted: 1 })
        }
        else {
            this.setState({ isDeleted: 0 })
        }

        console.log('isdeleted : ' + this.state.isDeleted);
    }

    handleIsAdministratorChecked(e) {

        if (e.target.checked) {
            this.setState({ isAdmin: 1 })
        }
        else {
            this.setState({ isAdmin: 0 })
        }
        console.log('isadmin : ' + this.state.isAdmin);
    }

    handleIsBlocked(e) {

        if (e.target.checked) {
            this.setState({ isBlocked: true })
        }
        else {
            this.setState({ isBlocked: false })
        }
        console.log('isblocked : ' + this.state.isBlocked);
    }

    //rest


    GetUser() {
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            fetch('api/Admin/GetUser' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(response => response.json())
                .then(data => {
                    this.setState({ user: data }, () => {
                        console.log('user');
                        console.log(this.state.user);
                        this.GetCustomerAccounts();
                        this.setState(
                            {
                                firstname: this.state.user.firstname,
                                static_firstname: this.state.user.firstname,
                                surname: this.state.user.surname,
                                static_surname: this.state.user.surname,
                                password: this.state.user.password,
                                account: this.state.user.accountCode,
                                email: this.state.user.email,
                                cell: this.state.user.cell,
                                customer: this.state.user.companyID,
                                isAdmin: this.state.user.isAdministrator,
                                isDeleted: this.state.user.isDeleted,
                                IsApprover: this.state.user.isApprover,
                                approvalLimit: this.state.user.approvalLimit,
                                orderLimit: this.state.user.orderLimit,
                                removed: this.state.user.removed,
                                isBlocked: this.state.user.isBlocked,
                                loginId: this.state.user.loginID,

                                firstApproverId: this.state.user.firstApprover,
                                secondApproverId: this.state.user.secondApprover,
                                thirdApproverId: this.state.user.thirdApprover,
                            }, () => {
                                console.log(this.state.firstname + ' ' + this.state.surname + ' your password is : ' + this.state.password + ' and you are ' + this.state.isBlocked + ' with customer :  ' + this.state.customer);
                            });

                    });
                });
        }


    }

    EditUser() {

        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            let userID = this.state.user.userID;
            let firstname = this.state.firstname;
            let surname = this.state.surname;
            let password = this.state.password;
            let account = this.state.account;
            let email = this.state.email;
            let cell = this.state.cell;
            let customerId = this.state.customer;
            let isAdmin = this.state.isAdmin;
            let isDeleted = this.state.isDeleted;
            let IsApprover = this.state.IsApprover;
            let approvalLimit = this.state.approvalLimit;
            let orderLimit = this.state.orderLimit;
            let removed = this.state.removed;
            let isBlocked = this.state.isBlocked;
            let loginId = this.state.loginId;

            let fApproverId = this.state.firstApproverId;
            let sApproverId = this.state.secondApproverId;
            let tApproverId = this.state.thirdApproverId;

            fetch('api/Admin/EditUser?userID=' + userID + '&firstname=' + firstname + '&surname=' + surname + '&password=' + password
                + '&accountCode=' + account + '&email=' + email + '&cell=' + cell + '&isAdministrator='
                + isAdmin + '&isDeleted=' + isDeleted + '&isApprover=' + IsApprover + '&approvalLimit='
                + approvalLimit + '&orderLimit=' + orderLimit + '&companyID=' + customerId + '&removed='
                + removed + '&isBlocked=' + isBlocked + '&loginId=' + loginId
                + '&fapprover=' + fApproverId + '&sapprover=' + sApproverId + '&tapprover=' + tApproverId
                + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control' : 'no-cache, no-store', Pragma : 'no-cache' })
                .then(() => {
                    console.log('User Edited'); this.setState({ toLogin: true });
                });
        }
    }



    IsChecked(value) {
        let result = '';

        if (value == 1)
            result = true;
        else if (value == 0)
            result = false;

        console.log('checkbox value is : ' + result);
        return result;
    }


    LogUserOut() {
        this.setState({ toLogin: true });
    }

    GoToProduct() {
        this.setState({ toProducts: true });
    }

    GoBackToProducts() {
        this.setState({ toProducts: true });
    }

    GoToBudget() {
        this.setState({ toBudgets: true });
    }

    GoToOrderForm() {
        this.setState({ toOrderForms: true });
    }

    GoToOrderSearch() {
        this.setState({ GoToOrderSearch: true });
    }

    GoToSavedCarts() {
        this.setState({ toSavedCarts: true });
    }


    GoBackToUsers() {
        this.setState({ toUsers: true });
    }

    render() {

        let firstname = this.state.firstname;
        let loginId = this.state.loginId;
        let static_firstname = this.state.static_firstname;
        let surname = this.state.surname;
        let static_surname = this.state.static_surname;
        let password = this.state.password;
        let account = this.state.account;
        let email = this.state.email;
        let cell = this.state.cell;
        let customerId = this.state.customer;
        let isAdmin = this.state.isAdmin;
        let isDeleted = this.state.isDeleted;
        let IsApprover = this.state.IsApprover;
        let approvalLimit = this.state.approvalLimit;
        let orderLimit = this.state.orderLimit;
        let removed = this.state.removed;
        let isBlocked = this.state.isBlocked;


        if (this.state.toClearCartPrompt) {
            return <Route path='/' component={ClearCartPrompt} />
        }

        if (this.state.toSessionTimedOut) {
            return <Route path='/' component={SessionTimedOut} />
        }

        if (this.state.toProducts) {
            return <Route path='/' component={Product} />
        }

        if (this.state.GoToOrderSearch) {
            return <Route path='/' component={OrderSearch} />
        }

        if (this.state.toBudgets) {
            return <Route path='/' component={Budget} />
        }

        if (this.state.toSavedCarts) {
            return <Route path='/' component={SavedCarts} />
        }

        if (this.state.toOrderForm) {
            return <Route path='/' component={OrderFormList} />
        }

        if (this.state.toSaveCart) {
            return <Route path='/' component={SaveCart} />
        }

        if (this.state.toManage) {
            return <Route path='/' component={Manage} />
        }

        if (this.state.toCheckOut) {
            return <Route path='/' component={CheckOut} />
        }

        if (this.state.toLogin) {
            return <Route path='/' component={Login} />
        }

        if (this.state.toUsers) {
            return <Route path='/' component={Users} />
        }
        else {
            let customer = this.renderCustomerAccounts();

            let firstApprovers = this.renderFirstApprovers();
            let secondApprovers = this.renderSecondApprovers();
            let thirdApprovers = this.renderThirdApprovers();

            let user = this.state.user;
            return (
                <div>
                    <link href={this.state.stylesheet} rel="stylesheet" />
                    <div style={{ top: '0', left: '0', height: '100%' }}>
                        <div className="outer-container customer_background" style={{ fontFamily: 'TW Cent' }}>
                            <div className="header" style={{ position: 'absolute', zIndex : '5000' }}>
                                <div className="grid_container_header" style={{ msHyphens: 'auto', display: 'inline-grid', gridTemplateColumns: '30% 40% 30%', display: 'grid -ms-grid', msGridColumns: '30% 40% 30%', columnCount: '3' }}>
                                    <div className="grid_header">
                                        <div className="icon_home" style={{ width: '100%', height: '50%', 'margin-top': '30px', 'margin-left': '10%', float: 'left', 'background-repeat': 'no-repeat' }}></div>
                                    </div>
                                    <div className="grid_header" style={{ 'text-align': 'left', overflow: 'hidden' }}>
                                        <div className="header_container" style={{ width: '100%' }}>
                                            <div>
                                                <div style={{ display: 'inline' }}>
                                                    <a className="header_text" onClick={() => this.GoBackToUsers()} >Go Back</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid_header">
                                        <div className="header_container" style={{ width: '100%', 'padding-right': '15%' }}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className=" sidenav customer_background_foreground" style={{ width: '100%', minHeight: '1000px', marginTop: '100px' }}>
                                <div style={{ width: '100%', textAlign: 'center' }}>
                                    <br />
                                    <div className="customer_background_foreground" style={{ width: '70%', margin: '0 15%', padding: '20px', verticalAlign: 'middle', height: '300px' }}>
                                        <div>
                                            <label className="customer_background_foreground" style={{ fontFamily: 'Tw Cen MT', fontSize: 'large', fontWeight: 'bold', width: '100%', textAlign: 'center' }}>Edit {static_firstname + ' ' + static_surname}</label>
                                            <hr />
                                        </div>
                                        <div style={{ padding: '0 20%' }}>
                                            <br />

                                            <table className="customer_background_foreground" style={{ width: '100%', margin: '0 auto', verticalAlign: 'middle', height: '200px', backgroundColor: 'rgba(255,255,255,0.3)' }}>
                                                <tr>
                                                    <td colspan="2" style={{ width: '100%' }}>
                                                        <div style={{ display: 'inline-grid', gridTemplateColumns: '20% 80%', width: '100%' }}>
                                                            <div><label className="form-control twfont" style={{ background: 'transparent', border: 'none', textAlign: 'left' }}>Login ID : </label></div>
                                                            <div><input id="loginId" name="loginId" type="text" value={loginId} disabled onChange={this.handleNameChange.bind(this)} className="form-control twfont" /></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr><td><br /></td></tr>
                                                <tr>
                                                    <td colspan="2" style={{ width: '100%' }}>
                                                        <div style={{ display: 'inline-grid', gridTemplateColumns: '20% 80%', width: '100%' }}>
                                                            <div><label className="form-control twfont" style={{ background: 'transparent', border: 'none', textAlign: 'left' }}>Firstname : </label></div>
                                                            <div><input id="firstname" name="firstname" type="text" value={firstname} onChange={this.handleNameChange.bind(this)} className="form-control twfont" /></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr><td><br /></td></tr>
                                                <tr>
                                                    <td colspan="2" style={{ width: '100%' }}>
                                                        <div style={{ display: 'inline-grid', gridTemplateColumns: '20% 80%', width: '100%' }}>
                                                            <div><label className="form-control twfont" style={{ background: 'transparent', border: 'none', textAlign: 'left' }}>Surname : </label></div>
                                                            <div><input id="surname" name="surname" type="text" value={surname} onChange={this.handleSurnameChange.bind(this)} className="form-control twfont" /></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr><td><br /></td></tr>
                                                <tr>
                                                    <td colspan="2" style={{ width: '100%' }}>
                                                        <div style={{ display: 'inline-grid', gridTemplateColumns: '20% 80%', width: '100%' }}>
                                                            <div><label className="form-control twfont" style={{ background: 'transparent', border: 'none', textAlign: 'left' }}>Email : </label></div>
                                                            <div><input id="register_email" name="register_email" type="text" value={email} onChange={this.handleEmailChange.bind(this)} className="form-control twfont" /></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr><td><br /></td></tr>
                                                <tr>
                                                    <td colspan="2" style={{ width: '100%' }}>
                                                        <div style={{ display: 'inline-grid', gridTemplateColumns: '20% 80%', width: '100%' }}>
                                                            <div><label className="form-control twfont" style={{ background: 'transparent', border: 'none', textAlign: 'left' }}>Cell : </label></div>
                                                            <div><input id="cell" className="form-control twfont" type="text" value={cell} onChange={this.handleCellChange.bind(this)} /></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr><td><br /></td></tr>
                                                <tr>
                                                    <td colspan="2" style={{ width: '100%' }}>
                                                        <div style={{ display: 'inline-grid', gridTemplateColumns: '20% 80%', width: '100%' }}>
                                                            <div><label className="form-control twfont" style={{ background: 'transparent', border: 'none', textAlign: 'left' }}>Password : </label></div>
                                                            <div><input id="password" type="password" className="form-control twfont" type="text" value={password} onChange={this.handlePasswordChange.bind(this)} /></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr><td><br /></td></tr>
                                                <tr>
                                                    <td colspan="2" style={{ width: '100%' }}>
                                                        <div style={{ display: 'inline-grid', gridTemplateColumns: '20% 20% 1% 59%', width: '100%' }}>
                                                            <div><label className="form-control twfont" style={{ background: 'transparent', border: 'none', textAlign: 'left' }}>Account: </label></div>
                                                            <div><input id="customer" type="text" className="form-control twfont" type="text" value={account} disabled /></div>
                                                            <div></div>
                                                            <div>

                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr><td><br /></td></tr>
                                                <tr>
                                                    <td colspan="2" style={{ width: '100%' }}>
                                                        <div style={{ display: 'inline-grid', gridTemplateColumns: '20% 80%', width: '100%' }}>
                                                            <div><label className="form-control twfont" style={{ background: 'transparent', border: 'none', textAlign: 'left' }}>Approval Limit: </label></div>
                                                            <div><input id="ApprovalLimit" className="form-control twfont" type="number" value={approvalLimit} onChange={this.handleApprovalLimitChange.bind(this)} /></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr><td><br /></td></tr>
                                                <tr>
                                                    <td colspan="2" style={{ width: '100%' }}>
                                                        <div style={{ display: 'inline-grid', gridTemplateColumns: '20% 80%', width: '100%' }}>
                                                            <div><label className="form-control twfont" style={{ background: 'transparent', border: 'none', textAlign: 'left' }}>Order Limit: </label></div>
                                                            <div><input id="OrderLimit" name="OrderLimit" type="number" value={orderLimit} onChange={this.handleOrderLimitChange.bind(this)} className="form-control twfont" /></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr><td><hr /></td></tr>
                                                <tr>
                                                    <td colspan="2">
                                                        <label className="twfont" style={{ fontSize: 'larger' }}>Approvers</label>
                                                    </td>
                                                </tr>
                                                <tr><td><br /></td></tr>
                                                <tr>
                                                    <td colspan="2" style={{ width: '100%' }}>
                                                        <div style={{ display: 'inline-grid', gridTemplateColumns: '20% 80%', width: '100%' }}>
                                                            <div><label className="form-control twfont" style={{ background: 'transparent', border: 'none', textAlign: 'left' }}>1st Approver: </label></div>
                                                            <div>
                                                                <select id="firstApprovers" onChange={this.handleFirstApproverChange.bind(this)} className="form-control twfont" >
                                                                    <option value="0">None...</option>
                                                                    {firstApprovers}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr><td><br /></td></tr>
                                                <tr>
                                                    <td colspan="2" style={{ width: '100%' }}>
                                                        <div style={{ display: 'inline-grid', gridTemplateColumns: '20% 80%', width: '100%' }}>
                                                            <div><label className="form-control twfont" style={{ background: 'transparent', border: 'none', textAlign: 'left' }}>2nd Approver: </label></div>
                                                            <div>
                                                                <select id="secondApprovers" onChange={this.handleSecondApproverChange.bind(this)} className="form-control twfont" >
                                                                    <option value="0">None...</option>
                                                                    {secondApprovers}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr><td><br /></td></tr>
                                                <tr>
                                                    <td colspan="2" style={{ width: '100%' }}>
                                                        <div style={{ display: 'inline-grid', gridTemplateColumns: '20% 80%', width: '100%' }}>
                                                            <div><label className="form-control twfont" style={{ background: 'transparent', border: 'none', textAlign: 'left' }}>3rd Approver: </label></div>
                                                            <div>
                                                                <select id="thirdApproers" onChange={this.handleThirdApproverChange.bind(this)} className="form-control twfont" >
                                                                    <option value="0">None...</option>
                                                                    {thirdApprovers}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr><td><br /></td></tr>
                                                <tr><td><hr /></td></tr>
                                                <tr>
                                                    <td colspan="1">
                                                        <label className="twfont" style={{ float: 'left', width: '50%' }}>Is Aministrator</label>
                                                        {React.createElement('input', { type: 'checkbox', checked: this.IsChecked(isAdmin), value: isAdmin, onClick: this.handleIsAdministratorChecked.bind(this) })}
                                                    </td>
                                                    <td colspan="1">
                                                        <label className="twfont" style={{ float: 'left', width: '50%' }}>Is Deleted</label>
                                                        {React.createElement('input', { type: 'checkbox', checked: this.IsChecked(isDeleted), value: isDeleted, onClick: this.handleIsDeletedChecked.bind(this) })}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="1">
                                                        <label className="twfont" style={{ float: 'left', width: '50%' }}>Is Approver</label>
                                                        {React.createElement('input', { type: 'checkbox', checked: this.IsChecked(IsApprover), value: IsApprover, onClick: this.handleIsApproverChecked.bind(this) })}
                                                    </td>
                                                    <td colspan="1">
                                                        <label className="twfont" style={{ float: 'left', width: '50%' }}>Is Blocked</label>
                                                        {React.createElement('input', { type: 'checkbox', checked: this.IsChecked(isBlocked), value: isBlocked, onClick: this.handleIsBlocked.bind(this) })}
                                                    </td>
                                                </tr>
                                                <tr><td><hr /></td></tr>
                                                <tr>
                                                    <td colspan="2" style={{ width: '100%' }}>
                                                        <button id="register" onClick={() => this.EditUser()} className="form-control btn primary_btn twfont">Save</button>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

    }
}

//<select id="account" onChange={this.handleAccountChange.bind(this)} className="form-control twfont" disabled>
//    <option>Select Account...</option>
//    {customer}
//</select>
