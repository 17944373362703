import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { OrderPlaced } from './OrderPlaced';
import { OrderProcessing } from './OrderProcessing'
import { PaymentScreen } from './PaymentScreen'
import { Route } from 'react-router';
import { ChangePassword } from './ChangePassword';
import { Link } from 'react-router-dom';

import { Product } from './Products';
import { Login } from './Login';
import { SavedCarts } from './SavedCarts';
import { Budget } from './Budget'
import { InvoiceOrderSearch } from './InvoiceOrderSearch';
import { OrderSearch } from './OrderSearch'
import { AdminUser } from './AdminUser'
import { Users } from './Users';
import { OrderApproval } from './OrderApproval'
import { ClearCartPrompt } from './ClearCartPrompt';
import { SessionTimedOut } from './SessionTimedOut';
import { DocumentsManage } from './DocumentsManage';
import { PaymentPortalDisabled } from './PaymentPortalDisabled';
import { AdminManage } from './AdminManage';

export class CheckOut extends Component {
    displayName = CheckOut.name

    constructor(props) {
        super(props);
        this.state = {
            toProducts: false,
            toClearCartPrompt: false,
            userDetails: {},
            toChangePassword: false,
            menuVisibility: 'hidden',
            orderLimitMessage: '',
            toOrderPlaced: false,
            toOrderProcessing: false,
            ToLogin: false,
            addresses: [],
            loadingAddresses: false,
            bPPaddresses: [],
            loadingBPPAddresses: false,
            orderList: [],
            loadingOrders: false,
            total: {},
            totalValue: 0,
            totalText: '',
            selectedAddress: '',
            selectedBPPAddress: '',
            addrborder: '#ced4da',
            deliveryNote: '',
            emailAddress: '',
            orderDetails: {},
            emailBorder: '#ced4da',
            customerRef: '',
            customerRefBorder: '#ced4da',

            orderButtonClicked: '',
            goBackVisibility: 'visible',
            contentHeight: 'unset',
            loaderHeight: '0',


            toCheckOut: false,
            toClearCartPrompt: false,
            toSaveCart: false,
            toManage: false,
            toDocuments: false,
            toOrderForm: false,
            toAdmin: false,
            toLogIn: false,
            toBudgets: false,
            toOrderSearch: false,
            toOrderApproval: false,
            toSavedCarts: false,
            toInactivePaymentPortal: false,

            canTrack: 'hidden',
            isAdminVisibile: 'hidden',
            toSessionTimedOut: false,
            toDocumentsManage: false,
            budgetMessage: 'Validating Budgets...',
            loadingBudgetMessage: true,

            accountPaymentActive: false,
            cardPaymentActive: false,
            eftPaymentActive: false,
            paymentTypesCount: 0,
            isPaymentPortalActive: 'false',
            cardPaymentModalVisibility: 'hidden',
            deliveryAddressVisible: 'hidden',
            skipPaymentVisible: false,

            deliveryMessage: '',
            collectDeliverType: '',
            collectVisible: 'hidden',
            collectionAddressVisible: 'hidden',
            selectedDelivery: '',

            termsNotification: 'hidden',
            termsAccepted: 'hidden',
            termsNotice: 'none',
            brandingRequirement: 'hidden',

            footerClass: '',

            deliveryFee: '',
            deliveryFeeVisible: 'hidden',

            specialinstructions: '',
            specialinstructionsBorder: '#ced4da'
        };

        this.CheckSessionInitialize();
    }


    CheckSessionInitialize() {
        this.setState({ complete_update: false });

        fetch('api/Login/CheckSession?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });

                    this.ValidateBudget();
                    this.GetUserDetails();
                    this.GetOrderDetails();
                    this.GetOrderList();
                    this.CheckIfAdmin();
                    this.CheckIfCanTrack();
                    this.loadAddress();
                    this.GetEmailAddress(); //added by am 20230516
                    this.loadBPPAddress();
                    this.GetPaymentType();
                    this.ValidatePaymentRequired();
                    this.ValidateLabelOrder();
                }
                else if (data == 'timedout') {
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }

    CheckSession() {
        this.setState({ complete_update: false });

        fetch('api/Login/CheckSession?buster=' + + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == 'running') {
                    this.setState({ toSessionTimedOut: false });
                }
                else if (data == 'timedout') {
                    this.setState({ toSessionTimedOut: true });
                }
            });
    }

    GetPaymentType() {
        this.setState({ complete_update: false });

        fetch('api/CheckOut/GetPaymentType?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.json())
            .then(data => {
                this.setState({ accountPaymentActive: data.accountActive == 1 });
                this.setState({ cardPaymentActive: data.cardActive == 1 });
                this.setState({ eftPaymentActive: data.eftActive == 1 });
                this.setState({ paymentTypesCount: data.activeCount });
                this.setState({ footerClass: 'footerButtons' + (data.activeCount + 1) });
            });
    }

    GoBackToProducts() {
        this.setState({ toProducts: true });
    }

    LogUserOut() {

        this.setState({ ToLogin: true });

    }

    handleSelectedAddress(e) {
        let { name, value } = e.target;
        this.setState({ selectedAddress: e.target.value }
            , () => {
                console.log(this.state.selectedAddress);

                if (this.state.selectedAddress === '') {
                    console.log('selected address is empty');
                }
                else {
                    console.log('getting products');
                }
            })
    }

    handleSelectedBPPAddress(e) {
        let { name, value } = e.target;
        this.setState({ selectedBPPAddress: e.target.value }
            , () => {
                console.log(this.state.selectedBPPAddress);

                if (this.state.selectedBPPAddress === '') {
                    console.log('selected address is empty');
                }
                else {
                    console.log('getting products');
                }
            })
    }

    ValidateLabelOrder() {
        fetch('/api/Label/HasLabels?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' }, { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                console.log('has labels : ' + data);
                if (data == 'false')
                    this.setState({ deliveryAddressVisible: 'visible' });
                else
                    this.setState({ deliveryAddressVisible: 'hidden' });
            });
    }

    handleDeliveryNote(e) {
        let inputtext = e.target.value;
        this.setState({ deliveryNote: inputtext });
    }

    handleEmailChange(e) {
        let inputtext = e.target.value;
        this.setState({ emailAddress: inputtext });
    }

    handleCustomerRef(e) {
        let inputtext = e.target.value;
        this.setState({ customerRef: inputtext });
    }

    ValidateOrder() {
        fetch('/api/CheckOut/ValidateOrder' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                this.setState({ orderLimitMessage: data });
            })

    }

    ValidatePaymentPortal(type) {
        fetch('/api/CheckOut/ValidatePaymentPortal' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                this.setState({ isPaymentPortalActive: data });

                if (data == 'true') {
                    this.PlaceCardPayment(type);
                }
                else {
                    this.setState({ toInactivePaymentPortal: true });
                }
            });

    }

    ValidateBudget() {
        this.setState({ loadingBudgetMessage: true });

        fetch('/api/Budget/GetExceededBudgetMessages' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                this.setState({ budgetMessage: data, loadingBudgetMessage: false });
            })

    }

    GetEmailAddress() {

        fetch('/api/CheckOut/GetEmailAddress' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data != '') {
                    this.setState({ emailAddress: data });
                }
            })
    }

    GetOrderDetails() {

        fetch('/api/CheckOut/GetOrderDetails' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    emailAddress: data.email,
                    deliveryNote: data.notes,
                    customerRef: data.customerRef,
                    deliveryFee: data.deliveryFee,
                    collectVisible: data.collectDeliver,
                    selectedAddress: data.deliveryAddressId,
                    deliveryMessage: data.deliveryMessage,
                    termsNotification: data.termsNotification,
                    brandingRequirement: data.brandingRequirement,
                }, () => { this.loadAddress() });
                //if this is a delivery fee order then add the delivery fee when initializing
                if (this.state.collectVisible == 'visible') {
                    this.setState({
                        selectedDelivery: 'deliver'
                    });
                    this.CreateDeliveryFeeOrderLine();
                    //this.GetOrderTotal();
                }
                if (this.state.collectVisible != 'visible') {
                    this.setState({
                        termsAccepted: 'visible'
                    });
                }
                //^&^ 20240429 branding requirements
                if (this.state.brandingRequirement == 'hidden') {
                    this.setState({
                        specialinstructions: 'Special Instructions:'
                    });
                }
                if (this.state.brandingRequirement == 'visible') {
                    this.setState({
                        specialinstructions: 'Branding Requirements :*'
                    });
                }
                //if (this.state.termsNotification == 'hidden') {
                //    this.setState({
                //        specialinstructions: 'Special Instructions:'
                //    });
                //}
                //if (this.state.termsNotification == 'visible') {
                //    this.setState({
                //        specialinstructions: 'Branding Requirements :*'
                //    });
                //}
                if (this.state.deliveryFee != '0') {
                    this.setState({ deliveryFeeVisible: 'visible' })
                }
                if (this.state.deliveryFee == '0') {
                    this.setState({ deliveryFeeVisible: 'hidden' })
                }
            })
    }

    //GetFeeOnPageLoad() {
    //    //if this is a delivery fee order then add the delivery fee when initializing
    //    if (this.state.collectVisible == 'visible') {
    //        this.setState({ selectedDelivery: 'deliver' });
    //        this.CreateDeliveryFeeOrderLine();
    //        this.GetOrderList();
    //        this.GetOrderTotal();
    //    }
    //}


    ValidatePaymentRequired() {

        fetch('/api/CheckOut/ValidatePaymentRequired' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                console.log('val res : '+data);
                if (data == 'false') {
                    this.setState({ skipPaymentVisible: true });
                }
            });

    }

    PlaceDefaultPayment() {
        if (this.state.toSessionTimedOut == false) {

            let valid = true;
            this.setState({ emailBorder: '#ced4da', addrborder: '#ced4da', customerRefBorder: '#ced4da' });

            if (this.state.emailAddress == '') {
                this.setState({ emailBorder: 'red' });
                valid = false;
            }

            if (this.state.customerRef == '') {
                this.setState({ customerRef: ' ' });
            }

            if (this.state.deliveryNote == '') {
                this.setState({ deliveryNote: ' ' });
            }

            if (valid) {
                this.setState({ orderButtonClicked: 'disabled', goBackVisibility: 'hidden', contentHeight: 0, loaderHeight: '101.68px' });
                fetch('/api/CheckOut/PlaceDefaultOrderRequest?orderNotes='  + this.state.deliveryNote + '&orderRef=' + this.state.customerRef
                                                                            + '&emailAddress=' + this.state.emailAddress
                                                                            + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
                    .then(() => {
                        this.setState({ toOrderPlaced: true });
                    });
            }
        }

        
    }

    GetOrderTotal() {

        fetch('/api/CheckOut/GetOrderTotal' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.json())
            .then(data => {
                this.setState({ total: data, totalValue: data.value, totalText: data.text }, () => {


                    this.ValidateOrder();
                    this.ValidateBudget();
                })
            });

    }

    GetOrderList() {

        this.setState({ loadingOrders: true });

        fetch('/api/CheckOut/GetOrder' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' }, { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.json())
            .then(data => { this.setState({ orderList: data }) })
            .then(() => {
                this.setState({ loadingOrders: false });
                this.GetOrderTotal();
            })
    }

    PlaceOrder() {
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            let valid = true;
            this.setState({ emailBorder: '#ced4da', addrborder: '#ced4da', customerRefBorder: '#ced4da' });


            if (this.state.selectedAddress == '') {
                this.setState({ addrborder: 'red' });
                valid = false;
            }

            //added for collect address
            if (this.state.selectedBPPAddress == undefined && this.state.collectionAddressVisible == 'visible') {
                this.setState({ addrborder: 'red' });
                valid = false;
            }
            if (this.state.selectedBPPAddress == '' && this.state.collectionAddressVisible == 'visible') {
                this.setState({ addrborder: 'red' });
                valid = false;
            }

            if (this.state.emailAddress == '') {
                this.setState({ emailBorder: 'red' });
                valid = false;
            }


            /*if (this.state.customerRef == '' && this.state.deliveryAddressVisible == 'visible') {*/
            if (this.state.customerRef == '') {
                this.setState({ customerRefBorder: 'red' });
                valid = false;
            }

            /* IF THE ORDER IS A CUSTOM BRANDING ORDER, E.G. SMITH POWER, THEN SET THE CUSTOM BRANDING AS REQUIRED */
            if (this.state.specialinstructions == 'Branding Requirements :*' && this.state.deliveryNote == '') {
                this.setState({ specialinstructionsBorder: 'red' });
                valid = false;
            }

            if (valid) {
                this.setState({ orderButtonClicked: 'disabled', goBackVisibility: 'hidden', contentHeight: 0, loaderHeight: '101.68px' });

                fetch('/api/CheckOut/PlaceOrder?deliveryAddress=' + this.state.selectedAddress + '&orderNotes=' + this.state.deliveryNote + '&orderRef=' + this.state.customerRef + '&emailAddress=' + this.state.emailAddress + '&collectAddressID=' + this.state.selectedBPPAddress + '&collectDeliver=' + this.state.selectedDelivery + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
                    .then(() => {
                        this.setState({ toOrderPlaced: true });
                    });
            }
        }
    }


    ChoosePaypalPaymentType() {
        this.CheckSession();

        if (this.state.toSessionTimedOut == false) {
            console.log('storing order');
            let valid = true;
            this.setState({ emailBorder: '#ced4da', addrborder: '#ced4da', customerRefBorder: '#ced4da' });


            if (this.state.selectedAddress == '') {
                this.setState({ addrborder: 'red' });
                valid = false;
            }

            if (this.state.emailAddress == '') {
                this.setState({ emailBorder: 'red' });
                valid = false;
            }

            if (this.state.customerRef == '') {
                this.setState({ customerRefBorder: 'red' });
                valid = false;
            }

            /* IF THE ORDER IS A CUSTOM BRANDING ORDER, E.G. SMITH POWER, THEN SET THE CUSTOM BRANDING AS REQUIRED */
            if (this.state.specialinstructions == 'Branding Requirements :*' && this.state.deliveryNote == '') {
                this.setState({ specialinstructionsBorder: 'red' });
                valid = false;
            }

            if (valid) {

                let isCardPayment = this.state.cardPaymentActive;
                let isEftPayment = this.state.eftPaymentActive;

                console.log('Card: ' + isCardPayment);
                console.log('Eft: ' + isCardPayment);

                if (isCardPayment && !isEftPayment) {
                    this.PlaceOrderPaypal('credit');
                    console.log('just card called');
                }
                else if (isEftPayment && !isCardPayment) {
                    this.PlaceOrderPaypal('eft');
                    console.log('just eft called');
                }
                else if (isCardPayment && isEftPayment) {
                    console.log('both called');
                    this.setState({ cardPaymentModalVisibility: 'visible' });
                }

            }
        }
    }

    HidePaymentModal() {
        this.setState({ cardPaymentModalVisibility: 'hidden' });
    }

    PlaceOrderPaypal(type) {
        this.CheckSession();
        this.ValidatePaymentPortal(type);
    }

    PlaceCardPayment(type) {

        if (this.state.toSessionTimedOut == false) {

            let valid = true;
            this.setState({ emailBorder: '#ced4da', addrborder: '#ced4da', customerRefBorder: '#ced4da' });


            if (this.state.selectedAddress == '') {
                this.setState({ addrborder: 'red' });
                valid = false;
            }

            if (this.state.emailAddress == '') {
                this.setState({ emailBorder: 'red' });
                valid = false;
            }

            if (this.state.customerRef == '') {
                this.setState({ customerRefBorder: 'red' });
                valid = false;
            }

            if (valid) {
                this.setState({ orderButtonClicked: 'disabled', goBackVisibility: 'hidden', contentHeight: 0, loaderHeight: '101.68px' });

                fetch('/api/Paypal/PlaceOrder?deliveryAddress=' + this.state.selectedAddress + '&orderNotes=' + this.state.deliveryNote + '&orderRef=' + this.state.customerRef + '&emailAddress=' + this.state.emailAddress + '&paymentType=' + type + '&collectDeliver=' + this.state.selectedDelivery + '&buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
                    .then(response => response.text())
                    .then((data) => {
                        //this.SetSession(data);
                        this.setState({ toOrderProcessing: true });
                    });
            }
        }
    }

    loadAddress() {
        this.setState({ loadingAddresses: true });

        fetch('/api/CheckOut/GetAddressess' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.json())
            .then(data => { this.setState({ addresses: data, loadingAddresses: true }) })
            .then(() => {
                let firstRecord = this.state.addresses[0].deliveryId;
                let defaultAddress = (this.state.selectedAddress == 0 ? firstRecord : this.state.selectedAddress);

                console.log('1. Selected Address: ' + this.state.selectedAddress);
                console.log('2. First Record: ' + firstRecord);
                console.log('3. Default Address: ' + defaultAddress);

                this.setState({ loadingAddresses: false, selectedAddress: defaultAddress });

            });
    }

    LoadAddresesToDropDown() {
        /*here*/
        let selectedAddress = this.state.selectedAddress;

        if (this.state.loadingAddresses) {
            return (<option>loading...</option>);
        }
        else {
            return (this.state.addresses.map(addressItem =>
                <option value={addressItem.deliveryId} selected={selectedAddress == addressItem.deliveryId}>{addressItem.address}</option>
            ));
        }
    }

    loadBPPAddress() {
        this.setState({ loadingBPPAddresses: true });

        fetch('/api/CheckOut/GetBPPAddresses' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.json())
            .then(data => { this.setState({ bPPaddresses: data, loadingBPPAddresses: true }) })
            .then(() => {
                let firstRecord = this.state.bPPaddresses[0].addressId;
                this.setState({ loadingBPPAddresses: false, selectedBPPAddress: firstRecord });

            });
    }

    //loadBPPAddress() {
    //    this.setState({ loadingBPPAddresses: true });

    //    fetch('/api/CheckOut/GetBPPAddresses' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
    //        .then(response => response.json())
    //        .then(data => { this.setState({ bPPaddresses: data, loadingBPPAddresses: true }) })
    //        .then(() => {
    //            let firstRecord = this.state.addresses[0].deliveryId;
    //            this.setState({ loadingBPPAddresses: false, selectedBPPAddress: firstRecord });

    //        });
    //}

    LoadBPPAddresesToDropDown() {

        let selectedBPPAddress = this.state.selectedBPPAddress;

        if (this.state.loadingBPPAddresses) {
            return (<option>loading...</option>);
        }
        else {
            return (this.state.bPPaddresses.map(addressItem =>
                <option value={addressItem.deliveryId} selected={selectedBPPAddress == addressItem.deliveryId}>{addressItem.address}</option>
            ));
        }
    }

    GetFooterContent() {
        let skipPaymentVisbile = this.state.skipPaymentVisible;
        let isAccountOnly = this.state.accountPaymentActive && this.state.paymentTypesCount == 1;
        let isCardPayment = (this.state.cardPaymentActive || this.state.eftPaymentActive) && !this.state.accountPaymentActive && (this.state.paymentTypesCount == 1 || this.state.paymentTypesCount == 2);
        let isOptionalPayment = (this.state.cardPaymentActive || this.state.eftPaymentActive) && this.state.accountPaymentActive;

        if (skipPaymentVisbile) {
            return (
                <div>
                    {/*DISABLED IN 20230804 REQUEST FROM MMABATO<div style={{ visibility: this.state.termsNotification, fontFamily: 'TW Cen MT', fontSize: 'large', fontWeight: 'bolder', color: 'red' }} id="spTermsQuote">*/}
                    {/*    <p>Please note a quote will be sent to you of branding requirements which is not included in this order.</p>*/}
                    {/*    </div>*/}
                    <div style={{ visibility: this.state.termsNotification, padding: "1em", margin: "2em", border: "3px solid #ddd", backgroundColor: "eee", maxWidth: "1200px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}><input style={{ visibility: this.state.termsNotification, marginRight: '10px' }} onChange={this.handleTermsChange.bind(this)} type="checkbox" id="chkTerms" /><label style={{ fontFamily: 'TW Cen MT', fontSize: 'large', fontWeight: 'bolder', marginRight: '10px' }}>I accept the Terms & Conditions</label><button style={{ fontFamily: 'TW Cen MT', fontSize: 'large', fontWeight: 'bolder' }} className="btn secondary_btn" onClick={() => this.handleTermsNoticeChange()} >View the Terms & Conditions</button>
                    </div>
                    <div style={{ display: this.state.termsNotice, fontFamily: 'TW Cen MT', fontSize: 'medium' }} id="spTerms">
                        <p>The pricing indicated on the portal includes the placement of our brands as agreed with the supplier. Should you wish to add additional logo etc, you should specify this in the branding requirement field when placing an order. After doing so, you will receive a revised pricing quote that includes the additional branding requirements.</p>
                        <p>Prices subject to change without notice.</p>
                        <p>Smith Power Equipment reserves the right of positioning for all pre-determined branding on behalf of the company.</p>
                        <p>Smith Power Equipment reserves the right to approve all branding on merchandise and collateral through this online store.</p>
                        <p>Should you wish to add your brands onto items, Smith Power reserves the right to approve before production.</p>
                        <p>Smith Power Equipment reserves the right to remove, add or edit items as it sees fit.</p>
                        <p>Smith Power Equipment welcomes input and ideas from our dealer network on possible additions to this online store.</p>
                        <p>All purchases made from this store are for the Dealers account by default. </p>
                        <p>Smith Power Equipment nor the supplier can be held liable for incorrect fitting clothing. It is suggested to size up by one size if ordering to fit.</p>
                        <p>Smith Power Equipment recommends add a minimum of 10% as a buffer to all required qtys for clothing, especially in commonly requested sizes (Mens L – 3XL and Ladies M – XL).</p>
                    </div>
                    <div id="pmtButtons" style={{ visibility: this.state.termsAccepted }}>
                    <div id="SkipPaymentVisibile" className="footerButtons2" style={{ height: '21px' }}>
                    <button id="btnBack" className="btn primary_btn row1 col1" disabled={this.state.orderButtonClicked} style={{ width: '90%', fontFamily: 'Tw Cen MT', marginLeft: '5%' }} onClick={() => this.GoBackToProducts()}> Return To Order</button >
                    <button id="btnSubmit" className="btn secondary_btn row1 col2" disabled={this.state.orderButtonClicked} style={{ width: '90%', fontFamily: 'Tw Cen MT' }} onClick={() => { this.PlaceDefaultPayment() }}>Confirm and Proceed</button>
                        </div>
                    </div>
                </div>
            );
        }
        else {
            if (isAccountOnly) {
                return (
                    <div>
                        {/*DISABLED IN 20230804 REQUEST FROM MMABATO<div style={{ visibility: this.state.termsNotification, fontFamily: 'TW Cen MT', fontSize: 'large', fontWeight: 'bolder', color: 'red' }} id="spTermsQuote">*/}
                        {/*    <p>Please note a quote will be sent to you of branding requirements which is not included in this order.</p>*/}
                        {/*</div>*/}
                        <div style={{ visibility: this.state.termsNotification, padding: "1em", margin: "2em", border: "3px solid #ddd", backgroundColor: "eee", maxWidth: "1200px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}><input style={{ visibility: this.state.termsNotification, marginRight: '10px' }} onChange={this.handleTermsChange.bind(this)} type="checkbox" id="chkTerms" /><label style={{ fontFamily: 'TW Cen MT', fontSize: 'large', fontWeight: 'bolder', marginRight: '10px' }}>I accept the Terms & Conditions</label><button style={{ fontFamily: 'TW Cen MT', fontSize: 'large', fontWeight: 'bolder' }} className="btn secondary_btn" onClick={() => this.handleTermsNoticeChange()} >View the Terms & Conditions</button>
                        </div>
                        <div style={{ display: this.state.termsNotice, fontFamily: 'TW Cen MT', fontSize: 'medium' }} id="spTerms">
                            <p>The pricing indicated on the portal includes the placement of our brands as agreed with the supplier. Should you wish to add additional logo etc, you should specify this in the branding requirement field when placing an order. After doing so, you will receive a revised pricing quote that includes the additional branding requirements.</p>
                            <p>Prices subject to change without notice.</p>
                            <p>Smith Power Equipment reserves the right of positioning for all pre-determined branding on behalf of the company.</p>
                            <p>Smith Power Equipment reserves the right to approve all branding on merchandise and collateral through this online store.</p>
                            <p>Should you wish to add your brands onto items, Smith Power reserves the right to approve before production.</p>
                            <p>Smith Power Equipment reserves the right to remove, add or edit items as it sees fit.</p>
                            <p>Smith Power Equipment welcomes input and ideas from our dealer network on possible additions to this online store.</p>
                            <p>All purchases made from this store are for the Dealers account by default. </p>
                            <p>Smith Power Equipment nor the supplier can be held liable for incorrect fitting clothing. It is suggested to size up by one size if ordering to fit.</p>
                            <p>Smith Power Equipment recommends add a minimum of 10% as a buffer to all required qtys for clothing, especially in commonly requested sizes (Mens L – 3XL and Ladies M – XL).</p>
                        </div>
                        <div id="pmtButtons" style={{ visibility: this.state.termsAccepted }}>
                        <div id="AccountOnlyPaymentActiveDiv" className="footerButtons2" style={{ height: '21px' }}>
                    <button id="btnBack" className="btn primary_btn row1 col1" disabled={this.state.orderButtonClicked} style={{ width: '90%', fontFamily: 'Tw Cen MT', marginLeft: '5%' }} onClick={() => this.GoBackToProducts()}> Return To Order</button >
                    <button id="btnSubmit" className="btn secondary_btn row1 col2" disabled={this.state.orderButtonClicked} style={{ width: '90%', fontFamily: 'Tw Cen MT' }} onClick={()/**/ => { this.PlaceOrder() }}>Pay On Account</button>
                            </div></div></div>);
            }
            else if (isCardPayment) {
                return (
                    <div>
                        {/*DISABLED IN 20230804 REQUEST FROM MMABATO<div style={{ visibility: this.state.termsNotification, fontFamily: 'TW Cen MT', fontSize: 'large', fontWeight: 'bolder', color: 'red' }} id="spTermsQuote">*/}
                        {/*    <p>Please note a quote will be sent to you of branding requirements which is not included in this order.</p>*/}
                        {/*</div>*/}
                        <div style={{ visibility: this.state.termsNotification, padding: "1em", margin: "2em", border: "3px solid #ddd", backgroundColor: "eee", maxWidth: "1200px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}><input style={{ visibility: this.state.termsNotification, marginRight: '10px' }} onChange={this.handleTermsChange.bind(this)} type="checkbox" id="chkTerms" /><label style={{ fontFamily: 'TW Cen MT', fontSize: 'large', fontWeight: 'bolder', marginRight: '10px' }}>I accept the Terms & Conditions</label><button style={{ fontFamily: 'TW Cen MT', fontSize: 'large', fontWeight: 'bolder' }} className="btn secondary_btn" onClick={() => this.handleTermsNoticeChange()} >View the Terms & Conditions</button>
                        </div>
                        <div style={{ display: this.state.termsNotice, fontFamily: 'TW Cen MT', fontSize: 'medium' }} id="spTerms">
                            <p>The pricing indicated on the portal includes the placement of our brands as agreed with the supplier. Should you wish to add additional logo etc, you should specify this in the branding requirement field when placing an order. After doing so, you will receive a revised pricing quote that includes the additional branding requirements.</p>
                            <p>Prices subject to change without notice.</p>
                            <p>Smith Power Equipment reserves the right of positioning for all pre-determined branding on behalf of the company.</p>
                            <p>Smith Power Equipment reserves the right to approve all branding on merchandise and collateral through this online store.</p>
                            <p>Should you wish to add your brands onto items, Smith Power reserves the right to approve before production.</p>
                            <p>Smith Power Equipment reserves the right to remove, add or edit items as it sees fit.</p>
                            <p>Smith Power Equipment welcomes input and ideas from our dealer network on possible additions to this online store.</p>
                            <p>All purchases made from this store are for the Dealers account by default. </p>
                            <p>Smith Power Equipment nor the supplier can be held liable for incorrect fitting clothing. It is suggested to size up by one size if ordering to fit.</p>
                            <p>Smith Power Equipment recommends add a minimum of 10% as a buffer to all required qtys for clothing, especially in commonly requested sizes (Mens L – 3XL and Ladies M – XL).</p>
                        </div>
                        <div id="pmtButtons" style={{ visibility: this.state.termsAccepted }}>
                            <div id="OnlineOnlyPaymentActiveDiv" className="footerButtons2" style={{ height: '21px' }}>
                            <button id="btnBack" className="btn primary_btn row1 col1" disabled={this.state.orderButtonClicked} style={{ width: '90%', fontFamily: 'Tw Cen MT', marginLeft: '5%' }} onClick={() => this.GoBackToProducts()}> Return To Order</button >
                            <button id="btnSubmitCard" className="btn secondary_btn row1 col2" disabled={this.state.orderButtonClicked} style={{ width: '90%', fontFamily: 'Tw Cen MT' }} onClick={() => { this.ChoosePaypalPaymentType() }}>Pay on Card or EFT</button>
                            </div>
                        </div>
                    </div>);
            }
            else if (isOptionalPayment) {
                return (
                    <div>
                        {/*DISABLED IN 20230817 REQUEST FROM MMABATO<div style={{ visibility: this.state.termsNotification, fontFamily: 'TW Cen MT', fontSize: 'large', fontWeight: 'bolder', color: 'red' }} id="spTermsQuote">*/}
                        {/*    <p>Please note a quote will be sent to you of branding requirements which is not included in this order.</p>*/}
                        {/*</div>*/}
                        <div style={{ visibility: this.state.termsNotification, padding: "1em", margin: "2em", border: "3px solid #ddd", backgroundColor: "eee", maxWidth: "1200px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}><input style={{ visibility: this.state.termsNotification, marginRight: '10px' }} onChange={this.handleTermsChange.bind(this)} type="checkbox" id="chkTerms" /><label style={{ fontFamily: 'TW Cen MT', fontSize: 'large', fontWeight: 'bolder', marginRight: '10px' }}>I accept the Terms & Conditions</label><button style={{ fontFamily: 'TW Cen MT', fontSize: 'large', fontWeight: 'bolder' }} className="btn secondary_btn" onClick={() => this.handleTermsNoticeChange()} >View the Terms & Conditions</button>
                        </div>
                        <div style={{ display: this.state.termsNotice, fontFamily: 'TW Cen MT', fontSize: 'medium' }} id="spTerms">
                            <p>The pricing indicated on the portal includes the placement of our brands as agreed with the supplier. Should you wish to add additional logo etc, you should specify this in the branding requirement field when placing an order. After doing so, you will receive a revised pricing quote that includes the additional branding requirements.</p>
                            <p>Prices subject to change without notice.</p>
                            <p>Smith Power Equipment reserves the right of positioning for all pre-determined branding on behalf of the company.</p>
                            <p>Smith Power Equipment reserves the right to approve all branding on merchandise and collateral through this online store.</p>
                            <p>Should you wish to add your brands onto items, Smith Power reserves the right to approve before production.</p>
                            <p>Smith Power Equipment reserves the right to remove, add or edit items as it sees fit.</p>
                            <p>Smith Power Equipment welcomes input and ideas from our dealer network on possible additions to this online store.</p>
                            <p>All purchases made from this store are for the Dealers account by default. </p>
                            <p>Smith Power Equipment nor the supplier can be held liable for incorrect fitting clothing. It is suggested to size up by one size if ordering to fit.</p>
                            <p>Smith Power Equipment recommends add a minimum of 10% as a buffer to all required qtys for clothing, especially in commonly requested sizes (Mens L – 3XL and Ladies M – XL).</p>
                        </div>
                        <div id="pmtButtons" style={{ visibility: this.state.termsAccepted }}>
                        <div id="AllPaymentsActiveDiv" className="footerButtons3" style={{ height: '21px' }}>
                    <button id="btnBack" className="btn primary_btn row1 col1" disabled={this.state.orderButtonClicked} style={{ width: '90%', fontFamily: 'Tw Cen MT', marginLeft: '5%' }} onClick={() => this.GoBackToProducts()}> Return To Order</button >
                    <button id="btnSubmit" className="btn secondary_btn row1 col2" disabled={this.state.orderButtonClicked} style={{ width: '90%', fontFamily: 'Tw Cen MT' }} onClick={() => { this.PlaceOrder() }}>Pay On Account</button>
                    <button id="btnSubmitCard" className="btn secondary_btn row1 col3" disabled={this.state.orderButtonClicked} style={{ width: '90%', fontFamily: 'Tw Cen MT' }} onClick={() => { this.ChoosePaypalPaymentType() }}>Pay on Card or EFT</button>
                            </div></div></div>);
            }
        }

    }

    LoadTableOrder() {

        if (this.state.loadingOrders) {
            return (
                <tr>
                    <td>loading...</td>
                    <td>loading...</td>
                    <td>loading...</td>
                    <td>loading...</td>
                    <td>loading...</td>
                    <td>loading...</td>
                </tr>
            );

        }
        else {
            return (
                this.state.orderList.map(order =>
                    <tr>
                        <td className="sideBorder">{order.productID}</td>
                        <td className="sideBorder" style={{ textAlign: 'left' }}>{order.itemDescription}</td>
                        <td className="sideBorder">{order.uomDescription}</td>
                        <td className="sideBorder">{order.qty}</td>
                        <td className="sideBorder">R {Number(order.price).toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        <td className="sideBorder">R {Number(order.extendedPrice).toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        <td className="sideBorder">R {Number(order.vat).toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        <td className="sideBorder">R {Number(order.extendedPriceWithVat).toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                    </tr>
                ));
        }

    }

    LoadBudgetMessage() {

        if (this.state.loadingBudgetMessage)
            return (<label style={{ fontFamily: 'TW Cen MT', fontSize: 'large', fontWeight: 'bolder' }}>Validating order...</label>);
        else
            return (<label style={{ fontFamily: 'TW Cen MT', color: 'orangered', fontSize: 'x-large', fontWeight: 'bolder' }}>{this.state.budgetMessage}</label>);

    }


    ToChangePassword() {
        fetch('/api/Login/CanUpdatePassword' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(response => {

                if (response == 'true')
                    this.setState({ toChangePassword: true });
                else
                    alert('You currently do not have the required permissions to change your password.');

            });
    }

    MenuClick() {

        if (this.state.menuVisibility == 'hidden') {
            this.setState({ menuVisibility: 'visible' });
        }
        else if (this.state.menuVisibility == 'visible') {
            this.setState({ menuVisibility: 'hidden' });
        }
    }


    render() {

        //Header Redirect

        if (this.state.toSessionTimedOut) {
            return <Route path='/' component={SessionTimedOut} />
        }

        if (this.state.toClearCartPrompt) {
            return <Route path='/' component={ClearCartPrompt} />
        }

        if (this.state.toAdmin) {
            return <Route path='/' component={AdminManage} />
        }

        if (this.state.toDocumentsManage) {
            return <Route path='/' component={DocumentsManage} />
        }

        if (this.state.toChangePassword) {
            return <Route path='/' component={ChangePassword} />

        }


        if (this.state.GoToOrderSearch) {
            return <Route path='/' component={OrderSearch} />
        }

        if (this.state.toBudgets) {
            return <Route path='/' component={Budget} />
        }

        if (this.state.toSavedCarts) {
            return <Route path='/' component={SavedCarts} />
        }

        if (this.state.toCheckOut) {
            return <Route path='/' component={CheckOut} />
        }

        if (this.state.toDocuments) {
            return <Route path='/' component={InvoiceOrderSearch} />
        }

        if (this.state.toOrderApproval) {
            return <Route path='/' component={OrderApproval} />
        }

        if (this.state.toLogIn) {
            return <Route path='/' component={Login} />
        }

        if (this.state.toProducts) {
            return <Route path='/' component={Product} />
        }

        if (this.state.toClearCartPrompt) {
            return <Route path='/' component={ClearCartPrompt} />
        }


        ////end

        if (this.state.ToLogin) {
            return <Route path='/' component={Login} />
        }

        if (this.state.toOrderPlaced) {
            return <Route path='/' component={OrderPlaced} />
        }

        if (this.state.toOrderProcessing) {
            return <Route path='/' component={PaymentScreen} />
            //return <Route path='/' component={OrderProcessing} />
        }

        if (this.state.toInactivePaymentPortal) {
            return <Route path='/' component={PaymentPortalDisabled} />
        }

        else if (this.state.toProducts) {
            return <Route path='/' component={Product} />
        }

        else {
            let addresses = this.LoadAddresesToDropDown();
            let bPPaddresses = this.LoadBPPAddresesToDropDown();
            let orderRows = this.LoadTableOrder();
            let orderTotal = this.state.totalValue;

            let deliveryId = this.state.selectedAddress;
            let notes = this.state.deliveryNote;
            let email = this.state.emailAddress;
            let custRef = this.state.customerRef;

            let totalVat = this.state.total.vat;
            let totalWithVat = this.state.total.totalWithVat;
            let footerContent = this.GetFooterContent();
            let budgetMessage = this.LoadBudgetMessage();
            let deliveryMessage = this.state.deliveryMessage;
            let collectionAddressVisible = this.state.collectionAddressVisible;
            let selectedDelivery = this.state.selectedDelivery;
            let termsNotification = this.state.termsNotification;
            let termsAccepted = this.state.termsAccepted;
            let termsNotice = this.state.termsNotice;
            let brandingRequirement = this.state.brandingRequirement;

            let deliveryFeeNote = this.state.deliveryFee;

            return (
                <div className="outer-container customer_background">
                    <div className="header" style={{ position: 'fixed', zIndex: '500' }}>
                        <div className="grid_container_header" style={{ msHyphens: 'auto', display: 'inline-grid', gridTemplateColumns: '30% 40% 30%', display: 'grid -ms-grid', msGridColumns: '30% 40% 30%', columnCount: '3' }}>
                            <div className="grid_header">
                                <div className="icon_home" style={{ width: '100%', height: '50%', marginTop: '30px', marginLeft: '10%', float: 'left', backgroundRepeat: 'no-repeat' }}></div>
                            </div>
                            <div className="grid_header" style={{ textAlign: 'left', overflow: 'hidden' }}>
                                <div className="header_container" style={{ width: '100%' }}>
                                    <div>
                                        <div style={{ display: 'inline' }}>
                                            <a className="header_text" onClick={() => this.GoBackToProducts()}  >Place Order</a>|
                                                <a className="header_text" onClick={() => this.GoToDocumentsManage()} >Documents</a><span style={{ visibility: this.state.canTrack }}>|</span>
                                            <a className="header_text" onClick={() => this.GoToOrderApproval()} style={{ visibility: this.state.canTrack }} >Order Approval</a>
                                            <span style={{ visibility: 'visible' }}>|</span>
                                            <a className="header_text" onClick={() => this.GoToBudget()} style={{ visibility: 'visible' }}>Budgets</a>
                                            <span style={{ visibility: this.state.isAdminVisibile }}>|</span>
                                            <a className="header_text" onClick={() => this.GoToAdmin()} style={{ visibility: this.state.isAdminVisibile }} >Admin</a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="grid_header">
                                <div className="header_container greeting" style={{ width: '100%' }}>
                                    <div className="header_text grid_item_1" style={{ lineHeight: '40px', textAlign: 'right' }}>
                                        <div style={{ height: '28px' }} className="overflow_header"> {this.state.userDetails.greeting + ', ' + this.state.userDetails.fullname}</div>
                                        <div style={{ height: '25px' }} className="overflow_header">{this.state.userDetails.accountName}</div>
                                        <div style={{ height: '28px' }} className="overflow_header">Group : {this.state.userDetails.accountCode}</div>
                                    </div>
                                    <div className="grid_item_2_reverse  h100">
                                        <div style={{ width: '100%', 'height': '100%' }}>
                                            <button id="btnMenu" onClick={() => this.MenuClick()} className="btn primary_btn header_btn twfont" style={{ lineHeight: '0.8', marginTop: '10px' }}  ><i className="material-icons" style={{ width: '100%' }} >menu</i><label>Options</label></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="header_menu_container" style={{ visibility: this.state.menuVisibility, marginTop: '100px' }}>
                        <div className="grid_item_1 max-height-menu">
                            <button onClick={() => { this.MenuClick() }} style={{ width: '100%', height: '100%', backgroundColor: 'transparent', border: 'none' }}></button>
                        </div>
                        <div className="header_menu grid_item_2 max-height-menu customer_background_foreground">
                            <hr />
                            <div>
                                <button id="canUpdateBtn" name="canUpdateBtn" className="btn  header_menu_item" onClick={() => { this.ToChangePassword() }}>Reset Password <i className="material-icons" style={{ position: 'relative', top: '6px', paddingLeft: '10px' }}>vpn_key</i></button>
                            </div>
                            <hr />
                            <div>
                                <button className="btn  header_menu_item" onClick={() => { this.GetCartState() }}>Logout <i className="material-icons" style={{ position: 'relative', top: '6px', paddingLeft: '10px' }}>exit_to_app</i></button>
                            </div>
                            <hr />
                        </div>
                    </div>
                    <div className=" sidenav customer_background_foreground" style={{ width: '100%', minHeight: '1000px', marginTop: '100px' }}>
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <br />
                            <div className="customer_background_foreground" style={{ width: '90%', margin: '0 5%', padding: '20px', verticalAlign: 'middle', height: '300px' }}>
                                <div>
                                    <label className="customer_background_foreground twfont" style={{ fontSize: 'xx-large', fontWeight: 'bolder', fontFamily: 'TW Cen MT' }}>My Order</label>
                                    <hr />
                                    <div>
                                        {budgetMessage}
                                    </div>
                                    <hr />
                                </div>
                                <div>
                                    <label id="lblMessage" ></label><br />
                                </div>

                                {/*get delivery fee if applicable start*/}
                                <div id="divDeliveryFee" className="divGridColumn" style={{ visibility: this.state.collectVisible }}>
                                    <div className="row1 col1">
                                        <label className="table_font twfont">Deliveries:</label>
                                    </div>
                                    <div className="row1 col2">
                                        <label id="lblDeliveryNote" style={{ fontFamily: 'TW Cen MT', fontSize: 'large' }} id="txtDeliveryNote">{deliveryMessage}</label>
                                    </div>
                                    <div className="row2 col1">
                                        <label className="table_font twfont">Collect or Deliver:</label>
                                    </div>
                                    <div className="row2 col2">
                                        <label className="checkbox-inline " style={{ fontFamily: 'TW Cen MT', fontSize: 'large' }}><input type="radio" style={{ cursor: 'pointer', width: '100px' }} name="optradio" checked={this.state.selectedDelivery === 'deliver'} onChange={this.handleCollectDeliverChange.bind(this)} id="deliver" value="deliver" /> Deliver</label>
                                        <label className="checkbox-inline " style={{ fontFamily: 'TW Cen MT', fontSize: 'large' }}><input type="radio" style={{ cursor: 'pointer', width: '100px' }} name="optradio" checked={this.state.selectedDelivery === 'collect'} onChange={this.handleCollectDeliverChange.bind(this)} id="collect" value="collect" /> Collect</label>
                                    </div>
                                    <div className="row3 col1" style={{ visibility: this.state.deliveryFeeVisible }}>
                                        <label className="table_font twfont">Delivery Fee:</label>
                                    </div>
                                    <div className="row3 col2" style={{ visibility: this.state.deliveryFeeVisible }}>
                                        <input id="lblDeliveryFee" id="txtDeliveryFee" readOnly={true} value={deliveryFeeNote} type="text" className="form-control twfont" maxLength="250" />
                                    </div>
                                    <div className="row4 col1" style={{ visibility: this.state.collectionAddressVisible }}>
                                        <label className="table_font twfont" >Collection Address:</label>
                                    </div>
                                    <div className="row4 col2" style={{ visibility: this.state.collectionAddressVisible }}>
                                        <select id="ddlBPPAddress" className="form-control twfont" style={{ borderColor: this.state.addrborder }} disabled={this.state.orderButtonClicked} onChange={this.handleSelectedBPPAddress.bind(this)}>
                                            {bPPaddresses}
                                        </select>
                                    </div>

                                </div>
                                {/*get delivery fee if applicable end*/}

                                <div className="divGridColumn" style={{ visibility: this.state.goBackVisibility, height: this.state.contentHeight }}>
                                    <div className="row1 col1" style={{ visibility: this.state.deliveryAddressVisible }}>
                                        <label id="lblDel" className="table_font twfont">Delivery Address:<span style={{ color: 'red' }}>*</span></label>
                                    </div>
                                    <div className="row1 col2" style={{ marginBottom: '10px', visibility: this.state.deliveryAddressVisible }}>
                                        <select id="ddlAddress" className="form-control twfont" style={{ borderColor: this.state.addrborder }} disabled={this.state.orderButtonClicked} onChange={this.handleSelectedAddress.bind(this)}>
                                            {addresses}
                                        </select>
                                    </div>

                                    <div className="row2 col1" style={{ visibility: this.state.userDetails.activeDeliveryDate ? 'visible' : 'hidden' }} >
                                        <label id="Label5" className="table_font twfont" >Delivery Date:</label>
                                    </div>
                                    <div className="row2 col2" style={{ marginBottom: '10px', visibility: this.state.userDetails.activeDeliveryDate ? 'visible' : 'hidden' }}>
                                        <select id="ddlDeliveryDate" className="form-control twfont"  >
                                            <option value="Next Available">Next Available</option>
                                        </select>
                                    </div>

                                    <div className="row3 col1">
                                        <label id="lblInstructionsNote" className="table_font twfont">{this.state.specialinstructions}</label >
                                    </div >
                                    <div className="row3 col2" style={{ marginBottom: '10px' }}>
                                        <input disabled={this.state.orderButtonClicked} type="text" id="txtNotes" value={notes} className="form-control twfont" style={{ borderColor: this.state.specialinstructionsBorder }} onChange={this.handleDeliveryNote.bind(this)} maxLength="250" />
                                    </div >

                                    <div className="row4 col1">
                                        <label id="lblNote" className="table_font twfont">Email:<span style={{ color: 'red' }}>*</span></label >
                                    </div >
                                    <div className="row4 col2" style={{ marginBottom: '10px' }}>
                                        <input disabled={this.state.orderButtonClicked} type="text" id="txtEmail" value={email} style={{ borderColor: this.state.emailBorder }} className="form-control twfont" onChange={this.handleEmailChange.bind(this)} />
                                    </div >

                                    <div className="row5 col1">
                                        <label id="lblNote" className="table_font twfont">Customer Ref. <span style={{ color: 'red' }}>*</span></label >
                                    </div >
                                    <div className="row5 col2" style={{ marginBottom: '10px' }}>
                                        <input disabled={this.state.orderButtonClicked} type="text" id="txtCustRef" maxLength="30" value={custRef} style={{ borderColor: this.state.customerRefBorder }} className="form-control twfont" onChange={this.handleCustomerRef.bind(this)} maxLength="30" />
                                    </div >

                                </div >
                                <div style={{ visibility: this.state.orderButtonClicked ? 'visible' : 'hidden', height: this.state.loaderHeight }}>
                                    <div >
                                        <label style={{ fontSize: 'larger', fontWeight: 'bold' }}>Sending Order</label>
                                        <br />
                                        <div className="spinner-border" style={{ color: 'red' }} role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        <br />
                                        <label style={{ fontSize: 'larger', fontWeight: 'bold' }}>Please wait...</label>
                                    </div>
                                </div>
                                <div>
                                    <br />
                                    <table className="table table-bordered twfont" style={{ width: '100%' }}>
                                        <thead>
                                        <tr className="customer_base_background">
                                            <th style={{ textAlign: 'center' }}>Product Code</th>
                                            <th style={{ textAlign: 'center' }}>Product</th>
                                            <th style={{ textAlign: 'center' }}>UOM</th>
                                            <th style={{ textAlign: 'center' }}>Quantity</th>
                                            <th style={{ textAlign: 'center' }}>Unit Price</th>
                                            <th style={{ textAlign: 'center' }}>Ext Price (Excl VAT.)</th>
                                            <th style={{ textAlign: 'center' }}>VAT</th>
                                            <th style={{ textAlign: 'center' }}>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {orderRows}
                                        <tr><td colSpan="4" style={{ border: 'none' }}></td>
                                            <th className="customer_base_background" style={{ fontSize: 'x-large', fontWeight: 'bold' }}>Total</th>
                                            <td className="sideBorder">R {this.state.totalValue.toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td className="sideBorder">R {Number(totalVat).toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} </td>
                                            <td className="sideBorder">R {Number(totalWithVat).toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <div>
                                        <label style={{ fontFamily: 'TW Cen MT', color: 'orangered', fontSize: 'x-large', fontWeight: 'bolder' }}>{this.state.orderLimitMessage}</label>
                                    </div>
                                    <hr />
                                    <div id="footerBtnContainer">
                                        {footerContent}
                                    </div>
                                    <br />
                                    <br />
                                    <hr />

                                </div >
                            </div>
                        </div >
                    </div >
                    <div style={{ visibility: this.state.cardPaymentModalVisibility }}>
                        <div className="outer-container" style={{ top: '0', fontFamily: 'TW Cent', backgroundColor: 'rgba(10,10,10, 0.8)' }} >
                            <div className="inner-container" style={{ height: '1000px', paddingTop: '100px', verticalAlign: 'inherit' }}>
                                <div className="centered-content twfont  customer_background_foreground"
                                    style={{ boxShadow: '2px 2px 8px #888888', border: 'none', width: '90%', maxWidth: '450px', backgroundColor: 'rgba(255,255,255,0.3)', fontFamily: 'TW Cen MT', height: '400px' }}>
                                    <table className="customer_background_foreground" style={{ width: '100%', margin: '0 auto', verticalAlign: 'middle', height: '250px', backgroundColor: 'rgba(255,255,255,0.3)' }}>
                                        <tbody>
                                            <tr><td colSpan="2"><label style={{ width: '100%', textAlign: 'left', fontSize: 'larger', fontWeight: 'bolder' }}>Select Payment Type</label></td></tr>
                                            <tr><td colSpan="2"><hr /></td></tr>
                                            <tr>
                                                <td>
                                                    <button id="btnCard" className="btn twfont primary_btn" style={{ border: 'solid 1px black', fontWeight: 'bolder', width: '100%' }} type="button" onClick={() => { this.PlaceOrderPaypal('card') }}>Credit/Debit</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <button id="btnEFT" className="btn twfont tertiary_btn" style={{ border: 'solid 1px black', fontWeight: 'bolder', width: '100%' }} type="button" onClick={() => { this.PlaceOrderPaypal('eft') }}>EFT</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <button id="btnBack" className="btn twfont secondary_btn" style={{ border: 'solid 1px black', fontWeight: 'bolder', width: '100%' }} type="button" onClick={() => { this.HidePaymentModal() }}>Back</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table >
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            );
        }
    }

    //// Route Change

    GetCartState() {

        fetch('/api/Product/GetCartCount' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                if (data == "valued") {
                    this.setState({ toClearCartPrompt: true });
                } else {
                    window.location.href = '\\';
                }
            });
    }

    GoToDocumentsManage() {
        this.setState({ toDocumentsManage: true });
    }

    CheckIfAdmin() {
        fetch('/api/Login/isAdmin' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => {
                this.setState({ isAdminVisibile: (data == 'Y' ? 'visible' : 'hidden') });
            });
    }

    GoToOrderSearch() {
        this.setState({ GoToOrderSearch: true });
    }

    GoToDocuments() {
        this.setState({ toDocuments: true });
    }

    GoToLogIn() {
        if (this.state.orderList.length > 0) {
            this.setState({ toClearCartPrompt: true });
        } else { this.setState({ toLogIn: true }); }
    }

    GoToAdmin() {
        this.setState({ toAdmin: true });
    }

    GoBackToProducts() {
        this.setState({ toProducts: true });
    }

    GoToBudget() {
        this.setState({ toBudgets: true });
    }

    GoToOrderForm() {
        this.setState({ toOrderForms: true });
    }

    GoToOrderSearch() {
        this.setState({ GoToOrderSearch: true });
    }

    GoToOrderApproval() {
        this.setState({ toOrderApproval: true });
    }

    GoToSavedCarts() {
        this.setState({ toSavedCarts: true });
    }


    GetUserDetails() {
        fetch('api/Login/GetUserDetails' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.json())
            .then(data => { this.setState({ userDetails: data }) })
            .then(() => {
                console.log("user details");
                console.log(this.state.userDetails);
            })
    }

    CheckIfCanTrack() {
        fetch('/api/Login/canTrack' + '?buster=' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
            .then(response => response.text())
            .then(data => { this.setState({ canTrack: (data == 'Y' ? 'visible' : 'hidden') }, () => { console.log('visibility : data=' + data + ' value= ' + this.state.isAdminVisibile); }) })
    }

    CreateDeliveryFeeOrderLine() {
        if (this.state.deliveryFee != 0) {
            fetch('/api/CheckOut/CreateDeliveryFeeOrderLine?price=' + this.state.deliveryFee + '&buster =' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
                .then(() => this.GetOrderList())
        }
        if (this.state.deliveryFee == 0) {
            fetch('/api/CheckOut/DeleteDeliveryFeeOrderLine?id=DEL_FEE&buster =' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
                .then(() => this.GetOrderList())
        }
    }

    DeleteDeliveryFeeOrderLine() {
        fetch('/api/CheckOut/DeleteDeliveryFeeOrderLine?id=DEL_FEE&buster =' + new Date().getTime(), { credentials: "same-origin", 'Cache-Control': 'no-cache, no-store', Pragma: 'no-cache' })
    }

    handleTermsChange(e) {
        let checVal = e.target.checked;
        console.log(checVal);
        if (checVal == true) {
            this.setState({
                termsAccepted: 'visible'
            });
        }
        if (checVal == false) {
            this.setState({
                termsAccepted: 'hidden'
            });
        }

    }

    handleTermsNoticeChange() {
        if (this.state.termsNotice == 'none') {
            this.setState({
                termsNotice: 'block'
            });
        }
        else if (this.state.termsNotice == 'block') {
            this.setState({
                termsNotice: 'none'
            });
        }

    }

    handleCollectDeliverChange(e) {
        this.setState({
            selectedDelivery: e.target.value
        });
        console.log('selectedDelivery final state : ' + this.state.selectedDelivery);
        console.log('collectDeliverType:' + this.state.collectDeliverType)
        this.setState({ collectDeliverType: e.target.value },
            () => {
                console.log('Document Type : ' + this.state.collectDeliverType);

                if (this.state.collectDeliverType == 'collect') {
                    //console.log('Collect : ' + this.state.collectVisible);
                    //console.log('selectedDelivery : ' + this.state.selectedDelivery);
                    this.setState({
                        collectionAddressVisible: 'visible',
                        deliveryAddressVisible: 'hidden'
                        //selectedDelivery: 'collect'
                    });
                    this.DeleteDeliveryFeeOrderLine();
                    this.GetOrderList();
                    this.GetOrderTotal();
                    console.log('selectedDelivery new state : ' + this.state.selectedDelivery);
                }
                else if (this.state.collectDeliverType == 'deliver') {
                    //console.log('Deliver : ' + this.state.collectVisible);
                    //console.log('selectedDelivery : ' + this.state.selectedDelivery);
                    this.setState({
                        collectionAddressVisible: 'hidden',
                        deliveryAddressVisible: 'visible'
                        //selectedDelivery: 'deliver'
                    });
                    //add delivery fee line
                    this.CreateDeliveryFeeOrderLine();
                    //this.GetOrderList();
                    //this.GetOrderTotal();
                    console.log('selectedDelivery new state : ' + this.state.selectedDelivery);
                }
            }
        );
    }
}

//var popUp = window.open(data);

//if (popUp == null || typeof (popUp) == 'undefined') {
//    alert('Please disable your pop-up blocker or click enable pop-up, once complete click the "Place Order" button again.');
//}



//<div className="footerButtons" style={{ visibility: this.state.isAccountPaymentVisible, height: (this.state.isAccountPaymentVisible == 'visible' ? '21.6px' : '0px') }}>
//    <button id="btnBack" className="btn primary_btn row1 col1" disabled={this.state.orderButtonClicked} Width="90%" style={{ width: '90%', fontFamily: 'Tw Cen MT', marginLeft: '5%' }} onClick={() => this.GoBackToProducts()}> Return To Order</button >
//    <button id="btnSubmit" className="btn secondary_btn row1 col2" disabled={this.state.orderButtonClicked} style={{ width: '90%', fontFamily: 'Tw Cen MT' }} onClick={() => { this.PlaceOrder() }}>Place Order</button>
//</div>
//    <div className="footerButtons" style={{ visibility: this.state.isCardPaymentVisible, height: (this.state.isAccountPaymentVisible == 'visible' ? '21.6px' : '0px') }}>
//        <button id="btnBack" className="btn primary_btn row1 col1" disabled={this.state.orderButtonClicked} Width="90%" style={{ width: '90%', fontFamily: 'Tw Cen MT', marginLeft: '5%' }} onClick={() => this.GoBackToProducts()}> Return To Order</button >
//        <button id="btnSubmitPaypal" className="btn secondary_btn row1 col2" disabled={this.state.orderButtonClicked} style={{ width: '90%', fontFamily: 'Tw Cen MT' }} onClick={() => { this.PlaceOrderPaypal() }}>Place Order (Online Payment)</button>
//    </div>
//    <div className="footerButtons3" style={{ visibility: this.state.isOptionalPaymentVisible, height: (this.state.isOptionalPaymentVisible == 'visible' ? '21.6px' : '0px') }}>
//        <button id="btnBack" className="btn primary_btn row1 col1" disabled={this.state.orderButtonClicked} Width="90%" style={{ width: '90%', fontFamily: 'Tw Cen MT', marginLeft: '5%' }} onClick={() => this.GoBackToProducts()}> Return To Order</button >
//        <button id="btnSubmit" className="btn secondary_btn row1 col2" disabled={this.state.orderButtonClicked} style={{ width: '90%', fontFamily: 'Tw Cen MT' }} onClick={() => { this.PlaceOrder() }}>Place Order (Account)</button>
//        <button id="btnSubmitPaypal" className="btn secondary_btn row1 col3" disabled={this.state.orderButtonClicked} style={{ width: '90%', fontFamily: 'Tw Cen MT' }} onClick={() => { this.PlaceOrderPaypal() }}>Place Order (Online Payment)</button>
//    </div>